<template>
  <div>
    <div class="trade-show-overview" v-if="this.tradeShow">
      <b-button
        class="edit-btn"
        variant="outline-primary"
        @click="$bvModal.show('edit-trade-show-modal')"
        >Edit Trade Show</b-button
      >
      <h4>Trade Show Details</h4>
      <div class="row trade-show-summary">
        <div class="col">
          <div>
            <div class="trade-show-company">
              {{ tradeShow.company }}
            </div>
          </div>
          <div>
            {{ tradeShow.email }}<br />
            {{ tradeShow.phone }}
          </div>
        </div>
        <div class="col">
          <!-- <div>
            Status: <span v-bind:style="{}">{{ tradeShow.status }}</span>
          </div> -->
          <div class="address" v-if="tradeShow.address">
            <div class="mt-3">{{ tradeShow.address.street1 }}</div>
            <div>
              {{ tradeShow.address.city }}, {{ tradeShow.address.state }}
              {{ tradeShow.address.zip }}
            </div>
            <div v-if="tradeShow.address.county">
              {{ tradeShow.address.county }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <h4>Contacts</h4>
          <div class="mt-2" v-if="tradeShow.contacts.length == 0">
            - No Contacts -
          </div>
          <div class="row">
            <div
              class="contact-card col col-6"
              v-for="(contact, index) in tradeShow.contacts"
              :key="`contact-${index}`"
            >
              <b-card>
                {{ contact.firstName }} {{ contact.lastName }}<br />
                {{ contact.email }} <br />
                {{ contact.phone }}
              </b-card>
            </div>
          </div>
        </div>
        <div class="col">
          <h4>Locations</h4>
          <div class="mt-2" v-if="tradeShow.locations.length == 0">
            - No Locations -
          </div>
          <div class="row">
            <div
              class="location-card col col-6"
              v-for="(location, index) in tradeShow.locations"
              :key="`location-${index}`"
            >
              <b-card>
                {{ location.name }} <br />
                {{ location.address.street1 }} <br />
                {{ location.address.city }}, {{ location.address.state }}
                {{ location.address.zip }}
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <h4 class="">Schedule</h4>
          <trade-show-schedule :trade-show="tradeShow"></trade-show-schedule>
        </div>
        <div class="col">
          <h4 class="mt-5">Notes</h4>
          <trade-show-notes :trade-show="tradeShow"></trade-show-notes>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h4>Files</h4>
          <trade-show-files-display
            :trade-show="tradeShow"
          ></trade-show-files-display>
        </div>
        <div class="col">
          <h4>Photos</h4>
          <trade-show-photos-display
            :trade-show="tradeShow"
          ></trade-show-photos-display>
        </div>
      </div>
    </div>
    <b-modal
      id="edit-trade-show-modal"
      hide-footer
      size="xl"
      title="Edit Trade Show"
    >
      <trade-show-edit-page :trade-show="tradeShow"></trade-show-edit-page>
    </b-modal>
  </div>
</template>

<script>
import TradeShowNotes from '@/components/TradeShowNotes'
import TradeShowSchedule from '@/components/TradeShowSchedule'
import axios from 'axios'
import TradeShowFilesDisplay from './TradeShowFilesDisplay.vue'
import TradeShowPhotosDisplay from './TradeShowPhotosDisplay.vue'
import TradeShowEditPage from '../views/TradeShowEditPage.vue'
export default {
  name: 'TradeShowOverviewPage',
  props: {
    tradeShow: {
      type: Object,
      required: true,
    },
  },
  inject: ['userStore'],
  data: function () {
    return {
      updatingLastContacted: false,
      submitting: false,
    }
  },
  components: {
    TradeShowNotes,
    TradeShowSchedule,
    TradeShowFilesDisplay,
    TradeShowPhotosDisplay,
    TradeShowEditPage,
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.trade-show-overview {
  position: relative;
}
.trade-show-summary {
  font-size: 18px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.trade-show-name {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 22px;
}
.trade-show-since {
  font-size: 14px;
  margin-top: -6px;
}
.contact-card .card {
  background: rgb(240, 250, 255);
}

.location-card .card {
  background: rgb(240, 255, 244);
}
.list {
  //   max-height: 300px;
  //   overflow: scroll;
}
.edit-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
</style>
