<template>
  <div class="customer-building-list">
    <b-button
      class="print-summary-button"
      @click="printBuildingSummary"
      size="sm"
      variant="secondary"
      >Print Summary</b-button
    >
    <div v-if="loading">
      <CircleLoader></CircleLoader>
    </div>
    <div v-if="!loading">
      <div v-if="buildings.length">
        <b-table
          striped
          :sticky-header="stickyHeader"
          hover
          @row-clicked="goToBuilding"
          :items="buildings"
          :fields="fields"
        ></b-table>
      </div>
      <div v-if="!buildings.length">- No buildings -</div>
    </div>
    <div v-show="false" id="building-summary">
      <div v-if="buildings">
        <QuoteSummary :buildings="buildings"></QuoteSummary>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CircleLoader from '@/components/CircleLoader.vue'
import axios from 'axios'
import QuoteSummary from '../components/QuoteSummary.vue'

export default {
  name: 'CustomerBuildingList',
  components: { CircleLoader, QuoteSummary },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      buildings: null,
      loading: true,
      stickyHeader: true,
      fields: [
        {
          key: 'nickname',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'type',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'dimensions',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  computed: {},
  mounted() {
    this.getBuildings()
  },
  methods: {
    printBuildingSummary() {
      var elem = 'building-summary'
      // todo: add styles for printing..

      // Get HTML to print from element
      const prtHtml = document.getElementById(elem).innerHTML

      // Get all stylesheets HTML
      let stylesHtml = ''
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      )

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`)

      window.setTimeout(function () {
        WinPrint.focus()
        WinPrint.print()
      }, 1000)

      window.setTimeout(function () {
        WinPrint.document.close()
        WinPrint.close()
      }, 2500)
    },
    goToBuilding(building, index, e) {
      if (e.metaKey) {
        window.open('/app/pricer/' + building.shortId, '_blank')
      } else {
        this.$router.push('/app/pricer/' + building.shortId)
      }
    },
    async getBuildings() {
      try {
        let id = this.customer._id
        let response = await axios.get(
          '/services/customers/buildings?customerId=' + id,
        )

        this.buildings = response.data.buildings.map(building => {
          const {
            shortId,
            nickname,
            type,
            createdAt,
            dimensions,
            price,
            customer,
            foundation,
          } = building
          return {
            nickname,
            type,
            dimensions: `${dimensions.width}x${dimensions.length}x${dimensions.height}`,
            createdAt: this.$options.filters.shortFullDateFilter(createdAt),
            shortId,
            price,
            customer,
            kitPrice: this.$options.filters.currencyFilter(price.kit.total),
            kitAndInstallPrice: this.$options.filters.currencyFilter(
              price.kitAndInstall.total,
            ),
            concretePrice: this.$options.filters.currencyFilter(
              foundation.price.total,
            ),
          }
        })
      } catch (error) {
        console.log('error: ', error)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.print-summary-button {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
