<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <h5>Customer</h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col input-col">
              <label for="name">Customer Name</label>
              <vue-typeahead-bootstrap
                id="name"
                v-model="customer.name"
                :data="customerStore.customerNames.value"
                input-name="customerName"
                label="Customer Name"
                @hit="setCustomer"
              />
            </div>
          </div>
        </div>
        <h5 class="mt-3">Contact</h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col input-col">
              <label for="firstName">First Name</label>
              <b-input-group>
                <b-form-input
                  id="firstName"
                  v-model="customer.firstName"
                  type="text"
                  :disabled="disableInputs || submitting"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col input-col">
              <label for="lastName">Last Name</label>
              <b-input-group>
                <b-form-input
                  id="lastName"
                  v-model="customer.lastName"
                  type="text"
                  :disabled="disableInputs || submitting"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="row">
            <div class="col input-col">
              <label for="email">Email</label>
              <b-input-group>
                <b-form-input
                  id="email"
                  v-model="customer.email"
                  type="email"
                  :disabled="disableInputs || submitting"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col input-col">
              <label for="phone">Phone</label>
              <b-input-group>
                <b-form-input
                  id="phone"
                  v-model="customer.phone"
                  type="tel"
                  :disabled="disableInputs || submitting"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
        </div>
        <h5 class="mt-3">Mailing Address</h5>
        <div class="container-fluid">
          <AddressFields
            class="mt-1"
            :sending="disableInputs || submitting"
            :address="customer.address"
            :autoComplete="true"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h5>Building Site</h5>
        <div class="container-fluid">
          <div class="row">
            <div class="col input-col">
              <label for="apn">APN</label>
              <b-input-group>
                <b-form-input
                  id="apn"
                  v-model="site.apn"
                  type="text"
                  :disabled="disableInputs || submitting"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
        </div>
        <h5 class="mt-3">Site Address</h5>
        <div class="container-fluid">
          <b-form-checkbox
            id="useMailingForSiteAddress"
            v-model="useMailingForSiteAddress"
            :disabled="disableInputs || submitting"
            >Use Mailing Address</b-form-checkbox
          >

          <AddressFields
            class="mt-1"
            :sending="disableInputs || useMailingForSiteAddress"
            :address="site.address"
            :autoComplete="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressFields from '@/components/AddressFields'
import _ from 'lodash'
import axios from 'axios'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  name: 'CustomerAndSiteForm',
  components: { AddressFields, VueTypeaheadBootstrap },
  inject: ['customerStore'],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
    disableInputs: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      useMailingForSiteAddress: false,
      submitting: false,
    }
  },
  methods: {
    setCustomer() {
      this.submitting = true
      axios
        .get(
          '/services/customers/lookup?name=' +
            encodeURIComponent(this.customer.name),
        )
        .then(response => {
          if (response.data?.customers?.length) {
            let customer = response.data.customers[0]
            this.customer.email = customer.email || ''
            this.customer.phone = customer.phone || ''
            this.customer.address = customer.address
            if (customer.contacts.length) {
              this.customer.firstName = customer.contacts[0].firstName
              this.customer.lastName = customer.contacts[0].lastName
            }
          }
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {},
  watch: {
    useMailingForSiteAddress(newValue) {
      if (newValue) this.site.address = _.cloneDeep(this.customer.address)
    },
    customer: {
      handler(newVal) {
        if (this.useMailingForSiteAddress) {
          this.site.address = _.cloneDeep(newVal.address)
        }
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.input-col {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
