<template>
  <div class="text-center">
    <div class="loading mt-5">cloning building configuration ...</div>
    <div class="loading-gif mt-5">
      <circle-loader :size="40"></circle-loader>
    </div>
  </div>
</template>

<script>
import CircleLoader from '@/components/CircleLoader'
export default {
  components: { CircleLoader },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  name: 'CloneBuildingConfiguration',
  mounted() {
    window.setTimeout(this.redirectToPricer, 2000)
  },
  methods: {
    redirectToPricer() {
      this.$router.replace({
        name: 'PricerPage',
        params: {
          config: this.config,
        },
      })
    },
  },
}
</script>

<style></style>
