<template>
  <div>
    <div class="home" v-if="!buildingConfig">
      <div class="loading mt-5" v-if="!error">
        Loading...
      </div>
      <div class="error mt-5" v-if="error">
        Error Loading Building
        <br>
        <router-link to="/app/">Back to Home</router-link>
      </div>
    </div>
    <div class="home" v-if="buildingConfig">
      <BuildingPricer :config="buildingConfig"></BuildingPricer>
    </div>
  </div>
</template>

<script>

import BuildingPricer from '@/components/BuildingPricer.vue'
import axios from 'axios';

export default {
  name: 'PricerPage',
  props: {
      config: {
          type: Object,
          required: false,
      },
      id: {
          type: String,
          required: false,
      },
  },
  data: function() {
    return {
      buildingConfig: null,
      error: null,
    }
  },
  components: { BuildingPricer },
  mounted() {
    if (this.config) {
      this.buildingConfig = this.config;
    } else if (this.id) {
      axios.get('/services/buildings/' + this.id)
      .then((response) => {
        this.buildingConfig = response.data.building;
      })
      .catch((error) => { 
        this.error = true;
      })
    } else {
      this.buildingConfig = {}
    }
  }
}
</script>

<style lang="scss" scoped>

.home {
  padding-left: 6px;
  padding-right: 6px;
}

</style>