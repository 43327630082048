<template>
  <div>
    <h3>Sales</h3>
    <div class="order-sales" v-if="order">
      <div class="row">
        <div class="col col-lg-6">
          <div>
            Sold On: {{ order.workflow.sales.soldDate | dateTimeFilter }}
          </div>
          <div>Due By: {{ order.workflow.sales.dueDate | dateTimeFilter }}</div>
          <last-contacted-display
            :order="order"
            department="sales"
          ></last-contacted-display>
          <div v-if="order.productId">
            <router-link :to="productLink"
              >Building #{{ order.productId }} Pricer Link</router-link
            >
          </div>
          <div>
            <notes
              :order="order"
              department="SALES"
              :notesFilter="[
                'SALES',
                'ARCHITECTURE',
                'ENGINEERING',
                'MANUFACTURING',
              ]"
            ></notes>
            <files-display
              class="mt-4"
              :order="order"
              department="sales"
            ></files-display>
          </div>
          <br />
          // change order flow will also be handled here <br />
          // access contracts on docusign?
        </div>
        <div
          class="col col-lg-6"
          v-if="order.type === 'BUILDING' && order.productId"
        >
          <div v-if="loadingBuilding">
            <circle-loader></circle-loader>
          </div>
          <div v-if="!loadingBuilding && response">
            <building-result :response="response"> </building-result>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuildingResult from '@/components/BuildingResult'
import Notes from '@/components/Notes'
import FilesDisplay from '@/components/FilesDisplay'
import CircleLoader from '@/components/CircleLoader'
import axios from 'axios'
import LastContactedDisplay from '../components/LastContactedDisplay.vue'
export default {
  name: 'OrderSalesPage',
  props: {},
  data: function() {
    return {
      order: null,
      loadingBuilding: true,
      response: null,
    }
  },
  components: {
    BuildingResult,
    CircleLoader,
    Notes,
    FilesDisplay,
    LastContactedDisplay,
  },
  mounted() {
    this.order = this.$parent.order
    if (this.order.productId && this.order.type === 'BUILDING') {
      this.getBuilding()
    }
  },
  methods: {
    getBuilding() {
      this.loadingBuilding = true
      return axios
        .get('/services/buildings/' + this.order.productId)
        .then(response => {
          this.response = response.data.building
          console.log('this.response is: ', this.response)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loadingBuilding = false
        })
    },
  },
  computed: {
    productLink() {
      return `/app/pricer/${this.order.productId}`
    },
  },
}
</script>

<style lang="scss" scoped></style>
