<template>
  <div class="tradeshows">
    <form @submit.prevent="showConfirmCreate">
      <div class="row">
        <div class="col">
          <div class="">
            <trade-show-form-fields
              :submitting="submitting"
              :trade-show="tradeShow"
            ></trade-show-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Add Trade Show</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmAddTradeShow"
      :title="`Add Trade Show - ${tradeShow.name}`"
      hide-footer
    >
      <p>Create new trade show: {{ tradeShow.name }}?</p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmAddTradeShow')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitCreateTradeShow"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import TradeShowFormFields from '@/components/TradeShowFormFields'

export default {
  name: 'AddTradeShowsPage',
  components: { TradeShowFormFields },
  inject: {
    userStore,
  },
  props: {},
  data: function () {
    return {
      tradeShow: {
        name: '',
        email: '',
        type: null,
        address: {
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        contacts: [],
        locations: [],
      },
      submitting: false,
    }
  },
  methods: {
    clearTradeShow() {
      this.tradeShow = {
        name: '',
        email: '',
        phone: '',
        address: {
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        contacts: [],
        locations: [],
      }
    },
    showConfirmCreate() {
      this.$bvModal.show('confirmAddTradeShow')
    },
    submitCreateTradeShow() {
      this.submitting = true
      const data = _.clone(this.tradeShow)
      return axios
        .post('/services/trade-shows', data)
        .then(response => {
          this.$bvModal.hide('confirmAddTradeShow')
          this.$bvModal.hide('add-trade-show-modal')
          this.clearTradeShow()
          return this.$toasted.success('Trade Show Added', {
            duration: 4000,
          })
        })
        .catch(err => {
          return this.$toasted.error(
            'Error creating trade show: ' + err?.response?.data?.error,
            {
              duration: 4000,
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 900px;
  max-width: 90%;
}
</style>
