<template>
  <div class="vendor-profile">
    <h3>
      Vendor Profile
      <b-button
        variant="secondary"
        size="sm"
        @click="disableEdit = !disableEdit"
        ><span class="nc-icon nc-settings-tool-66"></span> edit
        profile</b-button
      >
    </h3>

    <form class="questions" v-if="vendor" @submit.prevent="updateProfile">
      <VendorProfileFormFields
        :profile="vendor.profile"
        :disabled="disabled"
      ></VendorProfileFormFields>
      <div class="mt-4">
        <b-button type="submit" variant="primary" :disabled="disabled">
          <span v-if="submitting">Updating..</span>
          <span v-if="!submitting">Update Profile</span>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import VendorProfileFormFields from '../components/VendorProfileFormFields.vue'
export default {
  name: 'VendorProfilePage',
  props: {},
  data: function () {
    return {
      vendor: null,
      disableEdit: true,
      submitting: false,
    }
  },
  components: {
    VendorProfileFormFields,
  },
  computed: {
    disabled() {
      if (this.disableEdit || this.submitting) return true
      return false
    },
  },
  methods: {
    updateProfile() {
      this.submitting = true
      const data = {
        id: this.vendor.id,
        profile: this.vendor.profile,
      }
      return axios
        .put('/services/vendors/profile', data)
        .then(response => {
          this.$toasted.success(`Profile updated`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .catch(error => {
          this.$toasted.error(`Error updating profile`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {
    this.vendor = this.$parent.vendor
    this.vendor.profile = this.vendor.profile || {}
  },
}
</script>

<style lang="scss" scoped>
.vendor-profile {
  position: relative;
}
.vendor-type {
  position: absolute;
  right: 0px;
  top: 0px;
}

.nc-icon {
  font-size: 20px;
}
</style>
