<template>
  <div>
    <div class="order-overview" v-if="this.order">
      <div class="row">
        <div class="col">
          <order-timeline
            class="timeline"
            :order="order"
            v-if="false"
          ></order-timeline>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col col-6">
          <h3>Overview</h3>
          <div class="row order-summary">
            <div class="col">
              {{ order.type }} Order #{{ order.id }}<br />
              Sold: {{ order.workflow.sales.soldDate | dateFilter }} <br />
              Due: {{ order.workflow.sales.dueDate | dateTimeFilter }}
            </div>
            <div class="col">
              {{ order.jobName }}<br />
              <router-link
                :to="{
                  path: '/app/customers/1',
                  query: { name: order.customer.name },
                }"
                >{{ order.customer.name }}</router-link
              >
              <VIPBadge v-if="order.customer.vip"></VIPBadge> <br />
              {{ order.customer.email }}<br />
              {{ order.customer.phone }}
            </div>
          </div>
          <div
            v-if="order.type == 'BUILDING' && order.workflow.install.doInstall"
            class="mt-3"
          >
            <install-estimate :order="order"></install-estimate>
          </div>
          <h3 class="mt-3">Notes</h3>
          <div class="row">
            <div class="col">
              <notes :order="order"></notes>
            </div>
          </div>
        </div>
        <div class="col col-6">
          <full-checklist :order="order" v-if="true"></full-checklist>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notes from '@/components/Notes'
import VIPBadge from '@/components/VIPBadge'
import OrderTimeline from '@/components/OrderTimeline'
import FullChecklist from '../components/FullChecklist'
import InstallEstimate from '../components/InstallEstimate.vue'

// TODO: pull in all checklists and payments/schedule at end to show chronological view

export default {
  name: 'OrderOverviewPage',
  props: {},
  data: function () {
    return {
      order: null,
    }
  },
  components: {
    Notes,
    VIPBadge,
    OrderTimeline,
    FullChecklist,
    InstallEstimate,
  },
  mounted() {
    this.order = this.$parent.order
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.order-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.timeline {
  // width: 95%;
}
</style>
