<template>
  <div class="header">
    <div class="nav">
      <div class="nav-links">
        <router-link to="/app/account"
          ><span>
            {{ userStore.user.email }}
            <span class="nc-icon nc-circle-09 settings-icon"></span> </span
        ></router-link>
        |
        <span class="logout-link" @click="logout()">Logout</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { userStore } from '@/stores/userStore'

export default {
  name: 'AppLayout',
  props: {},
  components: {},
  inject: {
    userStore,
  },
  computed: {},
  methods: {
    logout() {
      axios
        .get('/services/user/logout')
        .then(() => {
          userStore.logout()
        })
        .catch(() => {})
        .finally(() => {
          this.$router.push('/login')
        })
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
}
.nav {
  height: 24px;

  a {
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.logout-link {
  color: #2c3e50;
  cursor: pointer;
}

.logout-link:hover {
  text-decoration: underline;
}

.settings-icon {
  font-size: 20px;
  vertical-align: sub;
}
</style>
