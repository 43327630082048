<template>
  <div>
    <h3>Engineering</h3>
    <div class="order-engineering" v-if="order">
      <div class="row">
        <div class="col">
          <div class="engineering-workflow">
            <div class="engineering-info">
              <h5>Engineering Info</h5>
              <div class="row">
                <div class="col">
                  <div>County: {{ order.siteAddress.county }}</div>
                  <div>APN: {{ order.siteAddress.apn }}</div>
                </div>
                <div class="col">
                  <div>Snow Load: {{ order.siteAddress.snowLoad }}</div>
                  <div>Wind Load: {{ order.siteAddress.windLoad }}</div>
                </div>
              </div>
            </div>
            <div class="checklist mt-3">
              <h5>Checklist</h5>
              <EngineeringChecklist
                :order="order"
                workflow="engineering"
              ></EngineeringChecklist>
            </div>
            <div class="design-criteira mt-5">
              <h5>Resources</h5>
              <a
                v-if="designCriteriaLink"
                :href="designCriteriaLink"
                target="_blank"
                >Link to {{ order.siteAddress.county || 'County' }} Design
                Criteria Request Form</a
              >
            </div>
            <div class="legacy-status mt-5">
              // We can calculate start date, end date, status based on the
              state of the checklist
              <br />
              // I don't think we need this anymore, but keeping around for now
              in case it's in use.<br />
              <status-display
                class="status"
                :order="order"
                department="engineering"
              ></status-display>
              <div>
                Started Date:
                {{ order.workflow.engineering.startedDate | dateTimeFilter }}
              </div>
              <div>
                Completed Date:
                {{ order.workflow.engineering.completedDate | dateTimeFilter }}
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <notes
            class="mt-4"
            :order="order"
            department="ENGINEERING"
            :notesFilter="['ARCHITECTURE', 'ENGINEERING']"
            :showEvents="true"
          ></notes>
          <files-display
            class="mt-4"
            :order="order"
            department="engineering"
          ></files-display>
        </div>
      </div>
    </div>

    <b-modal
      size="md"
      id="requestDesignCriteria"
      title="Design Criteria"
      @ok="markDesignCriteriaRequested"
    >
      Mark design criteria as requested on
      {{ designCriteriaRequestedDate | dateFilter }}?
      <div v-if="editDate">
        <input type="date" v-model="designCriteriaRequestedDate" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import StatusDisplay from '@/components/StatusDisplay'
import FilesDisplay from '@/components/FilesDisplay'
import Notes from '@/components/Notes'
import EngineeringChecklist from '@/components/EngineeringChecklist'

export default {
  name: 'OrderEngineeringPage',
  props: {},
  data: function () {
    return {
      order: null,
      engineering: null,
      editDate: false,
      designCriteriaRequestedDate: new Date(),
    }
  },
  components: { StatusDisplay, FilesDisplay, Notes, EngineeringChecklist },
  methods: {
    markDesignCriteriaRequested() {
      alert('requeesting,.')
    },
  },
  computed: {
    county() {
      return this.order.siteAddress.county || ''
    },
    designCriteriaLink() {
      if (this.county.toLowerCase().match('shasta')) {
        return 'https://www.shastacounty.gov/sites/default/files/fileattachments/building/page/2648/building_design_criteria_request.pdf'
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.order = this.$parent.order
    this.engineering = this.$parent.order.workflow.engineering
    this.engineering.designCriteria = this.engineering.designCriteria || {}
  },
}
</script>

<style lang="scss" scoped>
.workflow-status {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
</style>
