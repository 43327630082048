<template>
  <div class="orders page-wrapper">
    <h1 class="page-h1">Create New Order</h1>
    <form @submit.prevent="showConfirmCreate">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <order-form-fields
              :submitting="submitting"
              :order="order"
              :requireSalesOrderNumber="requireSalesOrderNumber"
            ></order-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Create Order</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmCreateOrder"
      :title="`Create Order - ${order.jobName}`"
      hide-footer
    >
      <p>Create new {{ order.type }} order for {{ order.customer.name }}?</p>
      <p>
        Delivery Method: {{ order.deliveryMethod }}<br />
        Due Date: {{ this.order.workflow.sales.dueDate | dateTimeFilter }}
      </p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmCreateOrder')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitCreateOrder"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment-business-days'
import { deliveryMethods, orderTypes } from '@/components/orderInputOptions'
import OrderFormFields from '@/components/OrderFormFields'

export default {
  name: 'CreateOrdersPage',
  components: { OrderFormFields },
  inject: {
    userStore,
  },
  props: {
    building: {
      type: Object,
    },
    customer: {
      type: Object,
    },
    onSuccess: {
      type: Function,
    },
  },
  data: function () {
    return {
      requireSalesOrderNumber: true,
      order: {
        jobName: '',
        productId: '',
        fastTrack: false,
        customer: {
          name: '',
          email: '',
          phone: '',
          vip: false,
        },
        type: 'MATERIALS',
        deliveryMethod: 'PICKUP',
        siteAddress: {
          apn: '',
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        totalAmount: '',
        workflow: {
          sales: {
            soldDate: new Date(),
            dueDate: null,
          },
          install: {
            doInstall: false,
            doConcrete: false,
          },
          permitting: {
            doPermitting: false,
          },
          architecture: {
            doArchitecture: false,
          },
          engineering: {
            doEngineering: false,
          },
        },
      },
      orderTypes,
      deliveryMethods,
      submitting: false,
    }
  },
  methods: {
    clearOrder() {
      this.order = {
        jobName: '',
        salesOrderNumber: '',
        fastTrack: false,
        customer: {
          name: '',
          email: '',
          phone: '',
          vip: false,
        },
        type: 'MATERIALS',
        deliveryMethod: 'PICKUP',
        siteAddress: {
          apn: '',
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        workflow: {
          sales: {
            soldDate: new Date(),
            dueDate: null,
          },
          install: {
            doInstall: false,
            doConcrete: false,
          },
          permitting: {
            doPermitting: false,
          },
          architecture: {
            doArchitecture: false,
          },
          engineering: {
            doEngineering: false,
          },
        },
      }
    },
    showConfirmCreate() {
      if (typeof this.order.workflow.sales.soldDate === 'string') {
        this.order.workflow.sales.soldDate = moment(
          this.order.workflow.sales.soldDate,
          'YYYY-MM-DD',
        ).set('hours', moment().hours())._d
      }
      this.$bvModal.show('confirmCreateOrder')
    },
    submitCreateOrder() {
      this.submitting = true
      const data = _.clone(this.order)
      return axios
        .post('/services/orders', data)
        .then(response => {
          this.$bvModal.hide('confirmCreateOrder')
          this.clearOrder()
          this.$toasted.success(
            'Order Created for ' + response.data.order.jobName,
            {
              action: {
                text: 'Go To Order ->',
                onClick: (e, toastObject) => {
                  window.open('/app/orders/' + response.data.order.id, '_blank')
                },
              },
              duration: 8000,
            },
          )
          if (this.onSuccess) this.onSuccess(response.data.order)
        })
        .catch(err => {
          return this.$toasted.error(
            'Error creating order: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {
    if (this.building) {
      this.requireSalesOrderNumber = false
      // when coming from building pricer, there is no sales order number yet
      this.order.type = 'BUILDING'
      this.order.deliveryMethod = 'DELIVERY'
      this.order.customer.email = this.building.customer.email
      this.order.customer.phone = this.building.customer.phone
      this.order.siteAddress = this.building.site.address
      this.order.customer.name = this.building.customer.name
      if (this.building.install.doInstall) {
        this.order.totalAmount = this.building.price.kitAndInstall.total
      } else {
        this.order.totalAmount = this.building.price.kit.total
      }
      if (this.building.foundation.doConcrete) {
        this.order.totalAmount += this.building.foundation.price.total
      }
      this.order.productId = this.building.shortId
      this.order.workflow.install.doInstall = this.building.install.doInstall
      this.order.workflow.install.doConcrete =
        this.building.foundation.doConcrete
      this.order.workflow.architecture.doArchitecture =
        this.building.architecture
      this.order.workflow.engineering.doEngineering = this.building.engineering
      // permitting is required for installs, and not tracked in building pricer
      this.order.workflow.permitting.doPermitting =
        this.building.install.doInstall
    }
    if (this.customer) {
      this.order.customer.name = this.customer.name
      this.order.customer.phone = this.customer.phone
      this.order.customer.email = this.customer.email
    }
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 600px;
  max-width: 90%;
}
</style>
