<template>
  <div class="files-wrapper">
    <div class="files-list">
      <table class="files-table">
        <thead>
          <td>File</td>
        </thead>
        <tbody>
          <tr v-for="(file, index) in vendor.files" :key="index">
            <td class="file-row">
              <div class="file-name-wrapper">
                <span @click="downloadFile(file)">
                  <file-icon :file="file" class="file-icon"></file-icon>
                </span>
                <span class="file-name" @click="downloadFile(file)">{{
                  last(file.name.split('/'))
                }}</span>
              </div>
              <span
                class="nc-icon nc-simple-remove"
                @click="confirmRemoveFile(file)"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="remove-file-modal" size="md" hide-header hide-footer>
      <div v-if="currentFile">
        <h5>Are you sure you want to delete this file?</h5>
        <p>
          <file-icon :file="currentFile" class="file-icon"></file-icon>
          {{ last(currentFile.name.split('/')) }}
        </p>
        <b-button @click="cancelRemoveFile()" :disabled="submittingDelete"
          >Cancel</b-button
        >
        <b-button
          class="mx-2"
          @click="removeFile(currentFile)"
          variant="danger"
          :disabled="submittingDelete"
        >
          <span v-if="!submittingDelete">Delete File</span>
          <span v-if="submittingDelete">Deleting..</span></b-button
        >
      </div>
    </b-modal>
    <div class="add-file-form">
      <b-form @submit.prevent="addFile()">
        <div id="new-file" class="mt-2">
          <b-form-file
            v-model="newFile"
            :state="Boolean(newFile)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            :required="true"
          ></b-form-file>
        </div>
        <div id="ctas">
          <div class="ctas text-right mt-2">
            <b-button
              variant="primary"
              size="sm"
              type="submit"
              :disabled="submitting"
            >
              <span v-show="submitting">Submitting</span>
              <span v-show="!submitting">Add File</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import FileIcon from './FileIcon.vue'
export default {
  components: { FileIcon },
  name: 'VendorFilesDisplay',
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      newFile: null,
      submitting: false,
      submittingDelete: false,
      signedUploadURL: '',
      currentFile: null,
      last: _.last,
    }
  },
  methods: {
    getFileType(file) {
      let fileType = _.last(file.name.split('.'))
      let supportedTypes = [
        'pdf',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'png',
        'jpg',
        'jpeg',
        'gif',
        'zip',
      ]
      if (!supportedTypes.includes(fileType)) {
        fileType = 'file'
      }
      if (fileType === 'xlsx') fileType = 'xls'
      if (fileType === 'docx') fileType = 'doc'
      return fileType
    },
    confirmRemoveFile(file) {
      this.currentFile = file
      this.$bvModal.show('remove-file-modal')
    },
    cancelRemoveFile() {
      this.$bvModal.hide('remove-file-modal')
      this.currentFile = null
    },
    async removeFile(file) {
      const data = {
        id: this.vendor.id,
        file,
      }
      this.submittingDelete = true
      try {
        const response = await axios.put('/services/vendors/delete-file', data)
        _.remove(this.vendor.files, f => {
          return f.name === file.name
        })

        this.cancelRemoveFile()
        this.$toasted.success(`File Removed`, {
          duration: 2500,
        })
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error removing file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submittingDelete = false
    },
    async downloadFile(file) {
      const data = {
        id: this.vendor.id,
        file,
      }
      try {
        const response = await axios.put(
          '/services/vendors/file-download-url',
          data,
        )
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.download = _.last(file.name.split('/'))
        a.href = response.data.signedDownloadURL
        a.target = '_blank'
        a.click()
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error downloading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async saveFile(file) {
      const data = {
        id: this.vendor.id,
        file,
      }
      try {
        await axios.put('/services/vendors/save-file', data)
        this.vendor.files.push(file)
        this.$toasted.success(`File Added`, {
          duration: 2500,
        })
        this.newFile = null
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async addFile() {
      if (!this.newFile) return
      const data = {
        id: this.vendor.id,
        fileName: this.newFile.name,
        contentType: this.newFile.type,
      }
      this.submitting = true
      try {
        const response = await axios.put(
          '/services/vendors/file-upload-url',
          data,
        )
        await axios.put(response.data.signedUploadURL, this.newFile, {
          headers: {
            'Content-Type': this.newFile.type,
          },
        })
        return this.saveFile(response.data.file)
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async onFileSelected(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.newFileUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.newFile = files[0]
    },
  },
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-radius: 6px;
}
thead {
  font-weight: bold;
  font-size: 16px;
}
td {
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 2px;
}
.nc-icon {
  font-size: 20px;
  font-weight: bold;
}

.file-row {
  position: relative;
  height: 24px;
  line-height: 24px;
}
.file-name-wrapper {
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.file-name {
  cursor: pointer;
}

.nc-simple-remove,
.nc-cloud-download-93 {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  vertical-align: middle;
}

.nc-cloud-download-93 {
  padding-right: 4px;
  padding-left: 2px;
}

.nc-simple-remove {
  position: absolute;
  right: 4px;
  top: 4px;
}
.file-icon {
  display: inline-block;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
