import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Toasted from 'vue-toasted'
import _ from 'lodash'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import CircleLoader from '@/components/CircleLoader'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Toasted)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('CircleLoader', CircleLoader)

Vue.config.productionTip = false

var currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

var percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

var dateFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
})

var yearFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
})

var shortDateFormatter = new Intl.DateTimeFormat('en', {
  month: 'numeric',
  day: '2-digit',
})
var shortFullDateFormatter = new Intl.DateTimeFormat('en', {
  month: 'numeric',
  day: '2-digit',
  year: '2-digit',
})

var dateTimeFormatter = new Intl.DateTimeFormat('en', {
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
})

var shortDateTimeFormatter = new Intl.DateTimeFormat('en', {
  month: 'numeric',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
})

Vue.filter('capitalize', function (string) {
  return _.capitalize(string)
})

Vue.filter('daysAgo', function (date) {
  if (!date) return 'not contacted'
  const now = moment()
  const lastContacted = moment(date)
  const days = now.diff(lastContacted, 'days')
  if (now.isSame(lastContacted, 'day')) {
    return 'today'
  } else if (days < 0) {
    return `in ${days * -1} days`
  } else if (days === 0) {
    return 'tomorrow'
  } else if (days === 1) {
    return 'yesterday'
  } else {
    return `${days} days ago`
  }
})

Vue.filter('daysAgoWithTime', function (date) {
  if (!date) return 'not contacted'
  const now = moment()
  const lastContacted = moment(date)
  const days = now.diff(lastContacted, 'days')
  const time = lastContacted.format('hh:mmA')
  if (days === 0) {
    return `today ${time}`
  } else if (days === 1) {
    return `yesterday ${time}`
  } else {
    return `${days} days ago ${time}`
  }
})

Vue.filter('dateFilter', function (value) {
  if (!value) return ''
  if (typeof value === 'string') value = new Date(value)
  return dateFormatter.format(value)
})

Vue.filter('yearFilter', function (value) {
  if (!value) return ''
  if (typeof value === 'string') value = new Date(value)
  return yearFormatter.format(value)
})

Vue.filter('shortDateFilter', function (value) {
  if (!value) return ''
  if (typeof value === 'string') value = new Date(value)
  return shortDateFormatter.format(value)
})

Vue.filter('shortFullDateFilter', function (value) {
  if (!value) return ''
  if (typeof value === 'string') value = new Date(value)
  return shortFullDateFormatter.format(value)
})

Vue.filter('shortDateTimeFilter', function (value) {
  if (!value) return ''
  if (typeof value === 'string') value = new Date(value)
  return shortDateTimeFormatter.format(value)
})

Vue.filter('dateTimeFilter', function (value) {
  if (!value) return ''
  if (typeof value === 'string') value = new Date(value)
  return dateTimeFormatter.format(value)
})

Vue.filter('currencyFilter', function (value) {
  if (!value && value !== 0) return ''
  return currencyFormatter.format(value)
})

Vue.filter('percentFilter', function (value) {
  if (!value && value !== 0) return ''
  return percentFormatter.format(value)
})

Vue.filter('shortTimeFilter', function (value) {
  if (!value) return ''
  let hour = parseInt(value.split(':')[0])
  let minutes = value.split(':')[1]
  if (hour < 12) {
    return hour + ':' + minutes + ' AM'
  } else if (hour === 12) {
    return hour + ':' + minutes + ' PM'
  } else {
    return hour - 12 + ':' + minutes + ' PM'
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
