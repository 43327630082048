var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status-wrapper row"},[_c('div',{staticClass:"status-display col"},[_vm._v(" Status: "),_c('span',{staticClass:"status",class:{
        open: _vm.status === 'OPEN',
        'in-progress': _vm.status === 'IN-PROGRESS',
        ready: _vm.status === 'READY',
        cancelled: _vm.status === 'CANCELLED',
        completed: _vm.status === 'COMPLETED',
        'on-hold': _vm.status === 'ON-HOLD',
      }},[_vm._v(_vm._s(_vm.status))]),(_vm.order.workflow[this.department].status === 'ON-HOLD')?_c('span',{staticClass:"order-info"},[_c('span',{staticClass:"hold-reason"},[_c('br'),_vm._v("("+_vm._s(_vm.order.workflow[this.department].holdReason)+")")])]):_vm._e()]),(_vm.showForm)?_c('div',{staticClass:"update-status-form col"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateOrderStatus()}}},[_c('div',{attrs:{"id":"new-status"}},[_c('label',{attrs:{"for":"status"}},[_vm._v("New Status")]),_c('b-form-select',{attrs:{"id":"status","options":_vm.orderStatuses,"required":"","disabled":_vm.submitting},model:{value:(_vm.newStatus),callback:function ($$v) {_vm.newStatus=$$v},expression:"newStatus"}}),(_vm.newHoldReason == 'Other' && _vm.newStatus == 'ON-HOLD')?_c('b-form-input',{staticClass:"mt-2",attrs:{"required":true,"placeholder":"Enter Reason"},model:{value:(_vm.customHoldReason),callback:function ($$v) {_vm.customHoldReason=$$v},expression:"customHoldReason"}}):_vm._e()],1),_c('div',{attrs:{"id":"ctas"}},[_c('div',{staticClass:"ctas text-right mt-2"},[_c('b-button',{staticClass:"mx-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.submitting}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitting),expression:"submitting"}]},[_vm._v("Submitting")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.submitting),expression:"!submitting"}]},[_vm._v("Update Status")])])],1)])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }