<template>
  <div>
    <div class="my-tasks" v-if="this.currentTasks">
      <div class="mt-4">
        <h3>Sales Tasks</h3>
        <task-list :tasks="tasks"></task-list>
        <div v-if="false">
          <h3>Approval Requests</h3>
          <approval-request-list
            :requests="approvalRequests"
          ></approval-request-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskList from '../components/TaskList.vue'
export default {
  name: 'SalesTasksPage',
  props: {},
  data: function () {
    return {
      currentTasks: null,
    }
  },
  components: { TaskList },
  mounted() {
    this.currentTasks = this.$parent.currentTasks
  },
  methods: {},
  computed: {
    tasks() {
      return this.currentTasks.filter(task => {
        return task.department === 'SALES'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.order-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.timeline {
  // width: 95%;
}
</style>

//// todo: 1. add tasks list component and show on differnt screens 2. add table
of # of tasks due today on dashboard 3. maybe add 1 example of a task that can
be blocked? 4. consider doing a flow for materials first since it's wayh simpler
5. mock out manager override modal 6. alert on dashboard if current user has
tasks or overrides due now
