<template>
  <div class="orders page-wrapper">
    <h1 class="page-h1">Import Materials Orders</h1>
    <p>Upload orders from Quickbooks Sales Report</p>
    <form @submit.prevent="submitUpload">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              :required="true"
            ></b-form-file>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary" :disabled="submitting">
              <span v-if="submitting">Submitting</span>
              <span v-if="!submitting">Import Orders</span>
            </b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment-business-days'
import { deliveryMethods, orderTypes } from '@/components/orderInputOptions'
import Papa from 'papaparse'

export default {
  name: 'ImportOrdersPage',
  components: {},
  inject: {
    userStore,
  },
  data: function() {
    return {
      orderTypes,
      deliveryMethods,
      submitting: false,
      file: null,
    }
  },
  methods: {
    clearUpload() {
      this.file = null
    },
    parseOrders() {
      return new Promise((resolve, reject) => {
        Papa.parse(this.file, {
          header: true,
          complete: function(results) {
            return resolve(results)
          },
        })
      })
    },
    async submitUpload() {
      const orders = await this.parseOrders()
      console.log('orders: ', orders)
      this.submitting = true
      const data = { orders: orders.data }
      return axios
        .post('/services/orders/bulk', data)
        .then(response => {
          return this.$toasted.success(
            `${response.data.orders.length} orders imported`,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .catch(err => {
          console.log('err: ', err)
          return this.$toasted.error(
            'Error creating order: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 400px;
  max-width: 90%;
}

.custom-file-input {
  cursor: pointerf;
}
</style>
