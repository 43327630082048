<template>
  <div class="reprice-memo text-center">
    <div class="letter-header">
    <img alt="MD logo" src="../assets/md-logo.png">
    <div class="address">
        LIC # 977510 <br>
        6771 Eastside Rd Redding, CA 96001<br>
        530-605-1931<br>
    </div>
    </div>
    <div class="page-content">
        <div class="letter-intro mt-1">
            <div class="memo-title">MEMORANDUM FOR RECORD</div>
            <div class="memo-subtitle mt-4">Materials Cost Increase</div>
            <div class="intro-msg">
                <p class="mt-3">
                    1. This letter is to serve as an addendum to the 
                    Metals Direct Inc Sales Contract dated 
                    <span class="underline">{{(building.soldDate || building.lastUpdatedDate) | dateFilter}}</span>.
                </p>
                <p class="mt-4">
                    2. Metals Direct Inc takes great pride in the ability to provide the highest
                    quality products for the most affordable price point to you our customers.
                    Unfortunately, after 20+ years of experience in this industry we are
                    experiencing market fluctuations that have never been seen before. Metals
                    Direct Inc as a company will make every conceivable attempt to keep a
                    building priced as affordable as possible, however there are aspects that
                    are outside our realm of control.
                </p>
                <p class="mt-4">
                    3. Due to aforementioned criteria, Metals Direct Inc, retains the right to
                    reprice contracted buildings. This is only done in the event of another
                    unforeseeable price hike in raw material cost.
                </p>
                <p class="mt-4">
                    4. By signing this letter head the customer agrees to outlined policy.
                </p>
            </div>
        </div>
        <div class="row mt-5 text-left">
            <div class="col mt-3">
                <div class="label">Customer Name</div>
                <div class="label mt-5">Signature</div>
            </div>
            <div class="col mt-3">
                <div class="label">Date</div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>

export default {
    name: 'RepriceMemo',
    props: {
        building: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {

        }
    },
    computed: {
        customerName() {
            if (this.building.customer.lastName) {
                return this.building.customer.firstName + ' ' + this.building.customer.lastName;
            } else {
                return this.building.customer.email;
            }  
        },
    }
}
</script>

<style scoped>
.letter-intro {
    position: relative;
    padding-top: 24px;
    text-align: left;
}

.memo-title {
    font-weight: 500;
}

.memo-subtitle {
    font-weight: 500;
}

.date {
    position: absolute;
    right: 0;
}

.intro-msg {
    text-align: left;
    font-size: 14px;
}

.letter-content {
    text-align: left;
    font-size: 14px;
}

.label {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}
@media print {
    .reprice-memo {
        padding: 36px 60px 60px 30px;
    }

    .page-content {
        font-size: 22px;
        max-width: 80%;
        margin: auto;
        margin-top: 48px;
    }
}
</style>