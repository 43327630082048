<template>
  <div>
    <h3>Architecture</h3>
    <div class="order-archictecture" v-if="order">
      <div class="row">
        <div class="col">
          <div>
            <status-display
              class="status"
              :order="order"
              department="architecture"
            ></status-display>
          </div>
          <div>
            Started Date:
            {{ order.workflow.architecture.startedDate | dateTimeFilter }}
          </div>
          <div>
            Completed Date:
            {{ order.workflow.architecture.completedDate | dateTimeFilter }}
          </div>
          <notes
            :order="order"
            class="mt-4"
            department="ARCHITECTURE"
            :notesFilter="['ARCHITECTURE', 'ENGINEERING']"
            :showEvents="true"
          ></notes>
        </div>
        <div class="col">
          <files-display
            :order="order"
            department="architecture"
          ></files-display>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusDisplay from '@/components/StatusDisplay'
import FilesDisplay from '@/components/FilesDisplay'
import Notes from '@/components/Notes'
export default {
  name: 'OrderArchitecturePage',
  props: {},
  data: function() {
    return {
      order: null,
    }
  },
  components: { StatusDisplay, FilesDisplay, Notes },
  mounted() {
    this.order = this.$parent.order
  },
}
</script>

<style lang="scss" scoped></style>
