<template>
  <span v-b-tooltip.hover :title="text" class="help-icon">?</span>
</template>

<script>
export default {
  name: 'HelpIcon',
  props: {
    text: {
      type: String,
      required: false,
    },
  },
}
</script>

<style scoped>
.help-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background: lightgray;
  color: darkslategray;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin: 0px 3px;
  vertical-align: text-top;
  font-weight: bold;
}
</style>
