<template>
  <div>
    <div class="my-tasks" v-if="this.currentTasks">
      <div class="mt-4">
        <h3>My Tasks</h3>

        <task-list
          :tasks="myTasks"
          :optionalFields="{ department: true }"
        ></task-list>
        <h3 class="mt-4">Tasks Assigned By Me</h3>
        <task-list
          :tasks="assignedByMeTasks"
          :optionalFields="{ edit: true, assignedTo: true, department: true }"
        ></task-list>
        <div v-if="false">
          <h3>Approval Requests</h3>
          <approval-request-list
            :requests="approvalRequests"
          ></approval-request-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApprovalRequestList from '../components/ApprovalRequestList.vue'
import TaskList from '../components/TaskList.vue'
export default {
  name: 'MyTasksPage',
  props: {},
  inject: ['userStore', 'taskStore'],
  data: function () {
    return {
      showCompleted: true,
      showOpen: true,
      approvalRequests: [
        {
          customerName: 'JEF Construction',
          jobName: '100x100 Shoppe',
          taskName: 'Start Manufacturing',
          status: 'OPEN',
          requestedBy: 'kaz@metalsdirect.com',
          assignedTo: 'mark@metalsdirect.com',
          dueDate: 'TODAY',
          details:
            'Permitting just needs one trivial revision, would like to start manufacturing this today because we have 10 big orders later this week.',
        },
      ],
      currentTasks: null,
    }
  },
  components: { TaskList, ApprovalRequestList },
  mounted() {
    this.currentTasks = this.$parent.currentTasks
  },
  methods: {},
  computed: {
    myTasks() {
      // eslint-disable-next-line
      this.taskStore.state.count
      return this.currentTasks.filter(task => {
        return task.assignedTo == this.userStore.user.email
      })
    },
    assignedByMeTasks() {
      // eslint-disable-next-line
      this.taskStore.state.count
      return this.currentTasks.filter(task => {
        return task.assignedBy == this.userStore.user.email
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.order-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.timeline {
  // width: 95%;
}

.checkbox {
  display: inline-block;
}
</style>

<br />
