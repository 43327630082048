<template>
  <div>
    <h4>Files</h4>
    <div v-if="vendor">
      <VendorFilesDisplay :vendor="vendor"></VendorFilesDisplay>
    </div>
    <h4>Photos</h4>
    <div v-if="vendor">
      <VendorPhotosDisplay :vendor="vendor"></VendorPhotosDisplay>
    </div>
  </div>
</template>

<script>
import VendorFilesDisplay from '../components/VendorFilesDisplay.vue'
import VendorPhotosDisplay from '../components/VendorPhotosDisplay.vue'
export default {
  name: 'VendorFilesPage',
  props: {},
  data: function () {
    return {
      vendor: null,
    }
  },
  components: { VendorFilesDisplay, VendorPhotosDisplay },
  mounted() {
    this.vendor = this.$parent.vendor
  },
}
</script>

<style lang="scss" scoped></style>
