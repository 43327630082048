<template>
  <div class="table-wrapper">
    <div class="filter mb-1" v-if="showFilter">
      <b-checkbox
        v-for="(value, key) in statusFilter"
        :key="key"
        v-model="statusFilter[key]"
        class="filter-btn"
      >
        {{ key }}
      </b-checkbox>
      <div class="legend">
        <span class="legend-item">
          <span class="legend-item-icon past-due">&#x25A0;</span>
          Past Due
        </span>
        <span class="legend-item">
          <span class="legend-item-icon important">&#x25A0;</span>
          Important
        </span>
      </div>
    </div>
    <b-table
      striped
      hover
      :items="taskList"
      :fields="fields"
      :tbody-tr-class="{
        'clickable-row': true,
      }"
      @row-clicked="openTask"
    >
      <template #cell(dueDate)="row">
        {{ row.item.dueDate | dateTimeFilter }}
      </template>
      <template #cell(edit)="row">
        <div>
          <b-button variant="outline-primary" size="sm" @click="editTask(row)"
            ><span class="nc-icon nc-settings-tool-66"></span> edit</b-button
          >
        </div>
      </template>
    </b-table>
    <b-modal
      :id="`edit-task-modal-${id}`"
      title="Edit Task"
      hide-footer
      size="lg"
    >
      <edit-task :ogTask="currentTask" :modalId="id.toString()"></edit-task>
    </b-modal>
    <b-modal
      :id="`task-detail-modal-${id}`"
      :title="currentTask && `Task - ${currentTask.name}`"
      hide-footer
      size="xl"
    >
      <task-detail :ogTask="currentTask" :modalId="id.toString()"></task-detail>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import EditTask from './EditTask'
import TaskDetail from './TaskDetail'
import moment from 'moment'

// QUESTION: Should this go to the order section to complete the task?
// or allow you to pull up the task completion modal right here?

export default {
  name: 'TaskList',
  components: { EditTask, TaskDetail },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    optionalFields: {
      type: Object,
      default: function () {
        return {}
      },
    },
    hiddenFields: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    const fields = [
      {
        key: 'name',
        sortable: true,
      },
      {
        key: 'status',
        sortable: true,
      },
      {
        key: 'customerName',
        sortable: true,
      },
      {
        key: 'jobName',
        sortable: true,
      },
      {
        key: 'dueDate',
        sortable: true,
      },
    ]
    if (this.optionalFields.customerName) {
      fields.push({
        key: 'assignedTo',
        sortable: true,
      })
    }
    if (this.optionalFields.assignedTo) {
      fields.push({
        key: 'assignedTo',
        sortable: true,
      })
    }
    if (this.optionalFields.department) {
      fields.push({
        key: 'department',
      })
    }
    if (this.optionalFields.edit) {
      fields.push({
        key: 'edit',
      })
    }

    _.each(this.hiddenFields, fieldName => {
      _.remove(fields, field => {
        return field.key === fieldName
      })
    })

    return {
      id: id,
      currentTask: null,
      fields,
      statusFilter: {
        COMPLETED: true,
        SKIPPED: true,
        OPEN: true,
        BLOCKED: true,
        READY: true,
        'IN-PROGRESS': true,
      },
    }
  },
  mounted() {},
  computed: {
    taskList() {
      let today = moment()
      // filter out tasks
      let tasks = this.tasks.filter(task => {
        if (task.status == 'COMPLETED' || task.status == 'SKIPPED') {
          return false
        } else {
          return this.statusFilter[task.status]
        }
      })

      // add metadata for styling  tasks
      _.each(tasks, task => {
        if (moment(task.dueDate).isBefore(today, 'day')) {
          task._rowVariant = 'danger'
        }
        if (task.important) {
          task._rowVariant = 'info'
        }
      })
      tasks = tasks.sort(function compare(a, b) {
        var dateA = new Date(a.dueDate)
        var dateB = new Date(b.dueDate)
        return dateA - dateB
      })

      tasks = tasks.sort(function compare(a, b) {
        return b.important - a.important
      })

      let completedTasks = this.tasks.filter(task => {
        if (task.status == 'COMPLETED' || task.status == 'SKIPPED') {
          return this.statusFilter[task.status]
        } else {
          return false
        }
      })

      completedTasks = completedTasks.sort(function compare(a, b) {
        var dateA = new Date(a.dueDate)
        var dateB = new Date(b.dueDate)
        return dateA - dateB
      })
      // todo: consider moving completed tasks to different table or section
      return [...tasks, ...completedTasks]
    },
  },
  methods: {
    openTask(task, index, e) {
      this.currentTask = task
      this.$bvModal.show(`task-detail-modal-${this.id}`)
    },
    editTask(row) {
      this.currentTask = row.item
      this.$bvModal.show(`edit-task-modal-${this.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
}

.filter-btn {
  display: inline-block;
  margin-right: 12px;
}

.legend {
  display: inline-block;
  margin-left: 32px;
}

.legend-item-icon {
  display: inline-block;
  font-size: 30px;
  color: black;
  margin-right: 2px;
  line-height: 14px;

  &.past-due {
    color: #f5c6cb;
  }
  &.important {
    color: #bee5eb;
  }
}
</style>

<style>
.clickable-row {
  cursor: pointer;
}
.important-row {
  background-color: red;
}
</style>
