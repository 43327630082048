var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Permitting")]),(_vm.order)?_c('div',{staticClass:"order-permitting"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"permitting-workflow"},[_c('div',{staticClass:"permitting-info"},[_c('h5',[_vm._v("Permitting Info")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',[_vm._v("County: "+_vm._s(_vm.order.siteAddress.county))]),_c('div',[_vm._v("APN: "+_vm._s(_vm.order.siteAddress.apn))])]),_c('div',{staticClass:"col"},[_vm._v(" Permit to be submitted by: "),_c('strong',[_vm._v(" "+_vm._s(_vm.order.workflow.permitting.customerHandlingPermit ? 'Customer' : 'Metals Direct')+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.changePermitHandling(
                      !_vm.order.workflow.permitting.customerHandlingPermit
                    )}}},[_vm._v(" Change to "+_vm._s(_vm.order.workflow.permitting.customerHandlingPermit ? 'Metals Direct' : 'Customer')+" ")])],1)])]),_c('div',{staticClass:"checklist mt-3"},[_c('h5',[_vm._v("Checklist")]),_c('permitting-checklist',{attrs:{"order":_vm.order}})],1)]),_c('div',{staticClass:"legacy-status mt-5"},[_vm._v(" // We can calculate start date, end date, status based on the state of the checklist "),_c('br'),_vm._v(" // I don't think we need this anymore, but keeping around for now in case it's in use."),_c('br'),_c('status-display',{staticClass:"status",attrs:{"order":_vm.order,"department":"permitting"}}),_c('div',[_vm._v(" Started Date: "+_vm._s(_vm._f("dateTimeFilter")(_vm.order.workflow.permitting.startedDate))+" ")]),_c('div',[_vm._v(" Completed Date: "+_vm._s(_vm._f("dateTimeFilter")(_vm.order.workflow.permitting.completedDate))+" ")])],1)]),_c('div',{staticClass:"col"},[_c('notes',{staticClass:"mt-4",attrs:{"order":_vm.order,"department":"PERMITTING","notesFilter":[
            'ARCHITECTURE',
            'ENGINEERING',
            'SALES',
            'ADMIN',
            'PERMITTING' ],"showEvents":true}}),_c('files-display',{attrs:{"order":_vm.order,"department":"permitting"}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }