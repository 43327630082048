<template>
  <div class="icons">
    <span class="nc-icon nc-air-baloon"></span>
    <span class="nc-icon nc-album-2"></span>
    <span class="nc-icon nc-alien-33"></span>
    <span class="nc-icon nc-align-center"></span>
    <span class="nc-icon nc-align-left-2"></span>
    <span class="nc-icon nc-ambulance"></span>
    <span class="nc-icon nc-android"></span>
    <span class="nc-icon nc-app"></span>
    <span class="nc-icon nc-apple"></span>
    <span class="nc-icon nc-atom"></span>
    <span class="nc-icon nc-attach-87"></span>
    <span class="nc-icon nc-audio-92"></span>
    <span class="nc-icon nc-backpack"></span>
    <span class="nc-icon nc-badge"></span>
    <span class="nc-icon nc-bag"></span>
    <span class="nc-icon nc-bank"></span>
    <span class="nc-icon nc-battery-81"></span>
    <span class="nc-icon nc-bell-55"></span>
    <span class="nc-icon nc-bold"></span>
    <span class="nc-icon nc-bulb-63"></span>
    <span class="nc-icon nc-bullet-list-67"></span>
    <span class="nc-icon nc-bus-front-12"></span>
    <span class="nc-icon nc-button-pause"></span>
    <span class="nc-icon nc-button-play"></span>
    <span class="nc-icon nc-button-power"></span>
    <span class="nc-icon nc-camera-20"></span>
    <span class="nc-icon nc-caps-small"></span>
    <span class="nc-icon nc-cart-simple"></span>
    <span class="nc-icon nc-cctv"></span>
    <span class="nc-icon nc-chart-bar-32"></span>
    <span class="nc-icon nc-chart-pie-35"></span>
    <span class="nc-icon nc-chart-pie-36"></span>
    <span class="nc-icon nc-chart"></span>
    <span class="nc-icon nc-chat-round"></span>
    <span class="nc-icon nc-check-2"></span>
    <span class="nc-icon nc-circle-09"></span>
    <span class="nc-icon nc-circle"></span>
    <span class="nc-icon nc-cloud-download-93"></span>
    <span class="nc-icon nc-cloud-upload-94"></span>
    <span class="nc-icon nc-compass-05"></span>
    <span class="nc-icon nc-controller-modern"></span>
    <span class="nc-icon nc-credit-card"></span>
    <span class="nc-icon nc-delivery-fast"></span>
    <span class="nc-icon nc-email-83"></span>
    <span class="nc-icon nc-email-85"></span>
    <span class="nc-icon nc-explore-2"></span>
    <span class="nc-icon nc-fav-remove"></span>
    <span class="nc-icon nc-favourite-28"></span>
    <span class="nc-icon nc-globe-2"></span>
    <span class="nc-icon nc-grid-45"></span>
    <span class="nc-icon nc-headphones-2"></span>
    <span class="nc-icon nc-html5"></span>
    <span class="nc-icon nc-istanbul"></span>
    <span class="nc-icon nc-key-25"></span>
    <span class="nc-icon nc-layers-3"></span>
    <span class="nc-icon nc-light-3"></span>
    <span class="nc-icon nc-lock-circle-open"></span>
    <span class="nc-icon nc-map-big"></span>
    <span class="nc-icon nc-mobile"></span>
    <span class="nc-icon nc-money-coins"></span>
    <span class="nc-icon nc-note-03"></span>
    <span class="nc-icon nc-notes"></span>
    <span class="nc-icon nc-notification-70"></span>
    <span class="nc-icon nc-palette"></span>
    <span class="nc-icon nc-paper-2"></span>
    <span class="nc-icon nc-pin-3"></span>
    <span class="nc-icon nc-planet"></span>
    <span class="nc-icon nc-preferences-circle-rotate"></span>
    <span class="nc-icon nc-puzzle-10"></span>
    <span class="nc-icon nc-quote"></span>
    <span class="nc-icon nc-refresh-02"></span>
    <span class="nc-icon nc-ruler-pencil"></span>
    <span class="nc-icon nc-satisfied"></span>
    <span class="nc-icon nc-scissors"></span>
    <span class="nc-icon nc-send"></span>
    <span class="nc-icon nc-settings-90"></span>
    <span class="nc-icon nc-settings-gear-64"></span>
    <span class="nc-icon nc-settings-tool-66"></span>
    <span class="nc-icon nc-simple-add"></span>
    <span class="nc-icon nc-simple-delete"></span>
    <span class="nc-icon nc-simple-remove"></span>
    <span class="nc-icon nc-single-02"></span>
    <span class="nc-icon nc-single-copy-04"></span>
    <span class="nc-icon nc-spaceship"></span>
    <span class="nc-icon nc-square-pin"></span>
    <span class="nc-icon nc-stre-down"></span>
    <span class="nc-icon nc-stre-left"></span>
    <span class="nc-icon nc-stre-right"></span>
    <span class="nc-icon nc-stre-up"></span>
    <span class="nc-icon nc-sun-fog-29"></span>
    <span class="nc-icon nc-support-17"></span>
    <span class="nc-icon nc-tablet-2"></span>
    <span class="nc-icon nc-tag-content"></span>
    <span class="nc-icon nc-tap-01"></span>
    <span class="nc-icon nc-time-alarm"></span>
    <span class="nc-icon nc-tv-2"></span>
    <span class="nc-icon nc-umbrella-13"></span>
    <span class="nc-icon nc-vector"></span>
    <span class="nc-icon nc-watch-time"></span>
    <span class="nc-icon nc-zoom-split"></span>
  </div>
</template>
<script>
export default {
  name: 'Icons',
  data() {
    return {}
  },
}
</script>

<style scoped>
.icons {
  font-size: 40px;
}

.nc-icon {
  margin: 8px;
}
</style>
