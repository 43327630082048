var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"multi-day-events"},_vm._l((_vm.multiDayEvents),function(event,index){return _c('div',{key:index,class:{
        'multi-day-event': true,
        delivery: event.name.toLowerCase().match('delivery'),
        offsite: event.customer || event.name.toLowerCase().match('offsite'),
        inspection: event.name.toLowerCase().match('inspection'),
        holiday:
          event.name.toLowerCase().match('pto') ||
          event.name.toLowerCase().match('vacation') ||
          event.name.toLowerCase().match('holiday') ||
          event.name.toLowerCase().match('dentist') ||
          event.name.toLowerCase().match('doctor'),
        concrete: event.name.toLowerCase().match('concrete'),
        install:
          event.name.toLowerCase().match('framing') ||
          event.name.toLowerCase().match('sheeting') ||
          event.name.toLowerCase().match('insulation'),
      },style:({
        background: event.calendarColor,
        color: _vm.pickTextColor(event.calendarColor),
      }),on:{"click":function($event){return _vm.editScheduleDate(event)}}},[(event.multiDayEvent)?_c('span',[(event.name)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(event.name))]):_vm._e(),(event.order)?_c('span',{staticClass:"name"},[_vm._v(" - "+_vm._s(event.order.jobName))]):_vm._e(),(event.order)?_c('span',{staticClass:"name"},[_vm._v(" - "+_vm._s(event.order.customer.name))]):_vm._e(),(event.assignedTo)?_c('span',{staticClass:"name"},[_vm._v(" - "+_vm._s(event.assignedTo))]):_vm._e()]):_vm._e()])}),0),_c('div',{staticClass:"daily-events"},_vm._l((_vm.dailyEvents),function(event,index){return _c('div',{key:index,class:{
        'daily-event': true,
        'vendor-delivery':
          event.recurringVendorDay &&
          event.name.toLowerCase().match('delivery'),
        'vendor-order':
          event.recurringVendorDay && event.name.toLowerCase().match('order'),
        delivery:
          !event.recurringVendorDay &&
          event.name.toLowerCase().match('delivery'),
        offsite: event.customer || event.name.toLowerCase().match('offsite'),
        inspection: event.name.toLowerCase().match('inspection'),
        holiday:
          event.name.toLowerCase().match('pto') ||
          event.name.toLowerCase().match('vacation') ||
          event.name.toLowerCase().match('holiday') ||
          event.name.toLowerCase().match('dentist') ||
          event.name.toLowerCase().match('doctor'),
        concrete: event.name.toLowerCase().match('concrete'),
        install:
          event.name.toLowerCase().match('framing') ||
          event.name.toLowerCase().match('sheeting') ||
          event.name.toLowerCase().match('insulation'),
      },style:({
        background: event.calendarColor,
        color: _vm.pickTextColor(event.calendarColor),
      })},[(event.allDayEvent)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editScheduleDate(event)}}},[(event.name)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(event.name))]):_vm._e(),(event.icon)?_c('span',{class:((event.icon) + " ml-1 icon")}):_vm._e()]):_vm._e()])}),0),_vm._l((_vm.hours),function(hour){return _c('div',{key:hour.time,staticClass:"hour"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1"},[_c('div',{staticClass:"time-display text-left"},[_vm._v(" "+_vm._s(hour.time > 12 ? hour.time - 12 : hour.time)+":00 ")])]),_c('div',{staticClass:"col col-11"},[_c('div',{staticClass:"events"},_vm._l((hour.events),function(event,index){return _c('div',{key:index,class:{
              'hour-event': true,
              'vendor-delivery':
                event.recurringVendorDay &&
                event.name.toLowerCase().match('delivery'),
              'vendor-order':
                event.recurringVendorDay &&
                event.name.toLowerCase().match('order'),
              delivery:
                !event.recurringVendorDay &&
                event.name.toLowerCase().match('delivery'),
              offsite:
                event.customer || event.name.toLowerCase().match('offsite'),
              inspection: event.name.toLowerCase().match('inspection'),
              holiday:
                event.name.toLowerCase().match('pto') ||
                event.name.toLowerCase().match('vacation') ||
                event.name.toLowerCase().match('dentist') ||
                event.name.toLowerCase().match('holiday') ||
                event.name.toLowerCase().match('doctor'),
              concrete: event.name.toLowerCase().match('concrete'),
              install:
                event.name.toLowerCase().match('framing') ||
                event.name.toLowerCase().match('sheeting') ||
                event.name.toLowerCase().match('insulation'),
            },style:({
              background: event.calendarColor,
              color: _vm.pickTextColor(event.calendarColor),
            })},[(event.customer)?_c('router-link',{staticClass:"link",attrs:{"to":("/app/customers/" + (event.customer.id) + "/schedule")}},[_c('strong',[_vm._v(_vm._s(event.dateTime.format('h:mm A')))]),_vm._v(" - "+_vm._s(event.name)+" - ("+_vm._s(event.customer.name)+") "),(event.assignedTo)?_c('span',[_vm._v(" - "+_vm._s(event.assignedTo))]):_vm._e()]):_vm._e(),(event.tradeShow)?_c('router-link',{staticClass:"link",attrs:{"to":("/app/trade-shows/" + (event.tradeShow.id) + "/schedule")}},[_c('strong',[_vm._v(_vm._s(event.dateTime.format('h:mm A')))]),_vm._v(" - "+_vm._s(event.name)+" - ("+_vm._s(event.tradeShow.name)+") "),(event.assignedTo)?_c('span',[_vm._v(" - "+_vm._s(event.assignedTo))]):_vm._e()]):_vm._e(),(event.order)?_c('router-link',{staticClass:"link",attrs:{"to":("/app/orders/" + (event.order.id) + "/schedule")}},[_c('strong',[_vm._v(_vm._s(event.dateTime.format('h:mm A')))]),_vm._v(" - "+_vm._s(event.name)+" ("+_vm._s(event.order.type)+") - "+_vm._s(event.order.jobName)+" ("+_vm._s(event.order.customer.name)+") "),(event.assignedTo)?_c('span',[_vm._v(" - "+_vm._s(event.assignedTo)+" ")]):_vm._e(),(event.memo)?_c('span',[_vm._v(" - "+_vm._s(event.memo)+" ")]):_vm._e()]):_vm._e(),(event.vendor)?_c('router-link',{staticClass:"link",attrs:{"to":("/app/vendors/" + (event.vendor.id) + "/schedule")}},[_vm._v(" "+_vm._s(event.dateTime.format('h:mm A'))+" - "+_vm._s(event.name)+" - "+_vm._s(event.vendor.name)+" "),(event.assignedTo)?_c('span',[_vm._v(" - "+_vm._s(event.assignedTo)+" ")]):_vm._e(),(event.memo)?_c('span',[_vm._v(" - "+_vm._s(event.memo)+" ")]):_vm._e()]):_vm._e(),(
                !event.vendor &&
                !event.customer &&
                !event.order &&
                !event.tradeShow
              )?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editScheduleDate(event)}}},[(event.name)?_c('span',{staticClass:"name link"},[_vm._v(" "+_vm._s(_vm._f("shortTimeFilter")((event.startTime || event.time)))+" - "+_vm._s(event.name.toLowerCase()))]):_vm._e()]):_vm._e()],1)}),0)])])])}),_c('b-modal',{attrs:{"id":"edit-schedule-date-modal","title":("Edit Calendar Event - " + (_vm.currentScheduleDate && _vm.currentScheduleDate.name)),"hide-footer":"","size":"lg"}},[(_vm.currentScheduleDate)?_c('div'):_vm._e(),_c('edit-schedule-date',{attrs:{"og-schedule-date":_vm.currentScheduleDate}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }