import './compositionAPI'
import { reactive, readonly, computed } from '@vue/composition-api'
import _ from 'lodash'

const state = reactive({
  searchResults: [],
  query: {
    email: '',
    name: '',
    phone: '',
    type: '',
    id: '',
    vip: false,
  },
  querySubmitted: false,
})

function setSearchResults(searchResults) {
  state.searchResults = searchResults
  state.querySubmitted = true
}

function clearSearchResults() {
  state.searchResults = []
}

function clearQuery() {
  _.each(state.query, (value, key) => {
    state.query[key] = ''
  })
  state.querySubmitted = false
}

const customers = reactive([])

function setCustomers(customerList) {
  _.mergeWith(customers, customerList)
}

function addCustomer(customer) {
  customers.push(customer)
}

function removeCustomer(customerName) {
  _.remove(customers, customer => {
    return customer.name === customerName
  })
}

const customerNames = computed(() => {
  let names = []
  customers.forEach(customer => {
    names.push(customer.name)
  })
  return names
})

export const customerStore = readonly({
  state,
  setSearchResults,
  clearSearchResults,
  clearQuery,
  customers,
  customerNames,
  setCustomers,
  addCustomer,
  removeCustomer,
})
