<template>
  <div class="building-created-chart">
    <apexchart type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { userStore } from '@/stores/userStore'

export default {
  name: 'SalesLeadsByPageChart',
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  inject: {
    userStore,
  },
  data: function () {
    return {
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent'],
        },
        xaxis: {
          categories: _.map(this.data, group => {
            return _.startCase(group._id)
          }),
        },
        yaxis: {
          title: {
            text: '# of Leads via Website',
          },
        },
        fill: {
          opacity: 1,
        },
        colors: ['#da562b'],
        tooltip: {
          y: {
            formatter: function (val) {
              return '# ' + val
            },
          },
        },
      },
    }
  },
  methods: {},
  mounted() {},
  computed: {
    series() {
      const series = _.map(this.data, d => {
        return d.total
      })
      return [{ name: 'Sales Leads', data: series }]
    },
  },
}
</script>

<style lang="scss" scoped></style>
