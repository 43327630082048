<template>
  <div>
    <div class="order-payments-schedule" v-if="order">
      <div class="row">
        <div class="col">
          <div class="payments">
            <h3>Payments</h3>
            <order-payments :order="order"></order-payments>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderPayments from '@/components/OrderPayments'

export default {
  name: 'OrderInstallPage',
  props: {},
  data: function () {
    return {
      order: null,
    }
  },
  components: {
    OrderPayments,
  },
  mounted() {
    this.order = this.$parent.order
  },
}
</script>

<style lang="scss" scoped></style>
