<template>
  <div v-if="task">
    <div class="row">
      <div class="col col-10">
        <label class="label">Details</label>
        {{ task.details }}
      </div>
      <div class="col col-2">
        <label class="label">Links</label>
        <div v-if="!task.customerName && !task.customerId">- No Links -</div>
        <div v-if="task.customerName || task.customerId">
          <router-link
            :to="`/app/customers/lookup?name=${task.customerName}`"
            >{{ task.customerName }}</router-link
          >
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <label class="label">Status</label>
        <div class="row">
          <div class="col">
            <div class="current-status">
              Current Status:
              <span
                v-bind:class="{
                  'text-danger': task.status === 'BLOCKED',
                  'text-success': task.status === 'COMPLETED',
                  'text-primary': task.status === 'READY',
                  'text-info': task.status === 'IN-PROGRESS',
                }"
              >
                <strong>
                  {{ task.status }}
                </strong>
              </span>
            </div>
          </div>
          <div class="col">
            <b-form-select
              id="status"
              class="mb-2"
              v-model="newStatus"
              :options="taskStatuses"
              required
              :disabled="submitting"
            ></b-form-select>
            <div class="completed-by mb-2" v-if="newStatus === 'COMPLETED'">
              Completed on:
              <div class="checklist-item-timestamp">
                <b-form-input
                  type="datetime-local"
                  id="checklist-item-timestamp"
                  size="sm"
                  v-model="newCompletedDateTime"
                ></b-form-input>
              </div>
            </div>
            <b-button
              size="sm"
              variant="primary"
              class="mt-1"
              @click="updateStatus"
              :disabled="submitting"
            >
              <span v-if="submitting">Updating Status..</span>
              <span v-if="!submitting">Update Status</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="col">
        <div>
          <label class="label">Due By</label>
          {{ task.dueDate | shortFullDateFilter }}
          <span v-if="task.dueTime">@ {{ task.dueTime }}</span>
          ({{ task.dueDate | daysAgo }})
          <b-button
            size="sm"
            variant="outline-primary"
            class="ml-3"
            @click="requestExtension"
            :disabled="submitting"
          >
            <span v-if="submitting">Requesting..</span>
            <span v-if="!submitting">Request Extension</span>
          </b-button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <label class="label">Assigned To</label>
        {{ task.department }} <br />
        {{ task.assignedTo }}
      </div>
      <div class="col">
        <label class="label">Assigned By</label>
        {{ task.assignedBy }}
      </div>
    </div>

    <div class="row mt-4">
      <div class="col"></div>
      <div class="col"></div>
    </div>

    <div class="row">
      <div class="col">
        <label class="label">Notes</label>
        <task-notes :task="task"></task-notes>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <task-photos-display
          :task="task"
          :department="task.department"
        ></task-photos-display>
      </div>
      <div class="col">
        <task-files-display
          :task="task"
          :department="task.department"
        ></task-files-display>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import TaskNotes from './TaskNotes.vue'
import TaskFilesDisplay from './TaskFilesDisplay.vue'
import TaskPhotosDisplay from './TaskPhotosDisplay.vue'

export default {
  name: 'updateStatus',
  props: {
    ogTask: {
      type: Object,
      required: true,
    },
    modalId: {
      type: String,
    },
  },
  components: { TaskNotes, TaskFilesDisplay, TaskPhotosDisplay },
  inject: ['userStore', 'customerStore', 'taskStore'],
  data: function () {
    let newCompletedDateTime = this.ogTask.completedDateTime
      ? moment(this.ogTask.completedDateTime)
      : moment()
    return {
      submitting: false,
      newStatus: this.ogTask.status,
      newCompletedDateTime: newCompletedDateTime.format('YYYY-MM-DDTHH:mm'),
      task: _.clone(this.ogTask),
      taskStatuses: [
        'OPEN',
        'IN-PROGRESS',
        'COMPLETED',
        'READY',
        'SKIPPED',
        'BLOCKED',
      ],
    }
  },
  watch: {},
  methods: {
    requestExtension() {
      window.alert('feature coming soon')
    },
    updateStatus() {
      const data = {
        id: this.task.id,
        status: this.newStatus,
        completedDateTime: this.newCompletedDateTime,
      }
      return axios
        .patch('/services/tasks/' + data.id + '/status', data)
        .then(response => {
          this.taskStore.deleteTask(response.data.task)
          this.taskStore.addTask(response.data.task)
          this.task = _.clone(response.data.task)

          this.$toasted.success(`Updated Task`, {
            duration: 2500,
          })
          this.$bvModal.hide(`edit-task-modal-${this.modalId}`)
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Editing Task`, {
            duration: 2500,
          })
        })
        .finally(() => {})
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.section-header {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 6px;
  margin-top: 8px;
  font-weight: 500;
}

.contact-col {
  padding-left: 5px;
  padding-right: 5px;

  &:first-of-type {
    padding-left: 15px;
  }
}

.modal-ctas {
  position: relative;
}
.label {
  display: block;
  border-bottom: 1px solid gray;
  font-weight: 500;
}
.current-status {
  font-size: 18px;
}
</style>
