var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('div',{staticClass:"daily-events mb-3"},_vm._l((_vm.dailyEvents),function(date,index){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.getTooltip(date)),expression:"getTooltip(date)",modifiers:{"hover":true,"left":true}}],key:index,class:{
        'daily-event': true,
        'multi-day': date.multiDayEvent,
        'hide-date': date.hideDate,
        'vendor-delivery':
          date.recurringVendorDay &&
          date.name.toLowerCase().match('delivery'),
        'vendor-order':
          date.recurringVendorDay && date.name.toLowerCase().match('order'),
        delivery:
          !date.recurringVendorDay &&
          date.name.toLowerCase().match('delivery'),
        offsite: date.customer || date.name.toLowerCase().match('offsite'),
        inspection: date.name.toLowerCase().match('inspection'),
        holiday:
          date.name.toLowerCase().match('pto') ||
          date.name.toLowerCase().match('vacation') ||
          date.name.toLowerCase().match('holiday') ||
          date.name.toLowerCase().match('dentist') ||
          date.name.toLowerCase().match('doctor'),
        concrete: date.name.toLowerCase().match('concrete'),
        install:
          date.name.toLowerCase().match('framing') ||
          date.name.toLowerCase().match('sheeting') ||
          date.name.toLowerCase().match('insulation'),
      },style:({
        background: date.calendarColor,
        color: _vm.pickTextColor(date.calendarColor),
      })},[(date.name)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(date.name.replace(/Delivery/g, '').replace(/Order/g, '')))]):_vm._e(),(date.icon)?_c('span',{class:((date.icon) + " ml-1 icon")}):_vm._e()])}),0),_vm._l((_vm.sortedDates),function(scheduleDate,index){return _c('div',{key:index},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.getTooltip(scheduleDate)),expression:"getTooltip(scheduleDate)",modifiers:{"hover":true,"left":true}}],class:{
        'hour-event': true,
        'hide-date': scheduleDate.hideDate,
        'vendor-delivery':
          scheduleDate.recurringVendorDay &&
          scheduleDate.name.toLowerCase().match('delivery'),
        'vendor-order':
          scheduleDate.recurringVendorDay &&
          scheduleDate.name.toLowerCase().match('order'),
        delivery:
          !scheduleDate.recurringVendorDay &&
          scheduleDate.name.toLowerCase().match('delivery'),
        offsite:
          scheduleDate.customer ||
          scheduleDate.name.toLowerCase().match('offsite'),
        inspection: scheduleDate.name.toLowerCase().match('inspection'),
        holiday:
          scheduleDate.name.toLowerCase().match('pto') ||
          scheduleDate.name.toLowerCase().match('vacation') ||
          scheduleDate.name.toLowerCase().match('dentist') ||
          scheduleDate.name.toLowerCase().match('holiday') ||
          scheduleDate.name.toLowerCase().match('doctor'),
        concrete: scheduleDate.name.toLowerCase().match('concrete'),
        install:
          scheduleDate.name.toLowerCase().match('framing') ||
          scheduleDate.name.toLowerCase().match('sheeting') ||
          scheduleDate.name.toLowerCase().match('insulation'),
      },style:({
        background: scheduleDate.calendarColor,
        color: _vm.pickTextColor(scheduleDate.calendarColor),
      })},[_vm._v(" "+_vm._s(scheduleDate.dateTime.format('h:mm A'))+": "+_vm._s(scheduleDate.name)+" ")])])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }