<template>
  <div class="government-profile">
    <div class="row">
      <div class="col">
        <label for="contractor-name">Contractor Name</label>
        <b-form-input
          v-model="profile.contractorName"
          id="contractor-name"
          :disabled="disabled"
        >
        </b-form-input>
      </div>
      <div class="col"></div>
    </div>
    <br />
    lists of contractors/preferred contractors
    <br />
    lists of important dates, purchasing cycles
    <br />
    contacts and locations covered on other page
  </div>
</template>

<script>
export default {
  components: {},
  name: 'GovernmentProfileFormFields',
  props: {
    profile: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
