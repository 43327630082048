<template>
  <div class="full-checklist">
    <div class="row">
      <div class="col">
        <div class="mt-2" v-if="!order.workflow.engineering.doEngineering">
          <h5>Engineering</h5>
          <div>n/a</div>
        </div>
        <div class="mt-2" v-if="order.workflow.engineering.doEngineering">
          <router-link :to="`/app/orders/${order.id}/engineering`">
            <h5>Engineering</h5>
          </router-link>
          <engineering-checklist
            :hide-add-button="true"
            :order="order"
            class="checklist"
          ></engineering-checklist>
        </div>
        <div class="mt-2" v-if="!order.workflow.permitting.doPermitting">
          <h5>Permitting</h5>
          <div>n/a</div>
        </div>
        <div class="mt-2" v-if="order.workflow.permitting.doPermitting">
          <router-link :to="`/app/orders/${order.id}/permitting`">
            <h5>Permitting</h5>
          </router-link>
          <permitting-checklist
            :hide-add-button="true"
            :order="order"
            class="checklist"
          ></permitting-checklist>
        </div>
        <div class="mt-2" v-if="!order.workflow.install.doInstall">
          <h5>Install</h5>
          <div>n/a</div>
        </div>
        <div class="mt-2" v-if="order.workflow.install.doInstall">
          <router-link :to="`/app/orders/${order.id}/install`">
            <h5>Install</h5>
          </router-link>
          <install-checklist
            :hide-add-button="true"
            :order="order"
            class="checklist"
          ></install-checklist>
        </div>
      </div>
      <div class="col">
        <div class="">
          <router-link :to="`/app/orders/${order.id}/manufacturing`">
            <h5>Manufacturing</h5>
          </router-link>
          <manufacturing-work-orders
            :order="order"
            :read-only="true"
          ></manufacturing-work-orders>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <router-link :to="`/app/orders/${order.id}/schedule`">
          <h5>Schedule</h5>
        </router-link>
        <order-schedule :order="order" mode="checklist"></order-schedule>
      </div>
      <div class="col">
        <router-link :to="`/app/orders/${order.id}/payments`">
          <h5>Payments</h5>
        </router-link>
        <order-payments :order="order" mode="checklist"></order-payments>
      </div>
    </div>
  </div>
</template>

<script>
import PermittingChecklist from '@/components/PermittingChecklist'
import EngineeringChecklist from '@/components/EngineeringChecklist'
import InstallChecklist from '@/components/InstallChecklist'

import axios from 'axios'
import OrderPayments from './OrderPayments.vue'
import OrderSchedule from './OrderSchedule.vue'
import ManufacturingWorkOrders from './ManufacturingWorkOrders.vue'

export default {
  name: 'FullChecklist',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    PermittingChecklist,
    EngineeringChecklist,
    InstallChecklist,
    OrderPayments,
    OrderSchedule,
    ManufacturingWorkOrders,
  },
  computed: {},
}
</script>

<style scoped lang="scss">
.full-checklist {
  margin: 0px 24px;
}
.checklist {
  pointer-events: none;
  padding-left: 12px;
}

a {
  color: black;
}
</style>
