<template>
  <div class="">
    <div class="border-bottom text-left">
      Openings ({{ openings.length }})
      <b-button
        class="mx-1 my-1 add-opening-button"
        @click="addOpening()"
        size="sm"
        :disabled="disableInputs"
      >
        + Opening
      </b-button>
      <b-button
        class="mx-1 my-1 add-opening-button"
        @click="addOpening('Window')"
        size="sm"
        :disabled="disableInputs"
      >
        + MD Window
      </b-button>
      <b-button
        class="mx-1 my-1 add-opening-button"
        @click="addOpening('Man Door')"
        size="sm"
        :disabled="disableInputs"
      >
        + MD Mandoor
      </b-button>
    </div>
    <div
      class="opening-detail"
      v-for="(opening, index) in openings"
      :key="index"
    >
      <div class="opening-detail-info">
        <!-- <span class="opening-counter">{{ index + 1 }}.</span> -->
        <b-button
          class="remove-opening-button"
          variant="danger"
          size="sm"
          @click="removeOpening(index)"
          :disabled="disableInputs"
          >x</b-button
        >
      </div>

      <div class="row mt-2 mb-2">
        <div class="col input-col">
          <label for="openingType">Type</label>
          <b-input-group>
            <b-form-select
              id="openingType"
              v-model="opening.type"
              :options="openingTypeOptions"
              required
              :disabled="disableInputs"
              @change="handleSetOpeningType(opening)"
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label :for="`opening-${index}-width-ft`">Width</label>
          <b-input-group append="ft">
            <b-form-input
              :id="`opening-${index}-width-ft`"
              v-model="opening.width.ft"
              type="number"
              placeholder="0"
              :max="maxWidth"
              :min="minWidth"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label :for="`opening-${index}-width-ft`">&emsp;</label>
          <b-input-group append="in">
            <b-form-input
              :id="`opening-${index}-width-in`"
              v-model="opening.width.in"
              type="number"
              placeholder="0"
              :max="12"
              :min="0"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label :for="`opening-${index}-height-ft`">Height</label>
          <b-input-group append="ft">
            <b-form-input
              :id="`opening-${index}-height-ft`"
              v-model="opening.height.ft"
              type="number"
              placeholder="0"
              :max="maxHeight"
              :min="minHeight"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label :for="`opening-${index}-height-in`">&emsp;</label>
          <b-input-group append="in">
            <b-form-input
              :id="`opening-${index}-height-in`"
              v-model="opening.height.in"
              type="number"
              placeholder="0"
              :max="12"
              :min="0"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col col-2 input-col">
          <label :for="`opening-${index}-quantity`">Quantity</label>
          <b-input-group>
            <b-form-input
              :id="`opening-${index}-quantity`"
              v-model="opening.quantity"
              type="number"
              placeholder="0"
              min="1"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col input-col">
          <label for="buildingSide">Building Side</label>
          <b-input-group>
            <b-form-select
              id="buildingSide"
              v-model="opening.buildingSide"
              :options="buildingSideOptions"
              :required="true"
              :disabled="disableInputs"
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label for="openingSource">Source</label>
          <b-input-group>
            <b-form-select
              id="openingSource"
              v-model="opening.source"
              :options="openingSourceOptions"
              required
              :disabled="disableInputs"
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="col input-col" v-if="opening.source !== 'Frame Only'">
          <label :for="`opening-${index}-model`"
            >Model
            <help-icon
              v-if="
                opening.source == 'Metals Direct' && opening.type !== 'Window'
              "
              :text="`Model selected automatically if size matches. If no match found, will not be able to save quote.`"
            ></help-icon>
            <help-icon
              v-if="
                opening.source == 'Metals Direct' && opening.type == 'Window'
              "
              :text="`Metals Direct carries two types of 3x3 windows. Select The Model.`"
            ></help-icon>
            <help-icon
              v-if="opening.source == 'External'"
              :text="`Enter model number for custom ${opening.type || 'item'}.`"
            ></help-icon
          ></label>

          <b-input-group v-if="opening.source == 'External'">
            <b-form-input
              :id="`opening-${index}-model`"
              v-model="opening.model"
              type="text"
              placeholder=""
              :disabled="disableInputs"
              required
            ></b-form-input>
          </b-input-group>
          <div
            v-if="opening.source == 'Metals Direct' && opening.type == 'Window'"
          >
            <b-form-select
              v-if="
                opening.height.ft == 3 &&
                opening.height.in == 0 &&
                opening.width.ft == 3 &&
                opening.width.in == 0
              "
              id="openingSource"
              v-model="opening.model"
              :options="models.Window"
              required
              :disabled="disableInputs"
            ></b-form-select>
            <b-form-input
              v-if="
                opening.height.ft != 3 ||
                opening.width.ft != 3 ||
                opening.height.in != 0 ||
                opening.width.in != 0
              "
              :value="`No Item Found`"
              :disabled="true"
            >
            </b-form-input>
          </div>
          <div
            v-if="opening.source == 'Metals Direct' && opening.type != 'Window'"
          >
            <b-form-input
              :value="
                (models[opening.type] &&
                  models[opening.type][
                    `${opening.width.ft}'${opening.width.in || '0'}x${
                      opening.height.ft
                    }'${opening.height.in || '0'}`
                  ]) ||
                `No Item Found`
              "
              :disabled="true"
            >
            </b-form-input>
          </div>
        </div>
      </div>
      <div
        class="row mt-1"
        v-if="
          opening.source != 'Metals Direct' && opening.source !== 'Frame Only'
        "
      >
        <div class="col col-4 input-col">
          <label :for="`opening-${index}-price`"
            >Price
            <help-icon
              :text="`Insert a price to be included in the quote total.`"
            ></help-icon>
          </label>

          <b-input-group>
            <b-form-input
              :id="`opening-${index}-price`"
              v-model="opening.price"
              type="text"
              placeholder=""
              :disabled="disableInputs"
              required
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col col-8 input-col">
          <label :for="`opening-${index}-reference`"
            >Reference
            <help-icon
              :text="`Include a link to the source of the door.`"
            ></help-icon>
          </label>

          <b-input-group>
            <b-form-input
              :id="`opening-${index}-reference`"
              v-model="opening.reference"
              type="text"
              placeholder=""
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  rollDoorModelsWithInches,
  shedDoorModels,
  manDoorModels,
  windowModels,
} from './openingsModels'
import HelpIcon from '@/components/HelpIcon'

export default {
  name: 'OpeningsInputNew',
  components: {
    HelpIcon,
  },
  props: {
    openings: {
      type: Array,
      required: true,
    },
    disableInputs: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      models: {
        'Roll Door': rollDoorModelsWithInches,
        'Shed Door': shedDoorModels,
        'Man Door': manDoorModels,
        Window: windowModels,
      },
      buildingSideOptions: [
        { text: 'Select Side', value: undefined },
        { text: 'Front Gable', value: 'Front Gable' },
        { text: 'End Gable', value: 'End Gable' },
        { text: 'Left Eave', value: 'Left Eave' },
        { text: 'Right Eave', value: 'Right Eave' },
      ],
      openingTypeOptions: [
        { text: 'Select Type', value: undefined },
        { text: 'Roll Door', value: 'Roll Door' },
        { text: 'Shed Door', value: 'Shed Door' },
        { text: 'Window', value: 'Window' },
        { text: 'Man Door', value: 'Man Door' },
      ],
      openingSourceOptions: [
        { text: 'Metals Direct', value: 'Metals Direct' },
        { text: 'External', value: 'External' },
        { text: 'Frame Only', value: 'Frame Only' },
      ],
    }
  },
  computed: {
    // todo add logic for mins/maxs based on type
    maxWidth() {
      return 20
    },
    maxHeight() {
      return 20
    },
    minWidth() {
      return 1
    },
    minHeight() {
      return 1
    },
  },
  methods: {
    addOpening(type) {
      let model
      let height = {
        ft: 0,
        in: 0,
      }
      let width = {
        ft: 0,
        in: 0,
      }

      if (type === 'Window') {
        width.ft = 3
        height.ft = 3
        model = 'Without Grid'
      }

      if (type === 'Man Door') {
        width.ft = 3
        height.ft = 6
        height.in = 8
        model = '95 Series'
      }

      this.openings.push({
        height,
        width,
        quantity: 1,
        source: 'Metals Direct',
        type,
        model,
      })
    },
    removeOpening(index) {
      this.openings.splice(index, 1)
    },
    handleSetOpeningType(opening) {
      opening.model = null

      if (!opening.height.ft || !opening.width.ft) {
        if (opening.type === 'Man Door') {
          opening.height.ft = 6
          opening.height.in = 8
          opening.width.ft = 3
          opening.width.in = 0
        } else if (opening.type === 'Window') {
          opening.height.ft = 3
          opening.height.in = 0
          opening.width.ft = 3
          opening.width.in = 0
        }
      }
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.input-col {
  padding-left: 4px;
  padding-right: 4px;
}

.add-opening-button {
  padding: 0px 4px 0px 4px;
}

.remove-opening-button {
  height: 26px;
  width: 26px;
  line-height: 20px;
  padding: 0px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.opening-counter {
  margin-top: 40px;
  font-size: 20px;
}

.vertical-checkbox {
  label {
    width: 100%;
    display: block;
    text-align: center;
  }

  .custom-checkbox {
    width: 100%;
    margin-top: 4px;
  }
}

label {
  text-align: left;
  display: block;
  margin-bottom: 2px;
}

.opening-detail {
  padding: 12px 30px;
  position: relative;
  border: 1px solid lightgray;
  margin-top: 12px;
}
.opening-detail-info {
  position: absolute;
  top: 6px;
  right: 6px;
}
</style>
