<template>
  <div class="table-wrapper">
    <b-table
      striped
      hover
      :items="customerList"
      :fields="fields"
      @row-clicked="goToCustomer"
    ></b-table>
  </div>
</template>

<script>
import _ from 'lodash'
// import VIPBadge from '@/components/VIPBadge'

export default {
  name: 'CustomerList',
  components: {},
  props: {
    customers: {
      type: Array,
      required: true,
    },
    optionalFields: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      fields: [
        {
          key: 'name',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'type',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'email',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'phone',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  mounted() {},
  computed: {
    customerList() {
      return this.customers.map(customer => {
        const { name, type, email, phone, id } = customer
        return { name, type, email, phone, id }
      })
    },
  },
  methods: {
    goToCustomer(customer, index, e) {
      if (e.metaKey) {
        window.open('/app/customers/' + customer.id, '_blank')
      } else {
        this.$router.push('/app/customers/' + customer.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
}
</style>

<style>
.clickable-row {
  cursor: pointer;
}
</style>
