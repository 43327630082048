<template>
  <div class="orders page-wrapper">
    <h1 class="page-h1">Manage Orders</h1>
    <h3>Order Lookup</h3>
    <order-lookup></order-lookup>
    <div class="order-stats text-center" v-show="!loading" v-if="false">
      <div class="row">
        <div class="col">
          <div>Past Due</div>
          <div class="orders-due-count">
            {{ pastDueOrders.length }}
          </div>
        </div>
        <div class="col">
          <div>Orders Today</div>
          <div class="orders-due-count">
            {{ todaysOrders.length }}
          </div>
        </div>
        <div class="col">
          <div>Orders Tomorrow</div>
          <div class="orders-due-count">
            {{ tomorrowsOpenOrders.length }}
          </div>
        </div>
      </div>
    </div>
    <div class="order-list-wrapper" v-if="false">
      <div class="order-list mt-3" v-show="!loading">
        <h3>Orders Last 14 Days ({{ currentOrders.length }})</h3>
        <order-list linkAction="detail"> :orders="currentOrders"</order-list>
      </div>
      <div class="order-list text-center mt-5" v-show="loading">
        <circle-loader :size="40"></circle-loader>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import OrderList from '@/components/OrderList.vue'
import moment from 'moment'
import CircleLoader from '@/components/CircleLoader.vue'
import OrderLookup from '@/components/OrderLookup.vue'

export default {
  name: 'ManageOrdersPage',
  components: { OrderList, CircleLoader, OrderLookup },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      currentOrders: [],
    }
  },
  methods: {
    getRecentOrders() {
      return axios
        .get('/services/orders/recent')
        .then(response => {
          _.each(response.data.orders, order => {
            if (order.workflow.sales.dueDate) {
              const minutesLeft = moment(order.workflow.sales.dueDate).diff(
                new Date(),
                'minutes',
              )
              if (minutesLeft <= 0) {
                order.timeLeft = 'PAST DUE'
              } else {
                order.timeLeft =
                  Math.floor(minutesLeft / 24 / 60) +
                  ':' +
                  Math.floor((minutesLeft / 60) % 24) +
                  ':' +
                  (minutesLeft % 60)
              }
            }
          })
          this.currentOrders = response.data.orders
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  computed: {
    allOpenOrders() {
      var todaysOpenOrders = []
      _.each(this.currentOrders, order => {
        if (order.status === 'OPEN' || order.status === 'IN-PROGRESS') {
          todaysOpenOrders.push(order)
        }
      })
      return todaysOpenOrders
    },
    tomorrowsOpenOrders() {
      var todaysOpenOrders = []
      _.each(this.currentOrders, order => {
        if (order.status === 'OPEN' || order.status === 'IN-PROGRESS') {
          const dueDate = moment(order.workflow.sales.soldDate).add('2', 'days')
          if (
            dueDate.isBefore(moment().add('1', 'day').endOf('day')) &&
            dueDate.isAfter(moment().add('1', 'day').startOf('day'))
          ) {
            todaysOpenOrders.push(order)
          }
        }
      })
      return todaysOpenOrders
    },
    readyOrders() {
      var readyOrders = []
      _.each(this.currentOrders, order => {
        if (order.status === 'READY') readyOrders.push(order)
      })
      return readyOrders
    },
  },
  mounted() {
    // this.getRecentOrders();
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
