<template>
  <div class="table-wrapper">
    <b-table
      striped
      hover
      :items="tradeShowList"
      :fields="fields"
      :tbody-tr-class="['clickable-row']"
      @row-clicked="openTradeShowDetail"
    >
    </b-table>
    <b-modal
      :title="`Trade Show - ${currentRow && currentRow.name}`"
      :id="`trade-show-detail-modal-${id}`"
      hide-footer
      size="xl"
    >
      <TradeShowOverviewPage :trade-show="currentRow"></TradeShowOverviewPage>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import TradeShowOverviewPage from './TradeShowOverviewPage'

export default {
  name: 'TradeShowList',
  components: { TradeShowOverviewPage },
  inject: ['userStore'],
  props: {
    tradeShows: {
      type: Array,
      required: true,
    },
    showOnLoad: {
      type: Number,
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      currentRow: null,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'company',
          sortable: true,
        },
      ],
    }
  },
  mounted() {
    if (this.showOnLoad) {
      let tradeShow = _.find(this.tradeShows, ts => {
        return ts.id == this.showOnLoad
      })
      if (tradeShow) {
        this.openTradeShowDetail(tradeShow)
      }
    }
  },
  computed: {
    tradeShowList() {
      return this.tradeShows.map(lead => {
        const {
          _id,
          id,
          name,
          submittedOnPage,
          status,
          email,
          phone,
          company,
          comments,
          createdAt,
          notes,
          schedule,
          contacts,
          locations,
          photos,
          files,
          address,
        } = lead
        return {
          _id,
          id,
          createdAt,
          name,
          submittedOnPage,
          status,
          email,
          phone,
          company,
          comments,
          notes,
          schedule,
          contacts,
          locations,
          photos,
          files,
          address,
        }
      })
    },
  },
  methods: {
    openTradeShowDetail(tradeShow, index, e) {
      this.currentRow = tradeShow
      this.$bvModal.show(`trade-show-detail-modal-${this.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
}
</style>

<style>
.clickable-row {
  cursor: pointer;
}
</style>
