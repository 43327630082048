import './compositionAPI'
import { reactive, readonly, computed } from '@vue/composition-api'
import _ from 'lodash'

const state = reactive({
  searchResults: [],
  query: {
    email: '',
    firstName: '',
    nickname: '',
    lastName: '',
    createdBy: '',
    type: null,
  },
  querySubmitted: false,
})

function setSearchResults(searchResults) {
  state.searchResults = searchResults
  state.querySubmitted = true
}

function clearSearchResults() {
  state.searchResults = []
}

function clearQuery() {
  _.each(state.query, (value, key) => {
    state.query[key] = ''
  })
  state.querySubmitted = false
}

export const buildingStore = readonly({
  state,
  setSearchResults,
  clearSearchResults,
  clearQuery,
})
