<template>
  <div class="photos-wrapper">
    <div class="container-fluid">
      <div class="photos-list row">
        <div
          v-for="(photo, index) in filteredPhotos"
          :key="index"
          class="photo-wrapper col"
        >
          <img
            :src="`https://storage.googleapis.com/md-buildings-photos/${photo.name}`"
            :alt="photo.description"
            class="photo-img"
            @click="openPhoto(photo)"
          />
          <div class="photo-description">{{ photo.description }}</div>
          <span
            class="nc-icon nc-simple-remove"
            @click="confirmRemovePhoto(photo)"
          ></span>
        </div>
      </div>
    </div>
    <b-modal id="remove-photo-modal" size="md" hide-header hide-footer>
      <div v-if="currentPhoto">
        <h5>Are you sure you want to delete this photo?</h5>
        <p>{{ last(currentPhoto.name.split('/')) }}</p>
        <b-button @click="cancelRemovePhoto()" :disabled="submittingDelete"
          >Cancel</b-button
        >
        <b-button
          class="mx-2"
          @click="removePhoto(currentPhoto)"
          variant="danger"
          :disabled="submittingDelete"
        >
          <span v-if="!submittingDelete">Delete Photo</span>
          <span v-if="submittingDelete">Deleting..</span></b-button
        >
      </div>
    </b-modal>
    <b-modal id="open-photo-modal" size="xl" hide-header hide-footer>
      <div v-if="currentPhoto">
        <img
          :src="`https://storage.googleapis.com/md-buildings-photos/${currentPhoto.name}`"
          :alt="currentPhoto.description"
          class="open-photo-img"
        />
        <p>{{ last(currentPhoto.name.split('/')) }}</p>
        <p>{{ currentPhoto.description }}</p>
      </div>
    </b-modal>
    <div class="add-photo-form">
      <b-form @submit.prevent="addPhoto()">
        <div id="new-photo" class="mt-2">
          <b-form-file
            v-model="newPhoto"
            placeholder="Choose a photo or drop it here..."
            drop-placeholder="Drop photo here..."
            :required="true"
          ></b-form-file>
          <b-textarea
            placeholder="Enter Description"
            name="description"
            class="description-input"
            v-model="newDescription"
          ></b-textarea>
        </div>
        <div id="ctas">
          <div class="ctas text-right mt-2">
            <b-button
              variant="primary"
              size="sm"
              type="submit"
              :disabled="submitting"
            >
              <span v-show="submitting">Submitting</span>
              <span v-show="!submitting">Add Photo</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
  components: {},
  name: 'PhotosDisplay',
  props: {
    order: {
      type: Object,
      required: true,
    },
    department: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      newPhoto: null,
      submitting: false,
      submittingDelete: false,
      signedUploadURL: '',
      currentPhoto: null,
      newDescription: '',
      last: _.last,
      filteredPhotos: this.order.photos.filter(photo => {
        return photo.department === this.department.toUpperCase()
      }),
    }
  },
  methods: {
    getFileType(photo) {
      let fileType = _.last(photo.name.split('.'))
      let supportedTypes = ['pdf', 'png', 'jpg', 'jpeg', 'gif']
      if (!supportedTypes.includes(fileType)) {
        fileType = 'file'
      }
      return fileType
    },
    confirmRemovePhoto(photo) {
      this.currentPhoto = photo
      this.$bvModal.show('remove-photo-modal')
    },
    openPhoto(photo) {
      this.currentPhoto = photo
      this.$bvModal.show('open-photo-modal')
    },
    cancelRemovePhoto() {
      this.$bvModal.hide('remove-photo-modal')
      this.currentPhoto = null
    },
    async removePhoto(photo) {
      const data = {
        id: this.order.id,
        photo,
      }
      this.submittingDelete = true
      try {
        const response = await axios.put('/services/orders/delete-photo', data)
        _.remove(this.filteredPhotos, p => {
          return p.name === photo.name
        })

        this.cancelRemovePhoto()
        this.$toasted.success(`Photo Removed`, {
          duration: 2500,
        })
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error removing photo`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submittingDelete = false
    },
    async savePhoto(photo) {
      photo.department = this.department.toUpperCase()
      photo.description = this.newDescription
      const data = {
        id: this.order.id,
        photo,
      }
      try {
        await axios.put('/services/orders/save-photo', data)
        console.log('pushing this photo to filterdPhotos: ', photo)
        this.filteredPhotos.push(photo)
        this.$toasted.success(`Photo Added`, {
          duration: 2500,
        })
        console.log('filteredPhotos is: ', this.filteredPhotos)
        this.newPhoto = null
        this.newDescription = ''
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading photo`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async addPhoto() {
      if (!this.newPhoto) return
      const data = {
        id: this.order.id,
        fileName: this.newPhoto.name,
        department: this.department,
        contentType: this.newPhoto.type,
      }
      this.submitting = true
      try {
        const response = await axios.put(
          '/services/orders/photo-upload-url',
          data,
        )
        await axios.put(response.data.signedUploadURL, this.newPhoto, {
          headers: {
            'Content-Type': this.newPhoto.type,
          },
        })
        return this.savePhoto(response.data.photo)
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading photo`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async onFileSelected(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.newPhotoUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.newPhoto = files[0]
    },
  },
}
</script>

<style scoped lang="scss">
.nc-icon {
  font-size: 20px;
  font-weight: bold;
}

.photo-wrapper {
  width: 200px;
}

.nc-cloud-download-93 {
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
}

.nc-simple-remove {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}
.file-icon {
  display: inline-block;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
}

.description-input {
  width: 100%;
  margin-top: 6px;
}

.photo-img {
  margin: auto;
  display: block;
  max-height: 200px;
  max-width: 100%;
  cursor: pointer;
}
</style>
