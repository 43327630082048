export const customerTypes = [
  {
    value: '',
    text: 'Select Customer Type',
  },
  {
    value: 'HOMEOWNER',
    text: 'HOMEOWNER',
  },
  {
    value: 'BUSINESS',
    text: 'BUSINESS',
  },
  {
    value: 'CONTRACTOR',
    text: 'CONTRACTOR',
  },
  {
    value: 'GOVERNMENT',
    text: 'GOVERNMENT',
  },
]

export const customerStatuses = [
  {
    value: '',
    text: 'Select Customer Status',
  },
  {
    value: 'NEW',
    text: 'NEW',
  },
]
