var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-wrapper",style:({ maxHeight: _vm.customMaxHeight })},[_c('table',{staticClass:"table"},[_c('thead',[_c('td',[_vm._v("Status")]),(_vm.optionalFields.holdReason)?_c('td',[_vm._v("Hold Reason")]):_vm._e(),_c('td',[_vm._v("Job Name")]),(_vm.optionalFields.salesOrderNumber)?_c('td',[_vm._v("Sales Order#")]):_vm._e(),_c('td',[_vm._v("Customer Name")]),(_vm.optionalFields.customerPhone)?_c('td',[_vm._v("Customer Phone")]):_vm._e(),(_vm.optionalFields.lastContacted)?_c('td',[_vm._v("Last Contacted")]):_vm._e(),(_vm.optionalFields.type)?_c('td',[_vm._v("Order Type")]):_vm._e(),(_vm.optionalFields.deliveryMethod)?_c('td',[_vm._v("Delivery/Pickup")]):_vm._e(),_c('td',[_vm._v("Sold Date")]),_c('td',[_vm._v("Due Date")]),(_vm.optionalFields.timeLeft)?_c('td',[_vm._v("Time Left")]):_vm._e()]),(!_vm.orders.length)?_c('tbody',[_vm._m(0)]):_vm._e(),(_vm.orders.length)?_c('tbody',_vm._l((_vm.orders),function(order,index){return _c('tr',{key:index,class:{
          'order-link': true,
          'past-due': _vm.calculatePastDue(
            order.workflow.sales.dueDate,
            order.status
          ),
          'requires-vendor-order': order.requiresVendorOrder,
        },on:{"click":function($event){return _vm.openOrder(order, $event)}}},[_c('td',[_vm._v(_vm._s(order.status))]),(_vm.optionalFields.holdReason)?_c('td',[_vm._v(" "+_vm._s(order.workflow.manufacturing.holdReason)+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(order.jobName))]),(_vm.optionalFields.salesOrderNumber)?_c('td',[_vm._v(" "+_vm._s(order.salesOrderNumber)+" ")]):_vm._e(),_c('td',[_c('span',{staticClass:"customer-name"},[_vm._v(_vm._s(order.customer.name))]),(order.customer.vip)?_c('VIPBadge',{staticClass:"vip-badge"}):_vm._e()],1),(_vm.optionalFields.customerPhone)?_c('td',[_vm._v(" "+_vm._s(order.customer.phone)+" ")]):_vm._e(),(_vm.optionalFields.lastContacted)?_c('td',[_vm._v(" "+_vm._s(_vm._f("daysAgoWithTime")(order.workflow.sales.lastContactedDate))+" ")]):_vm._e(),(_vm.optionalFields.type)?_c('td',[_vm._v(_vm._s(order.type))]):_vm._e(),(_vm.optionalFields.deliveryMethod)?_c('td',[_vm._v(" "+_vm._s(order.deliveryMethod)+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm._f("dateFilter")(order.workflow.sales.soldDate)))]),_c('td',[_vm._v(_vm._s(_vm._f("dateTimeFilter")(order.workflow.sales.dueDate)))]),(_vm.optionalFields.timeLeft)?_c('td',[_c('span',[_c('span',{class:{
                'time-left': true,
                'on-hold': order.status === 'ON-HOLD',
              }},[_vm._v(_vm._s(order.timeLeft))]),(order.fastTrack)?_c('FTBadge',{staticClass:"ft-badge"}):_vm._e()],1)]):_vm._e()])}),0):_vm._e()]),_c('b-modal',{attrs:{"id":("updateOrderModal" + _vm.id),"title":("Order #" + (_vm.currentOrder && _vm.currentOrder.salesOrderNumber
        ? _vm.currentOrder.salesOrderNumber
        : '') + " - " + (_vm.currentOrder && _vm.currentOrder.status)),"size":"xl","hide-footer":""}},[(_vm.currentOrder)?_c('update-order-status',{attrs:{"modalId":_vm.id,"currentOrder":_vm.currentOrder}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mt-3 mb-3"},[_c('div',{staticClass:"loader"})])}]

export { render, staticRenderFns }