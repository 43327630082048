<template>
  <div class="row">
    <div class="col">
      <form @submit.prevent="submitSearch">
        <div class="row mb-3">
          <div class="col-12 col-sm-4">
            <b-input
              type="text"
              v-model="customerStore.state.query.name"
              placeholder="Name"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="customerStore.state.query.email"
              placeholder="Email"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="customerStore.state.query.phone"
              placeholder="Phone"
              class="mt-2"
            ></b-input>
            <b-button
              type="submit"
              variant="info"
              :disabled="submitting"
              class="mt-2"
              >Submit Search</b-button
            >
            <b-button
              type="button"
              variant="secondary"
              :disabled="submitting"
              class="mt-2 ml-2"
              @click="clearSearch"
              >Clear Search</b-button
            >
          </div>
          <div class="col-12 col-sm-4">
            <b-input
              type="text"
              v-model="customerStore.state.query.id"
              placeholder="Customer ID"
              class="mt-2"
            ></b-input>
            <b-form-select
              v-model="customerStore.state.query.type"
              :options="customerTypes"
              class="mt-2"
            ></b-form-select>
            <b-form-checkbox id="vip" v-model="customerStore.state.query.vip"
              >VIP</b-form-checkbox
            >
          </div>
          <div class="col-12 col-sm-4"></div>
        </div>
        <div
          v-if="
            customerStore.state.querySubmitted &&
            customerStore.state.searchResults.length > 0
          "
        >
          Search results ({{ customerStore.state.searchResults.length }})
          <customer-list
            :customers="customerStore.state.searchResults"
            linkAction="detail"
          ></customer-list>
        </div>
        <div
          v-if="
            customerStore.state.querySubmitted &&
            customerStore.state.searchResults.length == 0
          "
        >
          No search results found
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CustomerList from '@/components/CustomerList'
import axios from 'axios'
import { customerTypes } from './customerInputOptions'

export default {
  name: 'CustomerLookup',
  components: { CustomerList },
  inject: ['customerStore'],
  data: function () {
    return {
      submitting: false,
      error: false,
      customerTypes,
    }
  },
  methods: {
    submitSearch() {
      this.submitting = true
      axios
        .get(
          '/services/customers/lookup?email=' +
            encodeURIComponent(this.customerStore.state.query.email) +
            '&type=' +
            this.customerStore.state.query.type +
            '&name=' +
            encodeURIComponent(this.customerStore.state.query.name) +
            '&phone=' +
            this.customerStore.state.query.phone +
            '&id=' +
            this.customerStore.state.query.id +
            '&vip=' +
            (this.customerStore.state.query.vip ? 1 : null),
        )
        .then(response => {
          this.customerStore.setSearchResults(response.data.customers)
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    clearSearch() {
      this.customerStore.clearQuery()
      this.customerStore.clearSearchResults()
    },
  },
}
</script>

<style lang="scss" scoped></style>
