<template>
  <div class="admin-home page-wrapper">
    <div class="current-orders">
      <h3>Current Orders</h3>
      <b-checkbox v-model="fastTrack">Fast Track</b-checkbox>
      <order-stats :orders="fastTrack ? ftOrders : orders"></order-stats>
      <h3>Building Orders</h3>
      <div v-if="currentOrders">
        <BuildingOrderStats
          :orders="currentOrders"
          v-if="false"
        ></BuildingOrderStats>
      </div>

      <!-- // to add next - <br />
      // display in linear feet (need to have building/materials info linked to
      each building) <br />
      // display timing stats ex. how many orders were completed by their due
      dates <br /> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import OrderStats from '@/components/OrderStats'
import BuildingOrderStats from '@/components/BuildingOrderStats'

export default {
  name: 'AdminHome',
  components: { OrderStats, BuildingOrderStats },
  inject: {
    userStore,
  },
  data: function () {
    return {
      orders: [],
      ftOrders: [],
      fastTrack: false,
      currentOrders: null,
    }
  },
  methods: {
    getCurrentBuildingOrders() {
      return axios
        .get('/services/orders/calendar?orderType=BUILDING')
        .then(response => {
          this.currentOrders = response.data.orders
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getCurrentOrders() {
      axios
        .get('/services/orders/current')
        .then(response => {
          console.log('response.data.ordrs: ', response.data.orders)
          this.orders = response.data.orders
          this.ftOrders = _.filter(response.data.orders, order => {
            return order.fastTrack === true
          })
        })
        .catch(error => {
          console.log('this error!!! ', error)
          this.error = true
        })
    },
  },
  mounted() {
    this.getCurrentOrders()
    this.getCurrentBuildingOrders()
  },
}
</script>

<style lang="scss" scoped></style>
