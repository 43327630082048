<template>
  <div>
    <div v-for="(payment, index) in payments" :key="index">
      <b-checkbox
        v-model="payment.done"
        :disabled="true"
        size="md"
        class="operations-checklist-checkbox my-1"
      >
        {{ `${payment.name} Payment Received` }}
      </b-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchedulePaymentsChecklist',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    payments() {
      let payments = []
      if (this.order.payments) {
        payments = this.order.payments.map(payment => {
          return {
            name: payment.name,
            done: payment.actualAmount ? true : false,
          }
        })
      }
      return payments
    },
  },
}
</script>

<style scoped>
.checklist {
  pointer-events: none;
  padding-left: 12px;
}
a {
  color: black;
}
</style>
