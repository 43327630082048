<template>
  <div class="vendors" v-if="vendor">
    <h3>Edit Vendor</h3>
    <form @submit.prevent="showConfirmEdit">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <vendor-form-fields
              :submitting="submitting"
              :vendor="vendor"
            ></vendor-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Edit Vendor</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmEditModal"
      :title="`Edit Vendor ${vendor.name}`"
      hide-footer
    >
      <p>Are you sure you want to edit {{ vendor.name }}</p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmEditModal')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitEditVendor"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment-business-days'
import VendorFormFields from '@/components/VendorFormFields'

export default {
  name: 'VendorEditPage',
  components: { VendorFormFields },
  props: {},
  inject: {
    userStore,
  },
  data: function () {
    return {
      vendor: null,
      submitting: false,
    }
  },
  methods: {
    showConfirmEdit() {
      this.$bvModal.show('confirmEditModal')
    },
    submitEditVendor() {
      this.submitting = true
      const data = _.clone(this.vendor)
      return axios
        .put('/services/vendors/' + data.id, data)
        .then(response => {
          this.$bvModal.hide('confirmEditModal')
          this.$parent.vendor = response.data.vendor
          return this.$toasted.success('vendor edited', {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(err => {
          console.log('err', err)
          return this.$toasted.error(
            'Error editing vendor: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {
    this.vendor = _.cloneDeep(this.$parent.vendor)
  },
}
</script>

<style lang="scss" scoped>
.orders {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 1200px;
  max-width: 100%;
}

.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 1000px;
  max-width: 90%;
}
</style>
