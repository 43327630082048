<template>
  <div>
    <div v-for="(scheduleDate, index) in schedule" :key="index">
      <b-checkbox
        v-model="scheduleDate.done"
        :disabled="true"
        size="md"
        class="operations-checklist-checkbox my-1"
      >
        {{ `${scheduleDate.name} Date Scheduled` }}
      </b-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchedulePaymentsChecklist',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    schedule() {
      let schedule = []
      if (this.order.schedule) {
        schedule = this.order.schedule.map(payment => {
          return {
            name: payment.name,
            done: payment.dateTime ? true : false,
          }
        })
      }
      return schedule
    },
  },
}
</script>

<style scoped>
.checklist {
  pointer-events: none;
  padding-left: 12px;
}
a {
  color: black;
}
</style>
