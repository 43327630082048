import _ from 'lodash'

export function byDateTime(a, b) {
  var dateA = new Date(a.timestamp || a.dateTime)
  var dateB = new Date(b.timestamp || b.dateTime)
  return dateA - dateB
}

export function createBuildingInstallSchedule(building) {
  const dates = []
  if (building.install.doInstall) {
    dates.push({
      name: 'Offsite',
    })
  }

  if (building.foundation.doConcrete) {
    // and no designation that customer is doing own concrete?
    dates.push({
      name: 'Concrete',
    })
  }

  dates.push({
    name: 'Delivery',
  })
  // todo:  Add Grading
  if (building.install.doInstall) {
    dates.push({
      name: 'Framing',
    })
    dates.push({
      name: 'Sheeting',
    })
  }

  if (
    building.roof.insulation.type.match('Spray Foam') ||
    building.walls.insulation.type.match('Spray Foam')
  ) {
    dates.push({
      name: 'Spray Foam',
    })
  }
  return dates
}

export function createBuildingPaymentSchedule(building) {
  const payments = []
  const depositAmount = _.round(building.price.kit.total / 10, 2)
  const concreteAmount = _.round(
    building.foundation.price.total || building.foundation.price.max,
    2,
  )
  const sprayFoamAmount = getSprayFoamAmount(building)
  const deliveryAmount = _.round(
    building.price.kit.total - depositAmount - sprayFoamAmount,
    2,
  ) // remaining materials price
  const installAmount = _.round(
    building.price.kitAndInstall.total - building.price.kit.total,
    2,
  )
  const firstInstallAmount = _.round(installAmount / 2, 2)
  const secondInstallAmount = _.round(installAmount - firstInstallAmount, 2)

  payments.push({
    name: 'Deposit',
    expectedAmount: depositAmount,
  })
  // todo: Add Grading

  if (building.foundation.doConcrete) {
    // and no designation that customer is doing own concrete?
    payments.push({
      name: 'Concrete',
      expectedAmount: concreteAmount,
    })
  }

  payments.push({
    name: 'Delivery',
    expectedAmount: deliveryAmount,
  })

  if (building.install.doInstall) {
    payments.push({
      name: 'Framing',
      expectedAmount: firstInstallAmount,
    })
    payments.push({
      name: 'Sheeting',
      expectedAmount: secondInstallAmount,
    })
  }
  // todo: Add Electrical?
  if (sprayFoamAmount > 0) {
    payments.push({
      name: 'Spray Foam',
      expectedAmount: sprayFoamAmount,
    })
  }

  return payments
}

function getSprayFoamTotal(building) {
  let roofInsulationCost = 0
  let wallInsulationCost = 0
  if (building.roof.insulation.type.match('Spray Foam')) {
    roofInsulationCost = building.roof.insulation.price.total
  }
  if (building.walls.insulation.type.match('Spray Foam')) {
    wallInsulationCost = building.walls.insulation.price.total
  }
  return roofInsulationCost + wallInsulationCost
}

function getSprayFoamAmount(building) {
  let sprayFoamTotal = getSprayFoamTotal(building)
  return _.round(sprayFoamTotal * 0.9)
}

export function pickTextColor(
  bgColor,
  lightColor = '#fff',
  darkColor = '#000',
) {
  try {
    if (!bgColor) return ''
    var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
    var r = parseInt(color.substring(0, 2), 16) // hexToR
    var g = parseInt(color.substring(2, 4), 16) // hexToG
    var b = parseInt(color.substring(4, 6), 16) // hexToB
    var uicolors = [r / 255, g / 255, b / 255]
    var c = uicolors.map(col => {
      if (col <= 0.03928) {
        return col / 12.92
      }
      return Math.pow((col + 0.055) / 1.055, 2.4)
    })
    var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
    return L > 0.179 ? darkColor : lightColor
  } catch (error) {
    return ''
  }
}
