<template>
  <div class="tasks page-wrapper">
    <h1 class="page-h1">Tasks</h1>
    <div class="loader" v-if="loading">
      <circle-loader :size="40" classs="mt-5"></circle-loader>
    </div>
    <div v-if="!loading">
      <div class="home text-left page-wrapper">
        <div class="nav text-center">
          <router-link
            class="nav-link"
            :to="`/app/tasks/`"
            style="margin-left: 2px"
            >My Tasks</router-link
          >
          <router-link class="nav-link" :to="`/app/tasks/sales`"
            >Sales</router-link
          >
          <router-link class="nav-link" :to="`/app/tasks/operations`"
            >Operations</router-link
          >
          <router-link class="nav-link" :to="`/app/tasks/manufacturing`"
            >Manufacturing</router-link
          >
          <router-link class="nav-link" :to="`/app/tasks/finance`"
            >Finance</router-link
          >
        </div>
        <b-button
          class="add-task-btn"
          @click="$bvModal.show('add-task-modal')"
          variant="primary"
        >
          Add Task
        </b-button>
        <router-view />
      </div>
      <b-modal id="add-task-modal" title="Add Task" hide-footer size="lg">
        <add-task></add-task>
      </b-modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import CircleLoader from '../components/CircleLoader.vue'
import AddTask from '../components/AddTask.vue'

export default {
  name: 'TasksPage',
  components: { CircleLoader, AddTask },
  inject: ['userStore', 'taskStore'],
  data: function () {
    return {
      currentTasks: null,
      loading: true,
      orders: [],
    }
  },
  methods: {
    getCurrentTasks() {
      return axios
        .get('/services/tasks/current')
        .then(response => {
          this.taskStore.addTasks(response.data.tasks)
          this.currentTasks = this.taskStore.state.tasks
        })
        .catch(err => {
          console.log('err', err)
          this.currentTasks = []
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getCurrentTasks()
  },
}
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
  color: #2c3e50;
  padding-left: 8px;
  padding-right: 8px;
  &.router-link-exact-active {
    color: #42b983;
  }

  // &:first-child {
  //   margin-left: 0px;
  // }
}

.nav {
  margin-bottom: 18px;
}

.nav-link {
  &::after {
    content: '|';
    color: #2c3e50;
    margin-left: 16px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.add-task-btn {
  position: absolute;
  right: 10px;
  top: 70px;
}
</style>
