<template>
  <div>
    <div v-if="loading" class="mt-5">
      <circle-loader :size="40"></circle-loader>
    </div>
    <div v-if="!loading">
      <div class="home text-left page-wrapper">
        <h1 class="page-h1">Sales</h1>
        <div class="nav text-center">
          <router-link
            class="nav-link"
            :to="`/app/sales/dashboard`"
            style="margin-left: 2px"
            >Dashboard</router-link
          >
          <router-link
            class="nav-link"
            :to="`/app/sales/leads`"
            style="margin-left: 2px"
            >Leads</router-link
          >
          <router-link class="nav-link" :to="`/app/sales/trade-shows`"
            >Trade Shows</router-link
          >
          <router-link class="nav-link" :to="`/app/sales/traffic`"
            >Sales Traffic</router-link
          >
          <router-link class="nav-link" :to="`/app/sales/stats`"
            >Team Stats</router-link
          >
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import CircleLoader from '@/components/CircleLoader.vue'
import { userStore } from '@/stores/userStore'

export default {
  name: 'SalesLeadDetailPage',
  inject: { userStore },
  props: {
    salesLeadData: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      salesLead: null,
      error: null,
      loading: true,
    }
  },
  components: { CircleLoader },
  methods: {
    goToNewBuilding() {
      this.$router.push({
        name: 'SalesLeadDetailPage',
        params: {
          config: {
            salesLead: {
              name: this.salesLead.name,
              phone: this.salesLead.phone,
              email: this.salesLead.email,
              address: this.salesLead.address,
            },
          },
        },
      })
    },
  },
  mounted() {
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
  color: #2c3e50;
  padding-left: 8px;
  padding-right: 8px;
  &.router-link-exact-active {
    color: #42b983;
  }

  &:first-child {
    margin-left: 0px;
  }
}

.nav {
  margin-bottom: 2px;
}

.nav-link {
  &::after {
    content: '|';
    color: #2c3e50;
    margin-left: 16px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.add-button {
  height: 28px;
  line-height: 28px;
  padding: 0px 6px;
  background-color: #42b983;
  border-color: #4bc08b;
  font-size: 16px;
}
</style>
