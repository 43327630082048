<template>
  <div class="row">
    <div class="col">
      <div class="inputs">
        <div class="section-header">Order Details</div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="sold-date">Sold Date</label>
              <b-form-datepicker
                id="sold-date"
                v-model="order.workflow.sales.soldDate"
                required
                locale="en-US"
                :disabled="submitting"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="orderType">Order Type</label>
              <b-form-select
                id="orderType"
                v-model="order.type"
                :options="orderTypes"
                required
                :disabled="submitting"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="due-date">Due Date</label>
              <date-time-picker
                v-model="order.workflow.sales.dueDate"
                :required="true"
                :submitting="submitting"
              ></date-time-picker>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="jobName">
                Sales Order Number
                <span v-if="!requireSalesOrderNumber">(Optional)</span>
              </label>
              <b-form-input
                id="jobName"
                type="text"
                v-model="order.salesOrderNumber"
                :required="requireSalesOrderNumber"
                :disabled="submitting"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="requires-vendor-order"
                >Requires Vendor Order
                <help-icon
                  text="Extends due date by 2 weeks for pickup orders"
                ></help-icon>
              </label>
              <b-form-checkbox
                id="requires-vendor-order"
                v-model="order.requiresVendorOrder"
                :disabled="submitting"
              ></b-form-checkbox>
            </div>
            <div class="form-group" v-if="order.requiresVendorOrder">
              <label for="vendorName"> Vendor Name (Optional) </label>
              <b-form-input
                id="vendorName"
                type="text"
                v-model="order.vendorName"
                :disabled="submitting"
              ></b-form-input>
            </div>
          </div>
          <div class="col">
            <div>
              <label for="fast-track">Order Fast Track</label>
              <b-form-checkbox
                id="fast-track"
                v-model="order.fastTrack"
                :disabled="submitting"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="jobName">Job Name</label>
              <b-form-input
                id="jobName"
                type="text"
                v-model="order.jobName"
                required
                :disabled="submitting"
              ></b-form-input>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="deliveryMethod">Delivery Method</label>
              <b-form-select
                id="deliveryMethod"
                v-model="order.deliveryMethod"
                :options="deliveryMethods"
                required
                :disabled="submitting"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="orderAmount">Order Amount (optional)</label>
              <b-input-group prepend="$">
                <b-form-input
                  id="orderAmount"
                  type="text"
                  v-model="order.totalAmount"
                  :disabled="submitting"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <div class="section-header">Customer Details</div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="customerName">Customer Name</label>
              <div class="hidden-customer-name-wrapper">
                <vue-typeahead-bootstrap
                  id="customerName"
                  v-model="order.customer.name"
                  :data="customerStore.customerNames.value"
                  :required="true"
                  input-name="customerName"
                  label="Customer Name"
                  @hit="setCustomer"
                />
                <input
                  type="text"
                  v-model="order.customer.name"
                  required
                  tabindex="-1"
                  class="hidden-customer-name"
                />
              </div>

            </div>
          </div>
          <div class="col">
            <div class="mt-5">
              <label for="vip">Customer VIP</label>
              <b-form-checkbox
                id="vip"
                v-model="order.customer.vip"
                :disabled="submitting"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="customerPhone">Customer Phone (optional)</label>
              <b-form-input
                id="customerPhone"
                type="text"
                v-model="order.customer.phone"
                :disabled="submitting"
              ></b-form-input>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="customerEmail">Customer Email (optional)</label>
              <b-form-input
                id="customerEmail"
                type="email"
                v-model="order.customer.email"
                :disabled="submitting"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="mb-2 section-header" v-if="order.type == 'BUILDING'">
          Building Details
        </div>
        <div class="row" v-if="order.type == 'BUILDING'">
          <div class="col">
            <div>
              <label for="install">Install</label>
              <b-form-checkbox
                id="install"
                v-model="order.workflow.install.doInstall"
                :disabled="submitting"
              ></b-form-checkbox>
            </div>
            <div>
              <label for="concrete">Concrete</label>
              <b-form-checkbox
                id="concrete"
                v-model="order.workflow.install.doConcrete"
                :disabled="submitting"
              ></b-form-checkbox>
            </div>
          </div>
          <div class="col">
            <div>
              <label for="permitting">Permitting</label>
              <b-form-checkbox
                id="permitting"
                v-model="order.workflow.permitting.doPermitting"
                :disabled="submitting || order.workflow.install.doInstall"
              ></b-form-checkbox>
            </div>
            <div>
              <label for="engineering">Engineering</label>
              <b-form-checkbox
                id="engineering"
                v-model="order.workflow.engineering.doEngineering"
                :disabled="submitting || order.workflow.install.doInstall"
              ></b-form-checkbox>
            </div>
            <div>
              <label for="architecture">Architecture</label>
              <b-form-checkbox
                id="architecture"
                v-model="order.workflow.architecture.doArchitecture"
                :disabled="submitting"
              ></b-form-checkbox>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="productId">{{ productIdLabel }}</label>
              <b-form-input
                id="productId"
                type="text"
                v-model="order.productId"
                :disabled="submitting"
                placeholder="20XX-XXX"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="section-header">Site Address</div>
            <label for="apn">APN</label>
            <b-form-input
              class="px-3 mb-1"
              id="apn"
              type="text"
              v-model="order.siteAddress.apn"
              :disabled="submitting"
            ></b-form-input>
            <div class="px-3">
              <AddressFields
                :required="false"
                :sending="submitting"
                :address="order.siteAddress"
                :autoComplete="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressFields from '@/components/AddressFields.vue'
import { deliveryMethods, orderTypes } from '@/components/orderInputOptions'
import moment from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import HelpIcon from '@/components/HelpIcon.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import _ from 'lodash'

export default {
  name: 'OrderFormFields',
  props: {
    order: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
    requireSalesOrderNumber: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AddressFields,
    DateTimePicker,
    HelpIcon,
    VueTypeaheadBootstrap,
  },
  inject: ['customerStore'],
  data: function () {
    return {
      orderTypes,
      deliveryMethods,
      localDueDate: '',
      editDueDate: false,
    }
  },
  watch: {
    'order.workflow.sales.soldDate': {
      handler: function (soldDate) {
        this.setDueDate()
      },
    },
    'order.deliveryMethod': {
      handler: function (deliveryMethod) {
        this.setDueDate()
      },
    },
    'order.type': {
      handler: function (type) {
        this.setDueDate()
      },
    },
    'order.workflow.install.doInstall': {
      handler: function (doInstall) {
        if (doInstall) {
          this.order.workflow.engineering.doEngineering = true
          this.order.workflow.permitting.doPermitting = true
        }
        this.setDueDate()
      },
    },
    'order.workflow.engineering.doEngineering': {
      handler: function (doInstall) {
        this.setDueDate()
      },
    },
    'order.workflow.permitting.doPermitting': {
      handler: function (doInstall) {
        this.setDueDate()
      },
    },
    'order.workflow.architecture.doArchitecture': {
      handler: function (doArchitecture) {
        this.setDueDate()
      },
    },
    'order.requiresVendorOrder': {
      handler: function (requiresVendorOrder) {
        this.setDueDate()
      },
    },
  },
  methods: {
    // TODO: How doe fast track change these dates?
    setDueDate() {
      const soldDate = this.order.workflow.sales.soldDate
      const deliveryMethod = this.order.deliveryMethod
      let days = 0
      if (this.order.type === 'MATERIALS') {
        if (this.order.requiresVendorOrder) {
          days += 3 + 5 * 2 // 3 days + 2 weeks for all materials orders if requires order from another vendor
        } else {
          days += deliveryMethod === 'DELIVERY' ? 8 : 3 // 3 bus days for pickup, 8 for deliveries
        }
      } else if (this.order.type === 'BUILDING') {
        if (this.order.workflow.architecture.doArchitecture) {
          days += 5 * 4 // 4 weeks in business days for architecture for now
        }
        if (this.order.workflow.engineering.doEngineering) {
          days += 5 * 2 // two weeks in business days for engineering
        }
        if (this.order.workflow.permitting.doPermitting) {
          days += 5 * 6 // 6 weeks in business days for permitting
        }
        if (this.order.workflow.install.doInstall) {
          days += 5 * 6 // installs are 6 weeks of async;
          // call before you dig in first 3 days, concrete in first week,
          // manufacturing done by week 3, install done by week 6
        } else {
          days += 5 * (3 + 2) // kits are 3 weeks manufacturing, 2 weeks pickup/delivery
        }
      }

      const dueDate = moment(soldDate)
        .businessAdd(days)
        .set('hour', 8)
        .set('minute', 0)
        .set('seconds', 0)
      this.localDueDate = dueDate.format('YYYY-MM-DDThh:mm')
      this.order.workflow.sales.dueDate = dueDate
    },
    setCustomer() {
      let customer = _.find(this.customerStore.customers, customer => {
        return customer.name === this.order.customer.name
      })
      if (customer) {
        this.order.customer.email = customer.email || ''
        this.order.customer.phone = customer.phone || ''
        this.order.customer.vip = customer.vip || false
      }
    },
  },
  mounted() {
    if (!this.order.workflow.sales.dueDate) {
      this.setDueDate()
    }
  },
  computed: {
    productIdLabel() {
      if (this.order.type === 'BUILDING') {
        return 'Building ID'
      } else if (this.order.type === 'MATERIALS') {
        return 'Materials ID'
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.section-header {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 6px;
  font-weight: 500;
}

.hidden-customer-name-wrapper {
  position: relative;
}
.hidden-customer-name {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
}
</style>
