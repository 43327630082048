<template>
  <div>
    <div class="customer-overview" v-if="this.customer">
      <div class="row customer-summary">
        <div class="col">
          <div>
            <div class="customer-name">
              {{ customer.name }}<VIPBadge v-if="customer.vip"></VIPBadge>
              <br />
            </div>
            <div class="customer-since">
              customer since {{ customer.createdAt | yearFilter }}
            </div>
          </div>
          {{ customer.email }}<br />
          {{ customer.phone }}
        </div>
        <div class="col">
          <div>{{ customer.type }}</div>
          <div class="address">
            <div class="mt-3">{{ customer.address.street1 }}</div>
            <div>
              {{ customer.address.city }}, {{ customer.address.state }}
              {{ customer.address.zip }}
            </div>
            <div v-if="customer.address.county">
              {{ customer.address.county }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h4>Contacts</h4>
          <div class="mt-2" v-if="customer.contacts.length == 0">
            - No Contacts -
          </div>
          <div class="row">
            <div
              class="contact-card col col-6"
              v-for="(contact, index) in customer.contacts"
              :key="`contact-${index}`"
            >
              <b-card>
                {{ contact.firstName }} {{ contact.lastName }}<br />
                {{ contact.email }} <br />
                {{ contact.phone }}
              </b-card>
            </div>
          </div>
        </div>
        <div class="col">
          <h4>Locations</h4>
          <div class="mt-2" v-if="customer.locations.length == 0">
            - No Locations -
          </div>
          <div class="row">
            <div
              class="location-card col col-6"
              v-for="(location, index) in customer.locations"
              :key="`location-${index}`"
            >
              <b-card>
                {{ location.name }} <br />
                {{ location.address.street1 }} <br />
                {{ location.address.city }}, {{ location.address.state }}
                {{ location.address.zip }}
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h4>Orders</h4>
          <CustomerOrderList
            :customer="customer"
            class="list"
          ></CustomerOrderList>
        </div>
        <div class="col">
          <h4>Buildings</h4>
          <CustomerBuildingList
            :customer="customer"
            class="list"
          ></CustomerBuildingList>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <h4>Notes</h4>
          <customer-notes :customer="customer"></customer-notes>
        </div>
        <div class="col">
          <h4>Tasks</h4>
          <CustomerTaskList
            :customer="customer"
            class="list"
          ></CustomerTaskList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerNotes from '@/components/CustomerNotes'
import VIPBadge from '@/components/VIPBadge'
import axios from 'axios'
import CustomerBuildingList from '../components/CustomerBuildingList.vue'
import CustomerOrderList from '../components/CustomerOrderList.vue'
import CustomerTaskList from '../components/CustomerTaskList.vue'
export default {
  name: 'CustomerDetailPage',
  props: {},
  data: function () {
    return {
      customer: null,
    }
  },
  components: {
    CustomerNotes,
    VIPBadge,
    CustomerBuildingList,
    CustomerOrderList,
    CustomerTaskList,
  },
  mounted() {
    this.customer = this.$parent.customer
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.customer-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.customer-name {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 22px;
}
.customer-since {
  font-size: 14px;
  margin-top: -6px;
}
.contact-card .card {
  background: rgb(240, 250, 255);
}

.location-card .card {
  background: rgb(240, 255, 244);
}
.list {
  //   max-height: 300px;
  //   overflow: scroll;
}
</style>
