<template>
  <div>
    <h3>Manufacturing</h3>
    <div class="order-manufacturing">
      <div class="row">
        <div class="col">
          <div class="update-wrapper mt-5" v-if="order">
            <update-order-status
              :current-order="order"
              :modal-id="9999"
            ></update-order-status>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateOrderStatus from '@/components/UpdateOrderStatus'

export default {
  name: 'OrderManufacturingPage',
  components: { UpdateOrderStatus },
  props: {},
  data: function () {
    return {
      order: null,
    }
  },
  mounted() {
    // todo: can i move this to props?
    this.order = this.$parent.order
  },
}
</script>

<style lang="scss" scoped></style>
