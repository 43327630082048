<template>
  <div>
    <label for="name"> <strong>Name</strong> </label>
    <b-form-input
      id="name"
      type="text"
      v-model="task.name"
      required
    ></b-form-input>
    <label for="details" class="mt-2"> <strong>Details</strong> </label>
    <b-textarea id="details" v-model="task.details" :required="false">
    </b-textarea>
    <div class="row">
      <div class="col">
        <label for="assigned-to" class="mt-3"
          ><strong>Department</strong>
          <HelpIcon
            text="Selecting a department will automatically assign to an employee in that department"
          ></HelpIcon>
        </label>
        <b-form-select
          id="openingType"
          v-model="task.department"
          :options="departmentOptions"
          required
          @change="handleSetDepartment(task)"
        ></b-form-select>
      </div>
      <div class="col">
        <label for="assigned-to" class="mt-3"
          ><strong>Assigned To</strong>
          <HelpIcon text="Track who this task is assigned to"></HelpIcon>
        </label>
        <b-form-select
          id="openingType"
          v-model="task.assignedTo"
          :options="assignedToOptions"
          required
        ></b-form-select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label for="end-schedule-date">
          <strong>Due Date</strong>
        </label>
        <b-form-datepicker
          id="end-schedule-date"
          v-model="task.dueDate"
          :value-as-date="true"
          :required="true"
        ></b-form-datepicker>
      </div>
      <div class="col">
        <label for="End Time">
          <strong>Due Time </strong>
        </label>
        <b-form-timepicker
          id="end-time"
          v-model="task.dueTime"
        ></b-form-timepicker>
      </div>
    </div>

    <div class="mt-4">
      <b-checkbox v-model="task.important" size="lg">
        Mark Important?
        <HelpIcon
          text="Marking a task as important will make it show up at the top of task lists"
        ></HelpIcon>
      </b-checkbox>
    </div>
    <div class="task-links">
      <div class="mt-5 link-to-objects">Optional: Link to</div>
      <div class="row mt-3">
        <div class="col">
          <div class="">
            <label for="customerName"
              ><strong>Customer</strong
              ><help-icon
                text="Start typing to pull up a list of customers. You must select a customer from the list."
              ></help-icon
            ></label>
            <div class="hidden-customer-name-wrapper">
              <vue-typeahead-bootstrap
                id="customerName"
                v-model="task.customerName"
                :data="customerStore.customerNames.value"
                input-name="customerName"
                label="Customer Name"
                @hit="setCustomer"
                @keyup="setCustomerName"
              />
              <input
                type="text"
                v-model="task.customerId"
                tabindex="-1"
                class="hidden-customer-name"
              />
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
      <div class="row mt-3" v-if="false">
        <div class="col">
          <label for="order"><strong>Order</strong></label>
          <vue-typeahead-bootstrap
            id="order"
            v-model="task.order"
            :data="customerOrders"
            :required="true"
            input-name="order"
            label="Order"
            @hit="setOrder"
            :disabled="!task.customerName"
          />
        </div>
        <div class="col">
          <label for="Building"><strong>Building</strong></label>
          <vue-typeahead-bootstrap
            id="order"
            v-model="task.order"
            :data="customerOrders"
            :required="true"
            input-name="order"
            label="Order"
            @hit="setBuilding"
            :disabled="!task.customerName"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from '@/components/HelpIcon.vue'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

const departmentDefaults = {
  OPERATIONS: {
    default: 'customercare@metalsdirectinc.com',
  },
  SALES: {
    default: 'erik@metalsdirectinc.com',
  },
  MANUFACTURING: {
    default: 'casmer@metalsdirectinc.com',
  },
  FINANCE: {
    default: 'yesha@metalsdirectinc.com',
  },
}

export default {
  name: 'TaskFormFields',
  props: {
    task: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
  },
  components: { HelpIcon, VueTypeaheadBootstrap },
  inject: ['userStore', 'customerStore'],
  data: function () {
    return {
      departmentOptions: Object.keys(departmentDefaults),
      assignedToOptions: this.userStore.userList.map(u => ({
        text: u.email,
        value: u.email,
      })),
      customerOrders: [],
    }
  },
  watch: {},
  methods: {
    handleSetDepartment(task) {
      this.task.assignedTo = departmentDefaults[task.department].default
    },
    setCustomerName(e) {
      this.task.customerName = e.target.value
    },
    setCustomer(name) {
      let customer = _.find(this.customerStore.customers, customer => {
        return customer.name === name
      })
      this.task.customerId = customer._id
      this.task.customerName = name
    },
    setOrder(order) {},
    setBuilding(building) {},
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}
.hidden-customer-name-wrapper {
  position: relative;
}
.hidden-customer-name {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
}

.link-to-objects {
  border-bottom: 1px solid gray;
}
</style>
