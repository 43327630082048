<template>
  <div class="tradeShows" v-if="tradeShow">
    <form @submit.prevent="showConfirmEdit">
      <div class="row">
        <div class="col">
          <div class="inputs">
            <trade-show-form-fields
              :submitting="submitting"
              :tradeShow="tradeShow"
            ></trade-show-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Edit Trade Show</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmEditModal"
      :title="`Edit Trade Show ${tradeShow.name}`"
      hide-footer
    >
      <p>Are you sure you want to edit {{ tradeShow.name }}</p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmEditModal')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitEditTradeShow"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment-business-days'
import TradeShowFormFields from '@/components/TradeShowFormFields'

export default {
  name: 'TradeShowEditPage',
  components: { TradeShowFormFields },
  props: {
    tradeShow: {
      type: Object,
      required: true,
    },
  },
  inject: {
    userStore,
  },
  data: function () {
    return {
      submitting: false,
    }
  },
  methods: {
    showConfirmEdit() {
      this.$bvModal.show('confirmEditModal')
    },
    submitEditTradeShow() {
      this.submitting = true
      const data = _.clone(this.tradeShow)
      return axios
        .put('/services/trade-shows/' + data.id, data)
        .then(response => {
          this.$bvModal.hide('confirmEditModal')
          this.$parent.tradeShow = response.data.tradeShow
          return this.$toasted.success('trade show edited', {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(err => {
          console.log('err', err)
          return this.$toasted.error(
            'Error editing trade show: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
.orders {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 1200px;
  max-width: 100%;
}

.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 1000px;
  max-width: 90%;
}
</style>
