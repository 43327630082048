<template>
    <div>
    <!-- <h1>Configure and Price Your Next Metal Building with Metals Direct</h1> -->
    <div class="container-fluid wrapper">
        <div id="customer-site-inputs" class="row mt-4 mb-4">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title text-left">Custom {{building.type}} Configuration For:</div>
                        <div class="row text-left">
                            <div class="col">
                                Email: {{building.customer.email}}<br>
                                Zipcode: {{building.site.address.zip}}
                            </div>
                            <div class="col">
                                Snow load: {{building.snowLoad}} psf <br>
                                Wind load: {{building.windLoad}} mph <br>
                            </div>
                        </div>
                    </div>
                    <b-button type="button" 
                        variant="secondary"
                        size="sm"
                        class="open-build-site-btn" 
                        :disabled="sending" 
                        @click="$bvModal.show('customerSiteInfo')">
                        Edit
                    </b-button>
                </div>
            </div>
            <b-modal id="customerSiteInfo" title="Welcome, let's get started" @ok="submitCustomer($event)" size="xl">
                <div class="container-fluid">
                    <!-- <div class="row">
                        <div class="col-12">
                            <h5>Welcome, Let's get started</h5>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <p class="site-info-p">
                                What kind of building are you looking for? 
                            </p>
                            <div class="row">
                                <div class="col site-info-building-option" @click="building.type='Shop'" v-bind:class="{selected: building.type == 'Shop'}">
                                    <img class="site-info-icon" src="../assets/shop-icon.jpg">
                                    <div class="site-info-icon-label">Shop / Garage</div>
                                </div>
                                <div class="col site-info-building-option" @click="building.type='Carport'" v-bind:class="{selected: building.type == 'Carport'}">
                                    <img class="site-info-icon" src="../assets/carport-icon.png">
                                    <div class="site-info-icon-label">Carport</div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col site-info-building-option" @click="building.type='RV Cover'" v-bind:class="{selected: building.type == 'RV Cover'}">
                                    <img class="site-info-icon" src="../assets/rv-cover-icon.png">
                                    <div class="site-info-icon-label">RV Cover</div>
                                </div>
                                <div class="col site-info-building-option" @click="building.type='Shed'" v-bind:class="{selected: building.type == 'Shed'}">
                                    <img class="site-info-icon" src="../assets/shed-icon.png">
                                    <div class="site-info-icon-label">Shed</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <p class="site-info-p">
                                Where will the building be located?
                            </p>
                            <div v-show="!useManualLoads">
                                <label for="zip">Zip Code</label>
                                <b-input-group>
                                    <b-form-input id="zip" v-model="building.site.address.zip" type="text" :disabled="sending"></b-form-input>
                                </b-input-group>
                                <div class="site-info-sublabel">
                                    Used to retrieve snow and wind loads for your location. 
                                    <span class="manual-load-link" @click="useManualLoads = true;">Enter Manually Instead</span>
                                </div>
                            </div>
                            
                            <div v-show="useManualLoads">
                                <div class="row mb-2">
                                    <div class="col" >
                                        <label for="snowLoad">Snow Load</label>
                                        <b-input-group append="#psf">
                                            <b-form-input id="snowLoad" v-model="building.snowLoad" type="number" :disabled="sending" max="100" min="0" required></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col">
                                        <label for="windLoad">Wind Load</label>
                                        <b-input-group append="mph">
                                            <b-form-input id="windLoad" v-model="building.windLoad" type="number" :disabled="sending" max="220" min="0" required></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="site-info-sublabel">
                                    Don't know this information?
                                    <span class="manual-load-link" v-show="useManualLoads" @click="useManualLoads = false;">Use Zipcode</span> or
                                    <span class="manual-load-link" v-show="useManualLoads" @click="setEstimatedLoads()">Use Estimated Values</span>
                                </div>
                            </div>
                            <label for="email" class="mt-4">Email</label>
                            <b-input-group>
                                <b-form-input id="email" v-model="building.customer.email" type="email" :disabled="sending"></b-form-input>
                            </b-input-group>
                            <div class="site-info-sublabel">
                                Optional, if you would like to save and edit your building later
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
        <div class="row">
            <div id="configuration-inputs" class="col">
                <b-form @submit.prevent="submitBuilding" @reset="resetConfigForm">
                    <div class="card">
                        
                        <div class="card-body">
                            <div class="card-title text-left">Building Dimensions</div>
                            <div class="container-fluid">
                                <div class="row mb-2">
                                    <div class="col">
                                        <img class="wlh-icon" src="../assets/wlh-icon.png">
                                    </div>
                                    <div class="col">
                                        <label class="mt-3" for="width">Width</label>
                                        <b-input-group append="ft">
                                            <b-form-input id="width" v-model="building.dimensions.width" type="text" :disabled="sending" required></b-form-input>
                                        </b-input-group>
                                        <label class="mt-3" for="length">Length</label>
                                        <b-input-group append="ft">
                                            <b-form-input id="length" v-model="building.dimensions.length" type="text" :disabled="sending" required></b-form-input>
                                        </b-input-group>
                                        <label class="mt-3" for="height">Height</label>
                                        <b-input-group append="ft">
                                            <b-form-input v-on:blur="handleHeightBlur" id="height" v-model="building.dimensions.height" type="text" :disabled="sending" required></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-body">
                            <div class="card-title text-left">Panels &amp; Colors</div>
                            <div class="container-fluid">
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="metalPanel">
                                            Metal Panel Type
                                            <span class="help-link" v-b-modal.panels-modal>Help me choose</span>
                                            <b-modal id="panels-modal" size="xl" hide-header hide-footer>
                                                <img src="../assets/panel-options.png" alt="metals-direct-panel-options">
                                            </b-modal>
                                        </label>
                                        <b-input-group>
                                            <b-form-select id="metalPanel" v-model="building.metalPanel" :options="metalPanelOptions"></b-form-select>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="wallDesign">Wall Design</label>
                                        <b-input-group>
                                            <b-form-select id="wallDesign" v-model="building.walls.design" :options="designOptions"></b-form-select>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <label for="wallColor">
                                            Wall Color
                                            <span class="help-link" v-b-modal.colors-modal>Color Choices</span>
                                            <b-modal id="colors-modal" size="lg" hide-header hide-footer>
                                                <img src="../assets/color-options.jpg" alt="metals-direct-panel-options">
                                            </b-modal>
                                        </label>
                                        <b-input-group>
                                            <b-form-select id="wallColor" v-model="building.walls.color" :options="colorOptions"></b-form-select>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="roofDesign">Roof Design</label>
                                        <b-input-group>
                                            <b-form-select id="roofDesign" v-model="building.roof.design" :options="designOptions" :disabled="sending" required></b-form-select>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <label for="roofColor">
                                            Roof Color
                                            <span class="help-link" v-b-modal.colors-modal>Color Choices</span>
                                            <b-modal id="colors-modal" size="lg" hide-header hide-footer>
                                                <img src="../assets/color-options.jpg" alt="metals-direct-panel-options">
                                            </b-modal>
                                        </label> 
                                        <b-input-group>
                                            <b-form-select id="roofColor" v-model="building.roof.color" :options="colorOptions" :disabled="sending"></b-form-select>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="trimColor">Trim Color</label>
                                        <b-input-group>
                                            <b-form-select id="trimColor" v-model="building.trimColor" :options="colorOptions"></b-form-select>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <b-input-group class="">
                                            <b-form-checkbox id="deluxeTrimPackage" v-model="building.deluxeTrimPackage.doDeluxeTrim"></b-form-checkbox>
                                        <label for="deluxeTrimPackage" class="mr-2">Deluxe Trim Package</label>
                                        </b-input-group>
                                        <b-input-group>
                                            <b-form-checkbox id="sameColorScrews" v-model="building.sameColorScrews"></b-form-checkbox>
                                            <label for="sameColorScrews" class="mr-2">Same Color Screws</label>
                                        </b-input-group>
                                        <b-input-group>
                                            <b-form-checkbox id="closure" v-model="building.closure"></b-form-checkbox>
                                            <label for="closure" class="mr-2">Closure</label>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-body">
                            <div class="card-title text-left">Roof Options</div>
                            <div class="container-fluid">
                                <div class="row mb-4">
                                    <div class="col input-col">
                                        <label for="roofPitch">Roof Pitch</label>
                                        <b-input-group>
                                            <b-form-select id="roofPitch" v-model="building.roof.pitch.slope" :options="roofPitchOptions" :disabled="sending" required></b-form-select>
                                        </b-input-group>
                                        <img src="../assets/roof-pitch.png" class="roof-pitch-icon mt-3">
                                    </div>
                                    <div class="col input-col">
                                        <label for="trussType">Truss Type</label>
                                        <b-input-group>
                                            <b-form-select id="trussType" v-model="building.truss.type" :options="trussTypeOptions" :disabled="sending" required></b-form-select>
                                        </b-input-group>
                                        <img src="../assets/truss-types.png" class="truss-types-icon mt-3">
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="gableOverhang">Gable Overhang Length</label>
                                        <b-input-group>
                                            <b-form-select id="gableOverhang" v-model="building.roof.gableOverhang.overhang" :options="overhangOptions"></b-form-select>
                                        </b-input-group>
                                        <label for="eaveOverhang">Eave Overhang Length</label>
                                        <b-input-group>
                                            <b-form-select id="eaveOverhang" v-model="building.roof.eaveOverhang.overhang" :options="overhangOptions"></b-form-select>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <img src="../assets/overhangs.png" class="gable-icon">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4" v-if="building.type == 'Carport' || building.type == 'RV Cover'">
                        <div class="card-body">
                            <div class="card-title text-left"> Carport &amp; RV Cover Walls</div>
                            <div class="container-fluid">
                                <div class="row mb-2" >
                                    <div class="col input-col">
                                        <label for="sideKitQuantity" class="mr-2">Side Kit Quantity</label>
                                        <b-input-group>
                                            <b-form-input id="sideKitQuantity" type="number" v-model="building.carportDetail.sideKitQuantity"></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col pt-3 input-col">
                                        <b-input-group>
                                            <label for="frontKit" class="mr-2">Front Kit</label>
                                            <b-form-checkbox id="frontKit" v-model="building.carportDetail.frontKit"></b-form-checkbox>
                                        </b-input-group>
                                        <b-input-group>
                                            <label for="endKit" class="mr-2">End Kit</label>
                                            <b-form-checkbox id="endKit" v-model="building.carportDetail.endKit"></b-form-checkbox>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2" v-if="building.type == 'Carport' || building.type == 'RV Cover'">
                                    <div class="col input-col">
                                        <b-input-group>
                                            <label for="gableFront" class="mr-2">Gable Front</label>
                                            <b-form-checkbox id="gableFront" v-model="building.carportDetail.gableFront"></b-form-checkbox>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <b-input-group>
                                            <label for="gableEnd" class="mr-2">Gable End</label>
                                            <b-form-checkbox id="gableEnd" v-model="building.carportDetail.gableEnd"></b-form-checkbox>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-body">
                            <div class="card-title text-left">Openings</div>
                            <div class="container-fluid">
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="manDoors"># Man Doors</label>
                                        <b-input-group>
                                            <b-form-input id="manDoors" v-model="building.manDoors.quantity" type="number" placeholder="1" :disabled="sending"></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <label for="ridgeVents"># Ridge Vents</label>
                                        <b-input-group>
                                            <b-form-input id="ridgeVents" v-model="building.ridgeVents" type="number" placeholder="0" min="0" :disabled="sending"></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <label for="skyLights"># Sky Lights</label>
                                        <b-input-group>
                                            <b-form-input id="skyLights" v-model="building.skyLights" type="number" placeholder="0" :disabled="sending"></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="manDoors"># Windows w/ Grid</label>
                                        <b-input-group>
                                            <b-form-input id="manDoors" v-model="building.windows.withGrid" type="number" placeholder="1" min="0" :disabled="sending"></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col input-col">
                                        <label for="manDoors"># Windows w/out Grid</label>
                                        <b-input-group>
                                            <b-form-input id="manDoors" v-model="building.windows.withoutGrid" type="number" placeholder="1" min="0" :disabled="sending"></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col mb-4">
                                        <div class="border-bottom text-left">
                                            Roll Doors ({{building.rollDoors.length}}) <b-button class="mt-2 mb-1 add-door-button" @click="addRollDoor()" size="sm"> + </b-button>
                                        </div>
                                        <div class="row" v-for="(rollDoor, index) in building.rollDoors" :key="index">
                                            <div class="col-1 input-col">
                                                <div class="rollDoor-counter">{{index + 1}}.</div>
                                            </div>
                                            <div class="col input-col">
                                                <label :for="`rollDoor-${index}-width`">Width</label>
                                                <b-input-group append="ft">
                                                    <b-form-input :id="`rollDoor-${index}-width`" v-model="rollDoor.width" type="number" placeholder="0" max="20" min="8" :disabled="sending"></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="col input-col">
                                                <label :for="`rollDoor-${index}-height`">Height</label>
                                                <b-input-group append="ft">
                                                    <b-form-input :id="`rollDoor-${index}-height`" v-model="rollDoor.height" type="number" placeholder="0" max="20" min="8" :disabled="sending"></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="col input-col">
                                                <label :for="`rollDoor-${index}-quantity`">Quantity</label>
                                                <b-input-group>
                                                    <b-form-input :id="`rollDoor-${index}-quantity`" v-model="rollDoor.quantity" type="number" placeholder="0" min="0" :disabled="sending"></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="col input-col">
                                                <b-input-group class="vertical-checkbox">
                                                    <label :for="`rollDoor-${index}-include-rollDoor`">Include Door</label>
                                                    <b-form-checkbox :id="`rollDoor-${index}-include-rollDoor`" v-model="rollDoor.includeDoor"></b-form-checkbox>
                                                </b-input-group>
                                            </div>
                                            <div class="col-1 input-col">
                                                <b-button class="remove-rollDoor-button" variant="danger" size="sm" @click="removeRollDoor(index)">x</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col mb-4">
                                        <div class="border-bottom text-left">
                                            Shed Doors ({{building.shedDoors.length}}) <b-button class="mt-2 mb-1 add-door-button" @click="addShedDoor()" size="sm"> + </b-button>
                                        </div>
                                        <div class="row" v-for="(shedDoor, index) in building.shedDoors" :key="index">
                                            <div class="col-1 input-col">
                                                <div class="shedDoor-counter">{{index + 1}}.</div>
                                            </div>
                                            <div class="col input-col">
                                                <label :for="`shedDoor-${index}-size`">Size</label>
                                                <b-input-group append="ft">
                                                    <b-form-select :id="`shedDoor-${index}-size`" v-model="shedDoor.size" :options="shedDoorSizeOptions" :disabled="sending"></b-form-select>
                                                </b-input-group>
                                            </div>
                                            <div class="col input-col">
                                                <label :for="`shedDoor-${index}-quantity`">Quantity</label>
                                                <b-input-group>
                                                    <b-form-input :id="`shedDoor-${index}-quantity`" v-model="shedDoor.quantity" type="number" placeholder="0" min="0" :disabled="sending"></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="col input-col">
                                                <b-input-group class="vertical-checkbox">
                                                    <label :for="`shedDoor-${index}-include-shedDoor`">Include Door</label>
                                                    <b-form-checkbox :id="`shedDoor-${index}-include-shedDoor`" v-model="shedDoor.includeDoor"></b-form-checkbox>
                                                </b-input-group>
                                            </div>
                                            <div class="col-1 input-col">
                                                <b-button class="remove-shedDoor-button" variant="danger" size="sm" @click="removeShedDoor(index)">x</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-body">
                            <div class="card-title text-left">Foundation</div>
                            <div class="container-fluid">
                                 <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="anchors">Anchors</label>
                                        <b-input-group>
                                            <b-form-select id="anchors" v-model="building.foundation.anchors" :options="anchorOptions" @change="handleAnchorChange"></b-form-select>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="card-body">
                            <div class="card-title text-left">Additional Options</div>
                            <div class="container-fluid">
                                 <div class="row mb-2">
                                    <div class="col input-col">
                                        <label for="insulationRoof">Roof Insulation</label>
                                        <b-input-group>
                                            <b-form-select id="insulationRoof" v-model="building.roof.insulation.type" :options="insulationOptions" :disabled="sending"></b-form-select>
                                        </b-input-group>
                                        <label for="insulationWalls">Wall Insulation</label>
                                        <b-input-group>
                                            <b-form-select id="insulationWalls" v-model="building.walls.insulation.type" :options="insulationOptions"></b-form-select>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col input-col">
                                        <b-input-group>
                                            <b-form-checkbox id="engineering" v-model="building.engineering"></b-form-checkbox>
                                            <label for="engineering" class="mr-2">Engineering</label>
                                        </b-input-group>
                                        <b-input-group>
                                            <b-form-checkbox id="mezzanine" v-model="building.mezzanine.doMezzanine"></b-form-checkbox>
                                            <label for="mezzanine" class="mr-2">Mezzanine</label>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="section-title" v-if="building.mezzanine.doMezzanine">Mezzanine</div>
                                <div class="row mb-2" v-if="building.mezzanine.doMezzanine">
                                    <div class="col input-col">
                                        <label for="mezzanineSqft">Mezzanine Sqft</label>
                                        <b-input-group>
                                            <b-form-input id="mezzanineSqft" v-model="building.mezzanine.sqft" type="number" placeholder="0" :disabled="sending"></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5 mb-5">
                        <div class="col">
                            <b-button class="get-quote-button" type="submit" variant="primary" size="lg" :disabled="sending">Get Quote</b-button>
                        </div>
                    </div>
                </b-form>      
            </div>
        </div>
        <b-modal id="quote-modal" size="xl" hide-header hide-footer>
            <div class="row" v-if="response && response._id">
                <div id="pricing-and-materials-breakdown" class="col-12">
                    <div class="card">
                        <button class="btn btn-light btn-sm print-btn" @click="printResultView()">print</button>
                        <div class="card-body" id="resultView">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="customer-display-header">Building Estimate For:</div>
                                    <div class="customer-display-line">{{response.customer.email}}</div>  
                                </div>
                                <div class="col">
                                    <div class="customer-display-header">Located In:</div>
                                    <div class="customer-display-line">{{response.site.address.zip}}</div>
                                </div>
                            </div>
                            <quote-letter :building="response"></quote-letter>    
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
    </div>

</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import QuoteLetter from '@/components/QuoteLetter.vue';

import { trussTypeOptions, trussPieceOptions, buildingTypeOptions, thicknessOptions, metalPanelOptions, designOptions, colorOptions, anchorOptions, insulationOptions, doorSwingOptions, windowOptions, overhangOptions, roofPitchOptions, shedDoorSizeOptions } from './pricerInputOptions';

export default {
    name: 'BuildingPricer',
    components: {QuoteLetter},
    data: function() {
        return {
            required: true,
            useManualLoads: false,
            building: {
                customer: {
                    email: 'jeff@alpinedevelopmentlabs.com',
                    firstName: '',
                    lastName: '',
                    address: {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        zip: '',
                        country: '',
                    }
                },
                site: {
                    snowLoad: '',
                    windLoad: '',
                    apn: '',
                    address: {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        zip: '',
                        country: '',
                    },
                    salesTaxRate: '',
                },
                type: 'Shop',
                snowLoad: '',
                windLoad: '',
                dimensions: {
                    length: '40',
                    width: '20',
                    height: '12',
                },
                thickness: '2x3',
                metalPanel: 'Energy Rib',
                manDoors: { quantity: 2 },
                rollDoors: [],
                shedDoors: [],
                windows: { withGrid: 0, withoutGrid: 6 },
                ridgeVents: 2,
                skyLights: 0,
                closure: true,
                engineering: true,
                sameColorScrews: true,
                mezzanine: {
                    doMezzanine: false,
                    sqft: '0',
                },
                install: {
                    doInstall: false,
                    milesAway: null,
                    manualLaborAddOn: 0,
                    siteInspections: 0,
                    titanInspections: 0,
                    includeTravel: {
                        numDays: 0,
                    },
                },
                carportDetail: {
                    
                },
                foundation: {
                    doConcrete: false,
                    anchors: 'slab w/ footings',
                },
                roof: {
                    design: 'Vertical',
                    eaveOverhang: { overhang: '2ft' },
                    gableOverhang: { overhang: '2ft' },
                    pitch: { slope: '3:12' },
                    insulation: { type: 'None' },
                    color: null,
                },
                walls: {
                    design: 'Vertical',
                    insulation: { type: 'None' },
                    color: null,
                    centers: 4,
                    splitSheetWall: false
                },
                trimColor: null,
                deluxeTrimPackage: { doDeluxeTrim: false },
                truss: {
                    type: 'web',
                    kickers: false,
                    trussComponents: '1-piece', 
                },
                discount: 0,
            },
            resultView: { pricingDetail: true },
            trussTypeOptions,
            trussPieceOptions,
            buildingTypeOptions,
            thicknessOptions,
            metalPanelOptions,
            designOptions,
            colorOptions,
            anchorOptions,
            insulationOptions,
            doorSwingOptions,
            windowOptions,
            overhangOptions,
            roofPitchOptions,
            shedDoorSizeOptions,
            sending: false, 
            gettingDistance: false, 
            showSiteDialog: false,
            showConcreteOption: false,
            showThicknessOption: false,
            heightFocused: false,
            response: {}
        }   
    },
    props: {
        config: {
            type: Object,
            required: false,
        }
    },
    methods: {
        setEstimatedLoads() {
            this.building.windLoad = 60;
            this.building.snowLoad = 30;
        },
        submitCustomer() {

        },
        submitBuilding() {
            var data = {
                pricerType: 'Customer',
                config: this.building,
            }
            this.sending = true;
            axios.post('/services/buildings', data)
            .then((response) => {
                this.building._id = response.data.building._id;
                this.response = response.data.building;
                this.$bvModal.show('quote-modal');
            })
            .catch((error) => {
                console.log('error: ', error);
                    this.$toasted.error(error.response.data.error.message, {
                    action: {
                        text: 'Close',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                });
            })
            .finally(() => {
                this.sending = false;
            })
        },
        getDistance() {
            // todo: add check for no zip/address or require zip to fill out pricer
            this.gettingDistance = true;
            axios.post('/services/buildings/get-distance', this.building.site.address)
            .then((response) => {
                this.building.install.milesAway = response.data.milesAway;
            })
            .catch((error) => {
                console.log('error: ', error);
                    this.$toasted.error(error.response.data.error.message, {
                    action: {
                        text: 'Close',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                });
            })
            .finally(() => {
                this.gettingDistance = false;
            })
        },
        openBuildSiteModal() {
            this.showSiteDialog = true;
        },
        resetConfigForm() {

        },
        addRollDoor() {
            this.building.rollDoors.push({
                height: 0,
                width: 0,
                quantity: 1,
                includeDoor: true,
            })
        },
        printResultView() {    
             var elem = 'resultView';
            // todo: add styles for printing..

            // Get HTML to print from element
            const prtHtml = document.getElementById(elem).innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);

            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            window.setTimeout(function() {
                WinPrint.close();
            }, 2000);            
        },
        removeRollDoor: function(index) {
            this.building.rollDoors.splice(index, 1);
        },
        addShedDoor: function() {
            this.building.shedDoors.push({
                size: null,
                quantity: 1,
                includeDoor: true,
            })
        },
        removeShedDoor: function(index) {
            this.building.shedDoors.splice(index, 1);
        },
        addWindow: function() {
            this.building.windows.push({
                height: 0,
                width: 0,
                includeWindow: true,
                swingDirection: '',
            })
        },
        removeWindow: function(index) {
            this.building.windows.splice(index, 1);
        },
        handleAnchorChange(newVal) {
            if (newVal === 'slab' || newVal === 'slab w/ footings' || newVal === 'piers' || newVal === 'footings') {
                this.showConcreteOption = true;
            } else {
                this.showConcreteOption = false;
                this.building.foundation.doConcrete = false;
            }
        },
        handleHeightBlur() {
            if (this.building.dimensions.height < 10) {
                this.showThicknessOption = true
            } else {
                this.showThicknessOption = false;
                this.building.thickness = '2x3';
            }
        },
        loadConfig() {
            if (this.config) {
                if (this.config.site.salesTaxRate && parseFloat(this.config.site.salesTaxRate) < 1) {
                    this.config.site.salesTaxRate = _.round(this.config.site.salesTaxRate * 100, 2);
                }
                _.merge(this.building, this.config);
                this.handleAnchorChange(this.building.foundation.anchors);
                this.handleHeightBlur();
                this.submitBuilding();
                // these are needed on page load in order to handle any sort of defaults or pre-populated data;
                // don't have to call these on load if we change these to watches, will fire automatically on load
                // but then will fire every cycle, and don't want that .... use this pattern for now? see if this gets unweildy.
            } 

            // todo -- anything with ID's? we keep ID's if provided? 
        },
    },
    mounted: function() {
        this.loadConfig();
        if (!this.building.snowLoad || !this.building.windLoad) {
            this.$bvModal.show('customerSiteInfo');
        }
    },

}
</script>

<style lang="scss" scoped>
    .open-build-site-btn {
        position: absolute;
        top: 6px;
        right: 6px;
    }
    .card-title {
        margin-top: 12px;
        margin-bottom: 6px;
        font-size: 20px;
        font-weight: bold;
    }
    .card-body {
        padding: 12px;
    }
    .input-col {
        padding-left: 4px;
        padding-right: 4px;
    }

    label {
        margin-bottom: 2px;
        display: block;
        text-align: left;
    }
    
    .section-title {
        margin-right: -15px;
        margin-left: -15px;
        border-bottom: 1px solid gray;
        margin-top: 36px;
        margin-bottom: 12px;
        text-align: left;
    }

    .display-section-title {
        border-bottom: 1px solid gray;
        margin-top: 36px;
        margin-bottom: 12px;
        text-align: left;
    }

    .display-subsection {
        margin-bottom: 10px;
        text-align: left;
    }

    .display-subsection-title {
        text-align: left;
        font-weight: 500;
    }

    .window-counter,
    .rollDoor-counter,
    .shedDoor-counter {
        margin-top: 40px;
        font-size: 20px;
    }

    .add-window-button,
    .add-door-button,
    .add-door-button {
        padding: 0px 4px 0px 4px;
    }

    .remove-window-button,
    .remove-rollDoor-button,
    .remove-shedDoor-button {
        margin-top: 32px;
    }

    .customer-display-header {
        font-weight: bold;
        text-align: left;
    }
    .customer-display-line {
        text-align: left;
    }
    .customer-display-subtitle {
        font-weight: bold;
        text-align: left;
    }

    .alert {
        padding:0.25rem 0.1rem;
    }

    .calc-distance-button {
        margin-top: 26px;
    }

    .vertical-checkbox {
        label {
            width: 100%;
            display: block;
            text-align: center;
        }

        .custom-checkbox {
            width: 100%;
            margin-top: 4px;
        }
    }
    
    .fixed-submit {
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: left;
        z-index: 10;
    }

    .print-btn {
        position: absolute; 
        top: 6px;
        right: 6px;
    }
    .addons-table {
        width: 100%;
        text-align: left;
        font-size: 14px;

        thead {
            text-decoration: underline;
        }
    }

    .addons-total-row {
        font-weight: bold;
        border-top: 1px dashed darkslategray;        
    }

    .site-info-sublabel {
        font-size: 14px;
    }

    .manual-load-link {
        font-size: 14px;
        color: #007bff;
        cursor: pointer;
    }

    .site-info-icon {
        max-width: 200px;
        height: 100px;
    }

    .site-info-building-option {
        padding: 12px;
        text-align: center;
        cursor: pointer;
    }

    .site-info-building-option:hover {
        border: 2px solid #61adff;
        border-radius: 4px;
    }

    .selected {
        border: 2px solid #007bff;
        border-radius: 4px;
    }

    .wrapper {
        max-width: 1200px;
    }

    .truss-types-icon {
        max-width: 340px;
    }
    .gable-icon {
        max-width: 300px;
    }
    .roof-pitch-icon,
    .wlh-icon {
        max-width: 260px;
    }

    img {
        max-width: 100%;
    }

    .help-link {
        font-size: 14px;
        text-decoration: underline;
        color: #007bff;
    }

    .help-link:hover {
        color: #61adff;
    }

    .get-quote-button {
        width: 300px;
    }

</style>