<template>
  <div class="page-wrapper pricer-page">
    <h1 class="page-h1">
      {{
        response && response.nickname ? response.nickname : 'Building Pricer'
      }}
    </h1>
    <div class="container-fluid">
      <div id="customer-site-inputs" class="row mb-4">
        <div class="col">
          <div class="card">
            <div class="admin-buttons">
              <div class="clone-edit-site-buttons">
                <b-button
                  type="button"
                  variant="secondary"
                  class="mr-2"
                  size="sm"
                  @click="
                    cloneFromEdit = false
                    $bvModal.show('confirmCloneBuilding')
                  "
                >
                  Clone Building
                </b-button>

                <b-button
                  type="button"
                  variant="primary"
                  size="sm"
                  class=""
                  :disabled="!response.shortId"
                  @click="$bvModal.show('createOrderModal')"
                  v-if="!building.orderId"
                >
                  Create Order
                </b-button>
                <span v-if="building.orderId">
                  <router-link :to="`/app/orders/${building.orderId}`"
                    >Go to order page
                  </router-link>
                </span>
              </div>

              <div class="last-contacted">
                <div class="last-contacted-time">
                  Last Contacted: <br />
                  {{ building.customer.lastContactedDate | daysAgoWithTime }}
                </div>

                <b-button
                  type="button"
                  variant="info"
                  size="sm"
                  class="last-contacted-btn"
                  :disabled="updatingLastContacted"
                  @click="updateLastContacted()"
                >
                  Update
                </b-button>
              </div>
            </div>
            <div class="card-title">
              Customer &amp; Site Information
              <b-button
                type="button"
                variant="secondary"
                size="sm"
                class=""
                :disabled="disableInputs"
                @click="$bvModal.show('customerSiteInfo')"
              >
                Edit
              </b-button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  {{ building.customer.email }}
                  <VIPBadge v-if="building.customer.vip"></VIPBadge>
                </div>
                <div class="col">
                  <router-link
                    :to="{
                      path: '/app/customers/1',
                      query: { name: building.customer.name },
                    }"
                    >{{ building.customer.name }}</router-link
                  >
                </div>
                <div class="col">{{ building.site.address.zip }}</div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="customerSiteInfo"
          title="Customer &amp; Site Info"
          @ok="submitCustomer($event)"
          size="xl"
        >
          <CustomerAndSiteForm
            :customer="building.customer"
            :site="building.site"
            :disableInputs="disableInputs"
          ></CustomerAndSiteForm>
        </b-modal>
        <b-modal
          id="confirmCloneBuilding"
          title="Clone Building Configuration"
          @ok="cloneBuildingConfiguration($event)"
          size="md"
        >
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col">
                <input
                  type="radio"
                  id="one"
                  :value="true"
                  v-model="keepPricingWhenCloning"
                />
                <label class="inline-label" for="one">Keep Same Pricing</label>
              </div>
              <div class="col">
                <input
                  type="radio"
                  id="two"
                  :value="false"
                  v-model="keepPricingWhenCloning"
                />
                <label class="inline-label" for="two">Use Newest Pricing</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div v-if="cloneFromEdit">
                  Building is over 1 day old, please clone to edit.
                </div>
                <div v-if="!cloneFromEdit">
                  Are you sure you want to clone this building configuration to
                  a new building?
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="createOrderModal"
          title="Create Order"
          @ok="createOrderFromBuilding($event)"
          size="lg"
          hide-footer
          hide-header
        >
          <create-order-page
            :building="response"
            :onSuccess="onCreateOrder"
          ></create-order-page>
        </b-modal>
      </div>
      <div class="row">
        <div id="configuration-inputs" class="col-12 col-lg-6">
          <div class="card pricer-side">
            <div class="card-title">Configuration</div>
            <div class="card-body">
              <b-form @submit.prevent="submitBuilding" @reset="resetConfigForm">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="nickname">Nickname</label>
                      <b-form-input
                        id="nickname"
                        v-model="building.nickname"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="buildingType">Building Type</label>
                      <b-form-select
                        id="buildingType"
                        v-model="building.type"
                        :options="buildingTypeOptions"
                        required
                        :disabled="disableInputs"
                      ></b-form-select>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="snowLoad">Snow Load</label>
                      <b-input-group append="#psf">
                        <b-form-input
                          id="snowLoad"
                          v-model="building.snowLoad"
                          type="number"
                          :disabled="disableInputs"
                          max="100"
                          min="0"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="windLoad">Wind Load</label>
                      <b-input-group append="mph">
                        <b-form-input
                          id="windLoad"
                          v-model="building.windLoad"
                          type="number"
                          :disabled="disableInputs"
                          max="220"
                          min="0"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="width">Width</label>
                      <b-input-group append="ft">
                        <b-form-input
                          id="width"
                          v-model="building.dimensions.width"
                          type="text"
                          :disabled="disableInputs"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="length">Length</label>
                      <b-input-group append="ft">
                        <b-form-input
                          id="length"
                          v-model="building.dimensions.length"
                          type="text"
                          :disabled="disableInputs"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="height">Height</label>
                      <b-input-group append="ft">
                        <b-form-input
                          v-on:blur="handleHeightBlur"
                          id="height"
                          v-model="building.dimensions.height"
                          type="text"
                          :disabled="disableInputs"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="showThicknessOption">
                    <div class="col input-col">
                      <label for="thickness">Thickness</label>
                      <b-input-group>
                        <b-form-select
                          id="thickness"
                          v-model="building.thickness"
                          :options="thicknessOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="metalPanel">Metal Panel</label>
                      <b-input-group>
                        <b-form-select
                          id="metalPanel"
                          v-model="building.metalPanel"
                          :options="metalPanelOptions"
                          :disabled="disableInputs"
                          required
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="anchors">Anchors</label>
                      <b-input-group>
                        <b-form-select
                          id="anchors"
                          v-model="building.foundation.anchors"
                          :options="anchorOptions"
                          @change="handleAnchorChange"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col"></div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="includeTitanBolts" class="mr-2"
                          >Include Titan Bolts</label
                        >
                        <b-form-checkbox
                          id="includeTitanBolts"
                          v-model="building.foundation.includeTitanBolts"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>

                  <div class="section-title">Roof</div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="trussType">Truss Type</label>
                      <b-input-group>
                        <b-form-select
                          id="trussType"
                          v-model="building.truss.type"
                          :options="trussTypeOptions"
                          :disabled="disableInputs"
                          required
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="trussComponents">Truss Components</label>
                      <b-input-group>
                        <b-form-select
                          id="trussComponents"
                          v-model="building.truss.trussComponents"
                          :options="trussPieceOptions"
                          :disabled="disableInputs"
                          required
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="kickers" class="mr-2">Kickers</label>
                        <b-form-checkbox
                          id="kickers"
                          v-model="building.truss.kickers"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                      <b-input-group>
                        <label for="expandedTruss" class="mr-2"
                          >Expanded Truss</label
                        >
                        <b-form-checkbox
                          id="expandedTruss"
                          v-model="building.truss.expanded"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="roofDesign">Roof Design</label>
                      <b-input-group>
                        <b-form-select
                          id="roofDesign"
                          v-model="building.roof.design"
                          :options="designOptions"
                          :disabled="disableInputs"
                          required
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="roofColor">Roof Color</label>
                      <b-input-group>
                        <b-form-select
                          id="roofColor"
                          v-model="building.roof.color"
                          :options="colorOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="insulationRoof">Roof Insulation</label>
                      <b-input-group>
                        <b-form-select
                          id="insulationRoof"
                          v-model="building.roof.insulation.type"
                          :options="insulationOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="roofPitch">Roof Pitch</label>
                      <b-input-group>
                        <b-form-select
                          id="roofPitch"
                          v-model="building.roof.pitch.slope"
                          :options="roofPitchOptions"
                          :disabled="disableInputs"
                          required
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="frontGableOverhang"
                        >Front Gable Overhang Length</label
                      >
                      <b-input-group>
                        <b-form-select
                          id="frontGableOverhang"
                          v-model="building.roof.frontGableOverhang.overhang"
                          :options="overhangOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="rightEaveOverhang"
                        >Right Eave Overhang Length</label
                      >
                      <b-input-group>
                        <b-form-select
                          id="rightEaveOverhang"
                          v-model="building.roof.rightEaveOverhang.overhang"
                          :options="overhangOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="endGableOverhang"
                        >End Gable Overhang Length</label
                      >
                      <b-input-group>
                        <b-form-select
                          id="endGableOverhang"
                          v-model="building.roof.endGableOverhang.overhang"
                          :options="overhangOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="leftEaveOverhang"
                        >Left Eave Overhang Length</label
                      >
                      <b-input-group>
                        <b-form-select
                          id="leftEaveOverhang"
                          v-model="building.roof.leftEaveOverhang.overhang"
                          :options="overhangOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="section-title">Walls</div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="wallDesign">Wall Design</label>
                      <b-input-group>
                        <b-form-select
                          id="wallDesign"
                          v-model="building.walls.design"
                          :options="designOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="wallColor">Wall Color</label>
                      <b-input-group>
                        <b-form-select
                          id="wallColor"
                          v-model="building.walls.color"
                          :options="colorOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="insulationWalls">Wall Insulation</label>
                      <b-input-group>
                        <b-form-select
                          id="insulationWalls"
                          v-model="building.walls.insulation.type"
                          :options="insulationOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="trimColor">Trim Color</label>
                      <b-input-group>
                        <b-form-select
                          id="trimColor"
                          v-model="building.trimColor"
                          :options="colorOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="centers">Centers</label>
                      <b-input-group>
                        <b-form-select
                          id="centers"
                          v-model="building.walls.centers"
                          :options="centersOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="deluxeTrimPackage" class="mr-2"
                          >Deluxe Trim Package</label
                        >
                        <b-form-checkbox
                          id="deluxeTrimPackage"
                          v-model="building.deluxeTrimPackage.doDeluxeTrim"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                      <br />
                      <b-input-group>
                        <label for="splitSheetWall" class="mr-2"
                          >Split Sheet Wall</label
                        >
                        <b-form-checkbox
                          id="splitSheetWall"
                          v-model="building.walls.splitSheetWall"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="deluxeTrimPackage" class="mr-2"
                          >Add Firewall</label
                        >
                        <b-form-checkbox
                          id="doFirewall"
                          v-model="building.walls.firewall.doFirewall"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col"></div>
                  </div>
                  <div
                    class="row mb-2"
                    v-if="building.walls.firewall.doFirewall"
                  >
                    <div class="col input-col">
                      <label for="centers">Firewall Location</label>
                      <b-input-group>
                        <b-form-select
                          id="centers"
                          v-model="building.walls.firewall.location"
                          :options="firewallLocationOptions"
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label
                        for="centers"
                        v-if="building.walls.firewall.location === 'other'"
                        >Firewall Sqft</label
                      >
                      <b-form-input
                        v-if="building.walls.firewall.location === 'other'"
                        id="mezzanineSqft"
                        v-model="building.walls.firewall.sqft"
                        type="number"
                        placeholder="0"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </div>
                  </div>

                  <div class="section-title">Openings</div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="manDoors"># Man Doors</label>
                      <b-input-group>
                        <b-form-input
                          id="manDoors"
                          v-model="building.manDoors.quantity"
                          type="number"
                          placeholder="1"
                          min="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="ridgeVents"># Ridge Vents</label>
                      <b-input-group>
                        <b-form-input
                          id="ridgeVents"
                          v-model="building.ridgeVents"
                          type="number"
                          placeholder="0"
                          min="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="skyLights"># Sky Lights</label>
                      <b-input-group>
                        <b-form-input
                          id="skyLights"
                          v-model="building.skyLights"
                          type="number"
                          placeholder="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="manDoors"># Windows w/ Grid</label>
                      <b-input-group>
                        <b-form-input
                          id="manDoors"
                          v-model="building.windows.withGrid"
                          type="number"
                          placeholder="1"
                          min="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="manDoors"># Windows w/out Grid</label>
                      <b-input-group>
                        <b-form-input
                          id="manDoors"
                          v-model="building.windows.withoutGrid"
                          type="number"
                          placeholder="1"
                          min="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col mb-4">
                      <openings-input
                        :disableInputs="disableInputs"
                        :openings="building.rollDoors"
                        title="Roll Doors"
                      >
                      </openings-input>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col mb-4">
                      <openings-input
                        :disableInputs="disableInputs"
                        :openings="building.shedDoors"
                        :openingsOptions="shedDoorSizeOptions"
                        title="Shed Doors"
                      >
                      </openings-input>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col mb-4">
                      <openings-input-new
                        :disableInputs="disableInputs"
                        :openings="building.openings"
                      >
                      </openings-input-new>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="false">
                    <div class="col input-col mb-4">
                      <openings-input
                        :disableInputs="disableInputs"
                        :openings="building.windowList"
                        :max-width="20"
                        :min-width="0.5"
                        :max-height="20"
                        :min-height="0.5"
                        title="Windows"
                      >
                      </openings-input>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="false">
                    <div class="col input-col mb-4">
                      <openings-input
                        :disableInputs="disableInputs"
                        :openings="building.manDoorList"
                        :max-width="20"
                        :min-width="2"
                        :max-height="20"
                        :min-height="3"
                        title="Man Doors"
                      >
                      </openings-input>
                    </div>
                  </div>
                  <div class="section-title">Front, Side, End Kits</div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="sideKitQuantity" class="mr-2"
                        >Side Kit Quantity</label
                      >
                      <b-input-group>
                        <b-form-input
                          id="sideKitQuantity"
                          type="number"
                          v-model="building.carportDetail.sideKitQuantity"
                          :disabled="disableInputs"
                          step="0.5"
                          min="0"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col pt-3 input-col">
                      <b-input-group>
                        <label for="frontKit" class="mr-2">Front Kit</label>
                        <b-form-checkbox
                          id="frontKit"
                          v-model="building.carportDetail.frontKit"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                      <b-input-group>
                        <label for="endKit" class="mr-2">End Kit</label>
                        <b-form-checkbox
                          id="endKit"
                          v-model="building.carportDetail.endKit"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="gableFront" class="mr-2">Gable Front</label>
                        <b-form-checkbox
                          id="gableFront"
                          v-model="building.carportDetail.gableFront"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="gableEnd" class="mr-2">Gable End</label>
                        <b-form-checkbox
                          id="gableEnd"
                          v-model="building.carportDetail.gableEnd"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="section-title">Additional Options</div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="closure" class="mr-2">Closure</label>
                        <b-form-checkbox
                          id="closure"
                          v-model="building.closure"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="sameColorScrews" class="mr-2"
                          >Same Color Screws</label
                        >
                        <b-form-checkbox
                          id="sameColorScrews"
                          v-model="building.sameColorScrews"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="engineering" class="mr-2"
                          >Engineering</label
                        >
                        <b-form-checkbox
                          id="engineering"
                          v-model="building.engineering"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="install" class="mr-2">Install</label>
                        <b-form-checkbox
                          id="install"
                          v-model="building.install.doInstall"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col" v-if="showConcreteOption">
                      <b-input-group>
                        <label for="concrete" class="mr-2">Concrete</label>
                        <b-form-checkbox
                          id="concrete"
                          v-model="building.foundation.doConcrete"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="electrical" class="mr-2">Electrical</label>
                        <b-form-checkbox
                          id="electrical"
                          v-model="building.install.doElectrical"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="grading" class="mr-2">Grading</label>
                        <b-form-checkbox
                          id="grading"
                          v-model="building.foundation.doGrading"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="architecture" class="mr-2"
                          >Architecture</label
                        >
                        <b-form-checkbox
                          id="architecture"
                          v-model="building.architecture"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <b-input-group>
                        <label for="mezzanine" class="mr-2">Mezzanine</label>
                        <b-form-checkbox
                          id="mezzanine"
                          v-model="building.mezzanine.doMezzanine"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="title24" class="mr-2">Title 24</label>
                        <b-form-checkbox
                          id="title24"
                          v-model="building.title24"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div
                    class="section-title"
                    v-if="building.mezzanine.doMezzanine"
                  >
                    Mezzanine
                  </div>
                  <div class="row mb-2" v-if="building.mezzanine.doMezzanine">
                    <div class="col input-col">
                      <label for="mezzanineSqft">Mezzanine Sqft</label>
                      <b-input-group>
                        <b-form-input
                          id="mezzanineSqft"
                          v-model="building.mezzanine.sqft"
                          type="number"
                          placeholder="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="section-title">Install</div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <div class="row">
                        <div class="col">
                          <label for="milesAway">Distance Away</label>
                          <b-input-group append="miles">
                            <b-form-input
                              id="milesAway"
                              v-model="building.install.milesAway"
                              type="number"
                              placeholder="0"
                              :disabled="disableInputs"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                        <div class="col">
                          <b-button
                            class="calc-distance-button"
                            variant="secondary"
                            @click="getDistance()"
                            :disabled="gettingDistance || disableInputs"
                            >Calculate</b-button
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col input-col">
                      <label for="manDoorStoops"># Man Door Stoops</label>
                      <b-input-group>
                        <b-form-input
                          id="manDoorStoops"
                          v-model="building.install.manDoorStoops"
                          type="number"
                          placeholder="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col"></div>
                    <div class="col input-col">
                      <b-input-group>
                        <label for="includeUferGround" class="mr-2"
                          >Include Ufer Ground</label
                        >
                        <b-form-checkbox
                          id="includeUferGround"
                          v-model="building.install.uferGround"
                          :disabled="disableInputs"
                        ></b-form-checkbox>
                      </b-input-group>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="
                      building.foundation.anchors == 'slab' ||
                      building.foundation.anchors == 'stem walls'
                    "
                  >
                    <div class="col input-col">
                      <label for="manualConcreteAddOn"
                        >Concrete est. for
                        {{ building.foundation.anchors }}</label
                      >
                      <b-input-group prepend="$">
                        <b-form-input
                          id="manualConcreteAddOn"
                          v-model="building.manualConcreteAddOn"
                          type="text"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col"></div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="siteInspections"># Site Inspections</label>
                      <b-input-group>
                        <b-form-input
                          id="siteInspections"
                          v-model="building.install.siteInspections"
                          type="number"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="titanInspections"># Titan Inspections</label>
                      <b-input-group>
                        <b-form-input
                          id="titanInspections"
                          v-model="building.install.titanInspections"
                          type="number"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="numDays">Include Travel (Num Days) </label>
                      <b-input-group>
                        <b-form-input
                          id="numDays"
                          v-model="building.install.includeTravel.numDays"
                          type="number"
                          :disabled="disableInputs"
                          min="0"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="gradeAllDays">Grade All # Days</label>
                      <b-input-group>
                        <b-form-input
                          id="gradeAllDays"
                          v-model="building.install.gradeAllDays"
                          type="number"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col">
                      <label for="salesTaxRate">Sales Tax Rate</label>
                      <b-input-group append="%">
                        <b-form-input
                          id="salesTaxRate"
                          v-model="building.site.salesTaxRate"
                          type="text"
                          placeholder="0"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="discount">Discount</label>
                      <b-input-group append="%">
                        <b-form-input
                          id="discount"
                          v-model="building.discount"
                          type="number"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col input-col mb-4">
                      <div class="border-bottom text-left">
                        Manual Add On Items ({{
                          building.manualAddOnItems.length
                        }})
                        <b-button
                          class="mt-2 mb-1 add-door-button"
                          @click="addManualAddOnItem()"
                          size="sm"
                          :disabled="disableInputs"
                        >
                          +
                        </b-button>
                      </div>
                      <div
                        class="row"
                        v-for="(
                          manualAddOnItem, index
                        ) in building.manualAddOnItems"
                        :key="index"
                      >
                        <div class="col-1 input-col">
                          <div class="manualAddOnItem-counter">
                            {{ index + 1 }}.
                          </div>
                        </div>
                        <div class="col col-3 input-col">
                          <label :for="`manualAddOnItem-${index}-name`"
                            >Name</label
                          >
                          <b-input-group>
                            <b-form-input
                              :id="`manualAddOnItem-${index}-name`"
                              v-model="manualAddOnItem.name"
                              type="text"
                              :disabled="disableInputs"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                        <div class="col input-col">
                          <label :for="`manualAddOnItem-${index}-description`"
                            >Description</label
                          >
                          <b-input-group>
                            <b-textarea
                              :id="`manualAddOnItem-${index}-description`"
                              v-model="manualAddOnItem.description"
                              :disabled="disableInputs"
                            ></b-textarea>
                          </b-input-group>
                        </div>
                        <div class="col col-2 input-col">
                          <label :for="`manualAddOnItem-${index}-price`"
                            >Price</label
                          >
                          <b-input-group>
                            <b-form-input
                              :id="`manualAddOnItem-${index}-price`"
                              v-model="manualAddOnItem.price"
                              type="text"
                              :disabled="disableInputs"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                        <!-- <div class="col col-1 input-col">
                                            <b-input-group class="vertical-checkbox">
                                                <label :for="`manualAddOnItem-${index}-taxable`">Tax</label>
                                                <b-form-checkbox :id="`manualAddOnItem-${index}-taxable`" v-model="manualAddOnItem.taxable" :disabled="disableInputs"></b-form-checkbox>
                                            </b-input-group>
                                        </div> -->
                        <div class="col col-1 input-col">
                          <b-button
                            class="remove-manualAddOnItem-button"
                            variant="danger"
                            size="sm"
                            @click="removeManualAddOnItem(index)"
                            :disabled="disableInputs"
                            >x</b-button
                          >
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col text-right">
                          Total: {{ manualAddOnItemsTotal | currencyFilter }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col input-col mb-4">
                      <div class="border-bottom text-left">
                        Install Add On Items ({{
                          building.manualAddOnItems.length
                        }})
                        <b-button
                          class="mt-2 mb-1 add-door-button"
                          @click="addInstallAddOnItem()"
                          size="sm"
                          :disabled="disableInputs"
                        >
                          +
                        </b-button>
                      </div>
                      <div
                        class="row"
                        v-for="(
                          installAddOnItem, index
                        ) in building.installAddOnItems"
                        :key="index"
                      >
                        <div class="col-1 input-col">
                          <div class="installAddOnItem-counter">
                            {{ index + 1 }}.
                          </div>
                        </div>
                        <div class="col col-3 input-col">
                          <label :for="`installAddOnItem-${index}-name`"
                            >Name</label
                          >
                          <b-input-group>
                            <b-form-input
                              :id="`installAddOnItem-${index}-name`"
                              v-model="installAddOnItem.name"
                              type="text"
                              :disabled="disableInputs"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                        <div class="col input-col">
                          <label :for="`installAddOnItem-${index}-description`"
                            >Description</label
                          >
                          <b-input-group>
                            <b-textarea
                              :id="`installAddOnItem-${index}-description`"
                              v-model="installAddOnItem.description"
                              :disabled="disableInputs"
                            ></b-textarea>
                          </b-input-group>
                        </div>
                        <div class="col col-2 input-col">
                          <label :for="`installAddOnItem-${index}-price`"
                            >Price</label
                          >
                          <b-input-group>
                            <b-form-input
                              :id="`installAddOnItem-${index}-price`"
                              v-model="installAddOnItem.price"
                              type="text"
                              :disabled="disableInputs"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                        <!-- <div class="col col-1 input-col">
                                            <b-input-group class="vertical-checkbox">
                                                <label :for="`installAddOnItem-${index}-taxable`">Tax</label>
                                                <b-form-checkbox :id="`installAddOnItem-${index}-taxable`" v-model="installAddOnItem.taxable" :disabled="disableInputs"></b-form-checkbox>
                                            </b-input-group>
                                        </div> -->
                        <div class="col col-1 input-col">
                          <b-button
                            class="remove-installAddOnItem-button"
                            variant="danger"
                            size="sm"
                            @click="removeInstallAddOnItem(index)"
                            :disabled="disableInputs"
                            >x</b-button
                          >
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col text-right">
                          Total: {{ installAddOnItemsTotal | currencyFilter }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="section-title">Referrals</div>
                  <div class="row">
                    <div class="col input-col">
                      <label for="referralSource">Referral Source</label>
                      <b-input-group>
                        <b-form-select
                          id="referralSource"
                          v-model="building.referralSource"
                          :options="referralSourceOptions"
                          required
                          :disabled="disableInputs"
                        ></b-form-select>
                      </b-input-group>
                    </div>
                    <div class="col input-col">
                      <label for="referralDetail">Referral Detail</label>
                      <b-input-group>
                        <b-form-input
                          id="referralDetail"
                          v-model="building.referralDetail"
                          type="text"
                          :disabled="disableInputs"
                        ></b-form-input>
                      </b-input-group>
                      <!-- <div class="mt-3">
                                        <div class="original-quote-date">
                                            Quote Started: {{(response.quoteDate || response.createdAt) | dateFilter}}
                                        </div>
                                        <div class="updated-quote-date">
                                            Last Updated: {{response.lastUpdatedDate | dateFilter}}
                                        </div>
                                        <div class="sold-quote-date" v-show="building.soldDate">
                                            Sold On: {{response.soldDate | dateFilter}}
                                        </div>
                                    </div>
                                    <div class="mt-3" v-if="!building.soldDate">
                                        <b-button variant="primary" type="button" @click="markSold()">Mark Building as Sold</b-button>
                                    </div>
                                    <div class="mt-3" v-if="building.soldDate">
                                        <b-button variant="primary" type="button" disabled>Sold on {{building.soldDate | dateFilter}}</b-button>
                                    </div> -->
                    </div>
                  </div>

                  <div class="section-title">Notes</div>
                  <div
                    class="mb-2 text-left"
                    v-if="building.notesList && false"
                  >
                    <building-notes :building="building"></building-notes>
                  </div>
                  <div class="row mb-2" v-if="true">
                    <div class="col input-col text-left">
                      <b-input-group>
                        <b-textarea
                          id="buildingNotes"
                          v-model="newNote"
                        ></b-textarea>
                      </b-input-group>
                      <b-button
                        type="button"
                        @click="addNote()"
                        size="sm"
                        class="mt-2 mb-3"
                        :disabled="!newNote"
                        >Add Note</b-button
                      >
                      <div
                        v-for="(note, index) in notesList.slice().reverse()"
                        :key="index"
                      >
                        <div class="note-wrapper">
                          <div class="note-from">
                            {{ note.user }} ({{ note.timestamp | dateFilter }})
                          </div>
                          <div class="note-text">{{ note.text }}</div>
                        </div>
                      </div>
                      <div class="note-wrapper mb-4" v-if="building.notes">
                        {{ building.notes }}
                      </div>
                    </div>
                  </div>
                  <b-button
                    size="lg"
                    type="submit"
                    variant="primary"
                    class="fixed-submit"
                    :disabled="disableInputs"
                    v-if="!viewOnly"
                    >Save Building</b-button
                  >
                </div>
              </b-form>
              <b-button
                size="lg"
                type="button"
                variant="primary"
                class="fixed-submit"
                @click="startEditBuilding"
                v-if="viewOnly"
                >Edit Building</b-button
              >
            </div>
          </div>
        </div>
        <div
          id="pricing-and-materials-breakdown"
          class="col-12 col-lg-6 pricing-and-materials-breakdown"
        >
          <div class="card pricer-side">
            <building-result :response="response"></building-result>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import BuildingResult from '@/components/BuildingResult.vue'
import VIPBadge from '@/components/VIPBadge'
import CreateOrderPage from '@/views/CreateOrderPage'
import CustomerAndSiteForm from '@/components/CustomerAndSiteForm'
import BuildingNotes from '@/components/BuildingNotes'

import {
  referralSourceOptions,
  trussTypeOptions,
  trussPieceOptions,
  buildingTypeOptions,
  thicknessOptions,
  firewallLocationOptions,
  metalPanelOptions,
  designOptions,
  colorOptions,
  anchorOptions,
  insulationOptions,
  doorSwingOptions,
  windowOptions,
  overhangOptions,
  roofPitchOptions,
  centersOptions,
  shedDoorSizeOptions,
} from './pricerInputOptions'
import { rollDoorPrices } from '../data/prices'
import OpeningsInput from '@/components/OpeningsInput.vue'
import OpeningsInputNew from '@/components/OpeningsInputNew.vue'
import {
  rollDoorModelsWithInches,
  shedDoorModels,
  manDoorModels,
  windowSizes,
} from './openingsModels'

export default {
  name: 'BuildingPricer',
  components: {
    BuildingResult,
    VIPBadge,
    CreateOrderPage,
    CustomerAndSiteForm,
    OpeningsInput,
    OpeningsInputNew,
    BuildingNotes,
  },
  data: function () {
    return {
      required: true,
      building: {
        referralSource: null,
        referralDetail: '',
        nickname: '',
        customer: {
          email: '',
          name: '',
          firstName: '',
          lastName: '',
          address: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            county: '',
            country: '',
            longitude: '',
            latitude: '',
          },
        },
        site: {
          snowLoad: '',
          windLoad: '',
          apn: '',
          address: {
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            county: '',
            country: '',
            longitude: '',
            latitude: '',
          },
          salesTaxRate: '',
        },
        type: 'Shop',
        snowLoad: 30,
        windLoad: 60,
        dimensions: {
          length: '40',
          width: '20',
          height: '12',
        },
        thickness: '2x3',
        metalPanel: 'Energy Rib',
        manDoors: { quantity: 0 },
        rollDoors: [],
        shedDoors: [],
        windowList: [],
        manDoorList: [],
        openings: [],
        manualAddOnItems: [],
        installAddOnItems: [],
        windows: { withGrid: 0, withoutGrid: 0 },
        ridgeVents: 0,
        skyLights: 0,
        closure: true,
        engineering: true,
        architecture: false,
        title24: false,
        sameColorScrews: true,
        mezzanine: {
          doMezzanine: false,
          sqft: '0',
        },
        manualLaborAddOn: 0,
        manualConcreteAddOn: 0,
        notes: '',
        install: {
          doInstall: false,
          doElectrical: false,
          milesAway: null,
          siteInspections: 0,
          gradeAllDays: 0,
          manDoorStoops: 0,
          uferGround: false,
          titanInspections: 0,
          includeTravel: {
            numDays: 0,
          },
        },
        carportDetail: {
          sideKitQuantity: 0,
        },
        foundation: {
          doConcrete: false,
          doGrading: false,
          includeTitanBolts: false,
          anchors: 'slab w/ footings',
        },
        roof: {
          design: 'Vertical',
          leftEaveOverhang: { overhang: '2ft' },
          rightEaveOverhang: { overhang: '2ft' },
          frontGableOverhang: { overhang: '2ft' },
          endGableOverhang: { overhang: '2ft' },
          pitch: { slope: '3:12' },
          insulation: { type: 'None' },
          color: null,
        },
        walls: {
          design: 'Vertical',
          insulation: { type: 'None' },
          color: null,
          centers: '4',
          splitSheetWall: false,
          firewall: {
            doFirewall: false,
            location: '',
            sqft: 0,
          },
        },
        trimColor: null,
        deluxeTrimPackage: { doDeluxeTrim: false },
        truss: {
          type: 'web',
          kickers: false,
          trussComponents: '3-piece',
          expanded: false,
        },
        discount: 0,
        priceListId: '',
      },
      newNote: '',
      notesList: [],
      resultView: { pricingDetail: true },
      trussTypeOptions,
      trussPieceOptions,
      buildingTypeOptions,
      thicknessOptions,
      metalPanelOptions,
      designOptions,
      colorOptions,
      anchorOptions,
      insulationOptions,
      doorSwingOptions,
      windowOptions,
      overhangOptions,
      roofPitchOptions,
      centersOptions,
      firewallLocationOptions,
      referralSourceOptions,
      shedDoorSizeOptions,
      sending: false,
      sendingNote: false,
      gettingDistance: false,
      showSiteDialog: false,
      showConcreteOption: true,
      showThicknessOption: false,
      heightFocused: false,
      viewOnly: false,
      updatingLastContacted: false,
      keepPricingWhenCloning: true,
      cloneFromEdit: false,
      models: {
        'Roll Door': rollDoorModelsWithInches,
        'Shed Door': shedDoorModels,
        'Man Door': manDoorModels,
        Window: windowSizes,
      },
      response: {},
    }
  },
  props: {
    config: {
      type: Object,
      required: false,
    },
  },
  methods: {
    startEditBuilding() {
      let today = moment()
      let createdAt = moment(this.building.createdAt)
      if (today.isSame(createdAt, 'day')) {
        // allow same day edits
        this.viewOnly = false
      } else {
        // force any buildings > 1 day old to be cloned
        this.cloneFromEdit = true
        this.$bvModal.show('confirmCloneBuilding')
      }
    },
    updateLastContacted() {
      this.updatingLastContacted = true
      const data = {
        _id: this.building._id,
        lastContacted: new Date(),
      }
      return axios
        .put('/services/buildings/last-contacted', data)
        .then(response => {
          this.building.customer.lastContactedDate = response.data.lastContacted
          this.$toasted.success(`Last Contacted updated`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error updating last contacted date`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.updatingLastContacted = false
        })
    },
    onCreateOrder(order) {
      this.markSold(order)
      this.$bvModal.hide('createOrderModal')
    },
    submitCustomer() {},
    cloneBuildingConfiguration() {
      const config = _.cloneDeep(this.building)
      config.parentBuildingId = config.shortId
      delete config._id
      delete config.shortId
      if (!this.keepPricingWhenCloning) {
        delete config.priceListId
      }
      config.createdAt = new Date().toISOString()
      config.lastUpdatedAt = new Date().toISOString()
      this.$router.replace({
        name: 'CloneBuildingConfiguration',
        params: {
          config: config,
        },
      })
    },
    addNote() {
      this.sendingNote = true
      const data = {
        _id: this.building._id,
        note: this.newNote,
      }
      axios
        .post('/services/buildings/add-note-legacy', data)
        .then(response => {
          this.notesList.push(_.last(response.data.notesList))
          this.newNote = ''
          this.building.notesList.push(_.last(response.data.notesList))
          this.response.notesList.push(_.last(response.data.notesList))
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.sendingNote = false
        })
    },
    markSold(order) {
      this.sending = true
      const data = {
        _id: this.building._id,
        orderId: order.id,
        salesOrderNumber: order.salesOrderNumber,
      }
      axios
        .post('/services/buildings/mark-sold', data)
        .then(response => {
          this.response.soldDate = response.data.soldDate
          this.response.orderId = data.orderId
          this.response.salesOrderNumber = data.salesOrderNumber
          this.building.soldDate = response.data.soldDate
          this.building.orderId = data.orderId
          this.building.salesOrderNumber = data.salesOrderNumber
          this.$forceUpdate() // isn't updating because it's being called from child component?
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.sending = false
        })
    },
    validateBuildingConfiguration() {
      let validBuildingConfiguration = true
      _.each(this.building.rollDoors, door => {
        let doorSize = door.width + 'x' + door.height
        let priceObj = rollDoorPrices[doorSize]
        if (!priceObj) {
          this.$toasted.error(
            `Invalid Roll Door Size - ${doorSize} not found`,
            {
              duration: 5000,
            },
          )
          validBuildingConfiguration = false
          return false
        }
      })
      _.each(this.building.shedDoors, door => {
        if (!door.size) {
          this.$toasted.error(`Invalid Shed Door - must select a size`, {
            duration: 5000,
          })
          validBuildingConfiguration = false
          return false
        }
      })
      _.each(this.building.openings, opening => {
        if (opening.source === 'Metals Direct') {
          let openingSize = `${opening.width.ft}'${opening.width.in || '0'}x${
            opening.height.ft
          }'${opening.height.in || '0'}`
          let modelObj = this.models[opening.type][openingSize]
          if (!modelObj) {
            this.$toasted.error(
              `Invalid ${opening.type} Size - ${openingSize} not found`,
              {
                duration: 5000,
              },
            )
            validBuildingConfiguration = false
            return false
          }
        }
      })

      return validBuildingConfiguration
    },
    submitBuilding() {
      var validBuildingConfiguration = this.validateBuildingConfiguration()
      if (!validBuildingConfiguration) return
      var data = {
        pricerType: 'Sales',
        config: this.building,
      }
      delete data.config.__v
      this.sending = true
      axios
        .post('/services/buildings', data)
        .then(response => {
          this.building._id = response.data.building._id
          this.building.priceListId = response.data.building.priceListId
          this.response = response.data.building
          if (!window.location.pathname.split('/')[3]) {
            window.history.replaceState(
              {},
              null,
              window.location.pathname + '/' + this.response.shortId,
            )
          }
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.sending = false
        })
    },
    getDistance() {
      // todo: add check for no zip/address or require zip to fill out pricer
      this.gettingDistance = true
      axios
        .post('/services/buildings/get-distance', this.building.site.address)
        .then(response => {
          this.building.install.milesAway = _.ceil(response.data.milesAway) * 2
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.gettingDistance = false
        })
    },
    openBuildSiteModal() {
      this.showSiteDialog = true
    },
    resetConfigForm() {},
    addManualAddOnItem() {
      this.building.manualAddOnItems.push({
        name: '',
        description: '',
        price: '',
        taxable: false,
      })
    },
    addInstallAddOnItem() {
      this.building.installAddOnItems.push({
        name: '',
        description: '',
        price: '',
        taxable: false,
      })
    },
    removeManualAddOnItem(index) {
      this.building.manualAddOnItems.splice(index, 1)
    },
    removeInstallAddOnItem(index) {
      this.building.installAddOnItems.splice(index, 1)
    },
    handleAnchorChange(newVal) {
      if (
        newVal === 'slab' ||
        newVal === 'slab w/ footings' ||
        newVal === 'piers' ||
        newVal === 'footings' ||
        newVal === 'stem walls'
      ) {
        this.showConcreteOption = true
      } else {
        this.showConcreteOption = false
        this.building.foundation.doConcrete = false
      }
    },
    handleHeightBlur() {
      if (this.building.dimensions.height < 10) {
        this.showThicknessOption = true
      } else {
        this.showThicknessOption = false
        this.building.thickness = '2x3'
      }
    },
    loadConfig() {
      if (this.config && Object.keys(this.config).length) {
        // if (this.config.site.salesTaxRate && parseFloat(this.config.site.salesTaxRate) < 1) {
        //     this.config.site.salesTaxRate = _.round(this.config.site.salesTaxRate * 100, 2);
        // }
        _.merge(this.building, this.config)
        let roof = this.building.roof

        // backwards compatibility for legacy buidlings with single overhang property
        if (roof?.eaveOverhang?.overhang) {
          roof.leftEaveOverhang.overhang = roof.eaveOverhang.overhang
          roof.rightEaveOverhang.overhang = roof.eaveOverhang.overhang
        }
        if (roof?.gableOverhang?.overhang) {
          roof.frontGableOverhang.overhang = roof.gableOverhang.overhang
          roof.endGableOverhang.overhang = roof.gableOverhang.overhang
        }

        _.each(this.building.notesList, note => {
          this.notesList.push(note)
        })

        // this is required to make the lists of openings reactive on app load
        // array data is not reactive when reloading existing building data =/
        this.$set(this.building, 'openings', this.config.openings || [])
        this.$set(this.building, 'rollDoors', this.config.rollDoors || [])
        this.$set(this.building, 'manDoorList', this.config.manDoorList || [])
        this.$set(this.building, 'windowList', this.config.windowList || [])
        this.$set(this.building, 'shedDoors', this.config.shedDoors || [])

        this.handleAnchorChange(this.building.foundation.anchors)
        this.handleHeightBlur()
        // TODO: refactor calling handlers above;
        // these are needed on page load in order to handle any sort of defaults or pre-populated data;
        // don't have to call these on load if we change these to watches, will fire automatically on load
        // but then will fire every cycle, and don't want that .... use this pattern for now? see if this gets unweildy.

        if (this.config._id) {
          this.response = _.cloneDeep(this.building)
          this.viewOnly = true
        } else {
          const configKeys = Object.keys(this.config)
          if (configKeys.length === 1 && configKeys[0] === 'customer') {
            // do nothing, this is just a new building being created from the customer page
          } else {
            this.submitBuilding()
          }
        }
      }
    },
  },
  mounted: function () {
    this.loadConfig()
    // reset items items to trigger computed property, not computing on app load when lodaing building ..... hm
    const items = _.clone(this.building.manualAddOnItems)
    this.building.manualAddOnItems = items
  },
  computed: {
    manualAddOnItemsTotal() {
      let total = 0
      _.each(this.building.manualAddOnItems, item => {
        if (item.price) total += parseFloat(item.price)
      })
      return total
    },
    installAddOnItemsTotal() {
      let total = 0
      _.each(this.building.installAddOnItems, item => {
        if (item.price) total += parseFloat(item.price)
      })
      return total
    },
    disableInputs() {
      return this.viewOnly || this.sending
    },
  },
}
</script>

<style lang="scss" scoped>
.pricer-page {
  text-align: center;
}
.clone-edit-site-buttons {
  position: absolute;
  top: 6px;
  left: 6px;
}

.last-contacted {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 14px;
  text-align: right;
}

.last-contacted-time {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.card-title {
  margin-top: 12px;
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: bold;
}
.card-body {
  padding: 12px;
}
.input-col {
  padding-left: 4px;
  padding-right: 4px;
}

label {
  margin-bottom: 2px;
  display: block;
  text-align: left;
}

.section-title {
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid gray;
  margin-top: 36px;
  margin-bottom: 12px;
  text-align: left;
}

.display-subsection {
  margin-bottom: 10px;
  text-align: left;
}

.display-subsection-title {
  text-align: left;
  font-weight: 500;
}

.installAddOnItem-counter,
.manualAddOnItem-counter,
.window-counter {
  margin-top: 40px;
  font-size: 20px;
}

.add-window-button,
.add-door-button,
.add-door-button {
  padding: 0px 4px 0px 4px;
}

.remove-installAddOnItem-button,
.remove-manualAddOnItem-button,
.remove-window-button {
  margin-top: 32px;
}

.alert {
  padding: 0.25rem 0.1rem;
}

.calc-distance-button {
  margin-top: 26px;
}

.vertical-checkbox {
  label {
    width: 100%;
    display: block;
    text-align: center;
  }

  .custom-checkbox {
    width: 100%;
    margin-top: 4px;
  }
}

.fixed-submit {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: left;
  z-index: 10;
}

.addons-table {
  width: 100%;
  text-align: left;
  font-size: 14px;

  thead {
    text-decoration: underline;
  }
}

.addons-total-row {
  font-weight: bold;
  border-top: 1px dashed darkslategray;
}

.note-wrapper {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
}

.note-from {
  font-size: 13px;
  font-weight: 500;
}

.building-short-id {
  position: absolute;
  top: 36px;
  right: 6px;
  font-size: 14px;
  font-weight: 500;
  color: darkslategray;
}

.pricer-side {
  height: calc(100vh - 200px);
  overflow: scroll;

  @media screen and (max-width: 990px) {
    height: inherit;
  }
}

.inline-label {
  display: inline-block;
  margin-left: 6px;
  cursor: pointer;
}

@media only screen and (max-width: 990px) {
  .admin-buttons {
    height: 44px;
    position: relative;
  }

  .pricing-and-materials-breakdown {
    margin-top: 24px;
  }

  .page-h1 {
    margin-top: 20px;
  }
}
</style>
