<template>
  <div>
    <h4>Files</h4>
    <div v-if="customer">
      <CustomerFilesDisplay :customer="customer"></CustomerFilesDisplay>
    </div>
    <h4>Photos</h4>
    <div v-if="customer">
      <CustomerPhotosDisplay :customer="customer"></CustomerPhotosDisplay>
    </div>
  </div>
</template>

<script>
import CustomerFilesDisplay from '../components/CustomerFilesDisplay.vue'
import CustomerPhotosDisplay from '../components/CustomerPhotosDisplay.vue'
export default {
  name: 'CustomerFilesPage',
  props: {},
  data: function () {
    return {
      customer: null,
    }
  },
  components: { CustomerFilesDisplay, CustomerPhotosDisplay },
  mounted() {
    this.customer = this.$parent.customer
  },
}
</script>

<style lang="scss" scoped></style>
