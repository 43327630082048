<template>
  <div class="checklist-wrapper">
    <div v-for="item in order.workflow[workflow].checklist" :key="item.task">
      <div class="row">
        <div
          class="col"
          v-bind:class="{ clickable: !item.done, 'col-5': !hideDetail }"
          @click.stop="!item.done && confirmMarkDone(item, $event)"
        >
          <div v-if="item.notApplicable" class="not-applicable">
            <div class="na-icon">n/a</div>
            <div class="na-task-name">{{ item.task }}</div>
          </div>
          <b-checkbox
            v-if="!item.notApplicable"
            v-model="item.done"
            :disabled="true"
            size="md"
            class="operations-checklist-checkbox my-1"
          >
            {{ item.task }}
          </b-checkbox>
        </div>
        <div class="col col-7" v-if="!hideDetail">
          <div v-if="item.done && !hideDetail">
            {{ item.timestamp | shortFullDateFilter }} - {{ item.user }}
            <span
              class="clear-icon nc-icon nc-simple-remove"
              @click="confirmClearDone(item, $event)"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :id="`confirm-done-modal-${componentId}`"
      :title="currentItem && currentItem.task"
      hide-footer
      size="lg"
    >
      <div v-if="currentItem">
        <div>
          Confirm mark <strong>{{ currentItem.task }}</strong> as done?
        </div>
        <div class="completed-by">
          Completed by {{ userStore.user.email }} on
          <div class="checklist-item-timestamp">
            <b-form-input
              type="datetime-local"
              id="checklist-item-timestamp"
              size="sm"
              v-model="currentItem.timestamp"
            ></b-form-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div style="max-width: 300px">
              <b-form-file
                size="sm"
                v-model="currentItem.file"
                placeholder="Include File (Optional)"
                drop-placeholder="Drop file here..."
                :required="false"
              ></b-form-file>
            </div>
            <div>
              <b-button
                variant="info"
                size="sm"
                class="my-2"
                @click="currentItem.notes.push({ key: '', value: '' })"
              >
                + Add Metadata (Optional)
              </b-button>
              <HelpIcon
                text="Add metadata to track with checklist event. Ex: When marking 'Permit Submitted' as done, you can inclue the name of the county agent it was submitted with."
              ></HelpIcon>
              <div
                class="row mb-2"
                v-for="(note, index) in currentItem.notes"
                :key="index"
              >
                <div class="col">
                  <b-form-input
                    type="text"
                    size="sm"
                    v-model="note.key"
                    placeholder="key"
                  />
                </div>
                <div class="col">
                  <b-form-input
                    type="text"
                    size="sm"
                    v-model="note.value"
                    placeholder="value"
                  />
                </div>
                <div class="col col-1">
                  <span
                    style="cursor: pointer"
                    class="nc-icon nc-simple-remove"
                    @click="currentItem.notes.splice(index, 1)"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        <div class="modal-ctas mt-5">
          <b-button
            variant="primary"
            @click="updateChecklist"
            :disabled="submitting"
          >
            <span v-if="submitting">Confirming..</span>
            <span v-if="!submitting">Confirm</span>
          </b-button>
          <b-button
            variant="secondary"
            @click="$bvModal.hide(`confirm-done-modal-${componentId}`)"
            :disabled="submitting"
            class="ml-2"
            >Cancel</b-button
          >
          <b-button
            variant="danger"
            @click="udpateNotApplicable"
            :disabled="submitting"
            class="ml-2 na-button"
            type="button"
          >
            <span v-if="!currentItem.notApplicable">Mark N/A</span>
            <span v-if="currentItem.notApplicable">Remove N/A</span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="`confirm-clear-modal-${componentId}`"
      :title="currentItem && currentItem.task"
      hide-footer
    >
      <div v-if="currentItem">
        <div>Undo 'done' state for {{ currentItem.task }}?</div>
        <div class="mt-2">
          Previously completed by {{ userStore.user.email }} on
          {{ currentItem.timestamp | dateTimeFilter }}
        </div>

        <div class="modal-ctas mt-4">
          <b-button
            variant="primary"
            @click="updateChecklist"
            :disabled="submitting"
          >
            <span v-if="submitting">Confirming..</span>
            <span v-if="!submitting">Confirm</span>
          </b-button>
          <b-button
            variant="secondary"
            @click="$bvModal.hide(`confirm-clear-modal-${componentId}`)"
            :disabled="submitting"
            class="ml-2"
            >Cancel</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { engineeringChecklist } from '../data/checklistDefaults'
import HelpIcon from '@/components/HelpIcon'

export default {
  name: 'TaskChecklist',
  props: {
    order: {
      type: Object,
      required: true,
    },
    workflow: {
      type: String,
      required: true,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: { HelpIcon },
  inject: ['userStore'],
  data: function () {
    return {
      submitting: false,
      savingChecklist: false,
      startCase: _.startCase,
      currentItem: null,
      componentId: _.random(0, 9999999).toString(),
    }
  },
  methods: {
    confirmMarkDone(item, $event) {
      this.currentItem = item
      this.currentItem.timestamp = moment().format('YYYY-MM-DDTHH:mm')
      this.$bvModal.show(`confirm-done-modal-${this.componentId}`)
    },
    confirmClearDone(item, $event) {
      this.currentItem = item
      this.$bvModal.show(`confirm-clear-modal-${this.componentId}`)
    },
    udpateNotApplicable() {
      this.submitting = true
      const data = {
        id: this.order.id,
        workflow: this.workflow,
        checklistItems: [
          {
            ...this.currentItem,
            notApplicable: !this.currentItem.notApplicable,
          },
        ],
      }
      return axios
        .put('/services/orders/checklist-task', data)
        .then(response => {
          this.order.workflow[this.workflow].checklist = response.data.checklist
          this.order.events = response.data.events
          this.$bvModal.hide(`confirm-done-modal-${this.componentId}`)
          this.$bvModal.hide(`confirm-clear-modal-${this.componentId}`)
          this.$toasted.success(
            `${this.startCase(this.currentItem.task)} updated to ${
              this.currentItem.notApplicable ? 'Applicable' : 'Not Applicable'
            }.`,
            { duration: 3000 },
          )
        })
        .catch(error => {
          this.$toasted.error(` Error updating order`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    updateChecklist() {
      this.submitting = true
      const data = {
        id: this.order.id,
        workflow: this.workflow,
        checklistItems: [{ ...this.currentItem, done: !this.currentItem.done }],
      }
      if (this.currentItem.file) {
        this.addFile(this.currentItem.file)
      }
      return axios
        .put('/services/orders/checklist-task', data)
        .then(response => {
          this.order.workflow[this.workflow].checklist = response.data.checklist
          this.order.events = response.data.events
          this.$bvModal.hide(`confirm-done-modal-${this.componentId}`)
          this.$bvModal.hide(`confirm-clear-modal-${this.componentId}`)
          this.$toasted.success(
            `${this.startCase(this.currentItem.task)} updated to ${!this
              .currentItem.done}.`,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
              duration: 4000,
            },
          )
        })
        .catch(error => {
          this.$toasted.error(` Error updating order`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    async saveFile(file) {
      file.department = this.workflow.toUpperCase()
      file.tags = this.currentItem.task
      const data = {
        id: this.order.id,
        file,
      }
      try {
        let response = await axios.put('/services/orders/save-file', data)
        this.$toasted.success(`File Added`, {
          duration: 2500,
        })
        this.order.files.push(file)
      } catch (error) {
        this.$toasted.error(` Error uploading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
    },
    async addFile(file) {
      const data = {
        id: this.order.id,
        fileName: file.name,
        department: this.workflow,
        contentType: file.type,
      }
      try {
        const response = await axios.put(
          '/services/orders/file-upload-url',
          data,
        )
        await axios.put(response.data.signedUploadURL, file, {
          headers: {
            'Content-Type': file.type,
          },
        })
        return this.saveFile(response.data.file)
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.status-display {
  font-size: 20px;
}
.hold-reason {
  font-size: 16px;
}
.status {
  font-weight: bold;
}
.open {
  color: $open-color;
}
.in-progress {
  color: $in-progress-color;
}
.on-hold {
  color: $on-hold-color;
}
.cancelled {
  color: $cancelled-color;
}
.ready {
  color: $ready-color;
}
.completed {
  color: $completed-color;
}

.completed-by {
  font-size: 15px;
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.clickable {
  input,
  label {
    cursor: pointer;
  }
}
.operations-checklist-checkbox {
  .custom-control-input[disabled] ~ .custom-control-label,
  .custom-control-input:disabled ~ .custom-control-label {
    color: #2c3e50 !important;
  }
}

.clear-icon {
  font-weight: bold;
  color: red;
  cursor: pointer;
  vertical-align: middle;
}

.checklist-item-timestamp {
  display: inline-block;
}

.na-button {
  position: absolute;
  right: 16px;
}

.not-applicable {
  margin-top: 2px;
  cursor: pointer;
}

.na-task-name {
  text-decoration: line-through;
  display: inline-block;
}

.na-icon {
  display: inline-block;
  margin-right: 4px;
  margin-left: -2px;
  color: lightcoral;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
}
</style>
