<template>
  <div class="table-wrapper">
    <div v-if="!buildings.length">
      <div class="text-center mt-3 mb-3"><div class="loader"></div></div>
    </div>
    <div v-if="buildings.length">
      <b-table
        striped
        :sticky-header="stickyHeader"
        hover
        @row-clicked="openBuilding"
        :items="buildingList"
        :fields="fields"
      ></b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ListInput',
  props: {
    buildings: {
      type: Array,
      required: true,
    },
    minCols: {
      type: Boolean,
      required: false,
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      stickyHeader: true,
      fields: [
        {
          key: 'customerName',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'customerEmail',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'dimensions',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'type',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'nickname',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'createdBy',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  methods: {
    formatDimensions(dimensions) {
      return (
        dimensions.width + 'x' + dimensions.length + 'x' + dimensions.height
      )
    },
    openBuilding(building, index, event) {
      console.log('openBuilding with building: ', building)
      event.preventDefault()
      if (event.metaKey) {
        window.open('/app/pricer/' + building.shortId, '_blank')
      } else {
        this.$router.push('/app/pricer/' + building.shortId)
      }
    },
  },
  computed: {
    buildingList() {
      return this.buildings.map(building => {
        // todo: change to use customerName field when ready
        let customerName =
          building.customer.name ||
          (building.customer.firstName || '') +
            ' ' +
            (building.customer.lastName || '')
        return {
          shortId: building.shortId,
          customerName,
          customerEmail: building.customer.email,
          nickname: building.nickname,
          dimensions: this.formatDimensions(building.dimensions),
          type: building.type,
          createdBy: building.createdBy,
          createdAt: this.$options.filters.shortFullDateFilter(
            building.createdAt,
          ),
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.table-wrapper {
  max-height: 300px;
  overflow: scroll;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: #000000;
  background: -moz-linear-gradient(
    left,
    #000000 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #000000 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(
    left,
    #000000 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    #000000 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 0.9s infinite linear;
  animation: load3 0.9s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #000000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
