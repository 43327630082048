<template>
  <div>
    <div class="sales-lead-overview" v-if="this.salesLead">
      <div class="row sales-lead-summary">
        <div class="col">
          <div>
            <div class="sales-lead-name">
              {{ salesLead.firstName }}
            </div>
            <div class="sales-lead-since">
              Created {{ salesLead.createdAt | dateFilter }}
            </div>
            <div class="sales-lead-company">
              {{ salesLead.company }}
            </div>
          </div>
          <div>
            {{ salesLead.email }}<br />
            {{ salesLead.phone }}
          </div>
          <div><strong>Page:</strong> {{ salesLead.submittedOnPage }}</div>
          <div class="sales-lead-comments">
            <strong>Comments:</strong><br />
            {{ salesLead.comments }}
          </div>
        </div>
        <div class="col">
          <div>
            Status: <span v-bind:style="{}">{{ salesLead.status }}</span>
          </div>
          <div>
            Assigned To:
            <span v-if="salesLead.salesPersonEmail">{{
              salesLead.salesPersonEmail
            }}</span>
            <span v-if="!salesLead.salesPersonEmail">
              - none -
              <b-button
                @click="$bvModal.show('confirm-assign-modal')"
                variant="primary"
                class="ml-2"
                size="sm"
                >Assign to me
              </b-button>
            </span>
          </div>
          <div class="mt-3">
            First Contacted:
            {{ salesLead.firstContactedDate | daysAgoWithTime }}
          </div>
          <div>
            Last Contacted: {{ salesLead.lastContactedDate | daysAgoWithTime }}
            <b-button
              type="button"
              variant="info"
              size="sm"
              class="last-contacted-btn"
              :disabled="updatingLastContacted"
              @click="updateLastContacted()"
            >
              Update
            </b-button>
          </div>
          <div class="mt-3">
            <b-button
              @click="$bvModal.show('confirm-close-modal')"
              variant="danger"
              class="mr-2"
              >Mark Closed</b-button
            >
            <b-button
              @click="$bvModal.show('confirm-spam-modal')"
              variant="secondary"
              class="mr-2"
              >Mark Spam</b-button
            >
          </div>
          <div class="mt-3">
            <b-button
              v-show="false"
              @click="$bvModal.show('confirm-convert-modal')"
              variant="success"
              >Convert to Customer</b-button
            >
          </div>
          <div class="address" v-if="salesLead.address">
            <div class="mt-3">{{ salesLead.address.street1 }}</div>
            <div>
              {{ salesLead.address.city }}, {{ salesLead.address.state }}
              {{ salesLead.address.zip }}
            </div>
            <div v-if="salesLead.address.county">
              {{ salesLead.address.county }}
            </div>
          </div>
        </div>
      </div>
      <h3 class="mt-3">Notes</h3>
      <div class="row">
        <div class="col col-lg-8">
          <sales-lead-notes :sales-lead="salesLead"></sales-lead-notes>
        </div>
      </div>
    </div>

    <div class="modals" v-if="salesLead">
      <b-modal :id="`confirm-spam-modal`" hide-header @ok="markStatus('SPAM')">
        Confirm mark email from {{ salesLead.email }} as spam?
      </b-modal>
      <b-modal
        :id="`confirm-close-modal`"
        hide-header
        @ok="markStatus('CLOSED')"
      >
        Confirm close lead from {{ salesLead.email }}?
      </b-modal>
      <b-modal
        :id="`confirm-convert-modal`"
        hide-header
        @ok="markStatus('CONVERTED')"
      >
        Convert {{ salesLead.email }} to customer?
      </b-modal>
      <b-modal
        :id="`confirm-assign-modal`"
        hide-header
        @ok="assignToCurrentUser()"
      >
        Confirm assign lead from {{ salesLead.email }}?
      </b-modal>
    </div>
  </div>
</template>

<script>
import SalesLeadNotes from '@/components/SalesLeadNotes'
import axios from 'axios'
export default {
  name: 'SalesLeadOverviewPage',
  props: {
    salesLead: {
      type: Object,
      required: true,
    },
  },
  inject: ['userStore'],
  data: function () {
    return {
      updatingLastContacted: false,
      submitting: false,
    }
  },
  components: {
    SalesLeadNotes,
  },
  methods: {
    updateLastContacted() {
      this.updatingLastContacted = true
      const data = {
        _id: this.salesLead._id,
        lastContacted: new Date(),
      }
      return axios
        .put('/services/sales-leads/last-contacted', data)
        .then(response => {
          this.salesLead.lastContactedDate = response.data.lastContacted
          this.salesLead.firstContactedDate =
            this.salesLead.firstContactedDate || response.data.lastContacted
          this.$toasted.success(`Last Contacted updated`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error updating last contacted date`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.updatingLastContacted = false
        })
    },
    markStatus(status) {
      const data = {
        _id: this.salesLead._id,
        status,
      }
      this.submitting = true
      return axios
        .put('/services/sales-leads/status', data)
        .then(response => {
          this.salesLead.status = status
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error('An error has occured', {
            duration: 5000,
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    assignToCurrentUser() {
      const data = {
        _id: this.salesLead._id,
        salesPersonEmail: this.userStore.user.email,
      }
      this.submitting = true
      return axios
        .put('/services/sales-leads/assign', data)
        .then(response => {
          this.salesLead.salesPersonEmail =
            response.data.salesLead.salesPersonEmail
          this.salesLead.status = response.data.salesLead.status
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.sales-lead-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.sales-lead-name {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 22px;
}
.sales-lead-since {
  font-size: 14px;
  margin-top: -6px;
}
.contact-card .card {
  background: rgb(240, 250, 255);
}

.location-card .card {
  background: rgb(240, 255, 244);
}
.list {
  //   max-height: 300px;
  //   overflow: scroll;
}
</style>
