<template>
  <div>
    <label for="name"> <strong>Name</strong> </label>
    <b-form-input
      id="name"
      type="text"
      v-model="scheduleDate.name"
      required
    ></b-form-input>

    <div class="row">
      <div class="col">
        <label for="schedule-date" class="mt-3">
          <strong> Date </strong>
        </label>
        <b-form-datepicker
          id="schedule-date"
          v-model="scheduleDate.dateTime"
          :value-as-date="true"
          :required="true"
        ></b-form-datepicker>
      </div>
      <div class="col">
        <label for="end-schedule-date" class="mt-3">
          <strong>End Date </strong>
        </label>
        <b-form-datepicker
          id="end-schedule-date"
          v-model="scheduleDate.endDateTime"
          :value-as-date="true"
          :required="true"
        ></b-form-datepicker>
      </div>
    </div>

    <div class="mt-4">
      <b-checkbox v-model="scheduleDate.allDayEvent" size="lg">
        All Day Event?
      </b-checkbox>
    </div>

    <div v-if="!scheduleDate.allDayEvent" class="row mt-3">
      <div class="col">
        <label for="start-time">
          <strong> Start Time </strong>
        </label>
        <b-form-timepicker
          id="start-time"
          v-model="scheduleDate.startTime"
          :required="!scheduleDate.allDay"
        ></b-form-timepicker>
      </div>
      <div class="col">
        <label for="End Time">
          <strong> End Time </strong>
        </label>
        <b-form-timepicker
          id="end-time"
          v-model="scheduleDate.endTime"
        ></b-form-timepicker>
      </div>
    </div>

    <label for="assigned-to" class="mt-3"
      ><strong>Assigned To</strong>
      <HelpIcon
        text="Track who this date is assigned to this date - delviery driver, install crew, etc"
      ></HelpIcon>
    </label>
    <b-form-input
      type="text"
      id="assigned-to"
      v-model="scheduleDate.assignedTo"
    ></b-form-input>
    <label for="memo" class="mt-3"
      ><strong>Memo</strong>
      <HelpIcon
        text="Notes or Instructions for date - ex. 'Date was rescheduled by vendor'"
      ></HelpIcon
    ></label>
    <b-textarea
      id="memo"
      v-model="scheduleDate.memo"
      :required="false"
    ></b-textarea>
    <div class="custom-color mt-3">
      <b-checkbox
        v-model="useCustomColor"
        size="lg"
        class="mt-3"
        @change="handleSetUseCustomColor"
      >
        Use Custom Color?
      </b-checkbox>
      <b-input
        class="color-input"
        v-if="useCustomColor"
        name="calendar-color"
        type="color"
        v-model="scheduleDate.calendarColor"
        required="true"
      ></b-input>
    </div>
    <b-checkbox v-model="scheduleDate.completed" size="lg" class="mt-3">
      Mark as complete?
    </b-checkbox>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import HelpIcon from './HelpIcon.vue'

export default {
  name: 'VendorFormFields',
  props: {
    scheduleDate: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
  },
  components: { HelpIcon },
  inject: ['scheduleDateStore'],
  data: function () {
    return {
      useCustomColor: this.scheduleDate.calendarColor ? true : false,
    }
  },
  watch: {
    'scheduleDate.dateTime'(newVal, oldVal) {
      if (!this.scheduleDate.endDateTime) {
        this.scheduleDate.endDateTime = this.scheduleDate.dateTime
      } else if (
        moment(this.scheduleDate.endDateTime).isBefore(
          moment(this.scheduleDate.dateTime),
        )
      ) {
        this.scheduleDate.endDateTime = this.scheduleDate.dateTime
      }
    },
    'scheduleDate.endDateTime'(newVal, oldVal) {
      if (!this.scheduleDate.dateTime) {
        this.scheduleDate.dateTime = this.scheduleDate.endDateTime
      } else if (
        moment(this.scheduleDate.endDateTime).isBefore(
          moment(this.scheduleDate.dateTime),
        )
      ) {
        this.scheduleDate.dateTime = this.scheduleDate.endDateTime
      }
    },
  },
  methods: {
    handleSetUseCustomColor(useCustomColor) {
      if (!useCustomColor) {
        this.scheduleDate.calendarColor = ''
      }
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.color-input {
  width: 200px;
  margin-top: 6px;
  cursor: pointer;
}
</style>
