<template>
  <div class="login mt-5">
    <img src="@/assets/Metals-Direct-Inc-logo.png" alt="" />
    <b-form @submit.prevent="submitLogin">
      <div class="container-fluid text-center">
        <div class="row justify-content-center">
          <div class="col-12 col-md-4">
            <h1 class="mt-4">Login</h1>
            <label for="email">Email</label>
            <b-input-group>
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                :disabled="sending"
                required
              ></b-form-input>
            </b-input-group>
            <label class="mt-3" for="password">Password</label>
            <b-input-group>
              <b-form-input
                id="password"
                v-model="password"
                type="password"
                :disabled="sending"
                v-show="!showPassword"
                required
              ></b-form-input>
            </b-input-group>
            <b-input-group>
              <b-form-input
                id="visiblePassword"
                v-model="password"
                type="text"
                :disabled="sending"
                v-show="showPassword"
                required
              ></b-form-input>
            </b-input-group>
            <div class="row text-left">
              <button
                type="button"
                class="show-pw-button mt-1 ml-2"
                @click="showPassword = !showPassword"
              >
                <span v-show="!showPassword">Show Password</span>
                <span v-show="showPassword">Hide Password</span>
              </button>
            </div>
            <b-button
              class="mt-3"
              type="submit"
              variant="primary"
              :disabled="sending"
            >
              <span v-if="sending">Submitting...</span>
              <span v-if="!sending">Submit</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
// import { userStore } from '@/stores/userStore';

export default {
  name: 'Login',
  props: {
    pathAfterLogin: {
      type: String,
    },
  },
  inject: ['userStore'],
  data: function () {
    return {
      email: '',
      password: '',
      sending: false,
      showPassword: false,
    }
  },
  methods: {
    submitLogin() {
      const data = { email: this.email, password: this.password }
      this.sending = true
      return axios
        .post('/services/public/user/login', data)
        .then(response => {
          return axios
            .get('/services/user')
            .then(response => {
              this.userStore.setUser(response.data.user)
              if (
                this.userStore.user.type === 'ADMIN' ||
                this.userStore.user.type === 'SALES' ||
                this.userStore.user.type === 'MANUFACTURING'
              ) {
                const routePath = this.pathAfterLogin || '/app'
                this.$router.push(routePath)
              } else if (this.userStore.user.type === 'CONTRACTOR') {
                this.$router.push('/contractor')
              }
            })
            .catch(error => {
              console.log('error: ', error)
              this.sending = false
              this.$toasted.error(error.response.data.errors, {
                action: {
                  text: 'Close',
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0)
                  },
                },
              })
            })
        })
        .catch(error => {
          console.log('error: ', error)
          this.sending = false
          this.$toasted.error(error.response.data.errors, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 24px;
}
label {
  text-align: left;
  font-weight: bold;
  display: block;
}
button {
  width: 100%;
}

.show-pw-button {
  width: 130px;
  border: none;
  background: none;
  color: #dc3545;
}
</style>
