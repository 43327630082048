<template>
  <div class="row">
    <div class="col">
      <form @submit.prevent="submitSearch">
        <div class="row mb-3">
          <div class="col-12 col-sm-4">
            <b-input
              type="text"
              v-model="orderStore.state.query.jobName"
              placeholder="Job Name"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="orderStore.state.query.customerEmail"
              placeholder="Customer Email"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="orderStore.state.query.customerName"
              placeholder="Customer Name"
              class="mt-2"
            ></b-input>
            <b-button
              type="submit"
              variant="info"
              :disabled="submitting"
              class="mt-2"
              >Submit Search</b-button
            >
            <b-button
              type="button"
              variant="secondary"
              :disabled="submitting"
              class="mt-2 ml-2"
              @click="clearSearch"
              >Clear Search</b-button
            >
          </div>
          <div class="col-12 col-sm-4">
            <b-form-select
              v-model="orderStore.state.query.type"
              :options="orderTypes"
              class="mt-2"
            ></b-form-select>
            <b-form-select
              v-model="orderStore.state.query.status"
              :options="orderStatuses"
              class="mt-2"
              placeholder="Order Status"
            ></b-form-select>
          </div>
          <div class="col-12 col-sm-4">
            <b-form-datepicker
              id="sold-date"
              class="mt-2"
              v-model="orderStore.state.query.soldDate"
              placeholder="Sold Date"
              required
            ></b-form-datepicker>
            <b-form-datepicker
              id="due-date"
              class="mt-2"
              v-model="orderStore.state.query.dueDate"
              placeholder="Due Date"
              required
            ></b-form-datepicker>
          </div>
        </div>
        <div
          v-if="
            orderStore.state.querySubmitted &&
            orderStore.state.searchResults.length > 0
          "
        >
          Search results ({{ orderStore.state.searchResults.length }})
          <order-list
            :orders="orderStore.state.searchResults"
            linkAction="detail"
            customMaxHeight="800px"
          ></order-list>
        </div>
        <div
          v-if="
            orderStore.state.querySubmitted &&
            orderStore.state.searchResults.length == 0
          "
        >
          No search results found
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import OrderList from '@/components/OrderList'
import axios from 'axios'
import { orderStatuses, orderTypes } from './orderInputOptions'
import moment from 'moment'

export default {
  name: 'OrderLookup',
  components: { OrderList },
  inject: ['orderStore'],
  data: function () {
    return {
      submitting: false,
      error: false,
      orderTypes,
      orderStatuses,
    }
  },
  methods: {
    submitSearch() {
      this.submitting = true
      axios
        .get(
          '/services/orders/lookup?customerEmail=' +
            encodeURIComponent(this.orderStore.state.query.customerEmail) +
            '&type=' +
            this.orderStore.state.query.type +
            '&jobName=' +
            encodeURIComponent(this.orderStore.state.query.jobName) +
            '&customerName=' +
            encodeURIComponent(this.orderStore.state.query.customerName) +
            '&soldDate=' +
            this.orderStore.state.query.soldDate +
            '&dueDate=' +
            this.orderStore.state.query.dueDate +
            '&status=' +
            this.orderStore.state.query.status,
        )
        .then(response => {
          _.each(response.data.orders, order => {
            if (order.workflow.sales.dueDate) {
              let minutesLeft = moment(order.workflow.sales.dueDate).diff(
                new Date(),
                'minutes',
              )
              const prefix = minutesLeft < 0 ? '-' : '+'
              minutesLeft = Math.abs(minutesLeft)
              order.timeLeft =
                prefix +
                Math.floor(minutesLeft / 24 / 60) +
                ':' +
                Math.floor((minutesLeft / 60) % 24) +
                ':' +
                (minutesLeft % 60)
            }
          })
          this.orderStore.setSearchResults(response.data.orders)
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    clearSearch() {
      this.orderStore.clearSearchResults()
      this.orderStore.clearQuery()
    },
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
