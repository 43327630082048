<template>
  <form @submit.prevent="addScheduleDate">
    <schedule-date-form-fields
      :scheduleDate="scheduleDate"
      :submitting="submitting"
    ></schedule-date-form-fields>
    <div class="modal-ctas mt-4">
      <b-button variant="primary" :disabled="submitting" type="submit">
        <span v-if="submitting">Saving..</span>
        <span v-if="!submitting">Save</span>
      </b-button>
      <b-button
        variant="secondary"
        type="button"
        @click="$bvModal.hide('add-schedule-date-modal')"
        :disabled="submitting"
        class="ml-2"
        >Cancel</b-button
      >
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import ScheduleDateFormFields from './ScheduleDateFormFields.vue'

export default {
  name: 'VendorFormFields',
  props: {},
  components: { ScheduleDateFormFields },
  inject: ['scheduleDateStore'],
  data: function () {
    return {
      submitting: false,
      scheduleDate: {
        name: '',
        dateTime: null,
        endDateTime: null,
        startTime: '',
        allDayEvent: false,
        endTime: '',
        assignedTo: '',
        completed: false,
        memo: '',
        calendarColor: '',
        referenceObject: '',
        referenceId: '',
      },
    }
  },
  watch: {},
  methods: {
    addScheduleDate() {
      const data = { scheduleDate: this.scheduleDate }
      if (!this.scheduleDate.dateTime) {
        return this.$toasted.error('Error: Must Select Date', {
          duration: 3000,
        })
      }
      if (!this.scheduleDate.allDayEvent && !this.scheduleDate.startTime) {
        return this.$toasted.error('Error: Must Select Start Time', {
          duration: 3000,
        })
      }
      return axios
        .post('/services/schedule-dates', data)
        .then(response => {
          this.scheduleDateStore.addScheduleDate(response.data.scheduleDate)

          this.$toasted.success(`Added Schedule Date`, {
            duration: 2500,
          })
          this.$bvModal.hide('add-schedule-date-modal')
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Adding Schedule Date`, {
            duration: 2500,
          })
        })
        .finally(() => {})
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}
</style>
