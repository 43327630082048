<template>
  <div class="modal-wrapper" v-if="order">
    <router-link
      class="order-page-link"
      :to="`/app/orders/${order.id}`"
      v-if="modalId != 9999"
      >Go to order page</router-link
    >
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col" id="info">
            <div>
              <label class="mb-1" for="">Job Name</label>
              <div class="order-info">
                <span>
                  {{ order.jobName }}
                  <FTBadge v-if="order.fastTrack"></FTBadge>
                </span>
              </div>
            </div>
            <div v-if="order.salesOrderNumber">
              <label class="mb-1" for="">Sales Order Number</label>
              <div class="order-info">{{ order.salesOrderNumber }}</div>
            </div>
            <div>
              <label class="mb-1" for="">Delivery Method</label>
              <div class="order-info">{{ order.deliveryMethod }}</div>
            </div>
            <div>
              <label class="mb-1" for="">Customer</label>
              <div class="order-info">
                {{ order.customer.name
                }}<span v-if="order.customer.phone">
                  ({{ order.customer.phone }})
                  <VIPBadge v-if="order.customer.vip"></VIPBadge>
                </span>
              </div>
            </div>
            <div id="current-status">
              <label class="mb-1" for="">Current Status</label>
              <div class="order-info">{{ order.status }}</div>
              <div class="order-info" v-if="order.status === 'ON-HOLD'">
                {{ order.workflow.manufacturing.holdReason }}
              </div>
            </div>
            <b-form @submit.prevent="confirmUpdateStatus">
              <div id="new-status">
                <label for="status">New Status</label>
                <b-form-select
                  id="status"
                  v-model="newStatus"
                  :options="orderStatuses"
                  required
                  :disabled="submitting"
                ></b-form-select>
                <b-form-select
                  id="status"
                  v-model="newHoldReason"
                  :options="holdReasons"
                  required
                  class="mt-2"
                  :disabled="submitting"
                  v-if="reasonRequired"
                ></b-form-select>
                <b-form-input
                  v-model="customHoldReason"
                  v-if="newHoldReason == 'Other'"
                  :required="true"
                  placeholder="Enter Reason"
                  class="mt-2"
                ></b-form-input>
              </div>
              <div id="ctas">
                <div class="ctas text-right mt-4">
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mx-2"
                    :disabled="submitting"
                  >
                    <span v-show="submitting">Submitting</span>
                    <span v-show="!submitting">Update Status</span>
                  </b-button>
                </div>
              </div>
            </b-form>
            <b-modal
              hide-header
              id="confirmUpdateStatusModal"
              size="sm"
              @ok="updateOrderStatus"
            >
              Are you sure you want to update status to {{ newStatus }}?
            </b-modal>
          </div>
          <div class="col">
            <div
              class="work-orders"
              id="workOrders"
              v-if="
                order.status === 'OPEN' ||
                order.status === 'ON-HOLD' ||
                order.status === 'IN-PROGRESS' ||
                order.status === 'COMPLETED' ||
                order.status === 'CANCELLED'
              "
            >
              <b-form @submit.prevent="updateWorkOrders()">
                <div
                  v-for="(status, step) in order.workflow.manufacturing
                    .workOrders"
                  :key="step"
                >
                  <!-- <label :for="step">{{ step }}</label> -->
                  <!-- <b-form-select
                  :id="step"
                  v-model="newWorkOrders[step]"
                  :options="workOrderStatuses"
                  required
                  :disabled="submitting"
                ></b-form-select> -->
                  <b-form-group :label="step | capitalize">
                    <b-form-radio-group
                      :id="step"
                      v-model="newWorkOrders[step]"
                      :options="workOrderStatuses"
                      :name="step"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
                <div id="ctas">
                  <div class="ctas text-right mt-4">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mx-2"
                      :disabled="submitting"
                    >
                      <span v-show="submitting">Submitting</span>
                      <span v-show="!submitting">Update Work Orders</span>
                    </b-button>
                  </div>
                </div>
              </b-form>
            </div>
            <div
              v-if="
                order.status === 'READY' ||
                order.status === 'ON-HOLD' ||
                order.status === 'COMPLETED' ||
                order.status === 'CANCELLED'
              "
              class="mt-3"
            >
              <label class="mb-1" for="">Last Contacted</label>
              <div class="order-info">
                <span v-if="order.workflow.sales.lastContactedDate">
                  {{ order.workflow.sales.lastContactedDate | dateTimeFilter }}
                  ({{ lastContactedDaysAgo }})
                </span>
                <span v-if="!order.workflow.sales.lastContactedDate">
                  Not Yet Contacted
                </span>
              </div>
              <b-button
                variant="primary"
                class="mt-2 float-right"
                @click="updateLastContacted"
                :disabled="submitting"
              >
                Update Last Contacted
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="notes-wrapper">
          <notes :order="order"></notes>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import {
  orderStatuses,
  workOrderStatuses,
  holdReasons,
} from './orderInputOptions'
import Notes from '@/components/Notes'
import moment from 'moment'
import FTBadge from '@/components/FTBadge'
import VIPBadge from '@/components/VIPBadge'

export default {
  name: 'UpdateOrderStatus',
  components: { Notes, FTBadge, VIPBadge },
  props: {
    currentOrder: {
      type: Object,
      required: true,
    },
    modalId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      order: this.currentOrder,
      submitting: false,
      newStatus: '',
      newWorkOrders: _.clone(
        this.currentOrder.workflow.manufacturing.workOrders,
      ),
      orderStatuses,
      holdReasons,
      workOrderStatuses,
      newHoldReason: '',
      customHoldReason: '',
    }
  },
  mounted() {},
  computed: {
    reasonRequired() {
      return this.newStatus === 'ON-HOLD'
    },
    lastContactedDaysAgo() {
      const now = moment()
      const lastContacted = moment(this.order.workflow.sales.lastContactedDate)
      const days = now.diff(lastContacted, 'days')
      return `${days} days ago`
    },
  },
  methods: {
    confirmUpdateStatus() {
      this.$bvModal.show('confirmUpdateStatusModal')
    },
    updateOrderStatus() {
      this.submitting = true
      const data = {
        id: this.order.id,
        status: this.newStatus,
      }
      if (this.newStatus === 'ON-HOLD') {
        data.holdReason =
          this.newHoldReason === 'Other'
            ? this.customHoldReason
            : this.newHoldReason
      }
      const orderNumber = this.order.salesOrderNumber || ''

      return axios
        .put('/services/orders/manufacturing/status', data)
        .then(response => {
          this.order.status = this.newStatus
          this.order.events = response.data.order.events
          this.order.workflow.manufacturing.holdReason =
            response.data.order.workflow.manufacturing.holdReason
          this.newStatus = ''
          this.$bvModal.hide(`updateOrderModal${this.modalId}`)
          this.$toasted.success(
            `Order ${orderNumber} updated to ${data.status}`,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
              duration: 4000,
            },
          )
        })
        .catch(error => {
          this.$toasted.error(` Error updating order ${orderNumber}`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    updateLastContacted() {
      this.submitting = true
      const data = {
        id: this.order.id,
        lastContacted: new Date(),
      }
      const orderNumber = this.order.salesOrderNumber || ''

      return axios
        .put('/services/orders/last-contacted', data)
        .then(response => {
          this.order.workflow.sales.lastContactedDate =
            response.data.order.workflow.sales.lastContactedDate
          this.order.events = response.data.order.events

          this.$toasted.success(
            `Last Contacted updated to ${data.lastContacted}`,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
              duration: 4000,
            },
          )
        })
        .catch(error => {
          this.$toasted.error(` Error updating order ${orderNumber}`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    updateWorkOrders() {
      this.submitting = true
      const data = {
        id: this.order.id,
        workOrders: this.newWorkOrders,
      }
      const orderNumber = this.order.salesOrderNumber || ''

      return axios
        .put('/services/orders/work-orders', data)
        .then(response => {
          this.order.workflow.manufacturing.workOrders =
            response.data.order.workflow.manufacturing.workOrders
          this.order.events = response.data.order.events
          this.$toasted.success(`Work orders updated`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(error => {
          this.$toasted.error(` Error updating order ${orderNumber}`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.order-info {
  font-weight: bold;
}
.time-left {
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  color: #02c60b;
}

.work-orders {
  & .custom-radio {
    margin-right: 0.5rem !important;
  }
}

.radio-group-label {
  text-decoration: underline;
}
</style>

<style lang="scss">
.work-orders {
  & .custom-radio {
    margin-right: 0.5rem !important;
  }

  & legend {
    padding-bottom: 2px;
  }
}

.order-page-link {
  position: absolute;
  top: -24px;
}
</style>
