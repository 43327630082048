import './compositionAPI'
import { reactive, readonly, computed } from '@vue/composition-api'
import _ from 'lodash'

const user = reactive({})
const userList = reactive([])

function setUser(configObject) {
  if (window.FS) {
    window.FS.identify(configObject._id)
    window.FS.setUserVars({
      displayName: configObject.firstName,
      email: configObject.email,
    })
  }
  _.merge(user, configObject)

  user.fullName = user.firstName + (user.lastName ? ' ' + user.lastName : '')
}

function setUserList(newUsers) {
  _.merge(userList, newUsers)
}

function logout(configObject) {
  _.each(user, (value, key) => {
    removeProp(key)
  })
}

function addProp(key, value) {
  user[key] = value
}

function removeProp(key) {
  delete user[key]
}

const fullName = computed(() => {
  return user.firstName + ' ' + user.lastName
})

export const userStore = readonly({
  user,
  userList,
  fullName,
  setUser,
  logout,
  addProp,
  removeProp,
  setUserList,
})
