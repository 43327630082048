<template>
  <form @submit.prevent="editScheduleDate" v-if="scheduleDate">
    <schedule-date-form-fields
      :scheduleDate="scheduleDate"
      :submitting="submitting"
    ></schedule-date-form-fields>
    <div class="modal-ctas mt-4">
      <b-button variant="primary" :disabled="submitting" type="submit">
        <span v-if="submitting">Saving..</span>
        <span v-if="!submitting">Save</span>
      </b-button>
      <b-button
        variant="secondary"
        type="button"
        @click="$bvModal.hide('edit-schedule-date-modal')"
        :disabled="submitting"
        class="ml-2"
        >Cancel</b-button
      >
      <b-button
        variant="danger"
        type="button"
        @click="$bvModal.show('delete-schedule-date-modal')"
        :disabled="submitting"
        class="ml-2 delete-btn"
        >Delete</b-button
      >
    </div>
    <b-modal
      id="delete-schedule-date-modal"
      :title="`Delete Schedule Date - ${scheduleDate.name}`"
      hide-footer
    >
      Are you sure you want to delete this scheduled date?
      <form @submit.prevent="removeScheduleDate" class="mt-5">
        <b-button variant="primary" :disabled="submitting" type="submit">
          <span v-if="submitting">Deleting..</span>
          <span v-if="!submitting">Delete</span>
        </b-button>
        <b-button
          variant="secondary"
          type="button"
          @click="$bvModal.hide('delete-schedule-date-modal')"
          :disabled="submitting"
          class="ml-2"
          >Cancel</b-button
        >
      </form>
    </b-modal>
  </form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import ScheduleDateFormFields from './ScheduleDateFormFields.vue'

export default {
  name: 'EditScheduleDate',
  props: {
    ogScheduleDate: {
      type: Object,
      required: true,
    },
  },
  components: { ScheduleDateFormFields },
  inject: ['scheduleDateStore'],
  data: function () {
    const scheduleDate = _.clone(this.ogScheduleDate)
    scheduleDate.dateTime = scheduleDate.dateTime.toDate()
    scheduleDate.endDateTime = scheduleDate.endDateTime
      ? scheduleDate.endDateTime.toDate()
      : ''
    return {
      submitting: false,
      scheduleDate,
    }
  },
  watch: {},
  methods: {
    editScheduleDate() {
      const data = { scheduleDate: this.scheduleDate }
      if (!this.scheduleDate.dateTime) {
        return this.$toasted.error('Error: Must Select Date', {
          duration: 3000,
        })
      }
      if (!this.scheduleDate.allDayEvent && !this.scheduleDate.startTime) {
        return this.$toasted.error('Error: Must Select Start Time', {
          duration: 3000,
        })
      }
      return axios
        .put('/services/schedule-dates/' + data.scheduleDate.id, data)
        .then(response => {
          this.scheduleDateStore.removeScheduleDate(response.data.scheduleDate)
          this.scheduleDateStore.addScheduleDate(response.data.scheduleDate)

          this.$toasted.success(`Updated Schedule Date`, {
            duration: 2500,
          })
          this.$bvModal.hide('edit-schedule-date-modal')
          this.$bvModal.hide('day-detail-modal')
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Editing Schedule Date`, {
            duration: 2500,
          })
        })
        .finally(() => {})
    },
    removeScheduleDate() {
      return axios
        .delete('/services/schedule-dates/' + this.scheduleDate.id)
        .then(response => {
          this.scheduleDateStore.removeScheduleDate(this.scheduleDate)

          this.$toasted.success(`Schedule Date Deleted`, {
            duration: 2500,
          })
          this.$bvModal.hide('edit-schedule-date-modal')
          this.$bvModal.hide('delete-schedule-date-modal')
          this.$bvModal.hide('day-detail-modal')
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Deleting Schedule Date`, {
            duration: 2500,
          })
        })
        .finally(() => {})
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.section-header {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 6px;
  margin-top: 8px;
  font-weight: 500;
}

.contact-col {
  padding-left: 5px;
  padding-right: 5px;

  &:first-of-type {
    padding-left: 15px;
  }
}

.modal-ctas {
  position: relative;
}
.delete-btn {
  position: absolute;
  right: 0;
}
</style>
