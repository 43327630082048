<template>
  <div class="orders page-wrapper">
    <h1 class="page-h1">Manage Customers</h1>
    <h3>
      Customer Lookup
      <router-link to="customers/new">
        <b-button size="sm" variant="primary">New Customer</b-button>
      </router-link>
    </h3>
    <customer-lookup></customer-lookup>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment'
import CustomerLookup from '@/components/CustomerLookup.vue'

export default {
  name: 'ManageCustomersPage',
  components: { CustomerLookup },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      currentOrders: [],
    }
  },
  methods: {},
  computed: {},
  mounted() {
    // this.getRecentOrders();
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
