<template>
  <div>
    <div class="vendor-overview" v-if="this.vendor">
      <div class="row vendor-summary">
        <div class="col">
          <div>
            <div class="vendor-name">
              {{ vendor.name }}<VIPBadge v-if="vendor.vip"></VIPBadge>
              <br />
            </div>
            <div class="vendor-since">
              vendor since {{ vendor.createdAt | yearFilter }}
            </div>
          </div>
          {{ vendor.email }}<br />
          {{ vendor.phone }}
        </div>
        <div class="col">
          <div>{{ vendor.type }}</div>
          <div class="address">
            <div class="mt-3">{{ vendor.address.street1 }}</div>
            <div>
              {{ vendor.address.city }}, {{ vendor.address.state }}
              {{ vendor.address.zip }}
            </div>
            <div v-if="vendor.address.county">
              {{ vendor.address.county }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h4>Contacts</h4>
          <div class="mt-2" v-if="vendor.contacts.length == 0">
            - No Contacts -
          </div>
          <div class="row">
            <div
              class="contact-card col col-6"
              v-for="(contact, index) in vendor.contacts"
              :key="`contact-${index}`"
            >
              <b-card>
                {{ contact.firstName }} {{ contact.lastName }}<br />
                {{ contact.email }} <br />
                {{ contact.phone }}
              </b-card>
            </div>
          </div>
        </div>
        <div class="col">
          <h4>Locations</h4>
          <div class="mt-2" v-if="vendor.locations.length == 0">
            - No Locations -
          </div>
          <div class="row">
            <div
              class="location-card col col-6"
              v-for="(location, index) in vendor.locations"
              :key="`location-${index}`"
            >
              <b-card>
                {{ location.name }} <br />
                {{ location.address.street1 }} <br />
                {{ location.address.city }}, {{ location.address.state }}
                {{ location.address.zip }}
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h4>Orders</h4>
          <VendorOrderList :vendor="vendor" class="list"></VendorOrderList>
        </div>
        <div class="col">
          <h4>Something Else..</h4>
        </div>
      </div>
      <h3 class="mt-3">Notes</h3>
      <div class="row">
        <div class="col col-lg-8">
          <vendor-notes :vendor="vendor"></vendor-notes>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorNotes from '@/components/VendorNotes'
import VIPBadge from '@/components/VIPBadge'
import axios from 'axios'
import VendorOrderList from '../components/VendorOrderList.vue'
export default {
  name: 'VendorDetailPage',
  props: {},
  data: function () {
    return {
      vendor: null,
    }
  },
  components: {
    VendorNotes,
    VIPBadge,
    VendorOrderList,
  },
  mounted() {
    this.vendor = this.$parent.vendor
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.vendor-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.vendor-name {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 22px;
}
.vendor-since {
  font-size: 14px;
  margin-top: -6px;
}
.contact-card .card {
  background: rgb(240, 250, 255);
}

.location-card .card {
  background: rgb(240, 255, 244);
}
.list {
  //   max-height: 300px;
  //   overflow: scroll;
}
</style>
