<template>
  <div v-if="!loading">
    <div class="daily-events mb-3">
      <div
        v-for="(date, index) in dailyEvents"
        v-b-tooltip.hover.left="getTooltip(date)"
        :key="index"
        :class="{
          'daily-event': true,
          'multi-day': date.multiDayEvent,
          'hide-date': date.hideDate,
          'vendor-delivery':
            date.recurringVendorDay &&
            date.name.toLowerCase().match('delivery'),
          'vendor-order':
            date.recurringVendorDay && date.name.toLowerCase().match('order'),
          delivery:
            !date.recurringVendorDay &&
            date.name.toLowerCase().match('delivery'),
          offsite: date.customer || date.name.toLowerCase().match('offsite'),
          inspection: date.name.toLowerCase().match('inspection'),
          holiday:
            date.name.toLowerCase().match('pto') ||
            date.name.toLowerCase().match('vacation') ||
            date.name.toLowerCase().match('holiday') ||
            date.name.toLowerCase().match('dentist') ||
            date.name.toLowerCase().match('doctor'),
          concrete: date.name.toLowerCase().match('concrete'),
          install:
            date.name.toLowerCase().match('framing') ||
            date.name.toLowerCase().match('sheeting') ||
            date.name.toLowerCase().match('insulation'),
        }"
        :style="{
          background: date.calendarColor,
          color: pickTextColor(date.calendarColor),
        }"
      >
        <span v-if="date.name" class="name">{{
          date.name.replace(/Delivery/g, '').replace(/Order/g, '')
        }}</span>
        <span v-if="date.icon" :class="`${date.icon} ml-1 icon`"></span>
      </div>
    </div>
    <div v-for="(scheduleDate, index) in sortedDates" :key="index">
      <div
        v-b-tooltip.hover.left="getTooltip(scheduleDate)"
        :class="{
          'hour-event': true,
          'hide-date': scheduleDate.hideDate,
          'vendor-delivery':
            scheduleDate.recurringVendorDay &&
            scheduleDate.name.toLowerCase().match('delivery'),
          'vendor-order':
            scheduleDate.recurringVendorDay &&
            scheduleDate.name.toLowerCase().match('order'),
          delivery:
            !scheduleDate.recurringVendorDay &&
            scheduleDate.name.toLowerCase().match('delivery'),
          offsite:
            scheduleDate.customer ||
            scheduleDate.name.toLowerCase().match('offsite'),
          inspection: scheduleDate.name.toLowerCase().match('inspection'),
          holiday:
            scheduleDate.name.toLowerCase().match('pto') ||
            scheduleDate.name.toLowerCase().match('vacation') ||
            scheduleDate.name.toLowerCase().match('dentist') ||
            scheduleDate.name.toLowerCase().match('holiday') ||
            scheduleDate.name.toLowerCase().match('doctor'),
          concrete: scheduleDate.name.toLowerCase().match('concrete'),
          install:
            scheduleDate.name.toLowerCase().match('framing') ||
            scheduleDate.name.toLowerCase().match('sheeting') ||
            scheduleDate.name.toLowerCase().match('insulation'),
        }"
        :style="{
          background: scheduleDate.calendarColor,
          color: pickTextColor(scheduleDate.calendarColor),
        }"
      >
        {{ scheduleDate.dateTime.format('h:mm A') }}:
        {{ scheduleDate.name }}
      </div>
    </div>
  </div>
</template>

<script>
// TODO: STYLE MULTI DAY EVENTS USING multiDayEvent and firstOf/lastOf variables
// show all multi-day events at top?
// do this for month day view, week day view, and full day view
// color code some things?
// issues:
// 1. sceduling a date on calendar is adding dates 1-day off -- adding them 1 day in the future
import _ from 'lodash'
import { pickTextColor } from '../utils'
export default {
  name: 'OrderCalendarMonthDay',
  props: {
    day: {
      type: Object,
      required: true,
    },
    scheduleDates: {
      type: Array,
      default: () => {
        return []
      },
    },
    recurringDates: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data: () => {
    return {
      loading: true,
      pickTextColor,
    }
  },
  computed: {
    dailyEvents() {
      let dates = []
      let dailyEvents = []
      _.each(this.scheduleDates, date => {
        if (date.allDayEvent) {
          dailyEvents.push(date)
        } else {
          dates.push(date)
        }
      })

      _.each(this.recurringDates, rd => {
        if (rd.time) {
          let hours = parseInt(rd.time.split(':')[0])
          let minutes = parseInt(rd.time.split(':')[1])

          let day = this.day.clone().set('hour', hours).set('minute', minutes)
          dates.push({
            name: rd.name,
            dateTime: day,
            // dateTime: day.set('hour', hour).set('minutes', minutes),
          })
          // debugger
        } else {
          dailyEvents.push(rd)
        }
      })

      return dailyEvents
    },
    sortedDates() {
      let dates = []
      let dailyEvents = []
      _.each(this.scheduleDates, date => {
        if (date.allDayEvent) {
          dailyEvents.push(date)
        } else {
          dates.push(date)
        }
      })

      _.each(this.recurringDates, rd => {
        if (rd.time) {
          let hours = parseInt(rd.time.split(':')[0])
          let minutes = parseInt(rd.time.split(':')[1])

          let day = this.day.clone().set('hour', hours).set('minute', minutes)
          dates.push({
            name: rd.name,
            dateTime: day,
            // dateTime: day.set('hour', hour).set('minutes', minutes),
          })
          // debugger
        } else {
          dailyEvents.push(rd)
        }
      })

      return dates.sort(function compare(a, b) {
        var dateA = new Date(a.dateTime)
        var dateB = new Date(b.dateTime)
        return dateA - dateB
      })
    },
  },
  methods: {
    getTooltip(date) {
      return `${
        date.allDayEvent ? '' : date.dateTime?.format('h:mm A') + ' '
      } ${date.name} ${
        date.order
          ? ' - ' + date.order.customer?.name + ' - ' + date.order.jobName
          : ''
      } ${date.customer ? ' - ' + date.customer.name : ''} ${
        date.assignedTo ? ' - ' + date.assignedTo : ''
      } ${date.memo ? ' - ' + date.memo : ''}`
    },
  },
  mounted() {
    this.loading = false
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.time {
  border-bottom: 1px solid lightgray;
}

.daily-event {
  font-size: 13px;
  border-radius: 6px;
  background: rgb(240, 240, 240);
  padding: 4px 6px;
  margin: 2px;
  display: inline-block;
}

.multi-day {
}

.name {
  vertical-align: middle;
}
.icon {
  vertical-align: middle;
}

.hour-event {
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 6px;
  margin: 3px 0px;
  background: rgb(240, 240, 240);
  display: inline-block;
}

.vendor-delivery {
  background-color: $vendor-delivery-color;
}
.vendor-order {
  background-color: $vendor-delivery-color;
}

.delivery {
  background-color: $delivery-color;
  color: white;
}

.offsite {
  background-color: $offsite-color;
}

.holiday {
  background-color: $holiday-color;
}

.concrete {
  background-color: $concrete-color;
}

.install {
  background-color: $install-color;
  color: white;
}

.inspection {
  background-color: $inspection-color;
}

.time {
  // border-bottom: 1px solid lightgray;
}

.hide-date {
  display: none;
}
</style>
