var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notes"},_vm._l((_vm.list.slice().reverse()),function(note,index){return _c('div',{key:index},[(note.text && !note.removed)?_c('div',{staticClass:"note-wrapper",class:{
        sales: note.department === 'SALES',
        architecture: note.department === 'ARCHICTECTURE',
        engineering: note.department === 'ENGINEERING',
        permitting: note.department === 'PERMITTING',
        manufacturing: note.department === 'MANUFACTURING',
        install: note.department === 'INSTALL',
        admin: note.department === 'ADMIN',
      }},[_c('div',{staticClass:"note-date"},[_vm._v(_vm._s(_vm._f("dateTimeFilter")(note.timestamp)))]),_c('div',{staticClass:"note-from"},[(true)?_c('span',[_vm._v(_vm._s(note.user))]):_vm._e(),(false)?_c('span',[_vm._v(_vm._s(note.user.split('@')[0]))]):_vm._e(),(false)?_c('span',[_vm._v("("+_vm._s(note.department)+")")]):_vm._e(),(_vm.userStore.user.email === note.user)?_c('span',{staticClass:"nc-icon nc-simple-remove ml-1",staticStyle:{"cursor":"pointer","color":"red","font-weight":"bold"},on:{"click":function($event){return _vm.confirmRemoveNote(note)}}}):_vm._e(),_c('span')]),_c('div',{staticClass:"note-text"},[_vm._v(" "+_vm._s(note.text)+" ")])]):_vm._e(),(note.name)?_c('div',[_c('event',{attrs:{"event":note}})],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }