<template>
  <div>
    <div class="home" v-if="!order">
      <div class="loading mt-5" v-if="!error">
        <circle-loader :size="30"></circle-loader>
      </div>
      <div class="error mt-5" v-if="error">
        Error Loading Order
        <br />
        <router-link to="/app/">Back to Home</router-link>
      </div>
    </div>
    <div class="home text-left page-wrapper" v-if="order">
      <h1 class="page-h1">Order #{{ order.id }}</h1>
      <div class="nav text-center">
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/`"
          style="margin-left: 2px"
          >Overview</router-link
        >
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/edit`"
          v-if="
            userStore.user.type === 'ADMIN' || userStore.user.type === 'SALES'
          "
          >Edit</router-link
        >
        <router-link class="nav-link" :to="`/app/orders/${order.id}/sales`"
          >Sales</router-link
        >
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/architecture`"
          v-if="order.workflow.architecture.doArchitecture"
          >Architecture</router-link
        >
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/engineering`"
          v-if="order.workflow.engineering.doEngineering"
          >Engineering</router-link
        >
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/permitting`"
          v-if="order.workflow.permitting.doPermitting"
          >Permitting</router-link
        >
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/manufacturing`"
          v-if="order.type != 'ENGINEERING'"
          >Manufacturing</router-link
        >
        <router-link
          class="nav-link"
          :to="`/app/orders/${order.id}/install`"
          v-if="order.workflow.install.doInstall"
          >Install</router-link
        >
        <router-link class="nav-link" :to="`/app/orders/${order.id}/payments`"
          >Payments</router-link
        >
        <router-link class="nav-link" :to="`/app/orders/${order.id}/schedule`"
          >Schedule</router-link
        >
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CircleLoader from '@/components/CircleLoader.vue'
import { userStore } from '@/stores/userStore'

export default {
  name: 'OrderDetailPage',
  inject: { userStore },
  props: {
    orderData: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      order: null,
      error: null,
    }
  },
  components: { CircleLoader },
  mounted() {
    if (this.config) {
      this.order = this.orderData
    } else if (this.id) {
      axios
        .get('/services/orders/' + this.id)
        .then(response => {
          console.log('response.data.order:', response.data.order)
          this.order = response.data.order
          this.order.workflow.sales.soldDate = new Date(
            this.order.workflow.sales.soldDate,
          )
        })
        .catch(error => {
          this.error = true
        })
    } else {
      this.order = {}
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
  color: #2c3e50;
  padding-left: 8px;
  padding-right: 8px;
  &.router-link-exact-active {
    color: #42b983;
  }

  // &:first-child {
  //   margin-left: 0px;
  // }
}

.nav {
  margin-bottom: 18px;
}

.nav-link {
  &::after {
    content: '|';
    color: #2c3e50;
    margin-left: 16px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
</style>
