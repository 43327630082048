<template>
  <div class="price-list page-wrapper">
    <h1 class="page-h1">View &amp; Update Prices</h1>
    <form @submit.prevent="submitPriceUpdate" v-if="priceList">
      <div class="row">
        <div class="col">
          <div class="inputs">
            <div
              class="mt-2"
              v-for="(price, item) in updatedPrices"
              :key="item"
            >
              <label :for="item">{{ item }}</label>
              <b-form-input
                :id="item"
                type="text"
                v-model="updatedPrices[item]"
                required
                class="price-input"
                :disabled="disableInputs"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="cta-wrapper">
            <b-button
              type="button"
              variant="secondary"
              @click="editMode = true"
              v-if="!editMode"
              >Edit Prices</b-button
            >
            <b-button
              type="button"
              variant="secondary"
              @click="cancelEdit()"
              v-if="editMode"
              >Cancel Edit</b-button
            >
            <br /><br />
            <b-button
              type="button"
              variant="danger"
              @click="confirmUpdate()"
              :disabled="disableInputs"
              >Review Price Changes</b-button
            >
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmUpdateModal"
      title="Confirm Update"
      @ok="submitPriceUpdate"
    >
      <p class="my-4">Are you sure you want to update the following prices?</p>
      <table class="table">
        <thead>
          <tr>
            <td>Item</td>
            <td>Initial Price</td>
            <td>New Price</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(price, item) in priceDiff" :key="item">
            <td>{{ item }}</td>
            <td>{{ initialPrices[item] }}</td>
            <td>{{ price }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'

export default {
  name: 'PriceList',
  components: {},
  inject: {
    userStore,
  },
  data: function() {
    return {
      priceList: null,
      loading: true,
      editMode: false,
      initialPrices: {},
      updatedPrices: {},
      priceDiff: {},
    }
  },
  methods: {
    fetchPrices() {
      return axios
        .get('/services/price-list/current')
        .then(response => {
          console.log('response: ', response)
          this.priceList = response.data.priceList
          this.setPriceObjects(response.data.priceList)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirmUpdate() {
      this.priceDiff = this.getPriceDiff()
      console.log('price Diff: ', this.priceDiff)
      this.$bvModal.show('confirmUpdateModal')
    },
    submitPriceUpdate() {
      // console.log('priceDiff.length', Object.keys(this.priceDiff).length);
      if (Object.keys(this.priceDiff).length === 0) {
        return this.$toasted.error('No prices updated', {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }

      const data = _.clone(this.updatedPrices)
      return axios
        .post('/services/price-list', data)
        .then(response => {
          console.log('response: ', response)
          this.priceList = response.data.priceList
          this.setPriceObjects(response.data.priceList)
          this.editMode = false
          return this.$toasted.success('Prices updated', {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    cancelEdit() {
      this.editMode = false
      this.updatedPrices = _.clone(this.initialPrices)
    },
    setPriceObjects(priceList) {
      const inputs = {}
      _.each(priceList, (value, key) => {
        if (
          key === '__v' ||
          key === '_id' ||
          key === 'createdAt' ||
          key === 'updatedAt'
        ) {
        } else {
          inputs[key] = value
        }
      })
      this.initialPrices = _.clone(inputs)
      this.updatedPrices = _.clone(inputs)
    },
    getPriceDiff() {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value
          }
        })
      }
      return changes(this.updatedPrices, this.initialPrices)
    },
  },
  computed: {
    disableInputs() {
      return !this.editMode
    },
  },
  mounted() {
    this.fetchPrices()
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 500px;
  max-width: 100%;
  height: 80vh;
  overflow-y: scroll;
}

.price-input {
  width: 100px;
  display: inline-block;
}

label {
  display: inline-block;
  width: 240px;
}

.cta-wrapper {
}
</style>
