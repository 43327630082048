<template>
  <div>
    <div v-if="loading" class="mt-5">
      <circle-loader :size="40"></circle-loader>
    </div>
    <div v-if="!loading">
      <div class="home" v-if="!vendor">
        <div class="loading mt-5" v-if="!error">
          <circle-loader :size="30"></circle-loader>
        </div>
        <div class="error mt-5" v-if="error">
          Error Loading Vendor
          <br />
          <router-link to="/app/">Back to Home</router-link>
        </div>
      </div>
      <div class="home text-left page-wrapper" v-if="vendor">
        <h1 class="page-h1">{{ vendor.name }}</h1>
        <div class="nav text-center">
          <router-link
            class="nav-link"
            :to="`/app/vendors/${vendor.id}/`"
            style="margin-left: 2px"
            >Overview</router-link
          >
          <router-link
            class="nav-link"
            :to="`/app/vendors/${vendor.id}/edit`"
            v-if="userStore.user.type === 'ADMIN'"
            >Edit</router-link
          >
          <router-link
            class="nav-link"
            :to="`/app/vendors/${vendor.id}/profile`"
            >Profile</router-link
          >
          <router-link class="nav-link" :to="`/app/vendors/${vendor.id}/files`"
            >Files</router-link
          >
          <router-link
            class="nav-link"
            :to="`/app/vendors/${vendor.id}/schedule`"
            >Schedule</router-link
          >
          <span class="nav-link">
            <b-button
              class="mx-1 add-button"
              @click="$bvModal.show('createOrderModal')"
              >+ Order</b-button
            >
          </span>
        </div>
        <b-modal
          id="createOrderModal"
          title="Create Order"
          size="lg"
          hide-footer
          hide-header
        >
          <create-vendor-order :vendor="vendor"></create-vendor-order>
        </b-modal>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import CircleLoader from '@/components/CircleLoader.vue'
import { userStore } from '@/stores/userStore'
// import CreateVendorOrder from './CreateVendorOrder'

export default {
  name: 'VendorDetailPage',
  inject: { userStore },
  props: {
    vendorData: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      vendor: null,
      error: null,
      loading: true,
    }
  },
  components: { CircleLoader },
  methods: {},
  mounted() {
    if (this.vendorData) {
      this.vendor = this.vendorData
      this.loading = false
    } else if (this.id) {
      axios
        .get('/services/vendors/' + this.id)
        .then(response => {
          this.vendor = response.data.vendor
          this.loading = false
        })
        .catch(error => {
          this.error = true
        })
    } else {
      this.vendor = {}
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
  color: #2c3e50;
  padding-left: 8px;
  padding-right: 8px;
  &.router-link-exact-active {
    color: #42b983;
  }

  &:first-child {
    margin-left: 0px;
  }
}

.nav {
  margin-bottom: 2px;
}

.nav-link {
  &::after {
    content: '|';
    color: #2c3e50;
    margin-left: 16px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}

.add-button {
  height: 28px;
  line-height: 28px;
  padding: 0px 6px;
  background-color: #42b983;
  border-color: #4bc08b;
  font-size: 16px;
}
</style>
