<template>
  <div class="building-order-stats">
    <b-table
      striped
      hover
      :items="orderList"
      :fields="fields"
      @row-clicked="openStatusDetail"
    >
      <template #cell(2x3)="row">
        <div v-for="(item, col1Index) in row.item['2x3']" :key="col1Index">
          {{ item }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'BuildingOrderStats',
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'status',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'count',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: '2x2',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: '2x3',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: '2x4',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'sheets',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  methods: {
    openStatusDetail() {},
  },
  computed: {
    orderList() {
      let orderList = {
        OPEN: {
          status: 'OPEN',
          count: 0,
          '2x3': [],
          '2x2': [],
          '2x4': [],
        },
        READY: {
          status: 'READY',
          count: 0,
          '2x3': [],
          '2x2': [],
          '2x4': [],
        },
        'IN-PROGRESS': {
          status: 'IN-PROGRESS',
          count: 0,
          '2x3': [],
          '2x2': [],
          '2x4': [],
        },
        'ON-HOLD': {
          status: 'ON-HOLD',
          count: 0,
          '2x3': [],
          '2x2': [],
          '2x4': [],
        },
      }
      this.orders.forEach(order => {
        let dataObj = _.find(orderList, listItem => {
          return order.status === listItem.status
        })
        dataObj.count++
        if (order.building && order.building.bom) {
          _.each(order.building.bom, bomItem => {
            // add up 2x2s
            // add up 2x3s
            // add up 2x4s
            // add up sheets

            if (bomItem.name.match('Leg') && bomItem.quantity > 0) {
              if (bomItem.name.match('2x2')) {
                orderList[order.status]['2x2'].push(
                  `${bomItem.name} - ${bomItem.quantity}`,
                )
              } else if (bomItem.name.match('2x3')) {
                orderList[order.status]['2x3'].push(
                  `${bomItem.name} - ${bomItem.quantity}`,
                )
              } else if (bomItem.name.match('2x4')) {
                orderList[order.status]['2x4'].push(
                  `${bomItem.name} - ${bomItem.quantity}`,
                )
              }
            }

            if (bomItem.name.match('Truss') && bomItem.quantity > 0) {
              if (bomItem.name.match('2x2')) {
                orderList[order.status]['2x2'].push(
                  `${bomItem.name} - ${bomItem.quantity}`,
                )
              } else if (bomItem.name.match('2x3')) {
                orderList[order.status]['2x3'].push(
                  `${bomItem.name} - ${bomItem.quantity}`,
                )
              } else if (bomItem.name.match('2x4')) {
                orderList[order.status]['2x4'].push(
                  `${bomItem.name} - ${bomItem.quantity}`,
                )
              }
            }
          })
        }
      })
      return _.map(orderList)
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped></style>
