<template>
  <div>
    <div class="multi-day-events">
      <div
        v-for="(event, index) in multiDayEvents"
        :key="index"
        :class="{
          'multi-day-event': true,
          delivery: event.name.toLowerCase().match('delivery'),
          offsite: event.customer || event.name.toLowerCase().match('offsite'),
          inspection: event.name.toLowerCase().match('inspection'),
          holiday:
            event.name.toLowerCase().match('pto') ||
            event.name.toLowerCase().match('vacation') ||
            event.name.toLowerCase().match('holiday') ||
            event.name.toLowerCase().match('dentist') ||
            event.name.toLowerCase().match('doctor'),
          concrete: event.name.toLowerCase().match('concrete'),
          install:
            event.name.toLowerCase().match('framing') ||
            event.name.toLowerCase().match('sheeting') ||
            event.name.toLowerCase().match('insulation'),
        }"
        @click="editScheduleDate(event)"
        :style="{
          background: event.calendarColor,
          color: pickTextColor(event.calendarColor),
        }"
      >
        <span v-if="event.multiDayEvent">
          <span v-if="event.name" class="name">{{ event.name }}</span>

          <span v-if="event.order" class="name">
            - {{ event.order.jobName }}</span
          >
          <span v-if="event.order" class="name">
            - {{ event.order.customer.name }}</span
          >
          <span v-if="event.assignedTo" class="name">
            - {{ event.assignedTo }}</span
          >
        </span>
      </div>
    </div>
    <div class="daily-events">
      <div
        v-for="(event, index) in dailyEvents"
        :key="index"
        :class="{
          'daily-event': true,
          'vendor-delivery':
            event.recurringVendorDay &&
            event.name.toLowerCase().match('delivery'),
          'vendor-order':
            event.recurringVendorDay && event.name.toLowerCase().match('order'),
          delivery:
            !event.recurringVendorDay &&
            event.name.toLowerCase().match('delivery'),
          offsite: event.customer || event.name.toLowerCase().match('offsite'),
          inspection: event.name.toLowerCase().match('inspection'),
          holiday:
            event.name.toLowerCase().match('pto') ||
            event.name.toLowerCase().match('vacation') ||
            event.name.toLowerCase().match('holiday') ||
            event.name.toLowerCase().match('dentist') ||
            event.name.toLowerCase().match('doctor'),
          concrete: event.name.toLowerCase().match('concrete'),
          install:
            event.name.toLowerCase().match('framing') ||
            event.name.toLowerCase().match('sheeting') ||
            event.name.toLowerCase().match('insulation'),
        }"
        :style="{
          background: event.calendarColor,
          color: pickTextColor(event.calendarColor),
        }"
      >
        <span
          v-if="event.allDayEvent"
          style="cursor: pointer"
          @click="editScheduleDate(event)"
        >
          <span v-if="event.name" class="name">{{ event.name }}</span>
          <span v-if="event.icon" :class="`${event.icon} ml-1 icon`"></span>
        </span>
      </div>
    </div>
    <div v-for="hour in hours" :key="hour.time" class="hour">
      <div class="row">
        <div class="col col-1">
          <div class="time-display text-left">
            {{ hour.time > 12 ? hour.time - 12 : hour.time }}:00
          </div>
        </div>
        <div class="col col-11">
          <div class="events">
            <div
              v-for="(event, index) in hour.events"
              :key="index"
              :class="{
                'hour-event': true,
                'vendor-delivery':
                  event.recurringVendorDay &&
                  event.name.toLowerCase().match('delivery'),
                'vendor-order':
                  event.recurringVendorDay &&
                  event.name.toLowerCase().match('order'),
                delivery:
                  !event.recurringVendorDay &&
                  event.name.toLowerCase().match('delivery'),
                offsite:
                  event.customer || event.name.toLowerCase().match('offsite'),
                inspection: event.name.toLowerCase().match('inspection'),
                holiday:
                  event.name.toLowerCase().match('pto') ||
                  event.name.toLowerCase().match('vacation') ||
                  event.name.toLowerCase().match('dentist') ||
                  event.name.toLowerCase().match('holiday') ||
                  event.name.toLowerCase().match('doctor'),
                concrete: event.name.toLowerCase().match('concrete'),
                install:
                  event.name.toLowerCase().match('framing') ||
                  event.name.toLowerCase().match('sheeting') ||
                  event.name.toLowerCase().match('insulation'),
              }"
              :style="{
                background: event.calendarColor,
                color: pickTextColor(event.calendarColor),
              }"
            >
              <router-link
                :to="`/app/customers/${event.customer.id}/schedule`"
                v-if="event.customer"
                class="link"
              >
                <strong>{{ event.dateTime.format('h:mm A') }}</strong> -
                {{ event.name }} - ({{ event.customer.name }})
                <span v-if="event.assignedTo"> - {{ event.assignedTo }}</span>
              </router-link>

              <router-link
                :to="`/app/trade-shows/${event.tradeShow.id}/schedule`"
                v-if="event.tradeShow"
                class="link"
              >
                <strong>{{ event.dateTime.format('h:mm A') }}</strong> -
                {{ event.name }} - ({{ event.tradeShow.name }})
                <span v-if="event.assignedTo"> - {{ event.assignedTo }}</span>
              </router-link>
              <router-link
                :to="`/app/orders/${event.order.id}/schedule`"
                v-if="event.order"
                class="link"
              >
                <strong>{{ event.dateTime.format('h:mm A') }}</strong> -
                {{ event.name }} ({{ event.order.type }}) -
                {{ event.order.jobName }} ({{ event.order.customer.name }})
                <span v-if="event.assignedTo"> - {{ event.assignedTo }} </span>
                <span v-if="event.memo"> - {{ event.memo }} </span>
              </router-link>

              <router-link
                :to="`/app/vendors/${event.vendor.id}/schedule`"
                v-if="event.vendor"
                class="link"
              >
                {{ event.dateTime.format('h:mm A') }} - {{ event.name }} -
                {{ event.vendor.name }}
                <span v-if="event.assignedTo"> - {{ event.assignedTo }} </span>
                <span v-if="event.memo"> - {{ event.memo }} </span>
              </router-link>

              <div
                v-if="
                  !event.vendor &&
                  !event.customer &&
                  !event.order &&
                  !event.tradeShow
                "
                style="cursor: pointer"
                @click="editScheduleDate(event)"
              >
                <!-- <span class="nc-icon nc-settings-tool-66" title="edit"></span> -->
                <span v-if="event.name" class="name link">
                  <!-- todo: extend to end time?  -->
                  {{ (event.startTime || event.time) | shortTimeFilter }} -
                  {{ event.name.toLowerCase() }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="edit-schedule-date-modal"
      :title="`Edit Calendar Event - ${
        currentScheduleDate && currentScheduleDate.name
      }`"
      hide-footer
      size="lg"
    >
      <div v-if="currentScheduleDate"></div>
      <edit-schedule-date
        :og-schedule-date="currentScheduleDate"
      ></edit-schedule-date>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import EditScheduleDate from '../components/EditScheduleDate.vue'
import { pickTextColor } from '../utils'

export default {
  name: 'OrderCalendarFullDay',
  components: { EditScheduleDate },
  props: {
    day: {
      type: Object,
      required: true,
    },
    scheduleDates: {
      type: Array,
      required: true,
    },
    recurringDates: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      currentScheduleDate: null,
      pickTextColor,
    }
  },
  methods: {
    editScheduleDate(scheduleDate) {
      if (scheduleDate.vendor) {
        this.$router.push(`/app/vendors/${scheduleDate.vendor.id}/schedule`)
      } else if (scheduleDate.customer) {
        this.$router.push(`/app/customers/${scheduleDate.customer.id}/schedule`)
      } else if (scheduleDate.order) {
        this.$router.push(`/app/orders/${scheduleDate.order.id}/schedule`)
      } else if (scheduleDate.tradeShow) {
        this.$router.push({
          name: `ManageTradeShowsPage`,
          params: { showOnLoad: scheduleDate.tradeShow.id },
        })
      } else if (scheduleDate.recurringVendorDay) {
        this.$router.push(`/app/vendors/${scheduleDate.vendorId}/profile`)
      } else {
        this.currentScheduleDate = scheduleDate
        this.currentScheduleDate.dateTime =
          scheduleDate.ogDateTime || scheduleDate.dateTime // when editing groups, ensure date time starts at original date
        this.$bvModal.show('edit-schedule-date-modal')
      }
    },
  },
  computed: {
    hours() {
      let hours = []
      _.times(24, index => {
        // if (index > 4 && index < 20) {
        hours.push({ time: index, events: [] })
        // }
      })
      _.each(this.scheduleDates, sd => {
        if (sd.allDayEvent) {
          // do nothing
        } else if (sd.multiDayEvent && sd.allDayEvent) {
          // do nothing
        } else if (!sd.hideDate) {
          let hour = sd.dateTime.clone().hour()
          let hourObj = _.find(hours, hr => {
            return hr.time === hour
          })
          hourObj.events.push(sd)
        }
      })
      _.each(this.recurringDates, rd => {
        let hour = rd.time?.split(':')[0]
        if (hour) {
          let hourObj = _.find(hours, hr => {
            return hr.time == hour
          })
          hourObj.events.push(rd)
        } else {
        }
      })
      return hours
    },
    multiDayEvents() {
      let multiDayEvents = []
      _.each(this.scheduleDates, sd => {
        if (sd.multiDayEvent && !sd.hideDate && sd.allDayEvent) {
          multiDayEvents.push(sd)
        } else {
        }
      })
      return multiDayEvents
    },
    dailyEvents() {
      let dailyEvents = []
      _.each(this.scheduleDates, sd => {
        if (sd.allDayEvent && !sd.multiDayEvent) {
          dailyEvents.push(sd)
        } else {
        }
      })
      _.each(this.recurringDates, rd => {
        let hour = rd.time?.split(':')[0]
        if (hour) {
        } else {
          dailyEvents.push(rd)
        }
      })
      return dailyEvents
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.hour {
  margin-top: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid lightgray;
}
.daily-event {
  font-size: 15px;
  border-radius: 6px;
  background: rgb(240, 240, 240);
  padding: 6px 8px;
  margin-right: 18px;
  display: inline-block;
}
.name {
  vertical-align: middle;
}
.icon {
  vertical-align: middle;
}

.nc-icon {
  vertical-align: middle;
  margin-right: 2px;
}

.link {
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.multi-day-event {
  font-size: 15px;
  border-radius: 6px;
  background: rgb(240, 240, 240);
  padding: 6px 8px;
  margin: 6px 0px;
  cursor: pointer;
}

.hour-event {
  padding: 4px 6px;
  border-radius: 6px;
  margin: 3px 0px;
  background: rgb(240, 240, 240);
}

.vendor-delivery {
  background-color: $vendor-delivery-color;
}
.vendor-order {
  background-color: $vendor-delivery-color;
}

.delivery {
  background-color: $delivery-color;
  color: white;
}

.offsite {
  background-color: $offsite-color;
}

.holiday {
  background-color: $holiday-color;
}

.concrete {
  background-color: $concrete-color;
}

.install {
  background-color: $install-color;
  color: white;
}

.inspection {
  background-color: $inspection-color;
}

.icon {
  vertical-align: middle;
}
.col {
  &:first-of-type {
    padding-right: 0px;
  }
  &:last-of-type {
    padding-left: 0px;
    // margin-left: -12px;
  }
}
</style>
