<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { userStore } from '@/stores/userStore'
import { customerStore } from '@/stores/customerStore'
import { orderStore } from '@/stores/orderStore'
import { vendorStore } from '@/stores/vendorStore'
import { buildingStore } from '@/stores/buildingStore'
import { taskStore } from '@/stores/taskStore'
import { scheduleDateStore } from '@/stores/scheduleDateStore'

export default {
  name: 'App',
  provide: {
    userStore,
    scheduleDateStore,
    customerStore,
    taskStore,
    orderStore,
    vendorStore,
    buildingStore,
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
