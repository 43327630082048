<template>
  <div class="table-wrapper">
    <b-table
      striped
      hover
      :items="requests"
      :fields="fields"
      :tbody-tr-class="['clickable-row']"
      @row-clicked="showRequest"
    ></b-table>
    <b-modal id="override-request-modal" title="Override Request" hide-footer>
      <div v-if="currentRequest">
        <div>
          Request from <strong>{{ currentRequest.requestedBy }}</strong
          ><br />
          to <strong>{{ currentRequest.taskName }}</strong>
        </div>
        <div style="border: 1px solid; padding: 6px">
          {{ currentRequest.details }}
        </div>
        <div class="mt-3">
          <label for="">Decision</label><br />
          <b-textarea name="" id=""></b-textarea>
          <b-button variant="primary">Approve</b-button>
          <b-button variant="danger">Deny</b-button>
        </div>
      </div>
      // actions here would get added to notes, and tracked in events log
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'

// QUESTION: Should this go to the order section to complete the task?
// or allow you to pull up the task completion modal right here?

export default {
  name: 'ApprovalRequestList',
  components: {},
  props: {
    // orders: {
    //   type: Array,
    //   required: true,
    // },
    requests: {
      type: Array,
      default: () => [],
    },
    optionalFields: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      currentRequest: null,
      fields: [
        {
          key: 'customerName',
          sortable: true,
        },
        {
          key: 'jobName',
          sortable: true,
        },
        {
          key: 'taskName',
          sortable: true,
        },
        {
          key: 'status',
          sortable: true,
        },
        {
          key: 'requestedBy',
          sortable: true,
        },
        {
          key: 'assignedTo',
          sortable: true,
        },
        {
          key: 'dueDate',
          sortable: true,
        },
      ],
    }
  },
  mounted() {},
  computed: {
    taskList() {
      return this.requests.map(task => {
        // const { name, type, email, phone, id } = customer
        // return { name, type, email, phone, id }
      })
    },
  },
  methods: {
    showRequest(request, index, e) {
      this.currentRequest = request
      console.log('show qre uest fired')
      this.$bvModal.show('override-request-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
}
</style>

<style>
.clickable-row {
  cursor: pointer;
}
</style>
