<template>
    <div class="page-not-found">
        <h1>Page Not Found</h1>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'PageNotFound',

  data: function() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

.page-not-found {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 800px;
  max-width: 100%;
  margin: 40px auto;
}
</style>