<template>
  <div>
    <div class="loading" v-if="loading">
      <Circle-Loader :size="36" class="mt-3"></Circle-Loader>
    </div>
    <div class="loading" v-if="!loading">
      <div
        class="checklist-wrapper"
        v-show="order.workflow[workflow].checklist.length"
      >
        <TaskChecklist
          :order="order"
          workflow="install"
          :hide-detail="hideAddButton"
        ></TaskChecklist>
        <b-button
          variant="success"
          @click="$bvModal.show('confirmAddPayment')"
          size="sm"
          class="mt-2"
          v-if="false"
          >+ Add Payment</b-button
        >
        <b-modal hide-header hide-footer id="confirmAddPayment">
          Add Additional Payment to checklist?

          <div class="modal-ctas mt-4">
            <b-button
              variant="primary"
              @click="addPayment"
              :disabled="savingChecklist"
            >
              <span v-if="savingChecklist">Confirming..</span>
              <span v-if="!savingChecklist">Confirm</span>
            </b-button>
            <b-button
              variant="secondary"
              @click="$bvModal.hide('confirmAddPayment')"
              :disabled="savingChecklist"
              class="ml-2"
              >Cancel</b-button
            >
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { installChecklist } from '../data/checklistDefaults'
import TaskChecklist from '@/components/TaskChecklist'

export default {
  name: 'InstallChecklist',
  props: {
    order: {
      type: Object,
      required: true,
    },
    hideAddButton: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['userStore'],
  components: { TaskChecklist },
  data: function () {
    return {
      workflow: 'install',
      savingChecklist: true,
      submitting: false,
      loading: true,
    }
  },
  methods: {
    async addPayment() {
      let revNum = 1
      let lastItem = _.last(this.order.workflow[this.workflow].checklist)
      let lastRevNum = lastItem.task.match(/\d+/)
      if (lastRevNum) {
        revNum = parseInt(lastRevNum[0]) + 1
      }
      this.order.workflow.install.checklist.push({
        task: `Additional Payment Received (${revNum})`,
        done: false,
        timestamp: null,
        user: '',
        notes: [],
      })
      await this.saveChecklist(this.order.workflow.install.checklist)
      this.$bvModal.hide('confirmAddPayment')
      this.$toasted.success(` Added payment to checklist`, {
        duration: 2500,
      })
    },
    saveChecklist(checklist) {
      const data = {
        id: this.order.id,
        workflow: this.workflow,
        checklist,
      }
      this.savingChecklist = true
      return axios
        .put('/services/orders/save-checklist', data)
        .then(response => {
          console.log('response.data.checklist: ', response.data.checklist)
        })
        .catch(error => {
          this.$toasted.error(` Error adding to checklist`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.savingChecklist = false
          this.loading = false
        })
    },
  },
  mounted() {
    // this.saveChecklist([]) // resets while deving locally

    // set default checklists
    if (!this.order.workflow.install.checklist.length) {
      window.setTimeout(() => {
        this.order.workflow.install.checklist = installChecklist.map(item => {
          return {
            task: item,
            done: false,
            timestamp: null,
            user: '',
            notes: [],
          }
        })
        this.saveChecklist(this.order.workflow.install.checklist)
      }, 3000)
    } else {
      this.loading = false
    }
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.status-display {
  font-size: 20px;
}
.hold-reason {
  font-size: 16px;
}
.status {
  font-weight: bold;
}
.open {
  color: $open-color;
}
.in-progress {
  color: $in-progress-color;
}
.on-hold {
  color: $on-hold-color;
}
.cancelled {
  color: $cancelled-color;
}
.ready {
  color: $ready-color;
}
.completed {
  color: $completed-color;
}
</style>
