<template>
  <div class="app-layout">
    <div
      class="sidebar"
      v-bind:class="{ sidebarOpen: sidebarOpen }"
      @mouseover="sidebarHovered = true"
      @mouseleave="sidebarMouseLeave"
    >
      <div class="logo-wrapper">
        <router-link to="/app">
          <img
            class="logo"
            src="@/assets/Metals-Direct-Inc-logo.png"
            alt="Metals Direct Inc Logo"
        /></router-link>
      </div>
      <div class="links-wrapper">
        <div
          v-for="(link, index) in navLinks"
          :key="index"
          @click="clearAlerts(link.text)"
        >
          <router-link :to="link.path">
            <div class="nav-link-wrapper">
              <span class="link-icon">
                <span
                  class="nc-icon"
                  v-bind:class="{ [link.icon]: true }"
                ></span>
                <span
                  class="alert"
                  v-if="showSalesLeadAlert && link.text == 'Sales'"
                >
                  &bull;
                </span>
              </span>
              <span class="link-text">{{ link.text }}</span>
            </div>
          </router-link>
        </div>
        <div class="keep-sidebar-open" v-if=sidebarOpen>
          <b-checkbox v-model="keepSidbarOpen" size="md" @change=toggleKeepSidebarOpen>Keep Sidebar Open</b-checkbox>
        </div>
      </div>
    </div>
    <div class="content">
      <Header></Header>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

  </div>
</template>

<script>
import { userStore } from '@/stores/userStore'
import Header from './Header'
import axios from 'axios'

export default {
  name: 'AppLayout',
  props: {},
  components: { Header },
  inject: {
    userStore,
  },
  data: function () {
    let navLinks = [
      { path: '/app', icon: 'nc-badge', text: 'Dashboard' },
      {
        path: '/app/tasks',
        icon: 'nc-bullet-list-67',
        text: 'Tasks',
      },
      {
        path: '/app/orders/current',
        icon: 'nc-delivery-fast',
        text: 'Current Orders',
      },
    ]
    if (userStore.user.type === 'MANUFACTURING') {
      navLinks = navLinks.concat([
        { path: '/app/orders', icon: 'nc-paper-2', text: 'Manage Orders' },
        {
          path: '/app/orders/calendar',
          icon: 'nc-album-2',
          text: 'Order Calendar',
        },
        {
          path: '/app/buildings',
          icon: 'nc-notes',
          text: 'Manage Buildings',
        },
        {
          path: '/app/vendors',
          icon: 'nc-cart-simple',
          text: 'Manage Vendors',
        },
      ])
    }
    if (userStore.user.type === 'ADMIN' || userStore.user.type === 'SALES') {
      navLinks = navLinks.concat([
        { path: '/app/orders/new', icon: 'nc-simple-add', text: 'New Order' },
        { path: '/app/orders', icon: 'nc-paper-2', text: 'Manage Orders' },
        {
          path: '/app/orders/calendar',
          icon: 'nc-album-2',
          text: 'Calendar',
        },
        // {
        //   path: '/app/orders/import',
        //   icon: 'nc-cloud-upload-94',
        //   text: 'Import Orders',
        // },
        {
          path: '/app/buildings',
          icon: 'nc-notes',
          text: 'Manage Buildings',
        },
        { path: '/app/pricer', icon: 'nc-bank', text: 'New Building' },
        {
          path: '/app/customers',
          icon: 'nc-single-02',
          text: 'Manage Customers',
        },
        {
          path: '/app/sales/dashboard',
          icon: 'nc-layers-3',
          text: 'Sales',
        },
        {
          path: '/app/vendors',
          icon: 'nc-cart-simple',
          text: 'Manage Vendors',
        },
      ])
    }
    if (userStore.user.type === 'ADMIN') {
      navLinks = navLinks.concat([
        {
          path: '/app/admin',
          icon: 'nc-lock-circle-open',
          text: 'Admin',
        },
      ])
    }
    return {
      sidebarHovered: false,
      navLinks,
      showSalesLeadAlert: false,
      keepSidbarOpen: (window.localStorage.getItem('keepSidebarOpen')) ? true : false,
    }
  },
  computed: {
    sidebarOpen() {
      // todo also factor in manual click, and mobile..
      if (this.keepSidbarOpen) {
        return true
      } else {
        return this.sidebarHovered
      }
      
    },
  },
  methods: {
    toggleKeepSidebarOpen(newValue) {
      if (newValue) {
        window.localStorage.setItem('keepSidebarOpen', 1)
      } else {
        window.localStorage.removeItem('keepSidebarOpen')

      }
    },
    clearAlerts(page) {
      if (page === 'Sales Leads') {
        this.showSalesLeadAlert = false
      }
    },
    sidebarMouseLeave() {
      setTimeout(() => {
        this.sidebarHovered = false
      }, 500)
    },
    checkForSalesLeadsAlert() {
      return axios
        .get('/services/sales-leads/show-new-alert')
        .then(response => {
          this.showSalesLeadAlert = response.data.showAlert
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
  },
  mounted() {
    if (
      this.userStore.user.type === 'ADMIN' ||
      this.userStore.user.type === 'SALES'
    ) {
      this.checkForSalesLeadsAlert()
    }
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 240px;
  z-index: 0;
  background: rgba(41, 41, 41, 0.77);
}

.content {
  position: absolute;
  left: 60px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  transition: left 250ms ease-out;
  z-index: 1;
  background: white;
  overflow: scroll;
}

.logo {
  width: 50px;
  margin: 6px 0px;
  transition: all 250ms ease-out;
}

.logo-wrapper {
  // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 12px;
  padding-bottom: 6px;
  text-align: left;
  padding-left: 6px;
}

.sidebarOpen {
  .logo {
    width: 170px;
    margin-left: 24px;
  }
}

.links-wrapper {
  position: absolute;
  top: 110px;
  bottom: 00px;
  right: 0px;
  left: 0px;
  text-align: left;
  overflow: auto;
}

.nav-link-wrapper {
  padding: 12px 0px;
  &:hover {
    background: rgba(41, 41, 41, 0.77);
  }
}

.link-icon {
  display: inline-block;
  color: white;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  font-size: 26px;
}

.link-text {
  display: inline-block;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.sidebarOpen + .content {
  left: 240px;
  transition-delay: 0s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

a {
  &.router-link-exact-active {
    .nav-link-wrapper {
      background: rgba(41, 41, 41, 0.77);
    }
  }
}

.alert {
  position: absolute;
  top: -30px;
  right: -10px;
  color: rgb(226, 25, 25);
}

.link-icon {
  position: relative;
}

.keep-sidebar-open {
  color: white;
  margin-left: 18px;
  margin-top: 20px;
}
</style>
