<template>
  <div class="">
    <div class="row">
      <div class="col input-col">
        <label for="street1">Address Line 1</label>
        <b-input-group>
          <input
            :id="`${id} + street1`"
            class="form-control"
            :required="required"
            type="address"
            name="address"
            ref="autocomplete"
            placeholder=""
            :disabled="sending"
            v-model="address.street1"
            autocomplet="false"
          />
        </b-input-group>
      </div>
    </div>
    <div class="row">
      <div class="col input-col">
        <label for="city">City</label>
        <b-input-group>
          <b-form-input
            :id="`${id} + city`"
            v-model="address.city"
            type="text"
            :disabled="sending"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="col input-col">
        <label for="state">State</label>
        <b-input-group>
          <b-form-input
            :id="`${id} + state`"
            v-model="address.state"
            type="text"
            :disabled="sending"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <div class="row">
      <div class="col input-col">
        <label for="zip">Zip</label>
        <b-input-group>
          <b-form-input
            :id="`${id} + zip`"
            v-model="address.zip"
            type="text"
            :disabled="sending"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="col input-col">
        <label for="county">County</label>
        <b-input-group>
          <b-form-input
            :id="`${id} + county`"
            v-model="address.county"
            type="text"
            :disabled="sending"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AddressFields',
  props: {
    address: {
      type: Object,
      required: true,
    },
    sending: {
      type: Boolean,
      required: true,
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      autocomplete: null,
      setupTimeout: null,
    }
  },
  methods: {
    setupGoogleAutocomplete() {
      let input = this.$refs.autocomplete
      const center = { lat: 40.5865, lng: -122.3917 }
      // Create a bounding box with sides ~10km away from the center point
      const defaultBounds = {
        north: center.lat + 1,
        south: center.lat - 1,
        east: center.lng + 1,
        west: center.lng - 1,
      }
      this.autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['geocode'],
        // todo: remove this
        bounds: defaultBounds,
      })

      this.autocomplete.addListener('place_changed', () => {
        let place = this.autocomplete.getPlace()
        const addressComponents = {}
        _.each(place.address_components, component => {
          addressComponents[component.types[0]] = component.short_name
        })
        this.address.street1 =
          (addressComponents.street_number
            ? addressComponents.street_number
            : '') +
          ' ' +
          (addressComponents.route ? addressComponents.route : '')
        this.address.city = addressComponents.locality
          ? addressComponents.locality
          : ''
        this.address.county = addressComponents.administrative_area_level_2
          ? addressComponents.administrative_area_level_2
          : ''
        this.address.state = addressComponents.administrative_area_level_1
          ? addressComponents.administrative_area_level_1
          : ''
        this.address.country = addressComponents.country
          ? addressComponents.country
          : ''
        this.address.zip = addressComponents.postal_code
          ? addressComponents.postal_code
          : ''
        if (place.geometry && place.geometry.location) {
          this.address.latitude = place.geometry.location.lat()
          this.address.longitude = place.geometry.location.lng()
        }
      })
      google.maps.event.addDomListener(input, 'keydown', function (event) {
        if (event.keyCode === 13) {
          event.preventDefault()
        }
      })
    },
  },
  mounted() {
    if (this.autoComplete) {
      this.setupTimeout = window.setTimeout(() => {
        this.setupGoogleAutocomplete()
      }, 1000)
    }
  },
  beforeDestroy() {
    window.clearTimeout(this.setupTimeout)
  },
}
</script>

<style lang="scss" scoped>
.input-col {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
<style>
.pac-container {
  z-index: 10000 !important;
}
</style>
