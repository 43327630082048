<template>
  <div class="orders" v-if="order">
    <h3>Edit Order</h3>
    <form @submit.prevent="showConfirmEdit">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <order-form-fields
              :submitting="submitting"
              :order="order"
            ></order-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Edit Order</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmEditModal"
      :title="`Edit Order #${order.id}`"
      hide-footer
    >
      <p>
        Are you sure you want to edit {{ order.type }} order for
        {{ order.jobName }}?
      </p>
      <p>
        Delivery Method: {{ order.deliveryMethod }}<br />
        Sold Date: {{ this.order.workflow.sales.soldDate | dateFilter }}<br />
        Due Date: {{ this.order.workflow.sales.dueDate | dateTimeFilter }}
      </p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmEditModal')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitEditOrder"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment-business-days'
import { deliveryMethods, orderTypes } from '@/components/orderInputOptions'
import OrderFormFields from '@/components/OrderFormFields'

export default {
  name: 'OrderEditPage',
  components: { OrderFormFields },
  props: {},
  inject: {
    userStore,
  },
  data: function() {
    return {
      order: null,
      orderTypes,
      deliveryMethods,
      submitting: false,
    }
  },
  methods: {
    showConfirmEdit() {
      if (typeof this.order.workflow.sales.soldDate === 'string') {
        this.order.workflow.sales.soldDate = moment(
          this.order.workflow.sales.soldDate,
          'YYYY-MM-DD',
        ).set('hours', 12)._d
      }
      this.$bvModal.show('confirmEditModal')
    },
    submitEditOrder() {
      this.submitting = true
      const data = _.clone(this.order)
      return axios
        .put('/services/orders/' + data.id, data)
        .then(response => {
          this.$bvModal.hide('confirmEditModal')
          this.$parent.order = response.data.order
          return this.$toasted.success(
            'Order edited for ' + response.data.order.jobName,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
              duration: 4000,
            },
          )
        })
        .catch(err => {
          console.log('err', err)
          return this.$toasted.error(
            'Error editing order: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {
    this.order = _.cloneDeep(this.$parent.order)
    this.order.workflow.sales.dueDate = moment(
      this.order.workflow.sales.dueDate,
    )
  },
}
</script>

<style lang="scss" scoped>
.orders {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 1200px;
  max-width: 100%;
}

.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 600px;
  max-width: 90%;
}
</style>
