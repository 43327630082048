<template>
  <div class="sales-dashboard page-wrapper">
    <div v-if="loading">
      <circle-loader :size="40"></circle-loader>
    </div>
    <div v-if="!loading">
      <div class="notifications">
        <h3>Notifications</h3>
        <b-alert show class="notification" v-if="showSalesLeadAlert">
          There are new sales leads to review
          <router-link class="" :to="`/app/sales/leads`">
            <b-button variant="outline-info" size="sm" style="margin-left: 6px">
              Go To Sales Leads
            </b-button>
          </router-link>
        </b-alert>
        <b-alert
          show
          class="notification"
          variant="secondary"
          v-if="!showSalesLeadAlert"
        >
          There are no new notifications
        </b-alert>
      </div>
      <div class="stats">
        <h3>My Stats</h3>
        <h5>Buildings Created By {{ userStore.user.email }}</h5>
        <div class="row">
          <div class="col">
            <div v-if="buildingsCreated.dailyBuildings">
              <h6>Last 2 Weeks</h6>
              <recent-buildings-created-chart
                :data="buildingsCreated.dailyBuildings"
              ></recent-buildings-created-chart>
            </div>
          </div>
          <div class="col">
            <div v-if="buildingsCreated">
              <h6>All Time buildings</h6>
              <buildings-created-chart
                :data="buildingsCreated.monthlyBuildings"
              ></buildings-created-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment'
import CircleLoader from '@/components/CircleLoader.vue'
import BuildingsCreatedChart from '../components/BuildingsCreatedChart.vue'
import RecentBuildingsCreatedChart from '../components/RecentBuildingsCreatedChart.vue'

export default {
  name: 'SalesDashboarPage',
  components: {
    CircleLoader,
    BuildingsCreatedChart,
    RecentBuildingsCreatedChart,
  },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      showSalesLeadAlert: false,
      buildingsCreated: null,
    }
  },
  methods: {
    checkForSalesLeadsAlert() {
      return axios
        .get('/services/sales-leads/show-new-alert')
        .then(response => {
          this.showSalesLeadAlert = response.data.showAlert
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    getBuildingStats() {
      return axios
        .get(
          '/services/buildings/stats/buildings-created?user=' +
            encodeURIComponent(this.userStore.user.email),
        )
        .then(response => {
          this.buildingsCreated = response.data
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  computed: {},
  mounted() {
    return Promise.all([
      this.checkForSalesLeadsAlert(),
      this.getBuildingStats(),
    ]).then(responses => {
      this.loading = false
    })
  },
}
</script>

<style lang="scss" scoped>
.notification {
  max-width: 800px;
}
</style>
