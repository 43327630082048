<template>
  <div class="addons">
    <button
      class="btn btn-sm btn-light show-detail-button"
      @click="showDetail = !showDetail"
    >
      {{ showDetail ? 'Hide Detail' : 'Show Detail' }}
    </button>
    <table class="addons-table">
      <thead>
        <td>Name</td>
        <td>Details</td>
        <td>Total</td>
        <!-- <td>Taxable</td> -->
      </thead>
      <tbody v-show="showDetail">
        <tr v-for="(item, index) in addOns" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td>{{ (item.price || 0) | currencyFilter }}</td>
          <!-- <td>{{(item.taxable) ? 'Yes' : 'No'}}</td> -->
        </tr>
      </tbody>
      <tfoot class="addons-total-row" v-if="total || total === 0">
        <tr>
          <td>&emsp;</td>
          <td>Total:</td>
          <td>{{ total | currencyFilter }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AddOnItems',
  props: {
    addOns: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      showDetail: false,
    }
  },
  computed: {
    total() {
      let total = 0
      _.each(this.addOns, addOn => {
        total += parseFloat(addOn.price)
      })
      return total
    },
  },
}
</script>

<style lang="scss" scoped>
.addons {
  text-align: left;
}
.addons-table {
  width: 100%;
  text-align: left;
  font-size: 14px;

  thead {
    text-decoration: underline;
  }
}

.addons-total-row {
  font-weight: bold;
  border-top: 1px dashed darkslategray;
}
</style>
