<template>
  <div class="vendors page-wrapper">
    <h1 class="page-h1">Add Vendor</h1>
    <form @submit.prevent="showConfirmCreate">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <vendor-form-fields
              :submitting="submitting"
              :vendor="vendor"
            ></vendor-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Add Vendor</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmAddVendor"
      :title="`Add Vendor - ${vendor.name}`"
      hide-footer
    >
      <p>Create new vendor: {{ vendor.name }}?</p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmAddVendor')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitCreateVendor"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import VendorFormFields from '@/components/VendorFormFields'

export default {
  name: 'AddVendorPage',
  components: { VendorFormFields },
  inject: {
    userStore,
  },
  props: {},
  data: function () {
    return {
      vendor: {
        name: '',
        email: '',
        type: null,
        address: {
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        contacts: [],
        locations: [],
      },
      submitting: false,
    }
  },
  methods: {
    clearVendor() {
      this.vendor = {
        name: '',
        email: '',
        phone: '',
        address: {
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        contacts: [],
        locations: [],
      }
    },
    showConfirmCreate() {
      this.$bvModal.show('confirmAddVendor')
    },
    submitCreateVendor() {
      this.submitting = true
      const data = _.clone(this.vendor)
      return axios
        .post('/services/vendors', data)
        .then(response => {
          this.$bvModal.hide('confirmAddVendor')
          this.clearVendor()
          return this.$toasted.success('Vendor Added', {
            duration: 4000,
          })
        })
        .catch(err => {
          return this.$toasted.error(
            'Error creating vendor: ' + err?.response?.data?.error,
            {
              duration: 4000,
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 900px;
  max-width: 90%;
}
</style>
