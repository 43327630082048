<template>
  <div class="sales-page">
    <div class="page-one">
      <div class="page-one-header">
        <div class="logo">
          <img alt="MD logo" src="../assets/md-logo.png" />
          <div class="address">
            6771 Eastside Rd Redding, CA 96001<br />
            (530)605-1931 PH (530)605-1938 Fax <br />
            www.metalsdirect.com <br />
          </div>
        </div>
      </div>
      <h1 class="sales-page-title text-center">Thank You</h1>
      <div class="sales-page-header text-center">This is not a contract</div>
      <div class="sales-page-subtitle text-center">
        This is to be used for informational purposes only
      </div>
      <div class="page-content">
        <div class="mt-3">
          <div class="mt-3">
            We would like to take a moment to thank you for your recent
            purchase. We greatly appreciate your business and the opportunity to
            assist you.<br /><br />
            Your satisfaction is the greatest recognition we could have! Please
            contact us if you have any questions or would like to make any
            changes on your purchase.<br /><br />
            This Page will help you at a glance know what your payment schedule
            is and who the payment going to. If you are in need of further
            information you can always refer to your copies of the act payment
            schedules, or call customer care and they will be more than happy to
            assist you with any of your questions.
          </div>

          <div class="mt-5 text-center">
            <div class="total-line">
              <span v-if="showAmounts">{{ totalAmount | currencyFilter }}</span>
            </div>
          </div>

          <div class="mt-1 text-center disclosure">
            Owner will pay total price of (includes any sales tax).
          </div>
          <b-form-checkbox v-model="showAmounts" class="hide-on-print">
            Show Amounts
          </b-form-checkbox>
          <b-form-group
            v-if="building.foundation.doConcrete"
            class="hide-on-print"
          >
            <b-form-radio v-model="concreteEst" name="some-radios" value="min"
              >Use Concrete Min</b-form-radio
            >
            <b-form-radio v-model="concreteEst" name="some-radios" value="max"
              >Use Concrete Max</b-form-radio
            >
          </b-form-group>
          <div class="mt-5 schedule">
            <div class="schedule-item">
              <div class="lines">
                <div class="sales-page-line">
                  <span v-if="showAmounts">{{
                    depositAmount | currencyFilter
                  }}</span>
                </div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is to be used as a non-refundable deposit paid directly to MDI
                  towards engineering and other preliminary work associated with
                  project.
                </div>
              </div>
            </div>
            <div class="schedule-item" v-if="building.foundation.doGrading">
              <div class="lines">
                <div class="sales-page-line"></div>
              </div>
              <div class="statements">
                <div class="sales-page-li">is paid upon grading.</div>
              </div>
            </div>
            <div class="schedule-item" v-if="building.foundation.doConcrete">
              <div class="lines">
                <div class="sales-page-line">
                  <span v-if="showAmounts">{{
                    concreteAmount | currencyFilter
                  }}</span>
                </div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is paid upon completion of the concrete.
                </div>
              </div>
            </div>
            <div class="schedule-item">
              <div class="lines">
                <div class="sales-page-line">
                  <span v-if="showAmounts">{{
                    deliveryAmount | currencyFilter
                  }}</span>
                </div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is paid upon delivery of building materials.
                </div>
              </div>
            </div>
            <div class="schedule-item" v-if="building.install.doInstall">
              <div class="lines">
                <div class="sales-page-line">
                  <span v-if="showAmounts">{{
                    firstInstallAmount | currencyFilter
                  }}</span>
                </div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is paid upon completion of framing.
                </div>
              </div>
            </div>
            <div class="schedule-item" v-if="building.install.doInstall">
              <div class="lines">
                <div class="sales-page-line">
                  <span v-if="showAmounts">{{
                    secondInstallAmount | currencyFilter
                  }}</span>
                </div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is paid upon completion of the building.
                </div>
              </div>
            </div>
            <div class="schedule-item" v-if="building.install.doElectrical">
              <div class="lines">
                <div class="sales-page-line"></div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is paid upon completion of electrical.
                </div>
              </div>
            </div>
            <div class="schedule-item" v-if="sprayFoamTotal > 0">
              <div class="lines">
                <div class="sales-page-line">
                  <span v-if="showAmounts">{{
                    sprayFoamAmount | currencyFilter
                  }}</span>
                </div>
              </div>
              <div class="statements">
                <div class="sales-page-li">
                  is paid upon completion of spray foam insulation.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-form-checkbox v-model="showSignature" class="hide-on-print">
        Show Signature
      </b-form-checkbox>
      <div class="signature" v-if="showSignature">
        <span>Buyer: ____________________________________________</span>
        <span class="ml-5">Date:______________________________</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SalesPage',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log('this.building: ', this.building)
  },
  data: function () {
    return {
      showAmounts: true,
      showSignature: true,
      concreteEst: 'max',
      today: new Date(),
    }
  },
  computed: {
    concreteAmount() {
      if (this.building.foundation.price.total) {
        return this.building.foundation.price.total
      } else if (this.concreteEst === 'max') {
        return this.building.foundation.price.max || 0
      } else {
        return this.building.foundation.price.min || 0
      }
    },
    totalAmount() {
      let total = 0
      // todo: will need to update this when we start charging for electical/grading
      if (this.building.install.doInstall) {
        total += this.building.price.kitAndInstall.total
      } else {
        total += this.building.price.kit.total
      }
      if (this.building.foundation.doConcrete) {
        total += this.concreteAmount
      }
      return total
    },
    depositAmount() {
      return _.round(this.building.price.kit.total / 10, 2)
    },
    sprayFoamTotal() {
      let roofInsulationCost = 0
      let wallInsulationCost = 0
      if (this.building.roof.insulation.type.match('Spray Foam')) {
        roofInsulationCost = this.building.roof.insulation.price.total
      }
      if (this.building.walls.insulation.type.match('Spray Foam')) {
        wallInsulationCost = this.building.walls.insulation.price.total
      }
      return roofInsulationCost + wallInsulationCost
    },
    sprayFoamAmount() {
      return _.round(this.sprayFoamTotal * 0.9)
    },
    deliveryAmount() {
      let remainingMaterialsPrice =
        this.building.price.kit.total -
        this.depositAmount -
        this.sprayFoamAmount

      return _.round(remainingMaterialsPrice, 2)
    },
    firstInstallAmount() {
      const installAmount =
        this.building.price.kitAndInstall.total - this.building.price.kit.total
      return _.round(installAmount / 2, 2)
    },
    secondInstallAmount() {
      const installAmount =
        this.building.price.kitAndInstall.total - this.building.price.kit.total
      return _.round(installAmount - this.firstInstallAmount, 2)
    },
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.sales-page {
  position: relative;
}

.page-content {
  text-align: left;
}

.address {
  padding: 18px;
  font-size: 18px;
}

.logo {
  text-align: left;
}

.sales-page-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
}

.lines,
.statements {
  display: inline-block;
  width: 49%;
  vertical-align: bottom;
}

.lines {
  text-align: right;
}

.disclosure {
  font-size: 26px;
}

.total-line {
  width: 50%;
  border-bottom: 1px solid black;
  margin: auto;
}
.sales-page-line {
  border-bottom: 1px solid black;
  margin: 0 12px 0 96px;
}
.schedule-item {
  margin-bottom: 36px;
}

.hide {
  display: none;
}

.signature {
  margin-top: 100px;
  text-align: center;
}

@media print {
  .page-two,
  .page-three {
    page-break-before: always;
  }

  .sales-page {
    padding: 30px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }

  .title {
    font-size: 42px;
  }

  .total-line,
  .sales-page-line {
    font-size: 22px;
  }
  .sales-page-li {
    font-size: 22px;
  }

  .hide-on-print {
    display: none;
  }
}
</style>
