<template>
  <div class="quote-summary">
    <div class="letter-header">
      <img alt="MD logo" src="../assets/md-logo.png" />
      <div class="address">
        6771 Eastside Rd Redding, CA 96001<br />
        (530)605-1931 PH (530)605-1938 Fax <br />
        www.metalsdirectinc.com <br />
      </div>
    </div>
    <div class="page-content">
      <div class="letter-intro mt-1">
        <div class="customer-name">{{ customerName }}</div>
        <div class="date">{{ new Date() | dateFilter }}</div>
        <div class="intro-msg">
          <p class="mt-5">
            Thank you for the opportunity to give you a quote on your metal
            building needs. I have compiled a summary of the buildings we've
            discussed.
          </p>
          <p>
            As a company we stand behind our product with a 40 year material
            warranty. Our Metal sheets have a ceramic baked coating that insures
            against fading, as well as being considered “cool roof technology”
            Our metal is designed to reflect the suns heat and emit absorbed
            radiation back into the atmosphere. We strive to provide you with
            more color options to fit your cosmetic surroundings while allowing
            you the benefits of the "cool roof technology".
          </p>
          <p>
            Framework is designed to be the strongest of its kind. Most
            framework is done in HSS structural tubing pre-welded at our
            facility. It will be designed per your APN number and certified by a
            licensed engineer for you county requirements. Our 14 gauge metal
            used for framework is visibly thicker than almost all competitors’
            products.
          </p>
          <p>
            All we need to get your project started is 10% of the building cost.
          </p>
        </div>
      </div>
      <div class="letter-content">
        <hr />
        <h6 class="text-center">Building Summary</h6>
        <hr />

        <b-table
          striped
          :sticky-header="stickyHeader"
          hover
          :items="buildings"
          :fields="fields"
        ></b-table>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="thank-you">
            Thank you,<br />
            {{ userStore.user.fullName }}
          </div>
        </div>
        <div class="col">
          <div class="disclaimer">
            FYI! Quotes are subject to change and are only valid for 48 hours!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'QuoteSummary',
  inject: ['userStore'],
  props: {
    buildings: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      stickyHeader: true,
      fields: [
        {
          key: 'createdAt',
          label: 'Created',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'nickname',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'dimensions',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'type',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'kitPrice',
          label: 'Kit Price',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'kitAndInstallPrice',
          label: 'Install Price',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'concretePrice',
          label: 'Concrete',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  mounted() {},
  methods: {},
  computed: {
    customerName() {
      if (!this.buildings[0]) return ''

      if (this.buildings[0].customer.lastName) {
        return (
          this.buildings[0].customer.firstName +
          ' ' +
          this.buildings[0].customer.lastName
        )
      } else {
        return this.buildings[0].customer.email
      }
    },
  },
}
</script>

<style scoped>
.letter-intro {
  position: relative;
  padding-top: 24px;
}
.customer-name {
  position: absolute;
  left: 0;
}
.date {
  position: absolute;
  right: 0;
}

.intro-msg {
  text-align: left;
  font-size: 14px;
}

.letter-content {
  text-align: left;
  font-size: 14px;
}

.label {
  font-weight: bold;
}

.disclaimer {
  color: red;
  font-weight: bold;
}

.thank-you {
  text-align: left;
}

.custom-control {
  display: inline-block;
  padding-right: 0;
}
@media print {
  .quote-summary {
    padding: 0px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }
  .hide {
    display: none;
  }
}
</style>
