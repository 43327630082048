<template>
    <div class="app-layout">
        <div class="nav clearfix">
            <div class="nav-links">
                <span>{{userStore.user.email}}</span> |
                <router-link to="/contractor/">Home</router-link> |
                <router-link to="/contractor/account">Account</router-link> |
                <span class="logout-link" @click="logout()">Logout</span>
            </div>
        </div>
        <router-view/>
    </div>
</template>

<script>
import axios from 'axios';
import { userStore } from '@/stores/userStore';

export default {
  name: 'AdminLayout',
  props: { },
  components: { },
  inject: {
    userStore
  },
  computed: {
  },
  methods: {
      logout() {
          axios.get('/services/user/logout')
          .then(() => {
            userStore.logout();
          })
          .catch(() => {
              
          }).finally(() => {
              this.$router.push('/login');
          })
      }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

.home {
  padding-left: 6px;
  padding-right: 6px;
}

.nav {
  height: 24px;

a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.nav-links {
  position: absolute;
  right: 6px;
  top: 4px;
}

.logout-link {
    font-weight: bold;
    color: #2c3e50;
    cursor: pointer;
}

.logout-link:hover {
    text-decoration: underline;
}

</style>