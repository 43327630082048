<template>
  <div class="orders page-wrapper">
    <h1 class="page-h1">Manage Vendors</h1>
    <h3>
      Vendor Lookup
      <router-link to="vendors/new">
        <b-button size="sm" variant="primary">New Vendor</b-button>
      </router-link>
    </h3>
    <vendor-lookup></vendor-lookup>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment'
import VendorLookup from '@/components/VendorLookup.vue'

export default {
  name: 'ManageVendorsPage',
  components: { VendorLookup },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      currentVendors: [],
    }
  },
  methods: {},
  computed: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
