export const referralSourceOptions = [
  { value: null, text: "How'd ya hear?" },
  { value: 'Web Search', text: 'Web Search' },
  { value: 'TV Commercial', text: 'TV Commercial' },
  { value: 'Contractor', text: 'Contractor' },
  { value: 'Repeat Customer', text: 'Repeat Customer' },
  { value: 'Trade Show', text: 'Trade Show' },
  { value: 'Other', text: 'Other' },
]

export const gableOverhangSideOptions = [
  { text: 'Both', value: 'both' },
  { text: 'Front', value: 'front' },
  { text: 'End', value: 'end' },
]

export const eaveOverhangSideOptions = [
  { text: 'Both', value: 'both' },
  { text: 'Left', value: 'left' },
  { text: 'Right', value: 'right' },
]

export const trussTypeOptions = [
  { value: null, text: 'Select Type' },
  { value: 'web', text: 'web' },
  { value: 'collar-tie', text: 'collar-tie' },
  { value: 'open', text: 'open' },
]
export const trussPieceOptions = [
  { value: null, text: 'Select Option' },
  { value: '1-piece', text: '1-piece' },
  { value: '3-piece', text: '3-piece' },
]
export const buildingTypeOptions = [
  { value: null, text: 'Select Type' },
  { value: 'Shop', text: 'Shop' },
  { value: 'Carport', text: 'Carport' },
  { value: 'RV Cover', text: 'RV Cover' },
  { value: 'Shed', text: 'Shed' },
]
export const thicknessOptions = [
  { value: null, text: 'Select Design' },
  { value: '2x2', text: '2x2' },
  { value: '2x3', text: '2x3' },
  { value: '2x4', text: '2x4' },
]

export const firewallLocationOptions = [
  { value: null, text: 'Select Location' },
  { value: 'gable', text: 'gable' },
  { value: 'eave', text: 'eave' },
  { value: 'gable+eave', text: 'gable+eave' },
  { value: 'other', text: 'other' },
]

export const centersOptions = [
  { value: null, text: 'Select Centers' },
  { value: '1', text: '1' },
  { value: '1.5', text: '1.5' },
  { value: '2', text: '2' },
  { value: '2.5', text: '2.5' },
  { value: '3', text: '3' },
  { value: '3.5', text: '3.5' },
  { value: '4', text: '4' },
  { value: '4.5', text: '4.5' },
  { value: '5', text: '5' },
]
export const metalPanelOptions = [
  { value: null, text: 'Select Option' },
  { value: 'Energy Rib', text: 'Energy Rib' },
  { value: 'PBR', text: 'PBR' },
  { value: 'No Panels', text: 'No Panels' },
]
export const designOptions = [
  { value: null, text: 'Select Design' },
  { value: 'Horizontal', text: 'Horizontal' },
  { value: 'Vertical', text: 'Vertical' },
]
export const colorOptions = [
  { value: null, text: 'Select Color' },
  { value: 'Green', text: 'Green' },
  { value: 'All Steel Blue', text: 'All Steel Blue' },
  { value: 'Gray', text: 'Gray' },
  { value: 'White', text: 'White' },
  { value: 'Ivory', text: 'Ivory' },
  { value: 'Stone', text: 'Stone' },
  { value: 'Hickory', text: 'Hickory' },
  { value: 'Brown', text: 'Brown' },
  { value: 'All Steel Red', text: 'All Steel Red' },
]
export const anchorOptions = [
  { value: null, text: 'Select Anchor' },
  { value: 'earth', text: 'earth' },
  { value: 'slab', text: 'slab' },
  { value: 'footings', text: 'footings' },
  { value: 'slab w/ footings', text: 'slab w/ footings' },
  { value: 'piers', text: 'piers' },
  { value: 'stem walls', text: 'stem walls' },
]
export const insulationOptions = [
  { value: null, text: 'Select Insulation' },
  { value: 'None', text: 'None' },
  { value: 'R-Tech', text: 'R-Tech' },
  { value: 'Thermal Guard', text: 'Thermal Guard' },
  { value: 'R-Tech & Thermal Guard', text: 'R-Tech & Thermal Guard' },
  { value: '1in Spray Foam', text: '1in Spray Foam' },
  { value: '2in Spray Foam', text: '2in Spray Foam' },
  { value: '3in Spray Foam', text: '3in Spray Foam' },
]
export const doorSwingOptions = [
  { value: null, text: 'Select Options' },
  { value: 'Out Left', text: 'Out Left' },
  { value: 'Out Right', text: 'Out Right' },
  { value: 'Inside Left', text: 'Inside Left' },
  { value: 'Inside Right', text: 'Inside Right' },
]
export const windowOptions = [
  { value: null, text: 'Select Model' },
  { value: 'framed', text: 'framed' },
  { value: 'not-framed', text: 'not-framed' },
]
export const overhangOptions = [
  { value: '0ft', text: '0ft' },
  { value: '0.5ft', text: '0.5ft' },
  { value: '1ft', text: '1ft' },
  { value: '1.5ft', text: '1.5ft' },
  { value: '2ft', text: '2ft' },
  { value: '2.5ft', text: '2.5ft' },
]
export const roofPitchOptions = [
  { value: null, text: 'Select Option' },
  { value: '1:12', text: '1:12' },
  { value: '2:12', text: '2:12' },
  { value: '3:12', text: '3:12' },
  { value: '4:12', text: '4:12' },
  { value: '5:12', text: '5:12' },
  { value: '6:12', text: '6:12' },
  { value: '7:12', text: '7:12' },
  { value: '8:12', text: '8:12' },
  { value: '9:12', text: '9:12' },
  { value: '10:12', text: '10:12' },
  { value: '11:12', text: '11:12' },
  { value: '12:12', text: '12:12' },
]
export const shedDoorSizeOptions = [
  { value: null, text: 'Select Size' },
  { value: "3'0x6'8", text: "3'0x6'8" },
  { value: "3'0x7'0", text: "3'0x7'0" },
  { value: "3'0x7'4", text: "3'0x7'4" },
  { value: "3'0x8'0", text: "3'0x8'0" },
  { value: "3'4x6'8", text: "3'4x6'8" },
  { value: "3'4x7'0", text: "3'4x7'0" },
  { value: "3'4x7'4", text: "3'4x7'4" },
  { value: "3'4x8'0", text: "3'4x8'0" },
  { value: "3'6x6'8", text: "3'6x6'8" },
  { value: "3'6x7'0", text: "3'6x7'0" },
  { value: "3'6x7'4", text: "3'6x7'4" },
  { value: "3'6x8'0", text: "3'6x8'0" },
  { value: "3'8x6'8", text: "3'8x6'8" },
  { value: "3'8x7'0", text: "3'8x7'0" },
  { value: "3'8x7'4", text: "3'8x7'4" },
  { value: "3'8x8'0", text: "3'8x8'0" },
  { value: "4'0x6'8", text: "4'0x6'8" },
  { value: "4'0x7'0", text: "4'0x7'0" },
  { value: "4'0x7'4", text: "4'0x7'4" },
  { value: "4'0x8'0", text: "4'0x8'0" },
  { value: "5'0x6'8", text: "5'0x6'8" },
  { value: "5'0x7'0", text: "5'0x7'0" },
  { value: "5'0x7'4", text: "5'0x7'4" },
  { value: "5'0x8'0", text: "5'0x8'0" },
  { value: "5'6x6'8", text: "5'6x6'8" },
  { value: "5'6x7'0", text: "5'6x7'0" },
  { value: "5'6x8'0", text: "5'6x8'0" },
  { value: "6'0x6'8", text: "6'0x6'8" },
  { value: "6'0x7'0", text: "6'0x7'0" },
  { value: "6'0x7'4", text: "6'0x7'4" },
  { value: "6'0x8'0", text: "6'0x8'0" },
  { value: "7'0x6'8", text: "7'0x6'8" },
  { value: "7'0x7'0", text: "7'0x7'0" },
  { value: "7'0x7'4", text: "7'0x7'4" },
  { value: "7'0x8'0", text: "7'0x8'0" },
  { value: "8'0x6'8", text: "8'0x6'8" },
  { value: "8'0x7'0", text: "8'0x7'0" },
  { value: "8'0x7'4", text: "8'0x7'4" },
  { value: "8'0x8'0", text: "8'0x8'0" },
  { value: "8'0x9'0", text: "8'0x9'0" },
  { value: "8'8x6'8", text: "8'8x6'8" },
  { value: "8'8x7'0", text: "8'8x7'0" },
  { value: "8'8x7'4", text: "8'8x7'4" },
  { value: "8'8x8'0", text: "8'8x8'0" },
  { value: "8'8x9'0", text: "8'8x9'0" },
  { value: "9'0x6'8", text: "9'0x6'8" },
  { value: "9'0x7'0", text: "9'0x7'0" },
  { value: "9'0x7'4", text: "9'0x7'4" },
  { value: "9'0x8'0", text: "9'0x8'0" },
  { value: "9'0x9'0", text: "9'0x9'0" },
  { value: "9'0x10'0", text: "9'0x10'0" },
  { value: "10'0x7'0", text: "10'0x7'0" },
  { value: "10'0x8'0", text: "10'0x8'0" },
  { value: "10'0x9'0", text: "10'0x9'0" },
  { value: "10'0x10'0", text: "10'0x10'0" },
]
