<template>
  <div class="">
    <div class="border-bottom text-left">
      {{ title }} ({{ openings.length }})
      <b-button
        class="mt-2 mb-1 add-opening-button"
        @click="addOpening()"
        size="sm"
        :disabled="disableInputs"
      >
        +
      </b-button>
    </div>
    <div
      class="opening-detail"
      v-for="(opening, index) in openings"
      :key="index"
    >
      <div class="row mt-2 mb-2">
        <div class="col-1 input-col">
          <div class="opening-counter">{{ index + 1 }}.</div>
        </div>
        <div class="col input-col" v-if="openingsOptions">
          <label :for="`opening-${title}-${index}-size`">Size</label>
          <b-input-group append="ft">
            <b-form-select
              :id="`opening-${title}-${index}-size`"
              v-model="opening.size"
              :options="openingsOptions"
              :disabled="disableInputs"
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="col input-col" v-if="!openingsOptions">
          <label :for="`opening-${title}-${index}-width`">Width</label>
          <b-input-group append="ft">
            <b-form-input
              :id="`opening-${title}-${index}-width`"
              v-model="opening.width"
              type="text"
              placeholder="0"
              :max="maxWidth"
              :min="minWidth"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col input-col" v-if="!openingsOptions">
          <label :for="`opening-${title}-${index}-height`">Height</label>
          <b-input-group append="ft">
            <b-form-input
              :id="`opening-${title}-${index}-height`"
              v-model="opening.height"
              type="text"
              placeholder="0"
              :max="maxHeight"
              :min="minHeight"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label :for="`opening-${title}-${index}-quantity`">Quantity</label>
          <b-input-group>
            <b-form-input
              :id="`opening-${title}-${index}-quantity`"
              v-model="opening.quantity"
              type="number"
              placeholder="0"
              min="1"
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col input-col">
          <b-input-group class="vertical-checkbox">
            <label :for="`opening-${title}-${index}-include-opening`"
              >Include {{ itemShortName }}</label
            >
            <b-form-checkbox
              :id="`opening-${title}-${index}-include-opening`"
              v-model="opening[`include${itemShortName}`]"
              :disabled="disableInputs"
            ></b-form-checkbox>
          </b-input-group>
        </div>
        <div class="col-1 input-col">
          <b-button
            class="remove-opening-button"
            variant="danger"
            size="sm"
            @click="removeOpening(index)"
            :disabled="disableInputs"
            >x</b-button
          >
        </div>
      </div>
      <div class="row mt-1" v-if="false">
        <div class="col-1 input-col">
          <b-button
            class="remove-opening-button"
            variant="danger"
            size="sm"
            @click="removeOpening(index)"
            :disabled="disableInputs"
            >x</b-button
          >
        </div>
        <div class="col-3 input-col">
          <label for="buildingSide">Building Side</label>
          <b-input-group>
            <b-form-select
              id="buildingSide"
              v-model="opening.buildingSide"
              :options="buildingSideOptions"
              required
              :disabled="disableInputs"
            ></b-form-select>
          </b-input-group>
        </div>
        <div class="col col-3 input-col">
          <b-input-group class="vertical-checkbox">
            <label :for="`opening-${title}-${index}-include-opening`"
              >Include {{ itemShortName }}</label
            >
            <b-form-checkbox
              :id="`opening-${title}-${index}-include-opening`"
              v-model="opening[`include${itemShortName}`]"
              :disabled="disableInputs"
            ></b-form-checkbox>
          </b-input-group>
        </div>
        <div class="col input-col">
          <label :for="`opening-${title}-${index}-model`"
            >Model
            <help-icon
              v-if="opening[`include${itemShortName}`]"
              :text="`Model selected automatically if size matches. If no ${itemShortName} found, customer will be charged for framed opening only.`"
            ></help-icon>
            <help-icon
              v-if="!opening[`include${itemShortName}`]"
              :text="`Enter model for custom door. Customer will be charged for framed opening only.`"
            ></help-icon
          ></label>

          <b-input-group v-if="!opening[`include${itemShortName}`]">
            <b-form-input
              :id="`opening-${title}-${index}-model`"
              v-model="opening.model"
              type="text"
              placeholder=""
              :disabled="disableInputs"
            ></b-form-input>
          </b-input-group>
          <div v-if="opening[`include${itemShortName}`]">
            <b-form-input
              :value="
                models[`${opening.width}x${opening.height}`] ||
                models[`${opening.size}`] ||
                `No ${itemShortName} Found`
              "
              :disabled="true"
            >
            </b-form-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { rollDoorModels, shedDoorModels } from './openingsModels'
import HelpIcon from '@/components/HelpIcon'

export default {
  name: 'OpeningsInput',
  components: {
    HelpIcon,
  },
  props: {
    openings: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    openingsOptions: {
      type: Array,
      required: false,
    },
    maxWidth: {
      type: Number,
      default: 20,
    },
    maxHeight: {
      type: Number,
      default: 20,
    },
    minWidth: {
      type: Number,
      default: 8,
    },
    minHeight: {
      type: Number,
      default: 8,
    },
    disableInputs: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      models: {},
      buildingSideOptions: [
        'Front Gable',
        'Back Gable',
        'Left Eave',
        'Right Eave',
      ],
    }
  },
  computed: {
    itemShortName() {
      if (this.title.match('Window')) {
        return 'Window'
      } else {
        return 'Door'
      }
    },
  },
  methods: {
    addOpening() {
      let height = 0
      let width = 0
      if (this.title === 'Windows') {
        height = 3
        width = 3
      }
      this.openings.push({
        height,
        width,
        quantity: 1,
        [`include${this.itemShortName}`]: true,
        size: null,
      })
    },
    removeOpening(index) {
      this.openings.splice(index, 1)
    },
  },
  mounted() {
    if (this.title === 'Roll Doors') {
      this.models = rollDoorModels
    } else if (this.title === 'Shed Doors') {
      this.models = shedDoorModels
    } else if (this.title === 'Man Doors') {
      this.models = { '3x7': 'standard' }
    } else if (this.title === 'Windows') {
      this.models = { '3x3': 'standard' }
    }
  },
}
</script>

<style lang="scss" scoped>
.input-col {
  padding-left: 4px;
  padding-right: 4px;
}

.add-opening-button {
  padding: 0px 4px 0px 4px;
}

.remove-opening-button {
  margin-top: 22px;
  height: 30px;
  width: 30px;
  line-height: 26px;
  padding: 0px;
  font-size: 18px;
}

.opening-counter {
  margin-top: 40px;
  font-size: 20px;
}

.vertical-checkbox {
  label {
    width: 100%;
    display: block;
    text-align: center;
  }

  .custom-checkbox {
    width: 100%;
    margin-top: 4px;
  }
}

label {
  text-align: left;
  display: block;
  margin-bottom: 2px;
}
</style>
