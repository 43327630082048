<template>
  <div>
    <div class="order-payments-schedule" v-if="order">
      <div v-if="order.type == 'BUILDING' && order.workflow.install.doInstall">
        <install-estimate :order="order"></install-estimate>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="schedule">
            <h3>Schedule</h3>
            <order-schedule :order="order"></order-schedule>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderSchedule from '@/components/OrderSchedule'
import InstallEstimate from '@/components/InstallEstimate'

export default {
  name: 'OrderInstallPage',
  props: {},
  data: function () {
    return {
      order: null,
    }
  },
  components: {
    OrderSchedule,
    InstallEstimate,
  },
  mounted() {
    this.order = this.$parent.order
  },
}
</script>

<style lang="scss" scoped></style>
