<template>
  <div class="home">
    <h1>Welcome to MD Buildings</h1>
    <router-link class="link" to="/app">Employee &amp; Admin App</router-link>
    <router-link class="link" to="/create">Public Customer Create</router-link>
    <router-link class="link" to="/partners">Partner App</router-link>
    <h2 v-if="false">Admin Tools</h2>

  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Index',
  components: { },
  inject: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

.home {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 800px;
  margin: 40px auto;
}

.link {
    display: block;
}
</style>