<template>
  <div class="inputs">
    <div class="section-header">Vendor Details</div>
    <div class="row">
      <div class="col">
        <div class="form-group mb-0">
          <label for="name">Vendor Name</label
          ><help-icon
            v-if="vendor._id"
            :text="`Name cannot be edited at this time.`"
          ></help-icon>
          <b-form-input
            id="name"
            type="text"
            v-model="vendor.name"
            required
            :disabled="submitting || vendor._id ? true : false"
          ></b-form-input>
        </div>
      </div>
      <div class="col"></div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="phone">Phone</label>
          <b-form-input
            id="phone"
            type="text"
            v-model="vendor.phone"
            :disabled="submitting"
          ></b-form-input>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="email">Email</label>
          <b-form-input
            id="email"
            type="email"
            v-model="vendor.email"
            :disabled="submitting"
            :required="true"
          ></b-form-input>
        </div>
      </div>
    </div>
    <div class="section-header">Address</div>
    <div class="row">
      <div class="col">
        <div class="px-2">
          <AddressFields
            :sending="submitting"
            :address="vendor.address"
            :autoComplete="true"
          />
        </div>
      </div>
    </div>
    <div class="section-header">
      Contacts
      <b-button size="sm" variant="primary" @click="addContact">+</b-button>
    </div>
    <div v-for="(contact, index) in vendor.contacts" :key="`contact-${index}`">
      <div class="row mt-2">
        <div class="col contact-col">
          <b-input
            type="text"
            v-model="contact.firstName"
            placeholder="First Name"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-input
            type="text"
            v-model="contact.lastName"
            placeholder="Last Name"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-input
            type="email"
            v-model="contact.email"
            placeholder="Email"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-input
            type="text"
            v-model="contact.phone"
            placeholder="Phone"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-button variant="danger" size="sm" @click="removeContact(index)"
            >X</b-button
          >
        </div>
      </div>
    </div>
    <div class="section-header">
      Locations
      <b-button size="sm" variant="primary" @click="addLocation">+</b-button>
    </div>
    <div
      v-for="(location, index) in vendor.locations"
      :key="`location-${index}`"
      class="mb-3"
    >
      <div class="row mt-2">
        <div class="col location-col address">
          <b-input
            type="text"
            v-model="location.name"
            placeholder="Location Name"
          ></b-input>
        </div>
        <div class="col location-col">
          <b-button variant="danger" size="sm" @click="removeLocation(index)"
            >X</b-button
          >
        </div>
      </div>
      <AddressFields
        class="px-3 mt-2 address"
        :sending="submitting"
        :address="location.address"
        :autoComplete="true"
      />
    </div>
  </div>
</template>

<script>
import AddressFields from '@/components/AddressFields.vue'
import HelpIcon from '@/components/HelpIcon.vue'

export default {
  name: 'VendorFormFields',
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
  },
  components: { AddressFields, HelpIcon },
  inject: {},
  data: function () {
    return {}
  },
  watch: {},
  methods: {
    addContact() {
      const newContact = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      }
      this.vendor.contacts.push(newContact)
    },
    removeContact(index) {
      this.vendor.contacts.splice(index, 1)
    },
    addLocation() {
      const newLocation = {
        name: '',
        address: {},
      }
      this.vendor.locations.push(newLocation)
    },
    removeLocation(index) {
      this.vendor.locations.splice(index, 1)
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.section-header {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 6px;
  margin-top: 8px;
  font-weight: 500;
}

.contact-col {
  padding-left: 5px;
  padding-right: 5px;

  &:first-of-type {
    padding-left: 15px;
  }
}

.address {
  max-width: 400px;
}
</style>
