<template>
  <div class="contractor-building-detail">
      <div class="loader text-center mt-5 mb-5" v-if="!loaded">
          <circle-loader :size="40"></circle-loader>
      </div>
      <div class="contract-page" v-if="loaded">
          <div class="print-button"> 
            <b-button type="button"  size="sm" variant="secondary" @click="printPage">
              Print
            </b-button>
           </div>
        <div class="letter-header">
            <img alt="MD logo" src="../assets/md-logo.png">
            <div class="date">
                Order Date: <br>
                {{buildingDetail.createdAt | dateFilter}}
            </div>
        </div>
        <div class="letter-content">
            <div class="row customer-info mt-5">
                <div class="col">
                    <div class="customer-info-header mb-3">Customer Information</div>
                    <div class="row">
                        <div class="col col-4">
                            <div class="customer-info-item">Sales Person</div>
                            <div class="customer-info-item">First Name</div>
                            <div class="customer-info-item">Last Name</div>
                            <div class="customer-info-item">Address</div>
                            <div class="customer-info-item">City</div>
                            <div class="customer-info-item">County</div>
                            <div class="customer-info-item">Phone</div>
                            <div class="customer-info-item">Zip</div>
                        </div>
                        <div class="col col-8">
                            <div class="customer-info-answer">{{buildingDetail.createdBy || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.firstName || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.lastName || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.address.street1 || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.address.city || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.address.county || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.phone || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.address.zip || ' -'}}</div>
                        </div>
                    </div>                
                </div>
                <div class="col">
                    <div class="customer-info-header mb-3">Work Site Information</div>
                    <div class="row">
                        <div class="col col-4">
                            <div class="mt-2 customer-info-item">APN</div>
                            <div class="customer-info-item">First Name</div>
                            <div class="customer-info-item">Last Name</div>
                            <div class="customer-info-item">Address</div>
                            <div class="customer-info-item">City</div>
                            <div class="customer-info-item">County</div>
                            <div class="customer-info-item">Phone</div>
                            <div class="customer-info-item">Zip</div>
                            <div class="customer-info-item">Snow Load</div>
                            <div class="customer-info-item">Wind Load</div>
                        </div>
                        <div class="col col-8">
                            <div class="customer-info-answer">{{buildingDetail.site.apn || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.firstName || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.lastName || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.site.address.street1 || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.site.address.city || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.site.address.county || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.customer.phone || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.site.address.zip || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.site.snowLoad || ' -'}}</div>
                            <div class="customer-info-answer">{{buildingDetail.site.windLoad || ' -'}}</div>
                        </div>
                    </div>                
                </div>
            </div>
            <hr>
            <h6 class="text-center">Building Specifications</h6>
            <hr>
            <div class="row download-buttons">
                <div class="col text-center mb-3">
                    <b-button variant="primary" size="sm" @click="downloadEngineering" class="mr-4">
                        Engineering Specs
                    </b-button>
                    <b-button variant="primary" size="sm" @click="downloadSketchup">
                        3D Sketchup File
                    </b-button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="label">Building Type</div>
                    <div>{{buildingDetail.type}}</div>
                    <div class="label">Truss</div>
                    <div>{{buildingDetail.truss.type}}</div>
                    <div class="label">Kickers</div>
                    <div>{{(buildingDetail.truss.kickers) ? 'Yes' : 'No'}}</div>
                </div>
                <div class="col">
                    <span class="label">Width: </span><span>{{buildingDetail.dimensions.width}}</span><br>
                    <span class="label">Length: </span><span>{{buildingDetail.dimensions.length}}</span><br>
                    <span class="label">Height: </span><span>{{buildingDetail.dimensions.height}}</span><br>
                    <span class="label">Centers: </span><span>{{buildingDetail.walls.centers}}</span><br>
                    <span class="label">Anchors: </span><span>{{buildingDetail.foundation.anchors}}</span><br>
                </div>
                <div class="col">
                    <div class="label">Panel Type</div>
                    <div>{{buildingDetail.metalPanel}}</div>
                    <div class="label">Roof Design</div>
                    <div>{{buildingDetail.roof.design}}</div>
                    <div class="label">Wall Design</div>
                    <div>{{buildingDetail.walls.design}}</div> 
                </div>
                <div class="col">
                    <div class="label">Roof Color</div>
                    <div>{{buildingDetail.roof.color || '-'}}</div>
                    <div class="label">Wall Color</div>
                    <div>{{buildingDetail.walls.color || '-'}}</div> 
                    <div class="label">Trim Color</div>
                    <div>{{buildingDetail.trimColor || '-'}}</div> 
                </div>
            </div>
            <hr>
                <h6 class="text-center">Accessories</h6>
            <hr>
            <div class="row">
                <div class="col">
                    <span class="label">Engineering: </span><span>{{(buildingDetail.engineering) ? 'Yes' : 'No'}}</span><br>
                    <span class="label">Skylights: </span><span>{{buildingDetail.skyLights}}</span><br>
                    <span class="label">Windows: </span><span>{{buildingDetail.windows.withGrid + buildingDetail.windows.withoutGrid}}</span><br>
                    <span class="label">Vents: </span><span>{{buildingDetail.ridgeVents}}</span><br>
                    <span class="label">Insulation: </span><span>{{insulation}}</span><br>
                    <span class="label">Same Color Screws: </span><span>{{buildingDetail.sameColorScrews ? 'Yes' : 'No'}}</span><br>
                    <span class="label">Man Doors: </span><span>{{buildingDetail.manDoors.quantity}}</span><br>
                </div>
                <div class="col">
                    <span class="label">Side Kit: </span><span>{{buildingDetail.carportDetail.sideKitQuantity}}</span><br>
                    <span class="label">End Kit: </span><span>{{(buildingDetail.carportDetail.endKit) ? 'Yes' : 'No'}}</span><br>
                    <span class="label">Front Kit: </span><span>{{(buildingDetail.carportDetail.frontKit) ? 'Yes' : 'No'}}</span><br>
                    <span class="label">Special Add-on: </span><span>{{buildingDetail.carportDetail.specialAddOn}}</span><br>
                    <span class="label">Gable Front: </span><span>{{(buildingDetail.carportDetail.gableFront) ? 'Yes' : 'No'}}</span><br>
                    <span class="label">Gable End: </span><span>{{(buildingDetail.carportDetail.gableEnd) ? 'Yes' : 'No'}}</span><br>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <div v-for="item, index in buildingDetail.addOnItems" :key="index">
                        <div v-if="item.total">
                            <span class="label">{{item.name}} </span><span>{{item.details}}</span><br>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div v-for="item, index in buildingDetail.manualAddOnItems" :key="index">
                        <span class="label">{{item.name}} </span><span>{{item.description}}</span><br>
                    </div>
                </div>
            </div>

            <div class="" id="display-section-openings">
                <div class="label">Openings</div>
                <div class="row" id="display-section-openings">
                    <div class="col">
                        <div class="display-subsection">
                            <div class="display-subsection-title">
                                Shed Doors ({{buildingDetail.shedDoors.length}}): 
                            </div>
                            <div v-for="(shedDoor, index) in buildingDetail.shedDoors" :key="index">
                                <div v-if="shedDoor.price">
                                    {{index + 1}}. {{shedDoor.size}} {{shedDoor.model}} ({{shedDoor.quantity}}) {{shedDoor.price | currencyFilter}} 
                                    <span v-if="!shedDoor.includeDoor">(Opening Only)</span>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div class="col">
                        <div class="display-subsection">
                            <div class="display-subsection-title">
                                Roll Doors ({{buildingDetail.rollDoors.length}}): 
                            </div>
                            <div v-for="(rollDoor, index) in buildingDetail.rollDoors" :key="index">
                                <div v-if="rollDoor.price">
                                    {{index + 1}}. {{rollDoor.width}}x{{rollDoor.height}} {{rollDoor.model}} ({{rollDoor.quantity}}) {{rollDoor.price | currencyFilter}} 
                                    <span v-if="!rollDoor.includeDoor">(Opening Only)</span>                                                
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { userStore } from '@/stores/userStore';
import CircleLoader from '@/components/CircleLoader';

export default {
  name: 'ContractorBuildingDetail',
  components: { CircleLoader },  
  props: {
      building: {
        type: Object,
        required: true,
      }
  },
  inject: {
    userStore
  },
  data: function() {
    return {
        loaded: false,
        buildingDetail: null
    }
  },
  methods: {
    getBuilding() {
      return axios.get('/services/buildings/contractor/' + this.building.shortId)
      .then((response) => {
        this.buildingDetail = response.data.building;
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
          this.loaded = true;
      })
    },
    printPage() {
        alert('print feature coming soon');
    },
    downloadEngineering() {
        alert('engineering coming soon');
    },
    downloadSketchup() {
        alert('sketchup coming soon');
    },
    formatDimensions(dimensions) {
        return dimensions.width + 'x' + dimensions.length + 'x' + dimensions.height;
    },
  },
  mounted() {
      console.log('this.buildingDetail...', this.building);
      this.getBuilding()
  },
  computed: {
    customerName() {
        if (this.buildingDetail.customer.lastName) {
            return this.buildingDetail.customer.firstName + ' ' + this.buildingDetail.customer.lastName;
        } else {
            return this.buildingDetail.customer.email;
        }  
    },
    insulation() {
        let insulation;
        console.log('building: ', this.building);
        if (this.buildingDetail.roof.insulation.type === 'None' && this.buildingDetail.walls.insulation.type === 'None') {
            insulation = 'No';
        } else {
            insulation = 'Yes';
        }
        return insulation;
    },
  }
}
</script>

<style lang="scss" scoped>

.building-link {
  cursor: pointer;
  color: #007bff;
}

.print-button {
    position: absolute;
    right: 12px;
    top: 12px;
}

.letter-intro {
    position: relative;
    padding-top: 24px;
}
.letter-header {
    text-align: center;
    position: relative;
}

.date {
    position: absolute;
    left: 0;
    top: 0px;
    text-align: center;
}

.referrer {
    position: absolute;
    left: 0;
    top: 0px;
    text-align: center;
}

.customer-info-header {
    text-align: left;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
}

.customer-info-answer {
    text-decoration: underline;
}

.letter-content {
    text-align: left;
    font-size: 14px;
}

.label {
    font-weight: bold;
}

@media print {
    .contract-page {
        padding: 0px 30px 30px 30px;
    }

    .letter-content {
        font-size: 17px;
    }

    .download-buttons {
        display: none;
    }
}

</style>