<template>
  <div class="home">
    <CustomerBuildingPricer :config="config"></CustomerBuildingPricer>
  </div>
</template>

<script>

import CustomerBuildingPricer from '@/components/CustomerBuildingPricer.vue'

export default {
  name: 'CustomerPricerPage',
  props: {
      config: {
          type: Object,
          required: false,
      }
  },
  components: { CustomerBuildingPricer },
  computed: {
      buildingConfig() {
          if (this.config) {
              return this.config;
          } else {
              return {};
          }
      }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

.home {
  padding-left: 6px;
  padding-right: 6px;
}

</style>