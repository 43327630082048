<template>
  <div class="table-wrapper">
    <b-table
      striped
      hover
      :items="salesLeadList"
      :fields="fields"
      :tbody-tr-class="showDetail ? ['clickable-row'] : []"
      @row-clicked="goToSalesLead"
    >
      <template #cell(createdAt)="row">
        {{ row.item.createdAt | shortFullDateFilter }}
      </template>
      <template #cell(assignToCurrentUser)="row" v-if="assignToCurrentUser">
        <b-button
          size="sm"
          variant="primary"
          @click="confirmAssign(row)"
          class="mr-1"
          :disabled="row.item.submitting"
        >
          <span v-show="row.item.submitting">submitting..</span>
          <span v-show="!row.item.submitting">Assign to Me</span>
        </b-button>
        <b-modal
          :id="`confirm-assign-modal-${row.index}`"
          hide-header
          @ok="assignToCurrentUser(row)"
        >
          Confirm assign lead from {{ row.item.email }}?
        </b-modal>
      </template>
      <template #cell(markSpam)="row" v-if="markSpam">
        <b-button
          size="sm"
          variant="secondary"
          @click="confirmMarkSpam(row)"
          :disabled="row.item.submitting"
        >
          <span v-show="row.item.submitting">submitting..</span>
          <span v-show="!row.item.submitting">Mark Spam</span>
        </b-button>
        <b-modal
          :id="`confirm-spam-modal-${row.index}`"
          hide-header
          @ok="markSpam(row)"
        >
          Confirm mark email from {{ row.item.email }} as spam?
        </b-modal>
      </template>
    </b-table>
    <b-modal
      :title="`Sales Lead - ${currentRow && currentRow.email}`"
      :id="`lead-detail-modal-${id}`"
      hide-footer
      size="xl"
    >
      <SalesLeadOverviewPage :sales-lead="currentRow"></SalesLeadOverviewPage>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import SalesLeadOverviewPage from './SalesLeadOverviewPage'

export default {
  name: 'SalesLeadList',
  components: { SalesLeadOverviewPage },
  inject: ['userStore'],
  props: {
    salesLeads: {
      type: Array,
      required: true,
    },
    markSpam: {
      type: Function,
    },
    showDetail: {
      type: Boolean,
      default: true,
    },
    assignToCurrentUser: {
      type: Function,
    },
    optionalFields: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      currentRow: null,
      fields: [
        {
          key: 'createdAt',
          label: 'date',
          sortable: true,
        },
        {
          key: 'firstName',
          label: 'name',
          sortable: true,
        },
        {
          key: 'status',
          sortable: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'company',
          sortable: true,
        },
        {
          label: 'page',
          key: 'submittedOnPage',
          sortable: true,
        },
        {
          key: 'comments',
          sortable: true,
        },
        {
          key: 'assignToCurrentUser',
          class: [],
          label: '',
          sortable: false,
        },
        {
          key: 'markSpam',
          class: [],
          label: '',
          sortable: false,
        },
      ],
    }
  },
  mounted() {},
  computed: {
    salesLeadList() {
      return this.salesLeads.map(lead => {
        const {
          _id,
          firstName,
          submittedOnPage,
          status,
          email,
          phone,
          company,
          comments,
          createdAt,
          notes,
          salesPersonEmail,
          firstContactedDate,
          lastContactedDate,
        } = lead
        return {
          _id,
          createdAt,
          firstName,
          submittedOnPage,
          status,
          email,
          phone,
          company,
          comments,
          notes,
          salesPersonEmail,
          firstContactedDate,
          lastContactedDate,
        }
      })
    },
  },
  methods: {
    confirmMarkSpam(row) {
      this.currentRow = row
      this.$bvModal.show('confirm-spam-modal-' + row.index)
    },
    confirmAssign(row) {
      this.currentRow = row
      this.$bvModal.show('confirm-assign-modal-' + row.index)
    },
    goToSalesLead(lead, index, e) {
      if (this.showDetail) {
        this.currentRow = lead
        this.$bvModal.show(`lead-detail-modal-${this.id}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
}
</style>

<style>
.clickable-row {
  cursor: pointer;
}
</style>
