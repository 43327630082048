var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page-wrapper"},[_c('h1',{staticClass:"page-h1"},[_vm._v("Dashboard")]),(_vm.pastDueTasks)?_c('b-alert',{staticClass:"notification",attrs:{"show":"","variant":"danger"}},[_vm._v(" There are "+_vm._s(_vm.pastDueTasks)+" Past Due Tasks across all Departments. ")]):_vm._e(),(_vm.openTasks || _vm.inProgressTasks)?_c('b-alert',{staticClass:"notification",attrs:{"show":"","variant":"primary"}},[_vm._v(" You have "+_vm._s(_vm.openTasks)+" Open Tasks and "+_vm._s(_vm.inProgressTasks)+" In-Progress Tasks. "),_c('a',{attrs:{"href":"/app/tasks/"}},[_vm._v("Go to My Tasks page.")])]):_vm._e(),_c('h3',[_vm._v("Current Tasks By Department")]),(_vm.loadingTaskStats)?_c('div',{staticStyle:{"height":"200px"}},[_c('circle-loader',{attrs:{"size":30}})],1):_vm._e(),(!_vm.loadingTaskStats)?_c('tasks-summary',{staticClass:"task-stats",attrs:{"stats":_vm.taskStats}}):_vm._e(),(_vm.userStore.user.type === 'SALES' || _vm.userStore.user.type === 'ADMIN')?_c('div',{staticClass:"mt-4"},[_c('h3',[_vm._v("Buildings")]),_c('router-link',{staticClass:"mx-2",attrs:{"to":"/app/pricer"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("New Building")])],1),_c('router-link',{staticClass:"mx-2",attrs:{"to":"/app/buildings"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Manage Buildings")])],1)],1):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('h3',[_vm._v("Orders")]),(
        _vm.userStore.user.type === 'SALES' || _vm.userStore.user.type === 'ADMIN'
      )?_c('router-link',{staticClass:"mx-2",attrs:{"to":"/app/orders/new"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Create Order")])],1):_vm._e(),(
        _vm.userStore.user.type === 'SALES' || _vm.userStore.user.type === 'ADMIN'
      )?_c('router-link',{staticClass:"mx-2",attrs:{"to":"/app/orders/import"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Import Orders")])],1):_vm._e(),(
        _vm.userStore.user.type === 'SALES' || _vm.userStore.user.type === 'ADMIN'
      )?_c('router-link',{staticClass:"mx-2",attrs:{"to":"/app/orders"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Manage Orders")])],1):_vm._e(),(
        _vm.userStore.user.type === 'MANUFACTURING' ||
        _vm.userStore.user.type === 'ADMIN' ||
        _vm.userStore.user.type === 'SALES'
      )?_c('router-link',{staticClass:"mx-2",attrs:{"to":"/app/orders/current"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v("Current Orders")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }