<template>
  <div class="account page-wrapper">
    <h1 class="page-h1">Account Settings</h1>
    <h3>User</h3>
    <div class="user-settings">
      Name: {{ userStore.user.firstName }} <br />
      User: {{ userStore.user.email }} <br />
    </div>

    <div class="row">
      <div class="col">
        <h3 class="mt-5">Communication Methods</h3>
        <form @submit.prevent="changeCommunication()" name="change-pw-form">
          <!-- <div class="row mt-3">
                    <div class="col col-12 col-md-4">
                        <label for="existingPW">Existing Password</label>
                        <b-form-input id="existingPW" v-model="existingPW" type="password" :disabled="submittingPW" :required="true"></b-form-input>
                    </div>
                </div> -->
          <div class="row mt-3">
            <div class="col">
              <label for="newPW1">Email</label>
              <b-form-input
                v-model="userStore.user.email"
                id="email"
                type="text"
                :required="true"
                disabled
              ></b-form-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label for="phone">Phone </label>
              <b-form-input
                v-model="newPhone"
                id="phone"
                minlength="10"
                type="text"
                :disabled="submittingComm"
                :required="true"
              ></b-form-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <b-form-group label="Communication Preference">
                <b-form-radio-group
                  :disabled="submittingComm"
                  id="preferences"
                  v-model="newCommunicationPreference"
                  :options="commOptions"
                  required
                  name="Communication Preference"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <b-button
            class="mt-2"
            type="submit"
            variant="danger"
            :disabled="submittingComm"
          >
            Update Communication Method
          </b-button>
        </form>
      </div>
      <div class="col">
        <h3 class="mt-5">Change Password</h3>
        <div class="user-settings">
          <form @submit.prevent="changePassword()" name="change-pw-form">
            <!-- <div class="row mt-3">
                    <div class="col col-12 col-md-4">
                        <label for="existingPW">Existing Password</label>
                        <b-form-input id="existingPW" v-model="existingPW" type="password" :disabled="submittingPW" :required="true"></b-form-input>
                    </div>
                </div> -->
            <div class="row mt-3">
              <div class="col">
                <label for="newPW1">New Password</label>
                <b-form-input
                  v-model="newPW1"
                  minlength="8"
                  type="password"
                  :disabled="submittingPW"
                  :required="true"
                ></b-form-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <label for="newPW2">Confirm New Password</label>
                <b-form-input
                  v-model="newPW2"
                  minlength="8"
                  type="password"
                  :disabled="submittingPW"
                  :required="true"
                ></b-form-input>
              </div>
            </div>
            <b-button
              class="mt-2"
              type="submit"
              variant="danger"
              :disabled="submittingPW"
              >Change Password</b-button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'

export default {
  name: 'Home',
  components: {},
  inject: {
    userStore,
  },
  data: function () {
    return {
      existingPW: '',
      newPW1: '',
      newPW2: '',
      newPhone: _.clone(userStore.user.phone),
      newCommunicationPreference: _.clone(
        userStore.user.communicationPreference,
      ),
      // commOptions: ['EMAIL'],
      commOptions: ['PHONE', 'EMAIL', 'BOTH'],
      submittingPW: false,
      submittingComm: false,
    }
  },
  methods: {
    getUser() {
      return axios
        .get('/services/user')
        .then(response => {
          this.user = response.data.user
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    changePassword() {
      if (this.newPW1 !== this.newPW2) {
        return this.$toasted.error('new passwords do not match', {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submittingPW = true

      var data = {
        newPW: this.newPW1,
      }
      axios
        .patch('/services/user/password', data)
        .then(response => {
          this.$toasted.success('successfully updated password', {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
          this.newPW1 = ''
          this.newPW2 = ''
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submittingPW = false
        })
    },
    changeCommunication() {
      this.submittingComm = true

      var data = {
        phone: this.newPhone,
        communicationPreference: this.newCommunicationPreference,
      }
      axios
        .patch('/services/user/communications', data)
        .then(response => {
          this.$toasted.success('successfully updated communications', {
            duration: 3000,
          })
          this.userStore.addProp('phone', response.data.user.phone)
          this.userStore.addProp(
            'communicationPreference',
            response.data.user.communicationPreference,
          )
          this.newPhone = response.data.user.phone
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error('Sorry, we encountered an error', {
            duration: 3000,
          })
        })
        .finally(() => {
          this.submittingComm = false
        })
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.user-settings {
  font-size: 20px;
}
</style>
