<template>
  <div class="contract-page">
    <div class="letter-header">
      <img alt="MD logo" src="../assets/md-logo.png" />
      <div class="date">
        Order Date: <br />
        {{ building.createdAt | dateFilter }}
      </div>
      <div class="referrer">
        Referrer/Promo:<br />
        {{ building.createdAt | dateFilter }}
      </div>
    </div>
    <div class="letter-content">
      <div class="row customer-info mt-5">
        <div class="col">
          <div class="customer-info-header mb-3">Mailing Information</div>
          <div class="row">
            <div class="col col-4">
              <div class="customer-info-item">Sales Person</div>
              <div class="customer-info-item">First Name</div>
              <div class="customer-info-item">Last Name</div>
              <div class="customer-info-item">Address</div>
              <div class="customer-info-item">City</div>
              <div class="customer-info-item">County</div>
              <div class="customer-info-item">Phone</div>
              <div class="customer-info-item">Zip</div>
            </div>
            <div class="col col-8">
              <div class="customer-info-answer">
                {{ building.createdBy || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.firstName || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.lastName || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.address.street1 || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.address.city || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.address.county || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.phone || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.address.zip || ' -' }}
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="customer-info-header mb-3">Work Site Information</div>
          <div class="row">
            <div class="col col-4">
              <div class="mt-2 customer-info-item">APN</div>
              <div class="customer-info-item">First Name</div>
              <div class="customer-info-item">Last Name</div>
              <div class="customer-info-item">Address</div>
              <div class="customer-info-item">City</div>
              <div class="customer-info-item">County</div>
              <div class="customer-info-item">Phone</div>
              <div class="customer-info-item">Zip</div>
              <div class="customer-info-item">Snow Load</div>
              <div class="customer-info-item">Wind Load</div>
            </div>
            <div class="col col-8">
              <div class="customer-info-answer">
                {{ building.site.apn || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.firstName || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.lastName || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.site.address.street1 || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.site.address.city || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.site.address.county || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.customer.phone || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.site.address.zip || ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.snowLoad ? building.snowLoad + 'psf' : ' -' }}
              </div>
              <div class="customer-info-answer">
                {{ building.windLoad ? building.windLoad + 'mph' : ' -' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h6 class="text-center">Building Specifications</h6>
      <hr />
      <div class="row">
        <div class="col">
          <div class="label">Building Type</div>
          <div>{{ building.type }}</div>
          <span class="label mt-2">Truss: </span>
          <span>{{ building.truss.type }}</span> <br />
          <span class="label">Kickers: </span>
          <span>{{ building.truss.kickers ? 'Yes' : 'No' }}</span> <br />
          <span class="label">Closure: </span>
          <span>{{ building.closure ? 'Yes' : 'No' }}</span>
        </div>
        <div class="col">
          <span class="label">Width: </span
          ><span>{{ building.dimensions.width }}</span
          ><br />
          <span class="label">Length: </span
          ><span>{{ building.dimensions.length }}</span
          ><br />
          <span class="label">Height: </span
          ><span>{{ building.dimensions.height }}</span
          ><br />
          <span class="label">Centers: </span
          ><span>{{ building.walls.centers }}</span
          ><br />
          <span class="label">Anchors: </span
          ><span>{{ building.foundation.anchors }}</span
          ><br />
        </div>
        <div class="col">
          <div class="label">Panel Type</div>
          <div>{{ building.metalPanel }}</div>
          <div class="label">Roof Design</div>
          <div>{{ building.roof.design }}</div>
          <div class="label">Wall Design</div>
          <div>{{ building.walls.design }}</div>
        </div>
        <div class="col">
          <div class="label">Roof Color</div>
          <div>{{ building.roof.color || '-' }}</div>
          <div class="label">Wall Color</div>
          <div>{{ building.walls.color || '-' }}</div>
          <div class="label">Trim Color</div>
          <div>{{ building.trimColor || '-' }}</div>
        </div>
      </div>
      <hr />
      <h6 class="text-center">Accessories</h6>
      <hr />
      <div class="row mb-3">
        <div class="col col-4">
          <span class="label">Side Kit: </span
          ><span>{{ building.carportDetail.sideKitQuantity }}</span>
        </div>
        <div class="col col-4">
          <span class="label">End Kit: </span
          ><span>{{ building.carportDetail.endKit ? 'Yes' : 'No' }}</span>
        </div>

        <div class="col col-4">
          <span class="label">Front Kit: </span
          ><span>{{ building.carportDetail.frontKit ? 'Yes' : 'No' }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Engineering: </span
          ><span>{{ building.engineering ? 'Yes' : 'No' }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Gable Front: </span
          ><span>{{ building.carportDetail.gableFront ? 'Yes' : 'No' }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Gable End: </span
          ><span>{{ building.carportDetail.gableEnd ? 'Yes' : 'No' }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col col-4">
          <span class="label">Skylights: </span
          ><span>{{ building.skyLights }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Windows: </span><span>{{ numWindows }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Vents: </span
          ><span>{{ building.ridgeVents }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Insulation: </span><span>{{ insulation }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Same Color Screws: </span
          ><span>{{ building.sameColorScrews ? 'Yes' : 'No' }}</span>
        </div>
        <div class="col col-4">
          <span class="label">Man Doors: </span><span>{{ numManDoors }}</span>
        </div>
        <div class="col col-4"></div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <b-button
            class="hide"
            size="sm"
            variant="secondary"
            @click="unselectAllAddOns"
          >
            Unselect All
          </b-button>
          <div v-for="(item, index) in addOnItems" :key="index">
            <div v-if="item.total" :class="{ row: true, hide: !item.show }">
              <div class="label col col-4">
                <b-form-checkbox
                  v-model="item.show"
                  class="hide"
                ></b-form-checkbox
                >{{ item.name }}
              </div>
              <div class="col col-5">
                {{
                  item.totalSqft || item.totalSqft === 0
                    ? item.totalSqft + 'sqft @' + item.perSqft + '/sqft'
                    : item.totalLft
                    ? item.totalLft + 'lft @' + item.perLft + '/lft'
                    : item.numDays
                    ? item.numDays + ' days @ ' + item.pricePerDay + '/day'
                    : item.details
                }}
              </div>
              <div class="col col-3">
                {{ item.total | currencyFilter }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-for="(item, index) in manualAddOnItems" :key="index">
            <div :class="{ row: true, hide: !item.show }">
              <div class="label col col-4">
                <b-form-checkbox
                  v-model="item.show"
                  class="hide"
                ></b-form-checkbox
                >{{ item.name }}
              </div>
              <div class="col col-5">
                {{ item.description }}
              </div>
              <div class="col col-3">
                {{ item.price | currencyFilter }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-for="(item, index) in installAddOnItems" :key="index">
            <div :class="{ row: true, hide: !item.show }">
              <div class="label col col-4">
                <b-form-checkbox
                  v-model="item.show"
                  class="hide"
                ></b-form-checkbox>
                {{ item.name }}
              </div>
              <div class="col col-5">
                {{ item.description }}
              </div>
              <div class="col col-3">
                {{ item.price | currencyFilter }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5" id="display-section-openings">
        <div class="row" id="display-section-openings">
          <div class="col">
            <div class="label">Openings</div>
            <div class="display-subsection">
              <div class="display-subsection-title">
                Shed Doors ({{ getTotalDoors(shedDoors) }}):
              </div>
              <div v-for="(shedDoor, index) in shedDoors" :key="index">
                <div v-if="shedDoor.size">
                  {{ index + 1 }}. {{ shedDoor.size }} {{ shedDoor.model }} ({{
                    shedDoor.quantity
                  }})
                  <span v-if="!shedDoor.includeDoor">(Opening Only)</span>
                </div>
                <div v-if="!shedDoor.size">
                  {{ index + 1 }}. {{ shedDoor.width.ft }}'{{
                    shedDoor.width.in
                  }}x{{ shedDoor.height.ft }}'{{ shedDoor.height.in }}
                  {{ shedDoor.model }} ({{ shedDoor.quantity }})
                </div>
              </div>
            </div>
            <div class="display-subsection">
              <div class="display-subsection-title">
                Roll Doors ({{ getTotalDoors(rollDoors) }}):
              </div>
              <div v-for="(rollDoor, index) in rollDoors" :key="index">
                <div v-if="typeof rollDoor.width === 'number'">
                  {{ index + 1 }}. {{ rollDoor.width }}x{{ rollDoor.height }}
                  {{ rollDoor.model }} ({{ rollDoor.quantity }})
                  <span v-if="!rollDoor.includeDoor">(Opening Only)</span>
                </div>
                <div v-if="typeof rollDoor.width === 'object'">
                  {{ index + 1 }}. {{ rollDoor.width.ft }}'{{
                    rollDoor.width.in
                  }}x{{ rollDoor.height.ft }}'{{ rollDoor.height.in }}
                  {{ rollDoor.model || rollDoor.source }} ({{
                    rollDoor.quantity
                  }})
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="label">Notes</div>
            <b-form-checkbox v-model="showBuildingNotes" class="hide"
              >Show Building Notes</b-form-checkbox
            >
            <b-textarea
              placeholder="Enter Additional Notes"
              v-model="notes"
              class="hide"
            ></b-textarea>
            <div
              :class="{ 'notes-list': true, 'print-only': showBuildingNotes }"
            >
              <div v-for="(note, index) in building.notesList" :key="index">
                <span v-if="!note.removed">
                  {{ note.text }}
                </span>
              </div>
            </div>
            <div class="print-only">{{ notes }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        Buyer Signature: ____________________________________________
        Date:______________________________
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'ContractPage',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      notes: '',
      showBuildingNotes: false,
      installAddOnItems: this.building.installAddOnItems.map(item => {
        return { show: true, ...item }
      }),
      addOnItems: this.building.addOnItems.map(item => {
        return { show: true, ...item }
      }),
      manualAddOnItems: this.building.manualAddOnItems.map(item => {
        return { show: true, ...item }
      }),
      rollDoors: [
        ...this.building.rollDoors,
        ...this.building.openings.filter(opening => {
          return opening.type == 'Roll Door'
        }),
      ],
      shedDoors: [
        ...this.building.shedDoors,
        ...this.building.openings.filter(opening => {
          return opening.type == 'Shed Door'
        }),
      ],
    }
  },
  methods: {
    getTotalDoors(list) {
      if (!list) return 0
      let doors = 0
      _.each(list, li => {
        doors += parseInt(li.quantity)
      })
      return doors
    },
    unselectAllAddOns() {
      _.each(this.addOnItems, addOn => {
        addOn.show = false
      })
      _.each(this.installAddOnItems, addOn => {
        addOn.show = false
      })
      _.each(this.manualAddOnItems, addOn => {
        addOn.show = false
      })
      // this.$forceUpdate()
    },
  },
  computed: {
    numWindows() {
      let windowsManDoors = 0
      _.each(this.building.openings, opening => {
        if (opening.type === 'Window') {
          windowsManDoors += parseInt(opening.quantity)
        }
      })
      return (
        parseInt(
          this.building.windows.withGrid + this.building.windows.withoutGrid,
        ) + windowsManDoors
      )
    },
    numManDoors() {
      let openingsManDoors = 0
      _.each(this.building.openings, opening => {
        if (opening.type === 'Man Door') {
          openingsManDoors += parseInt(opening.quantity)
        }
      })
      return parseInt(this.building.manDoors.quantity) + openingsManDoors
    },
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
    insulation() {
      let insulation
      if (
        this.building.roof.insulation.type === 'None' &&
        this.building.walls.insulation.type === 'None'
      ) {
        insulation = 'None'
      } else {
        if (this.building.roof.insulation.type === 'None') {
          insulation = 'Walls'
        } else if (this.building.walls.insulation.type === 'None') {
          insulation = 'Roof'
        } else {
          insulation = 'Both'
        }
      }
      return insulation
    },
  },
}
</script>

<style scoped>
.custom-control {
  display: inline-block;
  padding-right: 0;
}
.letter-intro {
  position: relative;
  padding-top: 24px;
}
.letter-header {
  text-align: center;
  position: relative;
}

.date {
  position: absolute;
  right: 0;
  top: 0px;
  text-align: center;
}

.referrer {
  position: absolute;
  left: 0;
  top: 0px;
  text-align: center;
}

.customer-info-header {
  text-align: left;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

.customer-info-answer {
  text-decoration: underline;
}

.letter-content {
  text-align: left;
  font-size: 14px;
}

.label {
  font-weight: bold;
}

.notes-list {
  display: none;
}
.print-only {
  display: none;
}

@media print {
  .contract-page {
    padding: 0px 30px 30px 30px;
  }

  .letter-content {
    font-size: 17px;
  }
  .hide {
    display: none;
  }

  .print-only {
    display: block;
  }
}
</style>
