<template>
  <div class="kit-contract">
    <div class="page-one">
      <div class="page-header clearfix">
        <div class="page-number">MDI Kit Contract<br />1 of 3</div>
      </div>
      <div class="page-one-header">
        <div class="logo">
          <img alt="MD logo" src="../assets/md-logo.png" />
          <div class="address">
            6771 Eastside Rd Redding, CA 96001<br />
            (530)605-1931 PH (530)605-1938 Fax <br />
            www.metalsdirect.com <br />
          </div>
        </div>
        <div class="title">Sales Contract for Kit</div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          This contract is made effective on
          <span class="underline">{{ today | dateFilter }} </span>
          Between Metals Direct Inc hereafter known as MDI located at 6771
          Eastside Rd. Redding CA 96001 and
          <span class="underline">{{ customerName }}</span> of
          <span class="underline">{{ building.customer.address.city }}</span>
          hereafter known BUYER Located at the address listed below: <br />
          <div class="customer-address row mt-3">
            <div class="col">
              <div class="row">
                <div class="col col-3 label-line">Street:</div>
                <div class="col col-8 address-line">
                  {{ building.customer.address.street1 }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col col-3 label-line">Zip:</div>
                <div class="col col-8 address-line">
                  {{ building.customer.address.zip }}
                </div>
              </div>
            </div>
          </div>
          <div class="customer-address row mt-3">
            <div class="col">
              <div class="row">
                <div class="col col-3 label-line">City:</div>
                <div class="col col-8 address-line">
                  {{ building.customer.address.city }}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col col-3 label-line">Phone:</div>
                <div class="col col-8 address-line">
                  {{ building.customer.address.phone }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <strong>MDI</strong> agrees to sell, ordered per customer contract,
          and the Buyer agrees to buy, the Goods, or building product, in
          accordance with the below stated terms and conditions. Buyer must be
          ready for delivery of building within 30 days of execution of
          contract.If Buyer is not ready within 30 days and no written extension
          is requested, Buyer forfeits any monies paid in at time of signature
          on contract. Neither party has the power to terminate this contract
          after date of execution.<br />
          <strong>PRODUCT STANDARDS:</strong> Building/Goods to meet the
          description specified herein and signed for by customer:<br />
          <strong>PAYMENT(s):</strong> Under this contract payment (s) will be
          made as followed: 10% of purchase price to be paid at time of signed
          contract, the balance of amount to be paid as described:
          __________Initial

          <div class="payment-schedule mt-4 mb-5">
            <div class="row">
              <div class="col col-6 label-li">
                Total Contract price of goods:
              </div>
              <div class="col col-5 answer-li"></div>
            </div>
            <div class="row mt-2">
              <div class="col col-6 label-li">
                10% due at time of signed contract:<br />
                (NON REFUNDABLE DOWN PAYMENT)
              </div>
              <div class="col col-5 answer-li"></div>
            </div>
            <div class="row mt-4">
              <div class="col col-6 label-li">Payment Received:</div>
              <div class="col col-5 answer-li"></div>
            </div>
            <div class="row mt-4">
              <div class="col col-6 label-li">
                Balance at delivery prior to release of purchase:
              </div>
              <div class="col col-5 answer-li"></div>
            </div>
          </div>

          In addition to all other rights and remedies provided by law if the
          BUYER fails to pay for goods when due, MDI will exercise the right to
          treat “nonpayment” as a material breach of this contract and herein
          has the right to collect materials/purchase (recover building or
          product) due to this breach.
        </div>
        <div class="mt-3">
          <strong>4) SECONDARY FEES: </strong>Customer understands that if MDI
          pursues recovery of building/product customer will also be charged
          secondary fees that may include, but are not limited to the following:
          $65.00 (sixty-five dollars per hour) per laborer and/or employee
          associated with any part of recovery of product, materials, utilities,
          spoils, equipment, rentals, deposits, transportation, etc. Note: in
          the event the building is recovered customer will still be liable for
          the contracted price of purchase, as well as, any additional or
          increased material costs necessary to complete order once contract is
          fulfilled. _______________Initial<br />
          <strong>5) TRANSFER OF POWER AND CONTROL: </strong>In the event the
          BUYER is not the property owner(s) listed where the building is
          delivered or property is owned by a third party, buyer legally
          transfers his rights of power and control of said property to MDI and
          in doing so legally grants MDI permission to enter for purpose of
          recovery without interference. ___________Initial<br />
          In the event of a material breach of contract, notification of
          recovery to the buyer is not required as the buyer fully understands
          his rights and responsibilities as outlined in this contract.
          _____________________Initial<br />
        </div>
      </div>
    </div>

    <div class="page-two clearfix">
      <div class="page-header clearfix">
        <div class="page-number">MDI Kit Contract<br />2 of 3</div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          <strong>6) PERMIT AND FEES: </strong> BUYER represents that they are
          aware that full and sole responsibility is held by the BUYER to
          acquire all applicable permits required by their government agencies
          or public authorities having jurisdiction over the assembly of this
          purchase. BUYER shall indemnify MDI from any claim by any public
          agency with request to the acquisition of permits and the payment of
          fees application to those agencies. Any BUYER choosing to not obtain
          necessary permits assumes all liability and risk for that choice.
          BUYER will not hold MDI or its agents responsible for damages or fines
          associated with that action.<br />
          <strong>7) AID IN THE PERMIT PROCESS: </strong>In the event that BUYER
          requires aid from the SELLER in the COUNTY or CITY Permit application
          process the BUYER is aware that due to multiple steps required in this
          process that may be unique to each BUYER based on specifications
          toward each customer’s property. Because of this MDI cannot guarantee
          specific time of completion of this process. While MDI Administration
          will make every effort possible toward swift completion of this
          process, the BUYER understands and herby agrees that delays in this
          project based on those efforts will not be held against MDI or the
          successful completion of this contract. In the event of an authorized
          cancellation based on delays beyond MDI’s control during this process:
          the BUYER is hereby made aware that a charge of $65.00 per hour
          totaling the time spent on this project will be assessed and
          subtracted from the down payment collected. _______________________
          Initial<br />
          <strong>8) DELAYS: </strong>In the event the Contractor is delayed in
          the prosecution of the work by or due to acts of God, labor strikes,
          late delivery of material; or by neglect of the owner: the time for
          completion of the work shall be extended for the same period as the
          delay occasioned by any of the aforementioned causes. Delays caused by
          third parties shall not constitute release or responsibility to the
          payment schedule due by the owner as described.<br />
          <strong>9) DELIVERY OF BUILDING: </strong>MDI will furnish and shall
          hold responsibility for packaging, shipping, and safe delivery and
          shall bear all risk of damage or risk of loss until goods are
          delivered to the provided address of the BUYER. Liability of material
          is transferred to the BUYER from SELLER upon signature. MDI will allow
          BUYER to assign an acting agent to \receive delivery however that
          signature will act as acceptance in BUYERS absence. Delivery of Goods
          may be adjusted based on other necessary components such as acts of
          God, unforeseen weather conditions. Other areas that may apply would
          be third party obligations these are to include, but are not limited
          to engineering and permit process and upon special circumstances
          request of customer when approved by management.<br />
          (Additional amendment must be signed if request made by customer for
          this to apply) <br />
          <strong>10) WARRANTIES: </strong>MDI warrants that product to be free
          of substantive defects in material and workmanship:<br />
          MDI shall in no event be liable for any incidental special or
          consequential damages of any nature even if MDI has been advised of
          such possibility of such damages. <br />
          <strong>11) NOTICES: </strong>All notices or other matters required or
          permitted by this contract or by law to be served on, given to, or
          delivered to Metals Direct Inc or Buyer must be done in writing and
          will be deemed duly served, given, or delivered when 1)personally
          served upon party whom it is addressed to, or his/her supervisorial
          employee of that party or 2) deposited by United States Mail, first
          class postage paid, addressed to appropriate party and mailed to
          either address contained on this contract.<br />
          <strong>12) LIENS: </strong>MDI retains the right to apply for any
          necessary liens applicable.<br />
          <strong>13) CANCELLATIONS: </strong>MDI Company policy is that all
          down payments given at time of purchase are nonrefundable. MDI
          understands that sometimes there are unforeseen events and
          circumstances that may arise in which a customer may need to cancel
          their contract. In the event cancellation should occur within the
          first 48 hours from purchase and the project has not been sent into
          production, administration will review your file for determination of
          your request. Should administration relieve you from your contract
          obligation your deposit will remain with us as an in store credit for
          one year from date of cancellation. Any cancellation requests done
          after the first 48 hours are subject to restock fees of 25% of
          material costs, admin fees, engineering costs and any fees associated
          with project
        </div>
      </div>
    </div>

    <div class="page-three clearfix">
      <div class="page-header clearfix">
        <div class="page-number">MDI Kit Contract<br />3 of 3</div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          <strong>14) LEGAL COST: </strong>In the event controversies or
          disputes arise out of performance of or relating to this contract both
          parties agree to split equally any mediation costs used to resolve the
          dispute prior to the necessity of other legal remedies. In the event
          no agreement can be mutually arrived and more serious legal steps are
          necessary the following is understood by both parties: All legal fees
          arising out of or relating to this contract brought by either party to
          this Contract, it is agreed the prevailing party will be entitled to
          receive from the other party, in addition to any other relief that may
          be granted, the reasonable attorney’s fees, costs and expenses.<br />
          <strong>15) REMEDIES: </strong>Seller shall not be held liable to
          Buyer or any other party for incidental, liquidated, special or
          consequential damages of any type, including, but not limited to, loss
          of profits, loss of rents, loss of expenses arising from unforeseen
          delays such as but not limited to, construction or completion delays,
          labor or overhead expenses, increased operating expenses, increased
          insurance or maintenance expenses, business interruptions, damage or
          loss to inventory, or any other type of consequential, incidental or
          special loss or damage whatsoever, whether claims for such damages or
          losses shall be based upon contract, warranty, tort or negligence,
          strict liability or any other cause of action. <br />
          <strong>16) SOLE AND ONLY AGREEMENT: </strong>This document is the
          only agreement of the parties to this contract relating to the project
          and correctly sets forth the rights and duties, and obligations of
          each to the other as of the designated date.<br />
          <strong>17) INDEMNITY AGREEMENT: </strong>MDI will indemnify and hold
          Buyer and the property owner free and harmless from any claim, losses
          damages, injuries and liabilities arising from the death or injury of
          any person or persons or from the damage or destruction of any
          property or properties, caused by or connected with the performance of
          the Contract by MDI or MDI’s Agents, independent contractors or
          employees.
        </div>
        <div class="storage-disclaimer">
          <div class="storage-disclaimer-title">Important Proper Storage</div>
          When moisture remains in contact with Galvanized, Galvalume, or
          Aluminum panels in the absence of freely circulating air, white,
          black, or dark gray corrosion products begin to form. Moisture can get
          between stacked panels either through capillary action or atmospheric
          humidity (if the temperature of the panels is below the dew point
          temperature of the surrounding air, condensation will form between the
          panels). If moisture becomes entrapped between the sheets, this
          condition can result in water stains or white rust, which can affect
          the service life of the metal and will detract from its appearance.
          <br /><br />
          If the metal panels will not be installed immediately, they must be
          kept warm and dry in inside storage only, where uniform temperature
          and good ventilation are available. Material should not be stored for
          prolonged periods in bundles as moisture may go undetected. If
          prolonged storage is necessary the bundle should be broken and the
          sheets stored in an inclined position.
          <br /><br />
          If outdoor storage cannot be avoided protect the metal with canvas or
          waterproof paper.
          <br /><br />
          Keep the sheets off the ground in an inclined position with an
          insulator such as wood.
          <br /><br />
          Under no circumstances is plastic to be used as it causes sweating and
          condensation.
          <br /><br />
          CAUTION!!! Sharp edges, wear protective clothing and gloves.
        </div>
        <div class="mt-5">
          Signatures below indicate agreement in the above contract:
        </div>
        <div class="mt-5">
          Buyer: ____________________________________________
          Date:______________________________
        </div>
        <div class="mt-5">
          Metals Direct Inc: __________________________________ Date:
          _____________________________
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KitContract',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    console.log('this.building....', this.building)
    return {
      today: new Date(),
    }
  },
  computed: {
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
    insulation() {
      let insulation
      if (
        this.building.roof.insulation.type === 'None' &&
        this.building.walls.insulation.type === 'None'
      ) {
        insulation = 'No'
      } else {
        insulation = 'Yes'
      }
      return insulation
    },
  },
}
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.label-li,
.label-line {
  text-align: right;
}

.answer-li,
.address-line {
  border-bottom: 1px solid black;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.kit-contract {
  position: relative;
}
.page-number {
  float: right;
}

.page-content {
  text-align: left;
}

.page-one-header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-top: 48px;
  text-align: left;
}

.address {
  padding: 12px 18px;
  font-size: 18px;
}

.labels,
.answers {
  display: inline-block;
  width: 49%;
}

.storage-disclaimer-title {
  font-weight: bold;
  margin-bottom: 6px;
}
.storage-disclaimer {
  margin-top: 24px;
  border: 1px solid black;
  font-size: 15px;
  padding: 12px;
}

@media print {
  .page-two,
  .page-three {
    page-break-before: always;
  }

  .kit-contract {
    padding: 0px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }

  .title {
    font-size: 42px;
  }
}
</style>
