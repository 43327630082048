<template>
  <div class="file-name-wrapper">
    <img
      v-if="
        !file.name.match('pdf') &&
        !file.name.match('png') &&
        !file.name.match('jpg') &&
        !file.name.match('jpeg') &&
        !file.name.match('xls') &&
        !file.name.match('doc') &&
        !file.name.match('gif') &&
        !file.name.match('zip')
      "
      src="@/assets/file.png"
    />
    <img v-if="file.name.match('pdf')" src="@/assets/pdf.png" />
    <img v-if="file.name.match('png')" src="@/assets/png.png" />
    <img v-if="file.name.match('zip')" src="@/assets/zip.png" />
    <img v-if="file.name.match('jpg')" src="@/assets/jpg.png" />
    <img v-if="file.name.match('jpeg')" src="@/assets/jpg.png" />
    <img v-if="file.name.match('doc')" src="@/assets/doc.png" />
    <img v-if="file.name.match('gif')" src="@/assets/gif.png" />
    <img v-if="file.name.match('xls')" src="@/assets/xls.png" />
  </div>
</template>

<script>
export default {
  name: 'FileIcon',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss">
img {
  width: 100%;
}
</style>
