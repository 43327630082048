<template>
  <div>
    <div class="customer-schedule-page" v-if="customer">
      <div class="row">
        <div class="col">
          <div class="schedule">
            <h3>Schedule</h3>
            <customer-schedule :customer="customer"></customer-schedule>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerSchedule from '@/components/CustomerSchedule'

export default {
  name: 'CustomerSchedulePage',
  props: {},
  data: function () {
    return {
      customer: null,
    }
  },
  components: {
    CustomerSchedule,
  },
  mounted() {
    this.customer = this.$parent.customer
  },
}
</script>

<style lang="scss" scoped></style>
