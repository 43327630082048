export const deliveryMethods = [
  {
    value: '',
    text: 'Select Delivery Method',
  },
  {
    value: 'DELIVERY',
    text: 'DELIVERY',
  },
  {
    value: 'PICKUP',
    text: 'PICKUP',
  },
]

export const workOrderStatuses = [
  {
    value: 'N/A',
    text: 'n/a',
  },
  {
    value: 'ISSUED',
    text: 'issued',
  },
  {
    value: 'COMPLETED',
    text: 'completed',
  },
]

export const holdReasons = [
  {
    value: '',
    text: 'Select Reason',
  },
  {
    value: 'Color Out of Stock (Gray)',
    text: 'Color Out of Stock (Gray)',
  },
  {
    value: 'Color Out of Stock (Red)',
    text: 'Color Out of Stock (Red)',
  },
  {
    value: 'Color Out of Stock (Brown)',
    text: 'Color Out of Stock (Brown)',
  },
  {
    value: 'Other',
    text: 'Other',
  },
]

export const orderTypes = [
  {
    value: '',
    text: 'Select Order Type',
  },
  {
    value: 'MATERIALS',
    text: 'MATERIALS',
  },
  {
    value: 'BUILDING',
    text: 'BUILDING',
  },
  {
    value: 'ENGINEERING',
    text: 'ENGINEERING',
  },
  {
    value: 'REPAIR',
    text: 'REPAIR',
  },
]

export const orderStatuses = [
  {
    value: '',
    text: 'Select Order Status',
  },
  {
    value: 'OPEN',
    text: 'OPEN',
  },
  {
    value: 'IN-PROGRESS',
    text: 'IN-PROGRESS',
  },
  {
    value: 'READY',
    text: 'READY FOR PICKUP/DELIVERY',
  },
  {
    value: 'COMPLETED',
    text: 'COMPLETED',
  },
  {
    value: 'ON-HOLD',
    text: 'ON-HOLD',
  },
  {
    value: 'CANCELLED',
    text: 'CANCELLED',
  },
]
