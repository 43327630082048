<template>
  <div class="leak-waiver">
    <div class="page-one">
      <div class="page-one-header">
        <div class="logo">
          <img alt="MD logo" src="../assets/md-logo.png" />
          <div class="address">
            6771 Eastside Rd Redding, CA 96001<br />
            (530)605-1931 PH (530)605-1938 Fax <br />
            www.metalsdirect.com <br />
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          <div class="permission-header">Leak Waiver</div>
          <div class="mt-3">
            This signed letter is intended to acknowledge that Metals Direct,
            INC has informed the customer that a horizontally oriented building
            DOES have the ability to leak based on how the sheets are ran along
            the building. Metals Direct, INC will do all that it can to insure
            the building is water tight, but cannot guarantee it with the panel
            orientation.
          </div>
        </div>
        <div>
          <div class="mt-5">
            Name: ____________________________________________
          </div>
          <div class="mt-4">
            Date: ___________________________________________
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeakWaiver',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      today: new Date(),
    }
  },
  computed: {
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.leak-waiver {
  position: relative;
}

.page-content {
  text-align: left;
}

.address {
  padding: 18px;
  font-size: 18px;
}

.logo {
  text-align: center;
}

.permission-header {
  text-transform: uppercase;
  font-weight: bold;
}

@media print {
  .page-two,
  .page-three {
    page-break-before: always;
  }

  .leak-waiver {
    padding: 30px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }

  .title {
    font-size: 42px;
  }
}
</style>
