<template>
  <div class="notes">
    <div class="row" v-if="building.notesList">
      <div class="col-12">
        <notes-list
          class="notes-list"
          :notes="building.notesList"
          :confirm-remove-note="confirmRemoveNote"
        ></notes-list>
        <form @submit.prevent="addNote">
          <b-textarea
            class="my-2"
            id="buildingNotes"
            v-model="newNote"
            :required="true"
          ></b-textarea>
          <div class="submit-btn-wrapper">
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="submit-btn"
              :disabled="submitting"
            >
              <span v-if="submitting">Submitting</span>
              <span v-if="!submitting">Add Note</span>
            </b-button>
          </div>
        </form>
      </div>
    </div>
    <b-modal id="confirmRemoveNote" title="Confirm Remove Note" hide-footer>
      <div v-if="selectedNote">
        <div class="warning">Are you sure you want to remove this note?</div>
        <div class="remove-note-date">
          {{ selectedNote.timestamp | dateTimeFilter }}
        </div>
        <div class="remove-note-text">{{ selectedNote.text }}</div>

        <div class="modal-ctas mt-5">
          <b-button variant="danger" @click="removeNote" :disabled="submitting">
            <span v-if="submitting">Removing..</span>
            <span v-if="!submitting">Remove</span>
          </b-button>
          <b-button
            variant="secondary"
            @click="$bvModal.hide(`confirmRemoveNote`)"
            :disabled="submitting"
            class="ml-2"
            >Cancel</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import NotesList from '@/components/NotesList'
import axios from 'axios'

export default {
  name: 'BuildingNotes',
  components: { NotesList },
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      newNote: '',
      submitting: false,
      selectedNote: null,
    }
  },
  computed: {
    notes() {
      return this.building.notesList
    },
  },
  methods: {
    confirmRemoveNote(note) {
      this.selectedNote = note
      this.$bvModal.show('confirmRemoveNote')
    },
    removeNote() {
      this.submitting = true
      const data = {
        id: this.building.shortId,
        note: this.selectedNote,
      }
      return axios
        .post('/services/buildings/remove-note', data)
        .then(response => {
          this.building.notesList = response.data.notesList
          this.$bvModal.hide('confirmRemoveNote')
          this.selectedNote = null
          this.$toasted.success(`Note Removed`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error removing note`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    addNote() {
      this.submitting = true
      const data = {
        id: this.building.shortId,
        note: {
          text: this.newNote,
        },
      }
      return axios
        .post('/services/buildings/add-note', data)
        .then(response => {
          this.building.notesList = response.data.notesList
          this.newNote = ''
        })
        .catch(error => {
          this.$toasted.error(`Error adding note to building`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.submit-btn-wrapper {
  text-align: right;
}
.notes-list {
  max-height: 400px;
  overflow: scroll;
}
.remove-note-date {
  margin-top: 24px;
  font-size: 14px;
  font-weight: bold;
}

.remove-note-text {
  font-size: 15px;
  color: gray;
}
</style>
