<template>
  <div class="calendar">
    <header class="calendar-header">
      <b-button variant="secondary" class="mx-1" @click="previousDates()"
        >&lt;</b-button
      >
      <b-button variant="secondary" class="mx-1" @click="nextDates()"
        >&gt;</b-button
      >
      <span class="display-month">
        {{ displayMonth }}
      </span>
      <div class="helper-buttons">
        <b-button
          variant="secondary"
          class="scope-button"
          @click="toggleScope(anchorDate)"
        >
          <span v-if="scope === 'month'">Week View</span>
          <span v-if="scope === 'week'">Month View</span>
        </b-button>
        <b-button
          variant="primary"
          class="scope-button ml-2"
          @click="resetToToday()"
        >
          Today
        </b-button>
      </div>
    </header>
    <div
      :class="{ 'calendar-days': true, 'week-view': scope === 'week' }"
      v-if="recurringDates && currentDates && currentDates.length"
    >
      <div
        class="row"
        v-for="(week, weekIndex) in currentDates"
        :key="`col-${weekIndex}`"
      >
        <div
          class="calendar-day col"
          v-bind:class="{
            today: day.format('MM/DD/YY') === today.format('MM/DD/YY'),
            'long-day': scope === 'week',
          }"
          v-for="(day, dayIndex) in week"
          :key="`col-${dayIndex}`"
          @click="openDay(day)"
        >
          <div class="day-title">
            {{ day.format('DD') }}
          </div>
          <div class="day-content" v-if="showData && scope === 'month'">
            <order-calendar-month-day
              :schedule-dates="calendarData[day.format('MM/DD/YYYY')] || []"
              :recurring-dates="[...recurringDates.weekly[day.day()]] || []"
              :day="day"
            >
            </order-calendar-month-day>
          </div>
          <div class="day-content" v-if="showData && scope === 'week'">
            <order-calendar-week-day
              :schedule-dates="calendarData[day.format('MM/DD/YYYY')] || []"
              :recurring-dates="[...recurringDates.weekly[day.day()]] || []"
              :day="day"
            ></order-calendar-week-day>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="day-detail-modal"
      size="xl"
      :title="modalData && modalData.day.format('MM/DD/YYYY')"
    >
      <div v-if="modalData">
        <order-calendar-full-day
          :schedule-dates="modalData.scheduleDates"
          :recurring-dates="modalData.recurringDates"
          :day="modalData.day"
        ></order-calendar-full-day>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import OrderCalendarFullDay from './OrderCalendarFullDay.vue'
import OrderCalendarMonthDay from './OrderCalendarMonthDay.vue'
import OrderCalendarWeekDay from './OrderCalendarWeekDay.vue'

export default {
  components: {
    OrderCalendarMonthDay,
    OrderCalendarFullDay,
    OrderCalendarWeekDay,
  },
  name: 'OrderCalendar',
  props: {
    calendarData: {
      type: Object,
      required: false,
    },
    recurringDates: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      anchorDate: moment(),
      today: moment(),
      currentDates: [[]],
      scope: 'week',
      updateDates: false,
      modalData: null,
      showData: true,
    }
  },
  methods: {
    openDay(day) {
      this.modalData = {
        scheduleDates: this.calendarData[day.format('MM/DD/YYYY')] || [],
        day,
        recurringDates: [...this.recurringDates.weekly[day.day()]] || [], // todo: add monthly recurring dates when necessary, also spread into this arr
      }
      this.$bvModal.show('day-detail-modal')
    },
    getRange(startDate, endDate, type) {
      let fromDate = moment(startDate)
      let toDate = moment(endDate)
      let diff = toDate.diff(fromDate, type)
      let range = []
      for (let i = 0; i < diff; i++) {
        range.push(moment(startDate).add(i, type))
      }
      return range
    },
    resetToToday() {
      this.anchorDate = this.today.clone()
      this.calculateDates()
    },
    calculateDates() {
      this.showData = false
      if (this.scope === 'month') {
        this.currentDates = this.setMonth(this.anchorDate)
      } else if (this.scope === 'week') {
        this.currentDates = this.setWeekView(this.anchorDate)
      } else {
        this.currentDates = [[]]
      }
      window.setTimeout(() => {
        this.showData = true
      })
    },
    toggleScope() {
      if (this.scope === 'month') {
        this.scope = 'week'
      } else {
        this.scope = 'month'
      }
      this.calculateDates()
    },
    setWeek(date) {
      let start = date.clone().startOf('isoWeek').subtract(1, 'day') // start of week is monday
      let end = start.clone().add(7, 'days')
      return this.getRange(start, end, 'days')
    },
    setWeekView(date) {
      let anchorDate = date.clone()
      let weeks = []
      weeks[0] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      weeks[1] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      return weeks
    },
    setMonth(date) {
      let anchorDate = date.clone().startOf('month')
      let weeks = []
      weeks[0] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      weeks[1] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      weeks[2] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      weeks[3] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      weeks[4] = this.setWeek(anchorDate)
      anchorDate.add(1, 'week')
      let firstDayNextWeek = anchorDate.clone().startOf('week')
      if (firstDayNextWeek.month() === date.month()) {
        weeks[5] = this.setWeek(anchorDate)
      }
      return weeks
    },
    nextDates() {
      if (this.scope === 'week') {
        this.anchorDate = this.anchorDate.add(7, 'days')
      }
      if (this.scope === 'month') {
        this.anchorDate = this.anchorDate.add(1, 'month')
      }
      this.calculateDates()
    },
    previousDates() {
      if (this.scope === 'week') {
        this.anchorDate.subtract(7, 'days')
      }
      if (this.scope === 'month') {
        this.anchorDate.subtract(1, 'month')
      }
      this.calculateDates()
    },
  },
  mounted() {
    // pull in order data and get onto calendar
    this.calculateDates()
  },
  computed: {
    // currentDates() {
    //   let date = this.anchorDate.clone()
    //   if (this.scope === 'month') {
    //     return this.setMonth(date)
    //   } else if (this.scope === 'week') {
    //     return [this.setWeek(date)]
    //   } else {
    //     return [[]]
    //   }
    // },
    displayMonth() {
      if (this.currentDates[0].length === 0) return ''
      let startWeek = _.first(this.currentDates)
      let startDate = _.first(startWeek)
      let endWeek = _.last(this.currentDates)
      let endDate = _.last(endWeek)

      if (this.scope === 'month') return this.anchorDate.format('MMMM YYYY')

      if (startDate.month() === endDate.month()) {
        return startDate.format('MMMM YYYY')
      } else {
        return (
          startDate.format('MMMM YYYY') + ' - ' + endDate.format('MMMM YYYY')
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-wrapper {
}

.calendar-header {
  border-bottom: 1px solid lightgray;
  padding-bottom: 12px;
  margin-bottom: 12px;
  position: relative;
}

.helper-buttons {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.display-month {
  font-size: 22px;
  font-weight: 500;
  vertical-align: middle;
}

.calendar-days {
  margin: 0px 12px;
}

.calendar-day {
  height: 200px;
  border: 1px solid lightgray;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: auto;

  // hide weekends on week view
  .week-view & {
    &:last-of-type,
    &:first-of-type {
      display: none;
    }
  }
}

.day-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.today {
  border: 2px solid steelblue;
}

.long-day {
  height: calc((100vh - 140px) / 2);
  // height: 400px;
}

.recurring-dates {
  font-size: 14px;
}
</style>
