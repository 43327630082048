<template>
  <div class="contractor-home">
    <h1>Contractor Home</h1>
    <h2>
      Buildings
    </h2>
    <div class="building-list" v-if="buildings">
        <contractor-building-list :buildings="buildings"></contractor-building-list>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { userStore } from '@/stores/userStore';
import ContractorBuildingList from '@/components/ContractorBuildingList';

export default {
  name: 'ContractorHome',
  components: { ContractorBuildingList },  
  inject: {
    userStore
  },
  data: function() {
    return {
        loaded: false,
        buildings: null
    }
  },
  methods: {
    getBuildings() {
      return axios.get('/services/buildings/contractor')
      .then((response) => {
        this.buildings = response.data.buildings;
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
          this.loaded = true;
      })
    },
  },
  mounted() {
      this.getBuildings()
  }
}
</script>

<style lang="scss" scoped>

.contractor-home {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 1200px;
  max-width: 100%;
  margin: 40px auto;
}

.building-link {
  cursor: pointer;
  color: #007bff;
}

</style>