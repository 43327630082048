<template>
  <div>
    <h3>Permitting</h3>
    <div class="order-permitting" v-if="order">
      <div class="row">
        <div class="col">
          <div class="permitting-workflow">
            <div class="permitting-info">
              <h5>Permitting Info</h5>
              <div class="row">
                <div class="col">
                  <div>County: {{ order.siteAddress.county }}</div>
                  <div>APN: {{ order.siteAddress.apn }}</div>
                </div>
                <div class="col">
                  Permit to be submitted by:
                  <strong>
                    {{
                      order.workflow.permitting.customerHandlingPermit
                        ? 'Customer'
                        : 'Metals Direct'
                    }}
                  </strong>
                  <b-button
                    size="sm"
                    variant="info"
                    @click="
                      changePermitHandling(
                        !order.workflow.permitting.customerHandlingPermit,
                      )
                    "
                  >
                    Change to
                    {{
                      order.workflow.permitting.customerHandlingPermit
                        ? 'Metals Direct'
                        : 'Customer'
                    }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="checklist mt-3">
              <h5>Checklist</h5>
              <permitting-checklist :order="order"></permitting-checklist>
            </div>
          </div>
          <div class="legacy-status mt-5">
            // We can calculate start date, end date, status based on the state
            of the checklist
            <br />
            // I don't think we need this anymore, but keeping around for now in
            case it's in use.<br />
            <status-display
              class="status"
              :order="order"
              department="permitting"
            ></status-display>
            <div>
              Started Date:
              {{ order.workflow.permitting.startedDate | dateTimeFilter }}
            </div>
            <div>
              Completed Date:
              {{ order.workflow.permitting.completedDate | dateTimeFilter }}
            </div>
          </div>
        </div>
        <div class="col">
          <notes
            :order="order"
            class="mt-4"
            department="PERMITTING"
            :notesFilter="[
              'ARCHITECTURE',
              'ENGINEERING',
              'SALES',
              'ADMIN',
              'PERMITTING',
            ]"
            :showEvents="true"
          ></notes>
          <files-display :order="order" department="permitting"></files-display>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import StatusDisplay from '@/components/StatusDisplay'
import FilesDisplay from '@/components/FilesDisplay'
import PermittingChecklist from '@/components/PermittingChecklist'
import Notes from '@/components/Notes'
export default {
  name: 'OrderPermittingPage',
  props: {},
  data: function () {
    return {
      order: null,
      submittingPermitHandling: false,
    }
  },
  components: { StatusDisplay, FilesDisplay, Notes, PermittingChecklist },
  methods: {
    changePermitHandling(newValue) {
      const data = {
        id: this.order.id,
        customerHandlingPermit: newValue,
      }
      this.submittingPermitHandling = true
      return axios
        .put('/services/orders/permit-handling', data)
        .then(response => {
          this.order.workflow.permitting.customerHandlingPermit =
            response.data.permitting.customerHandlingPermit
          this.$toasted.success(`Updated permit handling`, {
            duration: 2500,
          })
        })
        .catch(error => {
          console.log('error!! ', error)
          this.$toasted.error(`Error updating permit handling`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submittingPermitHandling = false
        })
    },
  },
  mounted() {
    this.order = this.$parent.order
  },
}
</script>

<style lang="scss" scoped></style>
