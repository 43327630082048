<template>
  <div v-if="!loading" class="week-day">
    <div class="multi-day-events mb-3">
      <div
        v-for="(date, index) in multiDayEvents"
        :key="index"
        :class="{
          'multi-day-event': date.multiDayEvent,
          'first-day': date.firstOfMultiDay,
          'last-day': date.lastOfMultiDay,
          'hide-day': date.hideDate,
          delivery: date.name.toLowerCase().match('delivery'),
          offsite: date.customer || date.name.toLowerCase().match('offsite'),
          inspection: date.name.toLowerCase().match('inspection'),
          holiday:
            date.name.toLowerCase().match('pto') ||
            date.name.toLowerCase().match('vacation') ||
            date.name.toLowerCase().match('holiday') ||
            date.name.toLowerCase().match('dentist') ||
            date.name.toLowerCase().match('doctor'),
          concrete: date.name.toLowerCase().match('concrete'),
          install:
            date.name.toLowerCase().match('framing') ||
            date.name.toLowerCase().match('sheeting') ||
            date.name.toLowerCase().match('insulation'),
        }"
        :style="{
          background: date.calendarColor,
          color: pickTextColor(date.calendarColor),
        }"
      >
        <span v-if="date.name" class="name">{{ date.name }}</span>
        <span v-if="date.order" class="name"> - {{ date.order.jobName }}</span>
        <span v-if="date.order" class="name">
          - {{ date.order.customer.name }}</span
        >
        <span v-if="date.assignedTo" class="name">
          - {{ date.assignedTo }}</span
        >
        <!-- consider adding this for dates not orders?  -->
        <!-- <span v-if="date.memo" class="name"> - {{ date.memo }}</span> -->
      </div>
    </div>
    <div class="daily-events mb-3">
      <div
        v-for="(date, index) in dailyEvents"
        :key="index"
        :class="{
          'daily-event': true,
          'vendor-delivery':
            date.recurringVendorDay &&
            date.name.toLowerCase().match('delivery'),
          'vendor-order':
            date.recurringVendorDay && date.name.toLowerCase().match('order'),
          delivery:
            !date.recurringVendorDay &&
            date.name.toLowerCase().match('delivery'),
          offsite: date.customer || date.name.toLowerCase().match('offsite'),
          inspection: date.name.toLowerCase().match('inspection'),
          holiday:
            date.name.toLowerCase().match('pto') ||
            date.name.toLowerCase().match('vacation') ||
            date.name.toLowerCase().match('holiday') ||
            date.name.toLowerCase().match('dentist') ||
            date.name.toLowerCase().match('doctor'),
          concrete: date.name.toLowerCase().match('concrete'),
          install:
            date.name.toLowerCase().match('framing') ||
            date.name.toLowerCase().match('sheeting') ||
            date.name.toLowerCase().match('insulation'),
        }"
        :style="{
          background: date.calendarColor,
          color: pickTextColor(date.calendarColor),
        }"
      >
        <span v-if="date.name" class="name">{{ date.name }}</span>
        <span v-if="date.icon" :class="`${date.icon} ml-1 icon`"></span>
      </div>
    </div>
    <div
      v-for="(scheduleDate, index) in sortedDates"
      :key="index"
      :class="{
        'hour-event': true,
        'vendor-delivery':
          scheduleDate.recurringVendorDay &&
          scheduleDate.name.toLowerCase().match('delivery'),
        'vendor-order':
          scheduleDate.recurringVendorDay &&
          scheduleDate.name.toLowerCase().match('order'),
        delivery:
          !scheduleDate.recurringVendorDay &&
          scheduleDate.name.toLowerCase().match('delivery'),
        offsite:
          scheduleDate.customer ||
          scheduleDate.name.toLowerCase().match('offsite'),
        inspection: scheduleDate.name.toLowerCase().match('inspection'),
        holiday:
          scheduleDate.name.toLowerCase().match('pto') ||
          scheduleDate.name.toLowerCase().match('vacation') ||
          scheduleDate.name.toLowerCase().match('dentist') ||
          scheduleDate.name.toLowerCase().match('holiday') ||
          scheduleDate.name.toLowerCase().match('doctor'),
        concrete: scheduleDate.name.toLowerCase().match('concrete'),
        install:
          scheduleDate.name.toLowerCase().match('framing') ||
          scheduleDate.name.toLowerCase().match('sheeting') ||
          scheduleDate.name.toLowerCase().match('insulation'),
      }"
      :style="{
        background: scheduleDate.calendarColor,
        color: pickTextColor(scheduleDate.calendarColor),
      }"
    >
      <div class="time">
        <strong>{{ scheduleDate.dateTime.format('h:mm A') }}</strong> -
        {{ scheduleDate.name }}
        <span v-if="scheduleDate.order"> ({{ scheduleDate.order.type }}) </span>
      </div>
      <div v-if="scheduleDate.order">
        {{ scheduleDate.order.jobName }} -
        {{ scheduleDate.order.customer.name }}
      </div>
      <div
        v-if="
          scheduleDate.order && (scheduleDate.assignedTo || scheduleDate.memo)
        "
      >
        {{ scheduleDate.assignedTo }} -
        {{ scheduleDate.memo }}
      </div>
      <div v-if="scheduleDate.customer">{{ scheduleDate.customer.name }}</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { byDateTime, pickTextColor } from '../utils'
export default {
  name: 'OrderCalendarWeekDay',
  props: {
    day: {
      type: Object,
      required: true,
    },
    scheduleDates: {
      type: Array,
      default: () => {
        return []
      },
    },
    recurringDates: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data: () => {
    return {
      loading: true,
      pickTextColor,
    }
  },
  mounted() {
    this.loading = false
  },
  computed: {
    multiDayEvents() {
      let multiDayEvents = []
      _.each(this.scheduleDates, date => {
        if (date.multiDayEvent) {
          multiDayEvents.push(date)
        }
      })
      return _.sortBy(multiDayEvents, 'name')
    },
    dailyEvents() {
      let dates = []
      let dailyEvents = []

      _.each(this.scheduleDates, date => {
        if (date.allDayEvent && !date.multiDayEvent) {
          dailyEvents.push(date)
        }
      })

      _.each(this.recurringDates, rd => {
        if (rd.time) {
          let hours = parseInt(rd.time.split(':')[0])
          let minutes = parseInt(rd.time.split(':')[1])

          let day = this.day.clone().set('hour', hours).set('minute', minutes)
          dates.push({
            name: rd.name,
            dateTime: day,
            recurringVendorDay: true,
            // dateTime: day.set('hour', hour).set('minutes', minutes),
          })
          // debugger
        } else {
          dailyEvents.push(rd)
        }
      })

      return dailyEvents.sort(byDateTime)
    },
    sortedDates() {
      let dates = []
      let dailyEvents = []
      let multiDayEvents = []
      _.each(this.scheduleDates, date => {
        if (date.allDayEvent) {
          dailyEvents.push(date)
        } else if (date.multiDayEvent) {
          multiDayEvents.push(date)
        } else {
          dates.push(date)
        }
      })

      _.each(this.recurringDates, rd => {
        if (rd.time) {
          let hours = parseInt(rd.time.split(':')[0])
          let minutes = parseInt(rd.time.split(':')[1])

          let day = this.day.clone().set('hour', hours).set('minute', minutes)
          dates.push({
            name: rd.name,
            dateTime: day,
            recurringVendorDay: true,
            // dateTime: day.set('hour', hour).set('minutes', minutes),
          })
          // debugger
        } else {
          dailyEvents.push(rd)
        }
      })

      return dates.sort(function compare(a, b) {
        var dateA = new Date(a.dateTime)
        var dateB = new Date(b.dateTime)
        return dateA - dateB
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';

.week-day {
  color: black;
}
.daily-event {
  font-size: 14px;
  border-radius: 6px;
  background: rgb(240, 240, 240);
  padding: 4px 6px;
  margin: 2px;
  display: inline-block;
}

.multi-day-event {
  font-size: 14px;
  background: rgb(240, 240, 240);
  padding: 4px 6px;
  margin: 2px;

  margin-left: -15px;
  margin-right: -15px;
}
.first-day {
  margin-left: 4px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.last-day {
  margin-right: 4px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.name {
  vertical-align: middle;
}
.icon {
  vertical-align: middle;
}
.hide-day {
  opacity: 0;
}

.hour-event {
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 6px;
  margin: 3px 0px;
  background: rgb(240, 240, 240);
}

.vendor-delivery {
  background-color: $vendor-delivery-color;
}
.vendor-order {
  background-color: $vendor-delivery-color;
}

.delivery {
  background-color: $delivery-color;
  color: white;
}

.offsite {
  background-color: $offsite-color;
}

.holiday {
  background-color: $holiday-color;
}

.concrete {
  background-color: $concrete-color;
}

.install {
  background-color: $install-color;
  color: white;
}

.inspection {
  background-color: $inspection-color;
}

.time {
  // border-bottom: 1px solid lightgray;
}
</style>
