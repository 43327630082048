<template>
  <div class="install-estimate">
    <div class="title">Estimated Time To Install</div>
    <div><span class="amount">Total Cost</span> / 7,000 = # of Days</div>
    <div>
      <span class="amount">{{ order.totalAmount | currencyFilter }}</span> /
      7,000 = {{ numDays }} Days
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'InstallEstimate',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    numDays() {
      return _.round(this.order.totalAmount / 7000, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  text-decoration: underline;
}
.amount {
  display: inline-block;
  width: 90px;
}
</style>
