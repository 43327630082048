<template>
  <div class="notes">
    <div v-for="(note, index) in list.slice().reverse()" :key="index">
      <div
        class="note-wrapper"
        v-if="note.text && !note.removed"
        v-bind:class="{
          sales: note.department === 'SALES',
          architecture: note.department === 'ARCHICTECTURE',
          engineering: note.department === 'ENGINEERING',
          permitting: note.department === 'PERMITTING',
          manufacturing: note.department === 'MANUFACTURING',
          install: note.department === 'INSTALL',
          admin: note.department === 'ADMIN',
        }"
      >
        <div class="note-date">{{ note.timestamp | dateTimeFilter }}</div>
        <div class="note-from">
          <span v-if="true">{{ note.user }}</span>
          <span v-if="false">{{ note.user.split('@')[0] }}</span>
          <span v-if="false">({{ note.department }})</span>
          <span
            v-if="userStore.user.email === note.user"
            style="cursor: pointer; color: red; font-weight: bold"
            class="nc-icon nc-simple-remove ml-1"
            @click="confirmRemoveNote(note)"
          ></span>
          <span></span>
        </div>
        <div class="note-text">
          {{ note.text }}
        </div>
      </div>
      <div v-if="note.name">
        <event :event="note"></event>
      </div>
    </div>
  </div>
</template>

<script>
import Event from '@/components/Event'
export default {
  name: 'NotesList',
  components: { Event },
  inject: ['userStore'],
  props: {
    notes: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: false,
    },
    confirmRemoveNote: {
      type: Function,
      default: () => {},
    },
  },
  data: function () {
    return {}
  },
  computed: {
    list() {
      const events = this.events || []
      const notes = this.notes || []
      return [...notes, ...events].sort(function compare(a, b) {
        var dateA = new Date(a.timestamp)
        var dateB = new Date(b.timestamp)
        return dateA - dateB
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.notes {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 12px;
}

.note-wrapper {
  padding: 6px;
  position: relative;
}

.note-from {
  font-size: 13px;
  font-weight: 700;
}

.note-date {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  right: 0px;
  top: 6px;
}

.sales {
  color: $sales-color;
}

.architecture {
  color: $architecture-color;
}

.engineering {
  color: $engineering-color;
}

.permitting {
  color: $permitting-color;
}

.manufacturing {
  color: $manufacturing-color;
}

.install {
  color: $install-color;
}

.admin {
  color: $admin-color;
}
</style>
