<template>
  <div class="customers page-wrapper">
    <h1 class="page-h1">Add Customer</h1>
    <form @submit.prevent="showConfirmCreate">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <customer-form-fields
              :submitting="submitting"
              :customer="customer"
            ></customer-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Add Customer</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmAddCustomer"
      :title="`Add Customer - ${customer.name}`"
      hide-footer
    >
      <p>Create new customer: {{ customer.name }}?</p>
      <p>Customer Type: {{ customer.type }}<br /></p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmAddCustomer')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitCreateCustomer"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import { customerTypes } from '@/components/customerInputOptions'
import CustomerFormFields from '@/components/CustomerFormFields'

export default {
  name: 'AddCustomerPage',
  components: { CustomerFormFields },
  inject: {
    userStore,
  },
  props: {},
  data: function () {
    return {
      customer: {
        name: '',
        email: '',
        vip: false,
        type: null,
        address: {
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        contacts: [],
        locations: [],
      },
      customerTypes,
      submitting: false,
    }
  },
  methods: {
    clearCustomer() {
      this.customer = {
        name: '',
        email: '',
        phone: '',
        vip: false,
        type: null,
        address: {
          street1: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          country: '',
        },
        contacts: [],
        locations: [],
      }
    },
    showConfirmCreate() {
      this.$bvModal.show('confirmAddCustomer')
    },
    submitCreateCustomer() {
      this.submitting = true
      const data = _.clone(this.customer)
      return axios
        .post('/services/customers', data)
        .then(response => {
          this.$bvModal.hide('confirmAddCustomer')
          this.clearCustomer()
          return this.$toasted.success('Customer Added', {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(err => {
          return this.$toasted.error(
            'Error creating customer: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 900px;
  max-width: 90%;
}
</style>
