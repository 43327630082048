<template>
  <img
    class="vip-icon"
    src="../assets/star-vip-icon.svg"
    v-b-tooltip.hover
    title="This customer should be prioritized"
  />
</template>

<script>
export default {}
</script>

<style scoped>
.vip-icon {
  width: 34px;
  vertical-align: middle;
}
</style>
