<template>
  <div class="sales-page">
    <div class="page-one">
      <div class="page-one-header">
        <div class="logo">
          <img alt="MD logo" src="../assets/md-logo.png" />
          <div class="address">
            6771 Eastside Rd Redding, CA 96001<br />
            (530)605-1931 PH (530)605-1938 Fax <br />
            www.metalsdirect.com <br />
          </div>
        </div>
      </div>
      <h1 class="sales-page-title text-center">Thank You</h1>
      <div class="sales-page-header text-center">This is not a contract</div>
      <div class="sales-page-subtitle text-center">
        This is to be used for informational purposes only
      </div>
      <div class="page-content">
        <div class="mt-3">
          <div class="mt-3">
            We would like to take a moment to thank you for your recent
            purchase. We greatly appreciate your business and the opportunity to
            assist you.<br /><br />
            Your satisfaction is the greatest recognition we could have! Please
            contact us if you have any questions or would like to make any
            changes on your purchase.<br /><br />
            This Page will help you at a glance know what your payment schedule
            is and who the payment going to. If you are in need of further
            information you can always refer to your copies of the act payment
            schedules, or call customer care and they will be more than happy to
            assist you with any of your questions.
          </div>
          <div class="mt-5 text-center">
            ________________________________________
          </div>
          <div class="mt-1 text-center disclosure">
            Owner will pay total price of (includes any sales tax).
          </div>
          <div class="mt-5 schedule">
            <div class="lines">
              <div class="sales-page-line">_______________________________</div>
              <div class="sales-page-line">_______________________________</div>
              <div class="sales-page-line">_______________________________</div>
              <div class="sales-page-line">_______________________________</div>
              <div class="sales-page-line">_______________________________</div>
              <div class="sales-page-line">_______________________________</div>
              <div class="sales-page-line">_______________________________</div>
            </div>
            <div class="statements">
              <div class="sales-page-li">
                is to be used as a non-refundable deposit paid directly to MDI
                towards engineering and other preliminary work associated with
                project.
              </div>
              <div class="sales-page-li">is paid upon grading.</div>
              <div class="sales-page-li">
                is paid upon completion of the concrete.
              </div>
              <div class="sales-page-li">
                is paid upon delivery of building materials.
              </div>
              <div class="sales-page-li">
                is paid upon completion of framing.
              </div>
              <div class="sales-page-li">
                is paid upon completion of the building.
              </div>
              <div class="sales-page-li">
                is paid upon completion of electrical.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesPage',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      today: new Date(),
    }
  },
  computed: {
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.sales-page {
  position: relative;
}

.page-content {
  text-align: left;
}

.address {
  padding: 18px;
  font-size: 18px;
}

.logo {
  text-align: left;
}

.sales-page-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
}

.lines,
.statements {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.lines {
  text-align: right;
}

.disclosure {
  font-size: 26px;
}

.sales-page-line {
  margin-bottom: 36px;
  &:first-of-type {
    margin-top: 60px;
  }
}
.sales-page-li {
  margin-bottom: 36px;
}

@media print {
  .page-two,
  .page-three {
    page-break-before: always;
  }

  .sales-page {
    padding: 30px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }

  .title {
    font-size: 42px;
  }

  .sales-page-line {
    font-size: 22px;
  }
  .sales-page-li {
    font-size: 22px;
  }
}
</style>
