<template>
  <div class="quote-letter">
    <div class="letter-header">
      <img alt="MD logo" src="../assets/md-logo.png" />
      <div class="address">
        6771 Eastside Rd Redding, CA 96001<br />
        (530)605-1931 PH (530)605-1938 Fax <br />
        www.metalsdirectinc.com <br />
      </div>
    </div>
    <div class="page-content">
      <div class="letter-intro mt-1">
        <div class="customer-name">{{ customerName }}</div>
        <div class="date">{{ building.createdAt | dateFilter }}</div>
        <div class="intro-msg">
          <p class="mt-5">
            Thank you for the opportunity to give you a quote on your metal
            building needs. I have compiled a price for you based on the
            specifics that we discussed.
          </p>
          <p>
            As a company we stand behind our product with a 40 year material
            warranty. Our Metal sheets have a ceramic baked coating that insures
            against fading, as well as being considered "cool roof technology"
            Our metal is designed to reflect the suns heat and emit absorbed
            radiation back into the atmosphere. We strive to provide you with
            more color options to fit your cosmetic surroundings while allowing
            you the benefits of the "cool roof technology".
          </p>
          <p>
            Framework is designed to be the strongest of its kind. Most
            framework is done in HSS structural tubing pre-welded at our
            facility. It will be designed per your APN number and certified by a
            licensed engineer for you county requirements. Our 14 gauge metal
            used for framework is visibly thicker than almost all competitors'
            products.
          </p>
          <p>
            All we need to get your project started is 10% of the building cost.
          </p>
        </div>
      </div>
      <div class="letter-content">
        <b-form-checkbox v-model="showNickname" class="hide">
          show nickname
        </b-form-checkbox>
        <hr />
        <h6 class="text-center">
          Building Specifications
          <span v-if="building.nickname && showNickname">
            - {{ building.nickname }}
          </span>
        </h6>
        <hr />
        <div class="row">
          <div class="col">
            <div class="label">Building Type</div>
            <div class="hide-screen">{{ type }}</div>
            <b-form-input
              size="sm"
              v-model="type"
              class="hide input"
            ></b-form-input>
            <div class="label">Truss</div>
            <div>{{ building.truss.type }}</div>
            <div class="label">Kickers</div>
            <div>{{ building.truss.kickers ? 'Yes' : 'No' }}</div>
          </div>
          <div class="col">
            <span class="label">Width: </span
            ><span class="inline-hide-screen">{{ width }}</span
            ><b-form-input
              class="hide inline-input"
              size="sm"
              v-model="width"
            ></b-form-input
            ><br />
            <span class="label">Length: </span
            ><span class="inline-hide-screen">{{ length }}</span
            ><b-form-input
              class="hide inline-input"
              size="sm"
              v-model="length"
            ></b-form-input
            ><br />
            <span class="label">Height: </span
            ><span class="inline-hide-screen">{{ height }}</span
            ><b-form-input
              class="hide inline-input"
              size="sm"
              v-model="height"
            ></b-form-input
            ><br />
            <span class="label">Centers: </span
            ><span>{{ building.walls.centers }}</span
            ><br />
            <span class="label">Anchors: </span
            ><span>{{ building.foundation.anchors }}</span
            ><br />
          </div>
          <div class="col">
            <div class="label">Roof</div>
            <div>{{ building.roof.design }}</div>
            <div class="label">Walls</div>
            <div>{{ building.walls.design }}</div>
          </div>
        </div>
        <div
          class="row"
          v-if="
            building.foundation.anchors === 'earth' &&
            (county.toLowerCase().match('shasta') ||
              county.toLowerCase().match('siskiyou'))
          "
        >
          <div class="col disclaimer">
            Note: {{ county
            }}{{ county.toLowerCase().match('county') ? '' : ' County' }}
            requires a $2,000 pull test for Earth Anchors.
          </div>
        </div>
        <div class="row">
          <div class="col">
            <hr />
            <h6>Accessories</h6>
            <hr />
            <span class="label">Engineering: </span
            ><span>{{ building.engineering ? 'Yes' : 'No' }}</span
            ><br />
            <span class="label">Skylights: </span
            ><span>{{ building.skyLights }}</span
            ><br />
            <span class="label">Rollup Doors: </span
            ><span>{{ getTotalDoors(rollDoors) }}</span
            ><br />
            <span class="label">Windows: </span
            ><span class="inline-hide-screen">{{ totalWindows }}</span
            ><b-form-input
              class="hide inline-input"
              size="sm"
              v-model="totalWindows"
            ></b-form-input
            ><br />
            <span class="label">Insulation: </span><span>{{ insulation }}</span
            ><br />
            <span class="label">Man Doors: </span
            ><span class="inline-hide-screen">{{ manDoors }}</span
            ><b-form-input
              class="hide inline-input"
              size="sm"
              v-model="manDoors"
            ></b-form-input
            ><br />
          </div>
          <div class="col">
            <hr />
            <h6>Carport &amp; RV Accessories</h6>
            <hr />
            <span class="label">Side Kit: </span
            ><span>{{ building.carportDetail.sideKitQuantity }}</span
            ><br />
            <span class="label">End Kit: </span
            ><span>{{ building.carportDetail.endKit ? 'Yes' : 'No' }}</span
            ><br />
            <span class="label">Front Kit: </span
            ><span>{{ building.carportDetail.frontKit ? 'Yes' : 'No' }}</span
            ><br />
            <span class="label">Special Add-on: </span>
            <span>
              <b-form-input
                size="sm"
                v-model="specialAddOn"
                class="hide input"
              ></b-form-input>
            </span>
            <span class="inline-hide-screen">{{ specialAddOn }}</span
            ><br />
            <span class="label">Gable Front: </span
            ><span>{{ building.carportDetail.gableFront ? 'Yes' : 'No' }}</span
            ><br />
            <span class="label">Gable End: </span
            ><span>{{ building.carportDetail.gableEnd ? 'Yes' : 'No' }}</span
            ><br />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <b-button
              class="hide"
              size="sm"
              variant="secondary"
              @click="unselectAllAddOns"
            >
              Unselect All
            </b-button>
            <div v-for="(item, index) in addOnItems" :key="index">
              <div v-if="item.total" :class="{ row: true, hide: !item.show }">
                <div class="label col col-3">
                  <b-form-checkbox
                    v-model="item.show"
                    class="hide"
                  ></b-form-checkbox
                  >{{ item.name }}
                </div>
                <div class="col col-5">
                  {{
                    item.totalSqft || item.totalSqft === 0
                      ? item.totalSqft + 'sqft @' + item.perSqft + '/sqft'
                      : item.totalLft
                      ? item.totalLft + 'lft @' + item.perLft + '/lft'
                      : item.numDays
                      ? item.numDays + ' days @ ' + item.pricePerDay + '/day'
                      : item.details
                  }}
                </div>
                <div class="col col-3">
                  {{ item.total | currencyFilter }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="(item, index) in manualAddOnItems" :key="index">
              <div :class="{ row: true, hide: !item.show }">
                <div class="label col col-3">
                  <b-form-checkbox
                    v-model="item.show"
                    class="hide"
                  ></b-form-checkbox
                  >{{ item.name }}
                </div>
                <div class="col col-5">
                  {{ item.description }}
                </div>
                <div class="col col-3">
                  {{ item.price | currencyFilter }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="(item, index) in installAddOnItems" :key="index">
              <div :class="{ row: true, hide: !item.show }">
                <div class="label col col-3">
                  <b-form-checkbox
                    v-model="item.show"
                    class="hide"
                  ></b-form-checkbox>
                  {{ item.name }}
                </div>
                <div class="col col-5">
                  {{ item.description }}
                </div>
                <div class="col col-3">
                  {{ item.price | currencyFilter }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3" id="display-section-openings">
          <div class="label">Openings</div>
          <div class="row" id="display-section-openings">
            <div class="col">
              <div class="display-subsection">
                <div class="display-subsection-title">
                  Shed Doors ({{ getTotalDoors(shedDoors) }}):
                </div>
                <div v-for="(shedDoor, index) in shedDoors" :key="index">
                  <div v-if="shedDoor.size">
                    {{ index + 1 }}. {{ shedDoor.size }}
                    {{ shedDoor.model }} ({{ shedDoor.quantity }})
                    <span v-if="!shedDoor.includeDoor">(Opening Only)</span>
                  </div>
                  <div v-if="!shedDoor.size">
                    {{ index + 1 }}. {{ shedDoor.width.ft }}'{{
                      shedDoor.width.in
                    }}x{{ shedDoor.height.ft }}'{{ shedDoor.height.in }}
                    {{ shedDoor.model }} ({{ shedDoor.quantity }})
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="display-subsection">
                <div class="display-subsection-title">
                  Roll Doors ({{ getTotalDoors(rollDoors) }}):
                </div>
                <div v-for="(rollDoor, index) in rollDoors" :key="index">
                  <div v-if="typeof rollDoor.width === 'number'">
                    {{ index + 1 }}. {{ rollDoor.width }}x{{ rollDoor.height }}
                    {{ rollDoor.model }} ({{ rollDoor.quantity }})
                    <span v-if="!rollDoor.includeDoor">(Opening Only)</span>
                  </div>
                  <div v-if="typeof rollDoor.width === 'object'">
                    {{ index + 1 }}. {{ rollDoor.width.ft }}'{{
                      rollDoor.width.in
                    }}x{{ rollDoor.height.ft }}'{{ rollDoor.height.in }}
                    {{ rollDoor.model || rollDoor.source }} ({{
                      rollDoor.quantity
                    }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col" :class="{ hide: !showInstall }">
            <div class="label">
              <b-form-checkbox
                v-model="showInstall"
                class="hide"
              ></b-form-checkbox
              >Materials &amp; Install By Metals Direct
            </div>
            <div>{{ building.price.kitAndInstall.total | currencyFilter }}</div>
            <div :class="{ hide: !showInstallPerSqft }">
              ({{
                (building.price.kitAndInstall.total / totalSqft)
                  | currencyFilter
              }}/sqft)
              <div class="label hide">
                <b-form-checkbox v-model="showInstallPerSqft"></b-form-checkbox
                >Show Price Per Sqft
              </div>
            </div>
          </div>
          <div class="col" :class="{ hide: !showConcrete }">
            <div class="label">
              <b-form-checkbox
                v-model="showConcrete"
                class="hide"
              ></b-form-checkbox
              >Concrete Estimate By Metals Direct
            </div>
            <div v-if="building.foundation.price.max">
              {{ building.foundation.price.min | currencyFilter }} -
              {{ building.foundation.price.max | currencyFilter }}
              <div :class="{ hide: !showConcretePerSqft }">
                ({{
                  (building.foundation.price.min / totalSqft) | currencyFilter
                }}
                -
                {{
                  (building.foundation.price.max / totalSqft) | currencyFilter
                }}/sqft)
              </div>
            </div>
            <div v-if="!building.foundation.price.max">
              {{ building.foundation.price.total | currencyFilter }}
              <div :class="{ hide: !showConcretePerSqft }">
                ({{
                  (building.foundation.price.total / totalSqft)
                    | currencyFilter
                }}/sqft)
              </div>
            </div>
            <div class="label hide">
              <b-form-checkbox v-model="showConcretePerSqft"></b-form-checkbox
              >Show Price Per Sqft
            </div>
            <div class="disclaimer">Pending Site Inspection</div>
          </div>
          <div class="col" :class="{ hide: !showKit }">
            <div class="label">
              <b-form-checkbox v-model="showKit" class="hide"></b-form-checkbox
              >Do It Yourself Kit Delivered
            </div>
            <div>{{ building.price.kit.total | currencyFilter }}</div>
            <div :class="{ hide: !showKitPerSqft }">
              ({{
                (building.price.kit.total / totalSqft) | currencyFilter
              }}/sqft)
            </div>
            <div class="label hide">
              <b-form-checkbox v-model="showKitPerSqft"></b-form-checkbox>Show
              Price Per Sqft
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="thank-you">
            Thank you,<br />
            {{ userStore.user.fullName }}
          </div>
        </div>
        <div class="col">
          <div class="disclaimer">
            FYI! Quotes are subject to change and are only valid for 48 hours!
          </div>
        </div>
      </div>
      <b-form-checkbox v-model="showSignature" class="hide-on-print">
        Show Signature
      </b-form-checkbox>
      <div class="signature" v-if="showSignature">
        <span>Buyer: ____________________________________________</span>
        <span class="ml-5">Date:______________________________</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'QuoteLetter',
  inject: ['userStore'],
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    let extraManDoors = 0
    let extraRollDoors = 0
    let extraShedDoors = 0
    let extraWindows = 0
    _.each(this.building.openings, opening => {
      if (opening.type === 'Man Door') {
        extraManDoors += parseInt(opening.quantity)
      }
      if (opening.type === 'Window') {
        extraWindows += parseInt(opening.quantity)
      }
      if (opening.type === 'Roll Door') {
        extraRollDoors += parseInt(opening.quantity)
      }
      if (opening.type === 'Shed Door') {
        extraShedDoors += parseInt(opening.quantity)
      }
    })
    return {
      showInstall: this.building.install.doInstall ? true : false,
      showInstallPerSqft: false,
      showConcrete: this.building.foundation.doConcrete ? true : false,
      showConcretePerSqft: false,
      showNickname: true,
      showSignature: true,
      totalWindows:
        this.building.windows.withGrid +
        this.building.windows.withoutGrid +
        extraWindows,
      rollDoors: [
        ...this.building.rollDoors,
        ...this.building.openings.filter(opening => {
          return opening.type == 'Roll Door'
        }),
      ],
      shedDoors: [
        ...this.building.shedDoors,
        ...this.building.openings.filter(opening => {
          return opening.type == 'Shed Door'
        }),
      ],
      height: this.building.dimensions.height,
      width: this.building.dimensions.width,
      length: this.building.dimensions.length,
      type: this.building.type,
      specialAddOn: '',
      manDoors: this.building.manDoors.quantity + extraManDoors,
      showKit: this.building.install.doInstall ? false : true,
      showKitPerSqft: false,
      installAddOnItems: this.building.installAddOnItems.map(item => {
        return { show: true, ...item }
      }),
      addOnItems: this.building.addOnItems.map(item => {
        return { show: true, ...item }
      }),
      manualAddOnItems: this.building.manualAddOnItems.map(item => {
        return { show: true, ...item }
      }),
    }
  },
  mounted() {},
  methods: {
    getTotalDoors(list) {
      if (!list) return 0
      let doors = 0
      _.each(list, li => {
        doors += parseInt(li.quantity)
      })
      return doors
    },
    unselectAllAddOns() {
      _.each(this.addOnItems, addOn => {
        addOn.show = false
      })
      _.each(this.installAddOnItems, addOn => {
        addOn.show = false
      })
      _.each(this.manualAddOnItems, addOn => {
        addOn.show = false
      })
      // this.$forceUpdate()
    },
  },
  watch: {
    building: {
      handler(newValue, oldValue) {
        // TODO: Also refresh add-on items, ruined reactivity because we made a copy in data() ):
        // also need to refresh everything in contractpage.vue
        this.height = this.building.dimensions.height
        this.width = this.building.dimensions.width
        this.length = this.building.dimensions.length
        this.type = this.building.type
        let extraManDoors = 0
        let extraRollDoors = 0
        let extraShedDoors = 0
        let extraWindows = 0
        _.each(this.building.openings, opening => {
          if (opening.type === 'Man Door') {
            extraManDoors += parseInt(opening.quantity)
          }
          if (opening.type === 'Window') {
            extraWindows += parseInt(opening.quantity)
          }
          if (opening.type === 'Roll Door') {
            extraRollDoors += parseInt(opening.quantity)
          }
          if (opening.type === 'Shed Door') {
            extraShedDoors += parseInt(opening.quantity)
          }
        })

        this.totalWindows =
          this.building.windows.withGrid +
          this.building.windows.withoutGrid +
          extraWindows
        this.manDoors = this.building.manDoors.quantity + extraManDoors
        this.rollDoors = [
          ...this.building.rollDoors,
          ...this.building.openings.filter(opening => {
            return opening.type == 'Roll Door'
          }),
        ]
        this.shedDoors = [
          ...this.building.shedDoors,
          ...this.building.openings.filter(opening => {
            return opening.type == 'Shed Door'
          }),
        ]
      },
      deep: true,
    },
  },
  computed: {
    totalSqft() {
      return this.building.dimensions.sqft + this.building.mezzanine.sqft || 0
    },
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
    insulation() {
      let insulation
      if (
        this.building.roof.insulation.type === 'None' &&
        this.building.walls.insulation.type === 'None'
      ) {
        insulation = 'No'
      } else {
        insulation = 'Yes'
      }
      return insulation
    },
    county() {
      return this.building.site.address.county || ''
    },
  },
}
</script>

<style scoped>
.letter-intro {
  position: relative;
  padding-top: 24px;
}
.customer-name {
  position: absolute;
  left: 0;
}
.date {
  position: absolute;
  right: 0;
}

.intro-msg {
  text-align: left;
  font-size: 14px;
}

.letter-content {
  text-align: left;
  font-size: 14px;
}

.label {
  font-weight: bold;
}

.disclaimer {
  color: red;
  font-weight: bold;
}

.thank-you {
  text-align: left;
}

.custom-control {
  display: inline-block;
  padding-right: 0;
}

.inline-hide-screen,
.hide-screen {
  display: none;
}
.input {
  width: 65%;
}
.inline-input {
  display: inline-block;
  width: 25%;
}
.signature {
  margin-top: 100px;
  text-align: center;
}
@media print {
  .inline-hide-screen {
    display: inline;
  }
  .hide-screen {
    display: block;
  }
  .quote-letter {
    padding: 0px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }
  .hide {
    display: none;
  }
  .hide-on-print {
    display: none;
  }
}
</style>
