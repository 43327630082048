<template>
  <div>
    <div class="loading" v-if="loading">
      <Circle-Loader :size="36" class="mt-3"></Circle-Loader>
    </div>
    <div class="payments" v-if="!loading && mode == 'checklist'">
      <order-payments-checklist :order="order"></order-payments-checklist>
    </div>
    <div class="payments" v-if="!loading & (mode != 'checklist')">
      <div v-if="!order.payments.length">
        Error: Order must have a Product ID or Amount to show Payments
      </div>
      <div v-if="order.payments.length">
        <b-table
          :tbody-tr-class="rowClass"
          :items="paymentList"
          :fields="fields"
          @row-clicked="openPaymentDetail"
        >
          <template #cell(expectedAmount)="row">
            {{ row.item.expectedAmount | currencyFilter
            }}<span
              v-if="row.item.name === 'Concrete'"
              class="ml-1 nc-icon nc-settings-tool-66 edit-expected-button"
              @click.stop="confirmEditExpected(row.item)"
            ></span>
          </template>
          <template #cell(actualAmount)="row">
            <span v-if="row.item.actualAmount || row.item.actualAmount == 0">
              {{ row.item.actualAmount | currencyFilter }}
            </span>
            <span v-if="!row.item.actualAmount && row.item.actualAmount != 0">
              -
            </span>
          </template>
          <template #cell(timestamp)="row">
            <span v-if="row.item.timestamp">
              {{ row.item.timestamp | shortFullDateFilter }}
            </span>
            <span v-if="!row.item.timestamp && row.item.name != 'Total'">
              <b-button
                size="sm"
                variant="info"
                @click="confirmRecordPayment(row.item)"
                >Record Payment</b-button
              >
            </span>
          </template>
        </b-table>
        <b-button
          variant="success"
          size="sm"
          @click="$bvModal.show('confirmAddPayment')"
          >+ Additional Payment</b-button
        >
      </div>
      <b-modal title="Record Payment" id="confirmRecordPayment" hide-footer>
        <form v-if="currentPayment" @submit.prevent="savePayment">
          <div class="mb-3">
            Record Payment for {{ currentPayment.name }}?<br />
            <label class="mt-3" for="expectedAmount">Expected Amount:</label>
            <div class="row">
              <div class="col">
                <b-input-group prepend="$">
                  <b-form-input
                    id="expectedAmount"
                    v-model="currentPayment.expectedAmount"
                    placeholder="0.00"
                    type="text"
                    :required="true"
                    :disabled="true"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <label class="mt-3" for="newPaymentAmount">Payment Amount:</label>
            <div class="row">
              <div class="col col-9">
                <b-input-group prepend="$">
                  <b-form-input
                    id="newPaymentAmount"
                    v-model="newPaymentAmount"
                    placeholder="0.00"
                    type="text"
                    :required="true"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col col-3">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="newPaymentAmount = currentPayment.expectedAmount"
                  :disabled="submittingRecordPayment"
                  >Autofill</b-button
                >
              </div>
            </div>
          </div>
          <label for="newPaymentDate" class="mt-1">Date Received:</label>
          <b-datepicker
            id="newPaymentDate"
            v-model="newPaymentDate"
            type="text"
            :required="true"
          ></b-datepicker>
          <label for="payment-memo" class="mt-2">Memo</label>
          <b-textarea
            id="payment-memo"
            v-model="newPaymentMemo"
            :required="false"
          ></b-textarea>
          <div class="modal-ctas mt-4">
            <b-button
              variant="primary"
              :disabled="submittingRecordPayment"
              type="submit"
            >
              <span v-if="submittingRecordPayment">Saving..</span>
              <span v-if="!submittingRecordPayment">Save</span>
            </b-button>
            <b-button
              variant="secondary"
              type="button"
              @click="$bvModal.hide('confirmRecordPayment')"
              :disabled="submittingRecordPayment"
              class="ml-2"
              >Cancel</b-button
            >
            <b-button
              variant="danger"
              type="button"
              :disabled="submittingRecordPayment"
              class="ml-2 remove-payment-btn"
              @click="confirmRemovePayment"
              >Remove Payment
            </b-button>
          </div>
        </form>
      </b-modal>

      <b-modal title="Add Payment" hide-footer id="confirmAddPayment">
        Add Additional Payment to list?
        <div class="modal-ctas mt-4">
          <b-button
            variant="primary"
            @click="addPaymentToSchedule"
            :disabled="submittingAddPayment"
          >
            <span v-if="submittingAddPayment">Confirming..</span>
            <span v-if="!submittingAddPayment">Confirm</span>
          </b-button>
          <b-button
            variant="secondary"
            @click="$bvModal.hide('confirmAddPayment')"
            :disabled="submittingAddPayment"
            class="ml-2"
            >Cancel</b-button
          >
        </div>
      </b-modal>

      <b-modal title="Delete Payment" id="confirmDeletePayment" hide-footer>
        <div class="" v-if="currentPayment">
          <div>
            <strong>{{ currentPayment.name }}</strong>
          </div>

          Are you sure you want to delete this payment? This will un-record the
          payment, but will not remove the expected payment from the list.
          <div class="detail-modal-ctas mt-5">
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('confirmDeletePayment')"
              :disabled="submittingDeletePayment"
              >Cancel</b-button
            >
            <b-button
              variant="danger"
              @click="deletePayment"
              :disabled="submittingDeletePayment"
            >
              <span v-if="submittingDeletePayment">Deleting..</span>
              <span v-if="!submittingDeletePayment">Delete Payment</span>
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal title="Remove Payment" id="confirmRemovePayment" hide-footer>
        <div class="" v-if="currentPayment">
          <div>
            <strong>{{ currentPayment.name }}</strong>
          </div>

          Are you sure you want to remove this payment from the list? This
          cannot be undone.
          <div class="detail-modal-ctas mt-5">
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('confirmRemovePayment')"
              :disabled="submittingRemovePayment"
              >Cancel</b-button
            >
            <b-button
              variant="danger"
              @click="removePayment"
              :disabled="submittingRemovePayment"
            >
              <span v-if="submittingRemovePayment">Removing..</span>
              <span v-if="!submittingRemovePayment">Remove Payment</span>
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        title="Edit Expected Payment Amount"
        id="confirmEditExpected"
        hide-footer
      >
        <div class="" v-if="currentPayment">
          <div v-if="currentPayment.name === 'Concrete'">
            Expected concrete payment amount is initially set to an estimate
            during building pricing.
          </div>
          <div class="mt-2">
            You can update the expected payment amount for
            <strong>{{ currentPayment.name }}</strong> once the bid is complete.
          </div>

          <label for="newExpectedAmount" class="mt-3"
            ><strong>Expected Amount</strong></label
          >
          <b-form-input
            type="text"
            v-model="newExpectedAmount"
            :required="true"
            id="newExpectedAmount"
          ></b-form-input>

          <div class="detail-modal-ctas mt-5">
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('confirmEditExpected')"
              :disabled="submittingUpdateExpected"
              >Cancel</b-button
            >
            <b-button
              variant="danger"
              @click="editExpectedAmount"
              :disabled="submittingUpdateExpected"
            >
              <span v-if="submittingUpdateExpected">Editing..</span>
              <span v-if="!submittingUpdateExpected">Edit Expected Amount</span>
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        :title="currentPayment && currentPayment.name"
        id="paymentDetailView"
        hide-footer
      >
        <div v-if="currentPayment">
          <div class="row">
            <div class="col">
              <strong> Actual: </strong>
              {{ currentPayment.actualAmount | currencyFilter }}
            </div>
            <div class="col" v-if="currentPayment.expectedAmount">
              <strong> Expected: </strong>
              {{ currentPayment.expectedAmount | currencyFilter }}
            </div>
          </div>
          <div class="mt-3">
            <strong> Date: </strong>
            {{ currentPayment.timestamp | shortFullDateFilter }}
          </div>
          <div class="mt-3">
            <strong> Memo: </strong>
          </div>
          <div>{{ currentPayment.memo }}</div>

          <div
            class="detail-modal-ctas mt-5"
            v-if="currentPayment.actualAmount"
          >
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('paymentDetailView')"
              :disabled="submittingAddPayment"
              >Close Payment Detail</b-button
            >
            <b-button variant="outline-danger" @click="confirmDeletePayment">
              Delete Payment
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import CircleLoader from '@/components/CircleLoader'
import { createBuildingPaymentSchedule } from '../utils'
import OrderPaymentsChecklist from './OrderPaymentsChecklist.vue'

export default {
  name: 'OrderPayments',
  props: {
    order: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'payments',
    },
  },
  inject: ['userStore'],
  components: { CircleLoader, OrderPaymentsChecklist },
  data: function () {
    return {
      submitting: false,
      loading: true,
      building: null,
      currentPayment: null,
      newPaymentAmount: '',
      newPaymentMemo: '',
      newExpectedAmount: '',
      submittingUpdateExpected: false,
      submittingRecordPayment: false,
      submittingDeletePayment: false,
      submittingRemovePayment: false,
      submittingAddPayment: false,
      newPaymentDate: new Date(),
      fields: [
        {
          key: 'name',
          label: 'Payment',
        },
        {
          key: 'actualAmount',
          label: 'Actual',
        },
        {
          key: 'expectedAmount',
          label: 'Expected',
        },
        {
          key: 'timestamp',
          //   sortable: true,
          label: 'Date',
        },
      ],
    }
  },
  computed: {
    paymentList() {
      let list = []
      let expectedTotal = 0
      let actualTotal = 0
      this.order.payments.forEach(payment => {
        if (payment.expectedAmount) expectedTotal += payment.expectedAmount
        if (payment.actualAmount) actualTotal += payment.actualAmount
        list.push(payment)
      })
      list.push({
        name: 'Total',
        expectedAmount: expectedTotal,
        actualAmount: actualTotal,
        _rowVariant: 'info',
        tdClass: 'total',
      })
      return list
    },
  },
  methods: {
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.name !== 'Total') {
          return 'clickable-row'
        } else {
          return null
        }
      } else {
        return null
      }
    },
    async addPaymentToSchedule() {
      let revNum = 1
      let lastItem = _.last(this.order.payments)
      let lastRevNum = lastItem.name.match(/\d+/)
      if (lastRevNum) {
        revNum = parseInt(lastRevNum[0]) + 1
      }
      const newPayment = {
        name: `Additonal Payment (${revNum})`,
        expectedAmount: 0,
      }
      this.submittingAddPayment = true
      await this.savePaymentSchedule([...this.order.payments, newPayment])
      this.submittingAddPayment = false
      this.$bvModal.hide('confirmAddPayment')
      this.$toasted.success(` Added payment to schedule`, {
        duration: 2500,
      })
    },
    editExpectedAmount() {
      const data = {
        id: this.order.id,
        payment: {
          ...this.currentPayment,
          expectedAmount: this.newExpectedAmount,
        },
      }
      this.submittingUpdateExpected = true
      return axios
        .put('/services/orders/save-expected-payment', data)
        .then(response => {
          this.currentPayment.expectedAmount = parseFloat(
            this.newExpectedAmount,
          )
          this.newExpectedAmount = ''
          this.$bvModal.hide('confirmEditExpected')
          this.$toasted.success(`Saved Payment`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error saving payment`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submittingUpdateExpected = false
        })
    },
    savePayment() {
      const data = {
        id: this.order.id,
        payment: {
          ...this.currentPayment,
          actualAmount: this.newPaymentAmount,
          timestamp: this.newPaymentDate,
          memo: this.newPaymentMemo,
        },
      }
      this.submittingRecordPayment = true
      return axios
        .put('/services/orders/save-payment', data)
        .then(response => {
          this.order.payments = response.data.payments
          this.newPaymentAmount = ''
          this.newPaymentDate = new Date()
          this.newPaymentMemo = ''
          this.$bvModal.hide('confirmRecordPayment')
          this.$toasted.success(`Saved Payment`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error saving payment`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submittingRecordPayment = false
        })
    },
    deletePayment() {
      const data = {
        id: this.order.id,
        payment: {
          name: this.currentPayment.name,
        },
      }
      this.submittingDeletePayment = true
      return axios
        .put('/services/orders/delete-payment', data)
        .then(response => {
          this.order.payments = response.data.payments
          this.newPaymentAmount = ''
          this.newPaymentDate = new Date()
          this.newPaymentMemo = ''
          this.$bvModal.hide('confirmDeletePayment')
          this.$toasted.success(`Deleted Payment`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error deleting payment`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submittingDeletePayment = false
        })
    },
    removePayment() {
      const data = {
        id: this.order.id,
        payment: {
          name: this.currentPayment.name,
        },
      }
      this.submittingRemovePayment = true
      return axios
        .put('/services/orders/remove-payment', data)
        .then(response => {
          this.order.payments = response.data.payments
          this.newPaymentAmount = ''
          this.newPaymentDate = new Date()
          this.newPaymentMemo = ''
          this.$bvModal.hide('confirmRemovePayment')
          this.$toasted.success(`Removed Payment`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error removing payment`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submittingRemovePayment = false
        })
    },
    openPaymentDetail(payment) {
      if (payment.name === 'Total') return
      this.currentPayment = payment
      if (this.currentPayment.actualAmount) {
        this.$bvModal.show('paymentDetailView')
      } else {
        this.$bvModal.show('confirmRecordPayment')
      }
    },
    confirmRecordPayment(payment) {
      this.currentPayment = payment
      this.$bvModal.show('confirmRecordPayment')
    },
    confirmDeletePayment(item) {
      this.currentItem = item
      this.$bvModal.hide('paymentDetailView')
      this.$bvModal.show('confirmDeletePayment')
    },
    confirmRemovePayment(item) {
      this.currentItem = item
      this.$bvModal.hide('confirmRecordPayment')
      this.$bvModal.show('confirmRemovePayment')
    },
    confirmEditExpected(payment) {
      this.currentPayment = payment
      this.newExpectedAmount = _.clone(payment.expectedAmount)
      this.$bvModal.show('confirmEditExpected')
    },
    getBuilding() {
      return axios
        .get('/services/buildings/' + this.order.productId)
        .then(response => {
          this.building = response.data.building
          return response.data.building
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    savePaymentSchedule(payments) {
      const data = {
        id: this.order.id,
        payments,
      }
      return axios
        .put('/services/orders/save-payment-schedule', data)
        .then(response => {
          this.order.payments = payments
        })
        .catch(error => {
          this.$toasted.error(` Error saving payment schedule`, {
            duration: 2500,
          })
        })
        .finally(() => {})
    },
  },
  async mounted() {
    // don't have a payment schedule yet, need to request
    if (!this.order.payments.length) {
      // check for building and
      if (this.order.type === 'BUILDING' && this.order.productId) {
        const building = await this.getBuilding()
        if (building) {
          let paymentSchedule = createBuildingPaymentSchedule(building)
          await this.savePaymentSchedule(paymentSchedule)
        }
      } else if (this.order.totalAmount) {
        const newPayment = {
          name: `Initial Payment`,
          expectedAmount: this.order.totalAmount,
        }
        await this.savePaymentSchedule([newPayment])
      }

      this.loading = false
    } else {
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.detail-modal-ctas {
  display: flex;
  justify-content: space-between;
}
.edit-expected-button {
  font-size: 22px;
  display: inline-block;
  vertical-align: inherit;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
}
.remove-payment-btn {
  position: absolute;
  right: 16px;
}
</style>
