export var rollDoorPrices = {
  '8x8': {
    cost: 391,
    model: '1950',
    width: '8',
    height: '8',
  },
  '8x9': {
    cost: 401,
    model: '1950',
    width: '8',
    height: '9',
  },
  '8x10': {
    cost: 441,
    model: '1950',
    width: '8',
    height: '10',
  },
  '8x12': {
    cost: 571,
    model: '1950',
    width: '8',
    height: '12',
  },
  '8x14': {
    cost: 631,
    model: '1950',
    width: '8',
    height: '14',
  },
  '9x8': {
    cost: 421,
    model: '1950',
    width: '9',
    height: '8',
  },
  '9x9': {
    cost: 433,
    model: '1950',
    width: '9',
    height: '9',
  },
  '9x10': {
    cost: 471,
    model: '1950',
    width: '9',
    height: '10',
  },
  '9x12': {
    cost: 631,
    model: '1950',
    width: '9',
    height: '12',
  },
  '9x14': {
    cost: 671,
    model: '1950',
    width: '9',
    height: '14',
  },
  '10x8': {
    cost: 451,
    model: '1950',
    width: '10',
    height: '8',
  },
  '10x9': {
    cost: 467,
    model: '1950',
    width: '10',
    height: '9',
  },
  '10x10': {
    cost: 491,
    model: '1950',
    width: '10',
    height: '10',
  },
  '10x12': {
    cost: 667,
    model: '1950',
    width: '10',
    height: '12',
  },
  '10x14': {
    cost: 721,
    model: '1950',
    width: '10',
    height: '14',
  },
  '12x8': {
    cost: 521,
    model: '1950',
    width: '12',
    height: '8',
  },
  '12x9': {
    cost: 531,
    model: '1950',
    width: '12',
    height: '9',
  },
  '12x10': {
    cost: 581,
    model: '1950',
    width: '12',
    height: '10',
  },
  '12x12': {
    cost: 731,
    model: '1950',
    width: '12',
    height: '12',
  },
  '12x14': {
    cost: 811,
    model: '1950',
    width: '12',
    height: '14',
  },
  '8x16': {
    cost: 733,
    model: '2000',
    width: '8',
    height: '16',
  },
  '9x16': {
    cost: 781,
    model: '2000',
    width: '9',
    height: '16',
  },
  '10x16': {
    cost: 905,
    model: '2000',
    width: '10',
    height: '16',
  },
  '12x16': {
    cost: 909,
    model: '2000',
    width: '12',
    height: '16',
  },
  '14x8': {
    cost: 1051,
    model: '3100',
    width: '14',
    height: '8',
  },
  '14x9': {
    cost: 1131,
    model: '3100',
    width: '14',
    height: '9',
  },
  '14x10': {
    cost: 1211,
    model: '3100',
    width: '14',
    height: '10',
  },
  '14x12': {
    cost: 1351,
    model: '3100',
    width: '14',
    height: '12',
  },
  '14x14': {
    cost: 1531,
    model: '3100',
    width: '14',
    height: '14',
  },
  '14x16': {
    cost: 1799.06,
    model: '2500',
    width: '14',
    height: '16',
  },
  '16x8': {
    cost: 1151,
    model: '3100',
    width: '16',
    height: '8',
  },
  '16x9': {
    cost: 1231,
    model: '3100',
    width: '16',
    height: '9',
  },
  '16x10': {
    cost: 1311,
    model: '3100',
    width: '16',
    height: '10',
  },
  '16x12': {
    cost: 1491,
    model: '3100',
    width: '16',
    height: '12',
  },
  '16x14': {
    cost: 1681,
    model: '3100',
    width: '16',
    height: '14',
  },
  '18x8': {
    cost: 1251,
    model: '3100',
    width: '18',
    height: '8',
  },
  '18x9': {
    cost: 1331,
    model: '3100',
    width: '18',
    height: '9',
  },
  '18x10': {
    cost: 1411,
    model: '3100',
    width: '18',
    height: '10',
  },
  '18x12': {
    cost: 1611,
    model: '3100',
    width: '18',
    height: '12',
  },
  '18x14': {
    cost: 1761,
    model: '3100',
    width: '18',
    height: '14',
  },
  '20x8': {
    cost: 1431,
    model: '3100',
    width: '20',
    height: '8',
  },
  '20x9': {
    cost: 1531,
    model: '3100',
    width: '20',
    height: '9',
  },
  '20x10': {
    cost: 1631,
    model: '3100',
    width: '20',
    height: '10',
  },
  '20x12': {
    cost: 1831,
    model: '3100',
    width: '20',
    height: '12',
  },
  '20x14': {
    cost: 2061,
    model: '3100',
    width: '20',
    height: '14',
  },
}
