<template>
  <div>
    <div class="my-tasks" v-if="this.currentTasks">
      <div class="mt-4">
        <h3>Operations Tasks</h3>
        <task-list :tasks="tasks"></task-list>
        <div v-if="false">
          <h3>Approval Requests</h3>
          <approval-request-list
            :requests="approvalRequests"
          ></approval-request-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskList from '../components/TaskList.vue'
export default {
  name: 'OperationsTasksPage',
  props: {},
  data: function () {
    return {
      currentTasks: null,
    }
  },
  components: { TaskList },
  mounted() {
    this.currentTasks = this.$parent.currentTasks
  },
  methods: {},
  computed: {
    tasks() {
      return this.currentTasks.filter(task => {
        return task.department === 'OPERATIONS'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.order-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.timeline {
  // width: 95%;
}
</style>
