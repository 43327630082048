<template>
  <div class="table-wrapper" :style="{ maxHeight: customMaxHeight }">
    <table class="table">
      <thead>
        <td>Status</td>
        <td v-if="optionalFields.holdReason">Hold Reason</td>
        <td>Job Name</td>
        <td v-if="optionalFields.salesOrderNumber">Sales Order#</td>
        <td>Customer Name</td>
        <td v-if="optionalFields.customerPhone">Customer Phone</td>
        <td v-if="optionalFields.lastContacted">Last Contacted</td>
        <td v-if="optionalFields.type">Order Type</td>
        <td v-if="optionalFields.deliveryMethod">Delivery/Pickup</td>
        <td>Sold Date</td>
        <td>Due Date</td>
        <td v-if="optionalFields.timeLeft">Time Left</td>
      </thead>
      <tbody v-if="!orders.length">
        <div class="text-center mt-3 mb-3"><div class="loader"></div></div>
      </tbody>
      <tbody v-if="orders.length">
        <tr
          v-for="(order, index) in orders"
          :key="index"
          :class="{
            'order-link': true,
            'past-due': calculatePastDue(
              order.workflow.sales.dueDate,
              order.status,
            ),
            'requires-vendor-order': order.requiresVendorOrder,
          }"
          @click="openOrder(order, $event)"
        >
          <td>{{ order.status }}</td>
          <td v-if="optionalFields.holdReason">
            {{ order.workflow.manufacturing.holdReason }}
          </td>
          <td>{{ order.jobName }}</td>
          <td v-if="optionalFields.salesOrderNumber">
            {{ order.salesOrderNumber }}
          </td>
          <td>
            <span class="customer-name">{{ order.customer.name }}</span>
            <VIPBadge class="vip-badge" v-if="order.customer.vip"></VIPBadge>
          </td>
          <td v-if="optionalFields.customerPhone">
            {{ order.customer.phone }}
          </td>
          <td v-if="optionalFields.lastContacted">
            {{ order.workflow.sales.lastContactedDate | daysAgoWithTime }}
          </td>
          <td v-if="optionalFields.type">{{ order.type }}</td>
          <td v-if="optionalFields.deliveryMethod">
            {{ order.deliveryMethod }}
          </td>
          <td>{{ order.workflow.sales.soldDate | dateFilter }}</td>
          <td>{{ order.workflow.sales.dueDate | dateTimeFilter }}</td>
          <td v-if="optionalFields.timeLeft">
            <span>
              <span
                :class="{
                  'time-left': true,
                  'on-hold': order.status === 'ON-HOLD',
                }"
                >{{ order.timeLeft }}</span
              >
              <FTBadge class="ft-badge" v-if="order.fastTrack"></FTBadge>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal
      :id="`updateOrderModal${id}`"
      :title="`Order #${
        currentOrder && currentOrder.salesOrderNumber
          ? currentOrder.salesOrderNumber
          : ''
      } - ${currentOrder && currentOrder.status}`"
      size="xl"
      hide-footer
    >
      <update-order-status
        v-if="currentOrder"
        :modalId="id"
        :currentOrder="currentOrder"
      >
      </update-order-status>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment-business-days'
import UpdateOrderStatus from '@/components/UpdateOrderStatus'
import VIPBadge from '@/components/VIPBadge'
import FTBadge from '@/components/FTBadge'

export default {
  name: 'OrderList',
  components: { UpdateOrderStatus, VIPBadge, FTBadge },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    linkAction: {
      type: String,
      required: false,
    },
    customMaxHeight: {
      type: String,
      required: false,
    },
    optionalFields: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      currentOrder: null,
      submitting: false,
    }
  },
  mounted() {},
  computed: {},
  methods: {
    openOrder(order, e) {
      e.preventDefault()
      if (this.linkAction === 'detail') {
        if (e.metaKey) {
          window.open('/app/orders/' + order.id, '_blank')
        } else {
          this.$router.push('/app/orders/' + order.id)
        }
      } else {
        this.currentOrder = order
        this.$bvModal.show(`updateOrderModal${this.id}`)
      }
    },
    calculatePastDue(dueDate, status) {
      if (status === 'OPEN' || status === 'IN-PROGRESS') {
        dueDate = moment(dueDate)
        if (moment().isAfter(dueDate)) return true
      }
      return false
    },
    calculateDueDate(soldDate) {
      return moment(soldDate).businessAdd(2)
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen {
  thead {
    position: sticky;
    top: -1px;
    background: #f3f3f3;
  }
}
.order-link {
  cursor: pointer;
  color: #007bff;
}

.table-wrapper {
  max-height: 400px;
  overflow: scroll;
}

.time-left {
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  color: #02c60b;

  &.on-hold {
    color: #007bff;
  }
}

.past-due {
  color: #ff2600;
  font-size: 22px;
  & .time-left {
    color: #ff2600;
    font-size: 22px;
  }
}

.requires-vendor-order {
  color: #ff9d00;

  & .time-left {
    color: #ff9d00;
  }
}

.notes-list {
  max-height: 200px;
  overflow: scroll;
  margin-top: 12px;
}

.customer-name {
  vertical-align: middle;
}
.vip-badge {
  margin-left: 2px;
}
.ft-badge {
  margin-left: 2px;
}

@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
  .order-link,
  .time-left {
    font-size: 12px;
  }

  .past-due {
    color: #ff2600;
    font-size: 12px;
    & .time-left {
      color: #ff2600;
      font-size: 12px;
    }
  }
}
</style>
