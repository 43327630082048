<template>
  <div class="contractor-contract">
    <div class="page-one">
      <div class="page-header clearfix">
        <div class="page-number">MDI Contractor Contract<br />1 of 4</div>
      </div>
      <div class="page-one-header">
        <div class="logo">
          <img alt="MD logo" src="../assets/md-logo.png" />
          <div class="address">
            6771 Eastside Rd Redding, CA 96001<br />
            (530)605-1931 PH (530)605-1938 Fax <br />
            www.metalsdirect.com <br />
          </div>
        </div>
        <div class="title">Contractor Contract</div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          This contract is made effective on
          <span class="underline">{{ today | dateFilter }} </span>
          Between Metals Direct Inc hereafter known as MDI located at 6771
          Eastside Rd. Redding CA 96001 and
          <span class="underline">{{ customerName }}</span> of
          <span class="underline">{{ building.customer.address.city }}</span>
          hereafter known BUYER Located at the address listed below: <br />
          <div class="address">
            Street: {{ building.customer.address.street1 }}<br />
            City: {{ building.customer.address.city }}<br />
            Zip: {{ building.customer.address.street1 }}<br />
            Phone: {{ building.customer.phone }}
          </div>
        </div>
        <div class="mt-3">
          WHEREAS the Owner finds the Contractor qualified to perform the work,
          all relevant factors considered. <br />
          NOW, THEREFORE, in consideration of mutual agreements set forth herein
          and intending to be legally bound, with all legal rights for governing
          this contract and its enforcement to be interpreted under the laws of
          the State of California, County of Shasta. The parties hereto agree as
          follows:<br />
          <strong>1. CONTRACTOR AGREEMENT: </strong>Contractor agrees to assume
          duties and responsibilities including required labor necessary to
          install/construct/complete the agreed upon project. In the prosecution
          of the work, the Contractor shall employ a sufficient number of
          workers skilled in their trades to suitably perform the work<br />
          <strong>2. OWNER REPRESENTS: </strong>Owner declared that the site
          will be prepared for assembly of the building, including all grading,
          leveling, application of concrete or other subsurface materials,
          utilities, spoils, electrical, site inspections (USA)( unless those
          items are to be covered under this contract) and all permits necessary
          for the same prior to assembly of building by your authorized
          installer. The location at which this project is to be completed is:
          <div class="address">
            Street: {{ building.site.address.street1 }}<br />
            City: {{ building.site.address.city }}<br />
            Zip: {{ building.site.address.street1 }}<br />
            Phone: {{ building.site.phone }}
          </div>
        </div>
        <div class="mt-3">
          <strong>3. DELAYS: </strong>In the event the Contractor is delayed in
          the prosecution of the work by or due to acts of God, labor strikes,
          late delivery of material; or by neglect of the Owner: the time for
          completion of the work shall be extended for the same period as the
          delay occasioned by any of the aforementioned causes. Delays caused by
          third parties shall not constitute release or responsibility to the
          payment schedule due by the Owner as described.<br />
          <strong>4. MODIFICATIONS TO THE WORK: </strong>All changes and
          deviations in the work require a written change order, the contract
          sum being increased or decreased according to the Contractor. Any
          claims for increases in the cost of work must be presented by the
          Contractor to the Owner in writing, and the written approval of the
          Owner shall be obtained by the Contractor before proceeding with the
          ordered changes or revision<br />
          <strong>5. ACCESS: </strong>The Owner, the Owners Representatives and
          public authorities shall at all times have access to the work until
          completed provided they remain far enough away as to be safe from both
          foreseen and unforeseen dangers that may or could occur on a
          construction site.<br />
        </div>
      </div>
    </div>

    <div class="page-two clearfix">
      <div class="page-header clearfix">
        <div class="page-number">MDI Contractor Contract<br />2 of 4</div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          <strong>6. PAYMENT: </strong>The Owner agrees to pay the Contractor,
          according to the described payment schedule listed herein for the work
          contracted within 24 hours of completed phase. Owner understands that
          all work will be done as contracted. Failure to pay as scheduled will
          be considered a material breach of this contract. Owner must be
          available or make advance arrangements to make payments as described
          for each step of completion. In the event of nonpayment at any stage
          completed on this project, all remaining work will cease until
          payments are made current. Owner further understands that if payment
          is not made current within 24 hours of completion of any phase
          completed that MDI reserves the right and will LIEN your property to
          secure its interests
        </div>
        <div class="mt-2">
          <div class="payment-schedule">
            <div class="labels">
              Concrete portion to be paid to MDI General Contracting
              (License#977510) upon completion of the concrete:<br />
              Framing portion to be paid to MDI General Contracting
              (License#977510) upon completion of the framing of the
              building:<br />
              Sheeting to be paid to MDI General Contracting (License#977510)
              upon completion of sheeting the building:
            </div>
            <div class="answers">
              <br /><br />________________________________
              <br /><br /><br />________________________________
              <br /><br /><br />________________________________
            </div>
          </div>
        </div>
        <div class="mt-2">
          The payments called for in the Schedule of Payments noted above are
          mandatory and shall be subject to strict compliance. Neither party has
          the power to terminate this Contract after date of execution. Owner
          shall make payments on the payment schedule in a timely manner.<br />
          <strong>7. INSURANCE COVERAGE: </strong>The Owner agrees to maintain
          full insurance on the above-described work during the progress of the
          work, in his own name and that of the Contractor. <br />
          <strong>8. WORKERS COMPENSATION INSURANCE: </strong>MDI General
          Contracting at its own cost and expense, must procure and maintain
          during the continuance of this Contract a policy of workers
          compensation or employer’s liability insurance for the protection of
          its employees (including executive, managerial, and supervisorial
          employees) engaged in work on the project and must, upon demand,
          deposit with the Owner a certificate evidencing the policy. <br />
          <strong>9. NO WAIVER BY PAYMENT: </strong>The recordation of a notice
          of completion of the project by Owner, the payment to Contractor by
          Owner of any progress payment or the full contract price, or the
          occupation of the project by Owner will not relieve Contractor of
          liability (1) for defective materials or workmanship used in the
          completion of the project, or (2) for failure to assemble the project
          according to this Contract or according to the drawings, change orders
          and specifications mentioned in this contract. Contractor will
          complete contracted work to meet or exceed industry standard of
          acceptability.<br />
          <strong>10. NOTICES: </strong> Any and all notices or other matters
          required or permitted by this Contract or by law to be served on,
          given to, or delivered to either Owner or MDI General Contracting by
          the other party to this Contract must be in writing and will be deemed
          duly served, given, or delivered when (1) personally delivered to the
          party to whom it is addressed or to a supervisorial employee of that
          party. Or (2) deposited in the United States mail, first-class postage
          prepaid, addressed to Owner as identified above or to Contractor at
          6771 Eastside Road Redding, CA 96001. Either party may change the
          party’s address for these purposes by giving written notice of the
          change to the other party in the manner provided in this paragraph.
          <br />
          <strong>11. CONCRETE WARRANTY: </strong>MDI General Contracting
          warranties the structural integrity of the work, the material used and
          the workmanship from any substantial defects. Warranty period is for
          one year from date of installation. Contractor recommends customer
          reapply Single Seal and Freeze Protect by Professional Products Direct
          every year as part of the maintenance to avoid any spalling and/or
          water penetration. Warranty excludes hairline cracks, slight surface
          imperfections, salt damage, and color variance
        </div>
      </div>
    </div>

    <div class="page-two clearfix">
      <div class="page-header clearfix">
        <div class="page-number">MDI Contractor Contract<br />3 of 4</div>
      </div>
      <div class="page-content">
        <div class="mt-3">
          <strong>12. WORK NOT CONTRACTED:</strong>Owner agrees that MDI is not
          liable for any work that MDI is not contracted to perform through
          written agreements. Owner acknowledges and consents that they are
          responsible for any work performed outside of written agreements with
          MDI. Including but not limited to the following items. Dimensions of
          concrete slab must be accurate, if slab is not accurate MDI will not
          warranty water seal of the building. Concrete Slab must be level, if
          slab is not level owner will be responsible for any additional
          material and labor needed to adjust the building. If owner choses to
          proceed without the corrections recommended by MDI, owner will need to
          sign a waiver releasing MDI of liability and warranty. Owner is
          responsible for placing the J-Bolts as specified per engineered plans.
          If the J-Bolt placements are inaccurate owner is accountable for
          additional labor at $65.00 an hour per laborer, additional material
          that is needed, and inspection fees that will be required due to
          changing anchors. All work will be halted until a change order is
          signed by owner accepting additional charges. Owner is liable for
          insuring that any door insets are accurate per engineered plans. If
          insets are inaccurate Owner is responsible for any additional charges
          that are required to amend the inset. Owner is responsible for sloping
          grade 5% in 10ft away from building as required per code and stoops as
          required by code. If there is any sort of liquefaction in the
          foundation it will void all warranties on MDI’s buildings.<br />
          <strong>13. ATTORNEY FEES:</strong>Attorney fees and court costs shall
          be paid by the defendant in the event that judgment must be, and is,
          obtained to enforce this agreement or any breach thereof.<br />
          <strong>14. SPECIAL DETAILS OF CONTRACTED PROJECT:</strong>MDI General
          Contracting agrees to perform the following work:
          <div class="detail-lines">
            Grading:
            ___________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
          </div>
          <div class="detail-lines">
            Concrete:
            ___________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
          </div>
          <div class="detail-lines">
            Electrical:
            ___________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
            ___________________________________________________________________________________<br />
          </div>
        </div>
      </div>
    </div>

    <div class="page-three clearfix">
      <div class="page-header clearfix">
        <div class="page-number">MDI Contractor Contract<br />4 of 4</div>
      </div>
      <div class="page-content">
        <div class="mt-3"></div>
        <div class="building-specs-title">Building Specs</div>
        <div class="building-specs mt-4 mb-3">
          <div class="row">
            <div class="col">
              <div class="label">Building Type</div>
              <div>{{ building.type }}</div>
              <div class="label">Truss</div>
              <div>{{ building.truss.type }}</div>
              <div class="label">Kickers</div>
              <div>{{ building.truss.kickers ? 'Yes' : 'No' }}</div>
            </div>
            <div class="col">
              <span class="label">Width: </span
              ><span>{{ building.dimensions.width }}</span
              ><br />
              <span class="label">Length: </span
              ><span>{{ building.dimensions.length }}</span
              ><br />
              <span class="label">Height: </span
              ><span>{{ building.dimensions.height }}</span
              ><br />
              <span class="label">Centers: </span
              ><span>{{ building.walls.centers }}</span
              ><br />
              <span class="label">Anchors: </span
              ><span>{{ building.foundation.anchors }}</span
              ><br />
            </div>
            <div class="col">
              <div class="label">Roof</div>
              <div>{{ building.roof.design }}</div>
              <div class="label">Walls</div>
              <div>{{ building.walls.design }}</div>
            </div>
          </div>
        </div>
        <div class="page-four-notes">Notes:</div>
        <div class="storage-disclaimer">
          <div class="storage-disclaimer-title">Important Proper Storage</div>
          When moisture remains in contact with Galvanized, Galvalume, or
          Aluminum panels in the absence of freely circulating air, white,
          black, or dark gray corrosion products begin to form. Moisture can get
          between stacked panels either through capillary action or atmospheric
          humidity (if the temperature of the panels is below the dew point
          temperature of the surrounding air, condensation will form between the
          panels). If moisture becomes entrapped between the sheets, this
          condition can result in water stains or white rust, which can affect
          the service life of the metal and will detract from its appearance.
          <br /><br />
          If the metal panels will not be installed immediately, they must be
          kept warm and dry in inside storage only, where uniform temperature
          and good ventilation are available. Material should not be stored for
          prolonged periods in bundles as moisture may go undetected. If
          prolonged storage is necessary the bundle should be broken and the
          sheets stored in an inclined position.
          <br /><br />
          If outdoor storage cannot be avoided protect the metal with canvas or
          waterproof paper.
          <br /><br />
          Keep the sheets off the ground in an inclined position with an
          insulator such as wood.
          <br /><br />
          Under no circumstances is plastic to be used as it causes sweating and
          condensation.
          <br /><br />
          CAUTION!!! Sharp edges, wear protective clothing and gloves.
        </div>
        <div class="mt-3">
          This agreement has been entered into on {{ today | dateFilter }} in
          Shasta County, California
        </div>
        <div class="mt-1">
          Signatures below indicate agreement in the above contract:
        </div>
        <div class="mt-5">
          Buyer: ____________________________________________
          Date:______________________________
        </div>
        <div class="mt-5">
          Metals Direct Inc: __________________________________ Date:
          _____________________________
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractorContract',
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    console.log('this.building....', this.building)
    return {
      today: new Date(),
    }
  },
  computed: {
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          ' ' +
          this.building.customer.lastName
        )
      } else {
        return this.building.customer.email
      }
    },
    insulation() {
      let insulation
      if (
        this.building.roof.insulation.type === 'None' &&
        this.building.walls.insulation.type === 'None'
      ) {
        insulation = 'No'
      } else {
        insulation = 'Yes'
      }
      return insulation
    },
  },
}
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.contractor-contract {
  position: relative;
}
.page-number {
  float: right;
}

.page-content {
  text-align: left;
}

.page-one-header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 32px;
  font-weight: bold;
  margin-top: 48px;
  text-align: left;
}

.address {
  padding: 12px 18px;
  font-size: 18px;
}

.labels,
.answers {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.labels {
  margin-right: 12px;
}

.payment-schedule {
  padding: 18px;
}

.building-specs-title {
  border: 1px solid black;
  padding: 6px;
  text-align: center;
}

.page-four-notes {
  border: 1px solid black;
  height: 120px;
  padding: 6px;
}

.label {
  font-weight: bold;
}
.storage-disclaimer-title {
  font-weight: bold;
  margin-bottom: 6px;
}
.storage-disclaimer {
  margin-top: 24px;
  border: 1px solid black;
  font-size: 15px;
  padding: 12px;
}

@media print {
  .page-two,
  .page-three {
    page-break-before: always;
  }

  .contractor-contract {
    padding: 0px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }

  .title {
    font-size: 42px;
  }

  .detail-lines {
    margin-top: 12px;
    line-height: 175%;
  }
}
</style>
