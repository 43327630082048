<template>
  <div>
    <h3>Install</h3>
    <div class="order-install" v-if="order">
      <div class="row">
        <div class="col">
          <div class="install-workflow">
            <div class="install-info">
              <h5>Install Info</h5>
              <div class="row">
                <div class="col">
                  <div>County: {{ order.siteAddress.county }}</div>
                  <div>APN: {{ order.siteAddress.apn }}</div>
                </div>
                <div class="col"></div>
              </div>
            </div>
            <div
              v-if="
                order.type == 'BUILDING' && order.workflow.install.doInstall
              "
              class="mt-3"
            >
              <install-estimate :order="order"></install-estimate>
            </div>
            <div class="checklist mt-3">
              <h5>Checklist</h5>
              <install-checklist :order="order"></install-checklist>
            </div>
          </div>
        </div>
        <div class="col">
          <notes
            :order="order"
            class="mt-4"
            department="INSTALL"
            :notesFilter="['INSTALL']"
            :showEvents="true"
          ></notes>
          <files-display
            class="mt-4"
            :order="order"
            department="install"
          ></files-display>
          <photos-display
            class="mt-4"
            :order="order"
            department="install"
          ></photos-display>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotosDisplay from '@/components/PhotosDisplay'
import FilesDisplay from '@/components/FilesDisplay'
import InstallChecklist from '@/components/InstallChecklist'
import Notes from '@/components/Notes'
import InstallEstimate from '../components/InstallEstimate.vue'

export default {
  name: 'OrderInstallPage',
  props: {},
  data: function () {
    return {
      order: null,
    }
  },
  components: {
    PhotosDisplay,
    Notes,
    FilesDisplay,
    InstallChecklist,
    InstallEstimate,
  },
  mounted() {
    this.order = this.$parent.order
  },
}
</script>

<style lang="scss" scoped></style>
