<template>
  <div class="home page-wrapper">
    <h1 class="page-h1">Manage Buildings</h1>

    <router-link class="mx-2" to="/app/pricer">
      <b-button variant="primary">New Building</b-button>
    </router-link>

    <div>
      <div class="row mt-4">
        <div class="col">
          <h4>Building Lookup</h4>
          <building-lookup></building-lookup>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <h4>{{ userStore.user.firstName }}'s Recent Buildings</h4>
          <building-list
            :buildings="currentUserBuildings"
            :min-cols="true"
          ></building-list>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <h4>Other Recent Buildings</h4>
          <div class="building-list">
            <building-list
              :buildings="recentBuildings"
              :min-cols="true"
            ></building-list>
          </div>
        </div>
      </div>
      <div class="row mt-4" v-if="false">
        <div class="col">
          <h4>Recent Customer Buildings</h4>
          <!-- <span># Created in last 24 Hours: {{recentCustomerBuildings.length}}</span> -->
          <div class="building-list">
            <building-list
              :buildings="recentCustomerBuildings"
              :min-cols="true"
            ></building-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import BuildingList from '@/components/BuildingList'
import BuildingLookup from '@/components/BuildingLookup'
import { userStore } from '@/stores/userStore'

export default {
  name: 'Home',
  components: { BuildingList, BuildingLookup },
  inject: {
    userStore,
  },
  data: function() {
    return {
      recentCustomerBuildings: [],
      recentPartnerBuildings: [],
      currentUserBuildings: [],
      recentBuildings: [],
    }
  },
  methods: {
    loadBuilding(building) {
      this.$router.push({
        name: 'PricerPage',
        params: {
          config: building,
        },
      })
    },
    getUser() {
      return axios
        .get('/services/user')
        .then(response => {
          this.user = response.data.user
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getUserRecentBuildings() {
      return axios
        .get('/services/buildings/currentUser')
        .then(response => {
          this.currentUserBuildings = response.data.buildings
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getRecentBuildings() {
      return axios
        .get('/services/buildings/recent')
        .then(response => {
          this.recentBuildings = response.data.buildings
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getRecentCustomerBuildings() {
      return axios
        .get('/services/buildings/recentCustomer')
        .then(response => {
          this.recentCustomerBuildings = response.data.buildings
        })
        .catch(err => {
          console.log('err', err)
        })
    },
  },
  mounted() {
    if (userStore.user.type === 'SALES' || userStore.user.type === 'ADMIN' || userStore.user.type === 'MANUFACTURING') {
      this.getUserRecentBuildings()
      this.getRecentBuildings()
    }
    // this.getRecentCustomerBuildings();
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
