<template>
  <div>
    <input
      type="datetime-local"
      id="due-date"
      class="form-control"
      style="display:inline-block; width: 90%;"
      v-model="localDueDate"
      @change="setSelected"
      :required="required"
      :disabled="submitting || !editDueDate"
    />
    <span
      class="nc-icon nc-settings-tool-66 edit-due-date-btn"
      @click="editDueDate = !editDueDate"
    ></span>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateTimePicker',
  components: {},
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
  },
  data: function() {
    return {
      model: this.value,
      localDueDate: '',
      editDueDate: false,
    }
  },
  watch: {
    model(currentValue) {
      this.$emit('input', currentValue)
    },
    value(newValue) {
      if (this.value && this.value._d) {
        this.localDueDate = this.value.format('YYYY-MM-DDThh:mm')
      }
    },
  },
  methods: {
    setSelected() {
      this.model = moment(this.localDueDate)
      this.$emit('input', this.model)
    },
  },
  mounted() {
    if (this.value && this.value._d) {
      this.localDueDate = this.value.format('YYYY-MM-DDThh:mm')
    }
  },
}
</script>

<style scoped lang="scss">
.edit-due-date-btn {
  cursor: pointer;
  font-size: 22px;
  vertical-align: middle;
  margin-left: 2px;
}
</style>
