<template>
  <form @submit.prevent="editTask" v-if="task">
    <task-form-fields :task="task" :submitting="submitting"></task-form-fields>
    <div class="modal-ctas mt-4">
      <b-button variant="primary" :disabled="submitting" type="submit">
        <span v-if="submitting">Saving..</span>
        <span v-if="!submitting">Save</span>
      </b-button>
      <b-button
        variant="secondary"
        type="button"
        @click="$bvModal.hide(`edit-task-modal-${modalId}`)"
        :disabled="submitting"
        class="ml-2"
        >Cancel</b-button
      >
      <b-button
        variant="danger"
        type="button"
        @click="$bvModal.show('delete-task-modal')"
        :disabled="submitting"
        class="ml-2 delete-btn"
        >Delete</b-button
      >
    </div>
    <b-modal
      id="delete-task-modal"
      :title="`Delete Task - ${task.name}`"
      hide-footer
    >
      Are you sure you want to delete this task?
      <form @submit.prevent="deleteTask" class="mt-5">
        <b-button variant="primary" :disabled="submitting" type="submit">
          <span v-if="submitting">Deleting..</span>
          <span v-if="!submitting">Delete</span>
        </b-button>
        <b-button
          variant="secondary"
          type="button"
          @click="$bvModal.hide('delete-task-modal')"
          :disabled="submitting"
          class="ml-2"
          >Cancel</b-button
        >
      </form>
    </b-modal>
  </form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import TaskFormFields from './TaskFormFields.vue'

export default {
  name: 'EditTask',
  props: {
    ogTask: {
      type: Object,
      required: true,
    },
    modalId: {
      type: String,
    },
  },
  components: { TaskFormFields },
  inject: ['userStore', 'customerStore', 'taskStore'],
  data: function () {
    return {
      submitting: false,
      task: _.clone(this.ogTask),
    }
  },
  watch: {},
  methods: {
    editTask() {
      const data = this.task
      if (!this.task.dueDate) {
        return this.$toasted.error('Error: Must Select Date', {
          duration: 3000,
        })
      }
      if (this.task.customerName) {
        let customer = _.find(this.customerStore.customers, customer => {
          return customer.name === this.task.customerName
        })
        if (!customer) {
          return this.$toasted.error(
            'Error: Must Select a Customer from the List, or Leave Customer Blank',
            {
              duration: 3000,
            },
          )
        }
      } else {
        this.task.customerId = '' // ensure id is blank too when user clears out customer.... ehh dont like it
      }
      this.submitting = true
      return axios
        .patch('/services/tasks/' + data.id, data)
        .then(response => {
          this.taskStore.deleteTask(response.data.task)
          this.taskStore.addTask(response.data.task)

          this.$toasted.success(`Updated Task`, {
            duration: 2500,
          })
          this.$bvModal.hide(`edit-task-modal-${this.modalId}`)
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Editing Task`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    deleteTask() {
      this.submitting = true
      return axios
        .delete('/services/tasks/' + this.task.id)
        .then(response => {
          this.taskStore.deleteTask(this.task)

          this.$toasted.success(`Task Deleted`, {
            duration: 2500,
          })
          this.$bvModal.hide(`edit-task-modal-${this.modalId}`)
          this.$bvModal.hide('delete-task-modal')
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Deleting Task`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.section-header {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 6px;
  margin-top: 8px;
  font-weight: 500;
}

.contact-col {
  padding-left: 5px;
  padding-right: 5px;

  &:first-of-type {
    padding-left: 15px;
  }
}

.modal-ctas {
  position: relative;
}
.delete-btn {
  position: absolute;
  right: 0;
}
</style>
