<template>
  <div class="files-wrapper">
    <div class="files-list">
      <b-table :items="filteredFiles" :fields="fields">
        <template #cell(name)="row">
          <span @click="downloadFile(row.item)">
            <file-icon :file="row.item" class="file-icon"></file-icon>
          </span>
          <span class="file-name" @click="downloadFile(row.item)">{{
            last(row.item.name.split('/'))
          }}</span>
        </template>
        <template #cell(timestamp)="row">
          {{ row.item.timestamp | shortFullDateFilter }}
        </template>
        <template #cell(remove)="row">
          <span
            class="nc-icon nc-simple-remove"
            @click="confirmRemoveFile(row.item)"
          ></span>
        </template>
      </b-table>
    </div>
    <b-modal id="remove-file-modal" size="md" hide-header hide-footer>
      <div v-if="currentFile">
        <h5>Are you sure you want to delete this file?</h5>
        <p>
          <file-icon :file="currentFile" class="file-icon"></file-icon>
          {{ last(currentFile.name.split('/')) }}
        </p>
        <b-button @click="cancelRemoveFile()" :disabled="submittingDelete"
          >Cancel</b-button
        >
        <b-button
          class="mx-2"
          @click="removeFile(currentFile)"
          variant="danger"
          :disabled="submittingDelete"
        >
          <span v-if="!submittingDelete">Delete File</span>
          <span v-if="submittingDelete">Deleting..</span></b-button
        >
      </div>
    </b-modal>
    <div class="add-file-form">
      <b-form @submit.prevent="addFile()">
        <div id="new-file" class="mt-2">
          <b-form-file
            v-model="newFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            :required="true"
          ></b-form-file>
        </div>
        <div class="mt-1">
          <b-form-input
            type="text"
            v-model="newFileTags"
            placeholder="Add Tags (Optional)"
          ></b-form-input>
        </div>
        <div id="ctas">
          <div class="ctas text-right mt-2">
            <b-button
              variant="primary"
              size="sm"
              type="submit"
              :disabled="submitting"
            >
              <span v-show="submitting">Submitting</span>
              <span v-show="!submitting">Add File</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import FileIcon from './FileIcon.vue'
export default {
  components: { FileIcon },

  name: 'FilesDisplay',
  props: {
    task: {
      type: Object,
      required: true,
    },
    department: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      newFile: null,
      newFileTags: '',
      submitting: false,
      submittingDelete: false,
      signedUploadURL: '',
      currentFile: null,
      last: _.last,
      fields: [
        {
          key: 'name',
          class: ['clickable-row'],
          sortable: true,
          label: 'File',
        },
        {
          key: 'timestamp',
          sortable: true,
          label: 'Date',
        },
        {
          key: 'tags',
          sortable: true,
        },
        {
          key: 'remove',
          class: ['clickable-row'],
          label: '',
        },
      ],
    }
  },
  computed: {
    filteredFiles() {
      return this.task.files
    },
  },
  methods: {
    getFileType(file) {
      let fileType = _.last(file.name.split('.'))
      let supportedTypes = [
        'pdf',
        'xls',
        'xlsx',
        'doc',
        'docx',
        'png',
        'jpg',
        'jpeg',
        'gif',
        'zip',
      ]
      if (!supportedTypes.includes(fileType)) {
        fileType = 'file'
      }
      if (fileType === 'xlsx') fileType = 'xls'
      if (fileType === 'docx') fileType = 'doc'
      return fileType
    },
    confirmRemoveFile(file) {
      this.currentFile = file
      this.$bvModal.show('remove-file-modal')
    },
    cancelRemoveFile() {
      this.$bvModal.hide('remove-file-modal')
      this.currentFile = null
    },
    async removeFile(file) {
      const data = {
        id: this.task.id,
        file,
      }
      this.submittingDelete = true
      try {
        const response = await axios.put('/services/tasks/delete-file', data)
        this.task.files = this.task.files.filter(f => {
          return f.name != file.name
        })
        this.cancelRemoveFile()
        this.$toasted.success(`File Removed`, {
          duration: 2500,
        })
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error removing file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submittingDelete = false
    },
    async downloadFile(file) {
      console.log('file: ', file)
      const data = {
        id: this.task.id,
        file,
        department: this.department,
      }
      try {
        const response = await axios.put(
          '/services/tasks/file-download-url',
          data,
        )
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.download = file.name.split('/')[3]
        a.href = response.data.signedDownloadURL
        a.target = '_blank'
        a.click()
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error downloading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async saveFile(file) {
      file.department = this.department.toUpperCase()
      file.tags = this.newFileTags
      const data = {
        id: this.task.id,
        file,
      }
      try {
        await axios.put('/services/tasks/save-file', data)
        this.task.files.push(file)
        this.$toasted.success(`File Added`, {
          duration: 2500,
        })
        this.newFile = null
        this.newFileTags = ''
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async addFile() {
      if (!this.newFile) return
      const data = {
        id: this.task.id,
        fileName: this.newFile.name,
        department: this.department,
        contentType: this.newFile.type,
      }
      this.submitting = true
      try {
        const response = await axios.put(
          '/services/tasks/file-upload-url',
          data,
        )
        await axios.put(response.data.signedUploadURL, this.newFile, {
          headers: {
            'Content-Type': this.newFile.type,
          },
        })
        return this.saveFile(response.data.file)
      } catch (error) {
        console.log('error: ', error)
        this.$toasted.error(` Error uploading file`, {
          action: {
            text: 'Close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
      this.submitting = false
    },
    async onFileSelected(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.newFileUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.newFile = files[0]
    },
  },
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-radius: 6px;
}
thead {
  font-weight: bold;
  font-size: 16px;
}
td {
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 2px;
}
.nc-icon {
  font-size: 20px;
  font-weight: bold;
}

.file-row {
  position: relative;
  height: 24px;
  line-height: 24px;
}
.file-name-wrapper {
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.file-name {
  cursor: pointer;
}

.nc-simple-remove,
.nc-cloud-download-93 {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  vertical-align: middle;
}

.nc-cloud-download-93 {
  padding-right: 4px;
  padding-left: 2px;
}

.file-icon {
  display: inline-block;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
