export var engineeringChecklist = [
  'Design Criteria Requested',
  'Design Criteria Received',
  'Engineering Requested',
  'Engineering Received',
]

export var permittingChecklist = [
  'Entered Quickbooks',
  'Followup Call',
  'Plot Plan Done',
  'Permit Submitted',
  'Permit Issued',
]

export var installChecklist = [
  'Concrete Bid Pack Created',
  'Crew Job Pack Created',
]
