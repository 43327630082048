<template>
    <div class="table-wrapper">
        <table class="table">
            <thead>
                <td>Customer</td>
                <td>Address</td>
                <td>Type</td>
                <td>Dimensions</td>
                <td>Created On</td>
            </thead>
            <tbody v-if="!buildings.length">
              <div class="text-center mt-3 mb-3"><div class="loader"></div></div>
             </tbody>
            <tbody v-if="buildings.length">
                
              <tr v-for="(building, index) in buildings" :key="index" class="building-link" @click="openBuilding(building.shortId, $event)">
                <td>{{building.customer.email}}</td>
                <td>{{building.site.address.street1 + ', ' + building.site.address.city}}</td>
                <td>{{building.type}}</td>
                <td>{{formatDimensions(building.dimensions)}}</td>
                <td>{{building.createdAt | dateFilter}}</td>
              </tr>
            </tbody>
        </table>
        <b-modal id="building-modal" size="xl" hide-header hide-footer>
            <contractor-building-detail :building="activeBuilding"></contractor-building-detail>
        </b-modal>
    </div>
</template>

<script>
  import _ from 'lodash';
  import ContractorBuildingDetail from '@/views/ContractorBuildingDetail';

  export default {
    name: 'ContractorBuildingList',
    components: { ContractorBuildingDetail },
    props: {
      buildings: {
        type: Array,
        required: true,
      },
    },
    data: function() {
      const id = _.random(0, 9999999);
      return {
        id: id,
        activeBuilding: null,
      }
    },
    methods: {
        formatDimensions(dimensions) {
            return dimensions.width + 'x' + dimensions.length + 'x' + dimensions.height;
        },
        loadBuilding(building) {
            this.$router.push({
                name: 'PricerPage',
                params: {
                    config: building
                }
            })
        },
        openBuilding(id, e) {
            this.activeBuilding = _.find(this.buildings, (building) => {
                return building.shortId === id;
            })
            console.log('activeBuilding: ', this.activeBuilding)
            window.setTimeout(() => {
                this.$bvModal.show('building-modal')
            },50)
        }
    }
  }
</script>

<style lang="scss" scoped>

.building-link {
  cursor: pointer;
  color: #007bff;
}

.table-wrapper {
  max-height: 300px;
  overflow: scroll;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: #000000;
  background: -moz-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #000000 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 .9s infinite linear;
  animation: load3 .9s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #000000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>