<template>
  <div
    class="event-wrapper"
    v-bind:class="{
      sales: event.department === 'SALES',
      architecture: event.department === 'ARCHICTECTURE',
      engineering: event.department === 'ENGINEERING',
      permitting: event.department === 'PERMITTING',
      manufacturing: event.department === 'MANUFACTURING',
      install: event.department === 'INSTALL',
      admin: event.department === 'ADMIN',
    }"
  >
    <div class="event-date">{{ event.timestamp | dateTimeFilter }}</div>
    <div class="event-name">
      {{ event.department }}: {{ event.name }}
      <span
        v-bind:class="{ 'show-data-btn': true, open: showData }"
        @click="showData = !showData"
        >&#9656;</span
      >
    </div>
    <div class="event-data" v-if="showData">{{ event.data }}</div>
  </div>
</template>

<script>
export default {
  name: 'NotesList',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showData: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.event-wrapper {
  padding: 6px;
  position: relative;
  // color: darkslategray;
}

.event-wrapper {
  color: rgb(24, 112, 100);
}

.event-from {
  font-size: 13px;
  font-weight: 700;
}

.event-date {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  right: 0px;
  top: 6px;
}

.event-data,
.event-name {
  font-size: 13px;
}

.show-data-btn {
  font-size: 28px;
  line-height: 13px;
  vertical-align: sub;
  display: inline-block;
  cursor: pointer;
}

.open {
  transform: rotate(90deg);
  vertical-align: middle;
}

.sales {
  color: $sales-color;
}

.architecture {
  color: $architecture-color;
}

.engineering {
  color: $engineering-color;
}

.permitting {
  color: $permitting-color;
}

.manufacturing {
  color: $manufacturing-color;
}

.install {
  color: $install-color;
}

.admin {
  color: $admin-color;
}
</style>
