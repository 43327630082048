<template>
  <div class="inputs">
    <div class="section-header">Customer Details</div>
    <div class="row">
      <div class="col">
        <div class="form-group mb-0">
          <label for="name">Customer Name</label
          ><help-icon
            v-if="customer._id"
            :text="`Name cannot be edited at this time.`"
          ></help-icon>
          <b-form-input
            id="name"
            type="text"
            v-model="customer.name"
            required
            :disabled="submitting || customer._id ? true : false"
          ></b-form-input>
          <label for="vip">Customer VIP</label>
          <b-form-checkbox
            id="vip"
            v-model="customer.vip"
            :disabled="submitting"
          ></b-form-checkbox>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="customerType">Customer Type</label>
          <b-form-select
            id="customerType"
            v-model="customer.type"
            :options="customerTypes"
            required
            :disabled="submitting"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="phone">Phone</label>
          <b-form-input
            id="phone"
            type="text"
            v-model="customer.phone"
            :disabled="submitting"
          ></b-form-input>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="email">Email</label>
          <b-form-input
            id="email"
            type="email"
            v-model="customer.email"
            :disabled="submitting"
            :required="true"
          ></b-form-input>
        </div>
      </div>
    </div>
    <div class="section-header">Address</div>
    <div class="row">
      <div class="col">
        <div class="px-2">
          <AddressFields
            :sending="submitting"
            :address="customer.address"
            :autoComplete="true"
          />
        </div>
      </div>
    </div>
    <div class="section-header">
      Contacts
      <b-button size="sm" variant="primary" @click="addContact">+</b-button>
    </div>
    <div
      v-for="(contact, index) in customer.contacts"
      :key="`contact-${index}`"
    >
      <div class="row mt-2">
        <div class="col contact-col">
          <b-input
            type="text"
            v-model="contact.firstName"
            placeholder="First Name"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-input
            type="text"
            v-model="contact.lastName"
            placeholder="Last Name"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-input
            type="email"
            v-model="contact.email"
            placeholder="Email"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-input
            type="text"
            v-model="contact.phone"
            placeholder="Phone"
          ></b-input>
        </div>
        <div class="col contact-col">
          <b-button variant="danger" size="sm" @click="removeContact(index)"
            >X</b-button
          >
        </div>
      </div>
    </div>
    <div class="section-header">
      Locations
      <b-button size="sm" variant="primary" @click="addLocation">+</b-button>
    </div>
    <div
      v-for="(location, index) in customer.locations"
      :key="`location-${index}`"
      class="mb-3"
    >
      <div class="row mt-2">
        <div class="col location-col address">
          <b-input
            type="text"
            v-model="location.name"
            placeholder="Location Name"
          ></b-input>
        </div>
        <div class="col location-col">
          <b-button variant="danger" size="sm" @click="removeLocation(index)"
            >X</b-button
          >
        </div>
      </div>
      <AddressFields
        class="px-3 mt-2 address"
        :sending="submitting"
        :address="location.address"
        :autoComplete="true"
      />
    </div>
  </div>
</template>

<script>
import AddressFields from '@/components/AddressFields.vue'
import { customerTypes } from '@/components/customerInputOptions'
import HelpIcon from '@/components/HelpIcon.vue'

export default {
  name: 'CustomerFormFields',
  props: {
    customer: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: true,
    },
  },
  components: { AddressFields, HelpIcon },
  inject: {},
  data: function () {
    return {
      customerTypes,
    }
  },
  watch: {},
  methods: {
    addContact() {
      const newContact = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      }
      this.customer.contacts.push(newContact)
    },
    removeContact(index) {
      this.customer.contacts.splice(index, 1)
    },
    addLocation() {
      const newLocation = {
        name: '',
        address: {},
      }
      this.customer.locations.push(newLocation)
    },
    removeLocation(index) {
      this.customer.locations.splice(index, 1)
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}

.section-header {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 6px;
  margin-top: 8px;
  font-weight: 500;
}

.contact-col {
  padding-left: 5px;
  padding-right: 5px;

  &:first-of-type {
    padding-left: 15px;
  }
}

.address {
  max-width: 400px;
}
</style>
