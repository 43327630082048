<template>
  <div class="building-created-chart">
    <apexchart type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { userStore } from '@/stores/userStore'

export default {
  name: 'SalesLeadsChart',
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  inject: {
    userStore,
  },
  data: function () {
    return {
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#f54526'],
        stroke: {
          show: true,
          width: 4,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
        yaxis: {
          title: {
            text: '# of Sales Leads via Website',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '# monthly leads ' + val
            },
          },
        },
      },
    }
  },
  methods: {},
  mounted() {},
  computed: {
    series() {
      let months = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ]
      //   let sorted2021 = _.sortBy(dates2021, ['date'])
      //   let dates2022 = _.filter(this.data, b => {
      //     return b.date.match('2022')
      //   })
      //   let sorted2022 = _.sortBy(dates2022, ['date'])
      let dates2023 = _.filter(this.data, b => {
        return b.date.match('2023')
      })
      let sorted2023 = _.sortBy(dates2023, ['date'])

      return [
        {
          name: '2023',
          data: months.map(m => {
            let record = sorted2023.find(sd => {
              return sd.date.match(m)
            })
            if (record) {
              return record.count
            } else {
              return 0
            }
          }),
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped></style>
