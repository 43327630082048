var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"week-day"},[_c('div',{staticClass:"multi-day-events mb-3"},_vm._l((_vm.multiDayEvents),function(date,index){return _c('div',{key:index,class:{
        'multi-day-event': date.multiDayEvent,
        'first-day': date.firstOfMultiDay,
        'last-day': date.lastOfMultiDay,
        'hide-day': date.hideDate,
        delivery: date.name.toLowerCase().match('delivery'),
        offsite: date.customer || date.name.toLowerCase().match('offsite'),
        inspection: date.name.toLowerCase().match('inspection'),
        holiday:
          date.name.toLowerCase().match('pto') ||
          date.name.toLowerCase().match('vacation') ||
          date.name.toLowerCase().match('holiday') ||
          date.name.toLowerCase().match('dentist') ||
          date.name.toLowerCase().match('doctor'),
        concrete: date.name.toLowerCase().match('concrete'),
        install:
          date.name.toLowerCase().match('framing') ||
          date.name.toLowerCase().match('sheeting') ||
          date.name.toLowerCase().match('insulation'),
      },style:({
        background: date.calendarColor,
        color: _vm.pickTextColor(date.calendarColor),
      })},[(date.name)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(date.name))]):_vm._e(),(date.order)?_c('span',{staticClass:"name"},[_vm._v(" - "+_vm._s(date.order.jobName))]):_vm._e(),(date.order)?_c('span',{staticClass:"name"},[_vm._v(" - "+_vm._s(date.order.customer.name))]):_vm._e(),(date.assignedTo)?_c('span',{staticClass:"name"},[_vm._v(" - "+_vm._s(date.assignedTo))]):_vm._e()])}),0),_c('div',{staticClass:"daily-events mb-3"},_vm._l((_vm.dailyEvents),function(date,index){return _c('div',{key:index,class:{
        'daily-event': true,
        'vendor-delivery':
          date.recurringVendorDay &&
          date.name.toLowerCase().match('delivery'),
        'vendor-order':
          date.recurringVendorDay && date.name.toLowerCase().match('order'),
        delivery:
          !date.recurringVendorDay &&
          date.name.toLowerCase().match('delivery'),
        offsite: date.customer || date.name.toLowerCase().match('offsite'),
        inspection: date.name.toLowerCase().match('inspection'),
        holiday:
          date.name.toLowerCase().match('pto') ||
          date.name.toLowerCase().match('vacation') ||
          date.name.toLowerCase().match('holiday') ||
          date.name.toLowerCase().match('dentist') ||
          date.name.toLowerCase().match('doctor'),
        concrete: date.name.toLowerCase().match('concrete'),
        install:
          date.name.toLowerCase().match('framing') ||
          date.name.toLowerCase().match('sheeting') ||
          date.name.toLowerCase().match('insulation'),
      },style:({
        background: date.calendarColor,
        color: _vm.pickTextColor(date.calendarColor),
      })},[(date.name)?_c('span',{staticClass:"name"},[_vm._v(_vm._s(date.name))]):_vm._e(),(date.icon)?_c('span',{class:((date.icon) + " ml-1 icon")}):_vm._e()])}),0),_vm._l((_vm.sortedDates),function(scheduleDate,index){return _c('div',{key:index,class:{
      'hour-event': true,
      'vendor-delivery':
        scheduleDate.recurringVendorDay &&
        scheduleDate.name.toLowerCase().match('delivery'),
      'vendor-order':
        scheduleDate.recurringVendorDay &&
        scheduleDate.name.toLowerCase().match('order'),
      delivery:
        !scheduleDate.recurringVendorDay &&
        scheduleDate.name.toLowerCase().match('delivery'),
      offsite:
        scheduleDate.customer ||
        scheduleDate.name.toLowerCase().match('offsite'),
      inspection: scheduleDate.name.toLowerCase().match('inspection'),
      holiday:
        scheduleDate.name.toLowerCase().match('pto') ||
        scheduleDate.name.toLowerCase().match('vacation') ||
        scheduleDate.name.toLowerCase().match('dentist') ||
        scheduleDate.name.toLowerCase().match('holiday') ||
        scheduleDate.name.toLowerCase().match('doctor'),
      concrete: scheduleDate.name.toLowerCase().match('concrete'),
      install:
        scheduleDate.name.toLowerCase().match('framing') ||
        scheduleDate.name.toLowerCase().match('sheeting') ||
        scheduleDate.name.toLowerCase().match('insulation'),
    },style:({
      background: scheduleDate.calendarColor,
      color: _vm.pickTextColor(scheduleDate.calendarColor),
    })},[_c('div',{staticClass:"time"},[_c('strong',[_vm._v(_vm._s(scheduleDate.dateTime.format('h:mm A')))]),_vm._v(" - "+_vm._s(scheduleDate.name)+" "),(scheduleDate.order)?_c('span',[_vm._v(" ("+_vm._s(scheduleDate.order.type)+") ")]):_vm._e()]),(scheduleDate.order)?_c('div',[_vm._v(" "+_vm._s(scheduleDate.order.jobName)+" - "+_vm._s(scheduleDate.order.customer.name)+" ")]):_vm._e(),(
        scheduleDate.order && (scheduleDate.assignedTo || scheduleDate.memo)
      )?_c('div',[_vm._v(" "+_vm._s(scheduleDate.assignedTo)+" - "+_vm._s(scheduleDate.memo)+" ")]):_vm._e(),(scheduleDate.customer)?_c('div',[_vm._v(_vm._s(scheduleDate.customer.name))]):_vm._e()])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }