<template>
  <div class="trade-shows page-wrapper">
    <div class="loader text-center mt-5" v-show="loading">
      <CircleLoader :size="45"></CircleLoader>
    </div>
    <div class="trade-shows" v-show="!loading">
      <h3>Trade Shows</h3>
      <div class="mb-3">
        <b-button
          variant="primary"
          @click="$bvModal.show('add-trade-show-modal')"
          >Add Trade Show</b-button
        >
      </div>
      <div class="text-center" v-if="tradeShows.length == 0">
        - no trade shows -
      </div>
      <div class="" v-if="tradeShows.length > 0">
        <trade-show-list
          :show-on-load="showOnLoad"
          :trade-shows="tradeShows"
        ></trade-show-list>
      </div>
    </div>
    <b-modal
      size="xl"
      id="add-trade-show-modal"
      title="Add Trade Show"
      hide-footer
    >
      <add-trade-show-page></add-trade-show-page>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment'
import CircleLoader from '@/components/CircleLoader.vue'
import TradeShowList from '../components/TradeShowList.vue'
import AddTradeShowPage from '../views/AddTradeShowPage.vue'

export default {
  name: 'ManageTradeShowsPage',
  components: { CircleLoader, TradeShowList, AddTradeShowPage },
  inject: {
    userStore,
  },
  props: {
    showOnLoad: {
      type: Number,
    },
  },
  data: function () {
    return {
      loading: true,
      tradeShows: [],
    }
  },
  methods: {
    getTradeShows() {
      return axios
        .get('/services/trade-shows/lookup')
        .then(response => {
          this.tradeShows = response.data.tradeShows
        })
        .catch(err => {
          console.log('err', err)
        })
    },
  },
  computed: {},
  mounted() {
    return Promise.all([this.getTradeShows()]).then(responses => {
      this.loading = false
    })
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
