<template>
  <div class="bom">
      <button class="btn btn-sm btn-light show-detail-button" @click="showDetail = !showDetail">{{(showDetail) ? 'Hide Detail' : 'Show Detail'}}</button>
      <!-- todo -- add colors to BOM -->
    <table class="bom-table">
        <thead>
            <td>Item</td>
            <td>Unit Price</td>
            <td>Price</td>
        </thead>
        <tbody v-show="showDetail">
            <tr v-for="(item, index) in labor" :key=index>
                <td>{{item.name}}</td>
                <td>{{item.unitPrice | currencyFilter}}</td>
                <td>{{item.price | currencyFilter}}</td>
            </tr>
        </tbody>
        <tfoot class="bom-total-row">
            <tr>
                <td>&emsp;</td>
                <td>Total: </td>
                <td>{{total | currencyFilter}}</td>
            </tr>
        </tfoot>
    </table>
  </div>
</template>

<script>

export default {
    name: 'ManufacuringLabor',
    props: {
        labor: {
            type: Array,
            required: true
        },
        total: {
            type: Number,
            required: false,
        },
    },
    data: function() {
        return {
            showDetail: false,
        }
    },
}
</script>

<style lang="scss" scoped>

    .bom {
        text-align: left;
    }
    .bom-table {
        width: 100%;
        text-align: left;
        font-size: 14px;

        thead {
            text-decoration: underline;
        }
    }

    .bom-total-row {
        font-weight: bold;
        border-top: 1px dashed darkslategray;        
    }

    .show-detail-button {
        font-size: 12px;
    }

</style>