<template>
  <div class="row">
    <div class="col">
      <form @submit.prevent="submitSearch">
        <div class="row mb-3">
          <div class="col-12 col-sm-4">
            <b-input
              type="text"
              v-model="vendorStore.state.query.name"
              placeholder="Name"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="vendorStore.state.query.email"
              placeholder="Email"
              class="mt-2"
            ></b-input>
            <b-button
              type="submit"
              variant="info"
              :disabled="submitting"
              class="mt-2"
              >Submit Search</b-button
            >
            <b-button
              type="button"
              variant="secondary"
              :disabled="submitting"
              class="mt-2 ml-2"
              @click="clearSearch"
              >Clear Search</b-button
            >
          </div>
          <div class="col-12 col-sm-4">
            <b-input
              type="text"
              v-model="vendorStore.state.query.phone"
              placeholder="Phone"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="vendorStore.state.query.id"
              placeholder="Vendor ID"
              class="mt-2"
            ></b-input>
          </div>
          <div class="col-12 col-sm-4"></div>
        </div>
        <div
          v-if="
            vendorStore.state.querySubmitted &&
            vendorStore.state.searchResults.length > 0
          "
        >
          Search results ({{ vendorStore.state.searchResults.length }})
          <vendor-list
            :vendors="vendorStore.state.searchResults"
            linkAction="detail"
          ></vendor-list>
        </div>
        <div
          v-if="
            vendorStore.state.querySubmitted &&
            vendorStore.state.searchResults.length == 0
          "
        >
          No search results found
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import VendorList from '@/components/VendorList'
import axios from 'axios'

export default {
  name: 'VendorLookup',
  components: { VendorList },
  inject: ['vendorStore'],
  data: function () {
    return {
      submitting: false,
      error: false,
    }
  },
  methods: {
    submitSearch() {
      this.submitting = true
      axios
        .get(
          '/services/vendors/lookup?email=' +
            encodeURIComponent(this.vendorStore.state.query.email) +
            '&name=' +
            encodeURIComponent(this.vendorStore.state.query.name) +
            '&phone=' +
            this.vendorStore.state.query.phone +
            '&id=' +
            this.vendorStore.state.query.id,
        )
        .then(response => {
          this.vendorStore.setSearchResults(response.data.vendors)
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    clearSearch() {
      this.vendorStore.clearSearchResults()
      this.vendorStore.clearQuery()
    },
  },
}
</script>

<style lang="scss" scoped></style>
