<template>
  <div class="work-orders" :class="{ 'read-only': readOnly }">
    <b-form @submit.prevent="updateWorkOrders()">
      <div
        v-for="(status, step) in order.workflow.manufacturing.workOrders"
        :key="step"
      >
        <!-- <label :for="step">{{ step }}</label> -->
        <!-- <b-form-select
                  :id="step"
                  v-model="newWorkOrders[step]"
                  :options="workOrderStatuses"
                  required
                  :disabled="submitting"
                ></b-form-select> -->
        <b-form-group :label="step | capitalize">
          <b-form-radio-group
            :id="step"
            v-model="newWorkOrders[step]"
            :options="workOrderStatuses"
            :name="step"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div id="ctas" v-if="!readOnly">
        <div class="ctas text-right mt-4">
          <b-button
            variant="primary"
            type="submit"
            class="mx-2"
            :disabled="submitting"
          >
            <span v-show="submitting">Submitting</span>
            <span v-show="!submitting">Update Work Orders</span>
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { workOrderStatuses } from './orderInputOptions'

export default {
  name: 'ManufacturingWorkOrders',
  props: {
    order: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: function () {
    return {
      submitting: false,
      newWorkOrders: _.clone(this.order.workflow.manufacturing.workOrders),
      workOrderStatuses,
    }
  },
  methods: {
    updateWorkOrders() {
      this.submitting = true
      const data = {
        id: this.order.id,
        workOrders: this.newWorkOrders,
      }
      const orderNumber = this.order.salesOrderNumber || ''

      return axios
        .put('/services/orders/work-orders', data)
        .then(response => {
          this.order.workflow.manufacturing.workOrders =
            response.data.order.workflow.manufacturing.workOrders
          this.order.events = response.data.order.events
          this.$toasted.success(`Work orders updated`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(error => {
          this.$toasted.error(` Error updating order ${orderNumber}`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.read-only {
  pointer-events: none;
}
</style>
