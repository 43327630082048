<template>
  <div class="notes">
    <div class="row" v-if="salesLead.notes">
      <div class="col-12">
        <notes-list class="notes-list" :notes="notes"></notes-list>
        <form @submit.prevent="addNote">
          <b-textarea
            class="my-2"
            id="buildingNotes"
            v-model="newNote"
            :required="true"
          ></b-textarea>
          <div class="submit-btn-wrapper">
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="submit-btn"
              :disabled="submitting"
            >
              <span v-if="submitting">Submitting</span>
              <span v-if="!submitting">Add Note</span>
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NotesList from '@/components/NotesList'
import axios from 'axios'

export default {
  name: 'SalesLeadNotes',
  components: { NotesList },
  props: {
    salesLead: {
      type: Object,
      required: true,
    },
    notesFilter: {
      type: Array,
      required: false,
    },
  },
  data: function () {
    return {
      newNote: '',
      submitting: false,
    }
  },
  computed: {
    notes() {
      if (this.notesFilter) {
        return this.salesLead.notes.filter(note => {
          return this.notesFilter.includes(note.department)
        })
      } else {
        return this.salesLead.notes
      }
    },
  },
  methods: {
    addNote() {
      this.submitting = true
      const data = {
        _id: this.salesLead._id,
        note: {
          text: this.newNote,
        },
      }
      return axios
        .post('/services/sales-leads/add-note', data)
        .then(response => {
          this.salesLead.notes = response.data.notes
          this.newNote = ''
        })
        .catch(error => {
          this.$toasted.error(`Error adding note to salesLead`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.submit-btn-wrapper {
  text-align: right;
}
.notes-list {
  max-height: 400px;
  overflow: scroll;
}
</style>
