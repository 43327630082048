<template>
  <div class="row">
    <div class="col col-12 col-sm-6">
      BUILDINGS
      <b-table :items="orderStats.buildings" :fields="fields"> </b-table>
    </div>
    <div class="col col-12 col-sm-6">
      MATERIALS
      <b-table :items="orderStats.materials" :fields="fields"> </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'OrderStats',
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
      fields: [{ key: 'status' }, { key: 'count' }, { key: 'totalValue' }],
    }
  },
  computed: {
    orderStats() {
      const orderStats = {}
      const amountTotals = {
        BUILDING: {
          'IN-PROGRESS': 0,
          'ON-HOLD': 0,
          OPEN: 0,
          READY: 0,
        },
        MATERIALS: {
          'IN-PROGRESS': 0,
          'ON-HOLD': 0,
          OPEN: 0,
          READY: 0,
        },
      }
      const statusCount = {
        BUILDING: {
          'IN-PROGRESS': 0,
          'ON-HOLD': 0,
          OPEN: 0,
          READY: 0,
        },
        MATERIALS: {
          'IN-PROGRESS': 0,
          'ON-HOLD': 0,
          OPEN: 0,
          READY: 0,
        },
      }
      _.each(this.orders, order => {
        amountTotals[order.type][order.status] += order.totalAmount || 0
        statusCount[order.type][order.status] += 1
      })

      orderStats.materials = [
        {
          status: 'IN-PROGRESS',
          count: statusCount['MATERIALS']['IN-PROGRESS'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['MATERIALS']['IN-PROGRESS'],
          ),
        },
        {
          status: 'OPEN',
          count: statusCount['MATERIALS']['OPEN'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['MATERIALS']['OPEN'],
          ),
        },
        {
          status: 'ON-HOLD',
          count: statusCount['MATERIALS']['ON-HOLD'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['MATERIALS']['ON-HOLD'],
          ),
        },
        {
          status: 'READY',
          count: statusCount['MATERIALS']['READY'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['MATERIALS']['READY'],
          ),
        },
      ]
      orderStats.buildings = [
        {
          status: 'IN-PROGRESS',
          count: statusCount['BUILDING']['IN-PROGRESS'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['BUILDING']['IN-PROGRESS'],
          ),
        },
        {
          status: 'OPEN',
          count: statusCount['BUILDING']['OPEN'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['BUILDING']['OPEN'],
          ),
        },
        {
          status: 'ON-HOLD',
          count: statusCount['BUILDING']['ON-HOLD'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['BUILDING']['ON-HOLD'],
          ),
        },
        {
          status: 'READY',
          count: statusCount['BUILDING']['READY'],
          totalValue: this.$options.filters.currencyFilter(
            amountTotals['BUILDING']['READY'],
          ),
        },
      ]
      return orderStats
    },
  },
}
</script>

<style></style>
