<template>
  <div class="timeline">
    <div
      class="timeline-item architecture"
      v-if="order.workflow.architecture.doArchitecture"
    >
      <div class="item-name">architecture</div>
      <span class="target-start-date">
        {{ order.workflow.architecture.targetStartDate | shortDateFilter }}
      </span>
      <span class="target-complete-date">
        {{ order.workflow.architecture.targetCompleteDate | shortDateFilter }}
      </span>
      <div class="item-status">
        {{ order.workflow.architecture.status }}
      </div>
    </div>
    <div
      class="timeline-item engineering"
      v-if="order.workflow.engineering.doEngineering"
    >
      <div class="item-name">engineering</div>
      <span class="target-start-date">
        {{ order.workflow.engineering.targetStartDate | shortDateFilter }}
      </span>
      <span class="target-complete-date">
        {{ order.workflow.engineering.targetCompleteDate | shortDateFilter }}
      </span>
      <div class="item-status">
        {{ order.workflow.engineering.status }}
      </div>
    </div>
    <div
      class="timeline-item permitting"
      v-if="order.workflow.permitting.doPermitting"
    >
      <div class="item-name">permitting</div>
      <span class="target-start-date">
        {{ order.workflow.permitting.targetStartDate | shortDateFilter }}
      </span>
      <span class="target-complete-date">
        {{ order.workflow.permitting.targetCompleteDate | shortDateFilter }}
      </span>
      <div class="item-status">
        {{ order.workflow.permitting.status }}
      </div>
    </div>
    <div
      class="timeline-item timeline-item-wrapper"
      v-bind:class="{
        'install-and-manufacturing': order.workflow.install.doInstall,
      }"
      v-if="order.type != 'ENGINEERING'"
    >
      <div
        class="timeline-item manufacturing"
        v-bind:class="{ overlay: order.workflow.install.doInstall }"
      >
        <div class="item-name">manufacturing</div>
        <span class="target-start-date">
          {{ order.workflow.manufacturing.targetStartDate | shortDateFilter }}
        </span>
        <span class="target-complete-date">
          {{
            order.workflow.manufacturing.targetCompleteDate | shortDateFilter
          }}
        </span>
        <div
          class="item-status"
          v-bind:class="{
            'manufacturing-item-status': order.workflow.install.doInstall,
          }"
        >
          {{ order.workflow.manufacturing.status }}
        </div>
      </div>
      <div
        class="timeline-item install"
        v-if="order.workflow.install.doInstall"
      >
        <div class="item-name">install</div>
        <div class="item-status">
          {{ order.workflow.engineering.status }}
        </div>
        <span class="target-complete-date">
          {{ order.workflow.install.targetCompleteDate | shortDateFilter }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderTimeline',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';
.timeline {
  display: flex;
  width: 100%;
  margin: 24px 0px;
}
.timeline-item {
  border: 2px solid gray;
  text-align: center;
  position: relative;
  height: 48px;
}

.item-name {
  font-weight: bold;
  margin-top: 8px;
}
.target-start-date {
  position: absolute;
  left: 6px;
  top: -24px;
  font-weight: 500;
  // font-size: 14px;
  color: black;
}
.target-complete-date {
  position: absolute;
  right: 6px;
  top: -24px;
  font-weight: 500;
  // font-size: 14px;
  color: black;
}
.architecture {
  width: 400px;
  border-color: $architecture-color;
  background-color: $architecture-color;
  color: white;
}
.engineering {
  width: 200px;
  border-color: $engineering-color;
  background-color: $engineering-color;
  color: white;
}
.permitting {
  width: 600px;
  border-color: $permitting-color;
  background-color: $permitting-color;
  color: white;
}
.manufacturing {
  width: 300px;
  border-color: $manufacturing-color;
  background-color: $manufacturing-color;
  color: white;
}
.overlay {
  height: 40px;
  z-index: 1;
}
.install {
  width: 450px;
  position: absolute;
  top: 0;
  left: 150px;
  right: 0;
  border-color: $install-color;
  background-color: $install-color;
  color: white;
}
.install-and-manufacturing {
  width: 600px;
  border: none;
  position: relative;
  background-color: #66a5ad;
}
.item-status {
  position: absolute;
  bottom: -26px;
  color: black;
  right: 0px;
  left: 0px;
  font-weight: 500;
}
.manufacturing-item-status {
  bottom: -38px;
}

.timeline-item-wrapper {
  border: none;
}
</style>
