<template>
  <div class="workflow">
      <h4>Workflow</h4>
      <div class="page-content">
         Coming soon! 
      </div>
  </div>
</template>

<script>

export default {
    name: 'Workflow',
    props: {
        building: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {

        }
    },
    computed: {
        customerName() {
            if (this.building.customer.lastName) {
                return this.building.customer.firstName + ' ' + this.building.customer.lastName;
            } else {
                return this.building.customer.email;
            }  
        },
    }
}
</script>

<style scoped>
.label {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}
@media print {
    .workflow {
        padding: 36px 30px 60px 30px;
    }

    .page-content {
        font-size: 22px;
        max-width: 80%;
        margin: auto;
        margin-top: 48px;
    }
}
</style>