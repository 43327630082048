var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-name-wrapper"},[(
      !_vm.file.name.match('pdf') &&
      !_vm.file.name.match('png') &&
      !_vm.file.name.match('jpg') &&
      !_vm.file.name.match('jpeg') &&
      !_vm.file.name.match('xls') &&
      !_vm.file.name.match('doc') &&
      !_vm.file.name.match('gif') &&
      !_vm.file.name.match('zip')
    )?_c('img',{attrs:{"src":require("@/assets/file.png")}}):_vm._e(),(_vm.file.name.match('pdf'))?_c('img',{attrs:{"src":require("@/assets/pdf.png")}}):_vm._e(),(_vm.file.name.match('png'))?_c('img',{attrs:{"src":require("@/assets/png.png")}}):_vm._e(),(_vm.file.name.match('zip'))?_c('img',{attrs:{"src":require("@/assets/zip.png")}}):_vm._e(),(_vm.file.name.match('jpg'))?_c('img',{attrs:{"src":require("@/assets/jpg.png")}}):_vm._e(),(_vm.file.name.match('jpeg'))?_c('img',{attrs:{"src":require("@/assets/jpg.png")}}):_vm._e(),(_vm.file.name.match('doc'))?_c('img',{attrs:{"src":require("@/assets/doc.png")}}):_vm._e(),(_vm.file.name.match('gif'))?_c('img',{attrs:{"src":require("@/assets/gif.png")}}):_vm._e(),(_vm.file.name.match('xls'))?_c('img',{attrs:{"src":require("@/assets/xls.png")}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }