<template>
  <div class="home page-wrapper">
    <h1 class="page-h1">Dashboard</h1>
    <b-alert show class="notification" variant="danger" v-if="pastDueTasks">
      There are {{ pastDueTasks }} Past Due Tasks across all Departments.
    </b-alert>
    <b-alert
      show
      class="notification"
      variant="primary"
      v-if="openTasks || inProgressTasks"
    >
      You have {{ openTasks }} Open Tasks and {{ inProgressTasks }} In-Progress
      Tasks. <a href="/app/tasks/">Go to My Tasks page.</a>
    </b-alert>
    <h3>Current Tasks By Department</h3>
    <div v-if="loadingTaskStats" style="height: 200px">
      <circle-loader :size="30"></circle-loader>
    </div>
    <tasks-summary
      class="task-stats"
      :stats="taskStats"
      v-if="!loadingTaskStats"
    ></tasks-summary>
    <div
      class="mt-4"
      v-if="userStore.user.type === 'SALES' || userStore.user.type === 'ADMIN'"
    >
      <h3>Buildings</h3>
      <router-link class="mx-2" to="/app/pricer">
        <b-button variant="primary">New Building</b-button>
      </router-link>
      <router-link class="mx-2" to="/app/buildings">
        <b-button variant="primary">Manage Buildings</b-button>
      </router-link>
    </div>

    <div class="mt-3">
      <h3>Orders</h3>
      <router-link
        class="mx-2"
        to="/app/orders/new"
        v-if="
          userStore.user.type === 'SALES' || userStore.user.type === 'ADMIN'
        "
      >
        <b-button variant="primary">Create Order</b-button>
      </router-link>
      <router-link
        class="mx-2"
        to="/app/orders/import"
        v-if="
          userStore.user.type === 'SALES' || userStore.user.type === 'ADMIN'
        "
      >
        <b-button variant="primary">Import Orders</b-button>
      </router-link>
      <router-link
        class="mx-2"
        to="/app/orders"
        v-if="
          userStore.user.type === 'SALES' || userStore.user.type === 'ADMIN'
        "
      >
        <b-button variant="primary">Manage Orders</b-button>
      </router-link>
      <router-link
        class="mx-2"
        to="/app/orders/current"
        v-if="
          userStore.user.type === 'MANUFACTURING' ||
          userStore.user.type === 'ADMIN' ||
          userStore.user.type === 'SALES'
        "
      >
        <b-button variant="primary">Current Orders</b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import TasksSummary from '../components/TasksSummary.vue'

export default {
  name: 'Home',
  components: { TasksSummary },
  inject: {
    userStore,
  },
  data: function () {
    return {
      recentCustomerBuildings: [],
      recentPartnerBuildings: [],
      currentUserBuildings: [],
      recentBuildings: [],
      loadingTaskStats: true,
      taskStats: { userTasks: [], totalTasks: [] },
    }
  },
  computed: {
    openTasks() {
      const openTasks = _.find(this.taskStats.userTasks, stat => {
        return stat._id == 'OPEN'
      })
      if (openTasks) {
        return openTasks.total
      } else {
        return 0
      }
    },
    pastDueTasks() {
      let numTasks = 0
      _.each(this.taskStats.pastDueTasks, stat => {
        console.log('stat ', stat)
        if (stat.total) numTasks += stat.total
      })
      return numTasks
    },
    inProgressTasks() {
      const inProgressTasks = _.find(this.taskStats.userTasks, stat => {
        return stat._id == 'IN-PROGRESS' || stat._id == 'BLOCKED'
      })
      if (inProgressTasks) {
        return inProgressTasks.total
      } else {
        return 0
      }
    },
  },
  methods: {
    loadBuilding(building) {
      this.$router.push({
        name: 'PricerPage',
        params: {
          config: building,
        },
      })
    },
    getUser() {
      return axios
        .get('/services/user')
        .then(response => {
          this.user = response.data.user
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getUserRecentBuildings() {
      return axios
        .get('/services/buildings/currentUser')
        .then(response => {
          this.currentUserBuildings = response.data.buildings
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getTaskStats() {
      return axios
        .get('/services/tasks/stats')
        .then(response => {
          this.taskStats = response.data
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loadingTaskStats = false
        })
    },
    getRecentBuildings() {
      return axios
        .get('/services/buildings/recent')
        .then(response => {
          this.recentBuildings = response.data.buildings
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getRecentCustomerBuildings() {
      return axios
        .get('/services/buildings/recentCustomer')
        .then(response => {
          this.recentCustomerBuildings = response.data.buildings
        })
        .catch(err => {
          console.log('err', err)
        })
    },
  },
  mounted() {
    if (userStore.user.type === 'SALES' || userStore.user.type === 'ADMIN') {
      this.getUserRecentBuildings()
      this.getRecentBuildings()
    }
    this.getTaskStats()
    // this.getRecentCustomerBuildings();
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.task-stats {
  max-width: 800px;
}
</style>
