<template>
  <div class="orders page-wrapper" id="orders">
    <h1 class="page-h1">Current Orders</h1>
    <div class="print-wrapper">
      <span class="date mr-2">{{ date | dateTimeFilter }}</span>
      <b-button
        variant="secondary"
        size="sm"
        class="print-btn"
        @click="printCurrentOrders"
        >Print</b-button
      >
    </div>
    <div class="row header-row">
      <div class="col col-4 legend">
        <div><strong>Legend</strong></div>
        <div class="legend-item">
          <div class="legend-item-icon past-due">&#x25A0;</div>
          Past Due
        </div>
        <div class="legend-item">
          <div class="legend-item-icon time-left">&#x25A0;</div>
          Time Left
        </div>
        <div class="legend-item">
          <div class="legend-item-icon requires-vendor-order">&#x25A0;</div>
          Requires Vendor Order
        </div>
      </div>
      <div class="col col-8 mt-4">
        <div class="order-stats text-center" v-show="!loading">
          <div class="row">
            <div class="col">
              <div>Past Due</div>
              <div class="orders-due-count">
                {{ pastDueOrders.length }}
              </div>
            </div>
            <div class="col">
              <div>Due Today</div>
              <div class="orders-due-count">
                {{ todaysOpenOrders.length }}
              </div>
            </div>
            <div class="col">
              <div>Due Next Bus Day</div>
              <div class="orders-due-count">
                {{ nextBusDayOrders.length }}
              </div>
            </div>
            <div class="col">
              <div>On Hold</div>
              <div class="orders-due-count">
                {{ onHoldOrders.length }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-list" v-show="!loading">
      <h3>Materials Orders ({{ openMaterialsOrders.length }})</h3>
      <OrderList
        :orders="openMaterialsOrders"
        :optionalFields="{ timeLeft: true, salesOrderNumber: true }"
      ></OrderList>
      <h3>Building Orders ({{ openBuildingOrders.length }})</h3>
      <OrderList
        :orders="openBuildingOrders"
        :optionalFields="{ timeLeft: true, salesOrderNumber: true }"
      ></OrderList>
      <h3>Orders Ready ({{ readyOrders.length }})</h3>
      <OrderList
        :orders="readyOrders"
        :optionalFields="{
          lastContacted: true,
          type: true,
          deliveryMethod: true,
        }"
      ></OrderList>
      <h3>Orders On Hold ({{ onHoldOrders.length }})</h3>
      <OrderList
        :orders="onHoldOrders"
        :optionalFields="{
          type: true,
          deliveryMethod: true,
          timeLeft: true,
          holdReason: true,
        }"
      ></OrderList>
    </div>
    <div class="order-list text-center mt-5" v-show="loading">
      <circle-loader :size="40"></circle-loader>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import OrderList from '@/components/OrderList.vue'
import moment from 'moment-business-days'
import CircleLoader from '@/components/CircleLoader.vue'

export default {
  name: 'CurrentOrdersPage',
  components: { OrderList, CircleLoader },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      getOrdersInterval: null,
      currentOrders: [],
      date: new Date(),
    }
  },
  methods: {
    getCurrentOrders() {
      return axios
        .get('/services/orders/current')
        .then(response => {
          _.each(response.data.orders, order => {
            if (order.workflow.sales.dueDate) {
              let minutesLeft = moment(order.workflow.sales.dueDate).diff(
                new Date(),
                'minutes',
              )
              const prefix = minutesLeft < 0 ? '+' : ''
              minutesLeft = Math.abs(minutesLeft)
              order.timeLeft =
                prefix +
                Math.floor(minutesLeft / 24 / 60) +
                ':' +
                Math.floor((minutesLeft / 60) % 24) +
                ':' +
                (minutesLeft % 60)
            }
          })
          this.currentOrders = response.data.orders
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    printCurrentOrders() {
      this.date = new Date()
      const prtHtml = document.getElementById('orders').innerHTML
      let stylesHtml = ''
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML
      }
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      )

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
      <head>
          ${stylesHtml}
      </head>
      <body>
          ${prtHtml}
      </body>
      </html>`)

      window.setTimeout(function () {
        WinPrint.focus()
        WinPrint.print()
      }, 4000)

      window.setTimeout(function () {
        WinPrint.document.close()
        WinPrint.close()
      }, 5000)
    },
  },
  computed: {
    pastDueOrders() {
      var pastDueOrders = []
      _.each(this.currentOrders, order => {
        if (
          (order.status === 'OPEN' ||
            order.status === 'IN-PROGRESS' ||
            order.status === 'ON-HOLD') &&
          order.workflow.sales.dueDate
        ) {
          const endOfYesterday = moment().subtract('1', 'day').endOf('day')
          if (moment(order.workflow.sales.dueDate).isBefore(endOfYesterday)) {
            pastDueOrders.push(order)
          }
        }
      })
      return pastDueOrders
    },
    todaysOpenOrders() {
      var todaysOpenOrders = []
      _.each(this.currentOrders, order => {
        if (
          (order.status === 'OPEN' || order.status === 'IN-PROGRESS') &&
          order.workflow.sales.dueDate
        ) {
          if (
            moment(order.workflow.sales.dueDate).isBefore(
              moment().endOf('day'),
            ) &&
            moment(order.workflow.sales.dueDate).isAfter(
              moment().startOf('day'),
            )
          ) {
            todaysOpenOrders.push(order)
          }
        }
      })
      return todaysOpenOrders
    },
    openMaterialsOrders() {
      var openMaterialsOrders = []
      _.each(this.currentOrders, order => {
        if (
          (order.status === 'OPEN' || order.status === 'IN-PROGRESS') &&
          order.type === 'MATERIALS'
        ) {
          openMaterialsOrders.push(order)
        }
      })
      return openMaterialsOrders
    },
    openBuildingOrders() {
      var openBuildingOrders = []
      _.each(this.currentOrders, order => {
        if (
          (order.status === 'OPEN' || order.status === 'IN-PROGRESS') &&
          order.type === 'BUILDING'
        ) {
          openBuildingOrders.push(order)
        }
      })
      return openBuildingOrders
    },
    nextBusDayOrders() {
      var todaysOpenOrders = []
      _.each(this.currentOrders, order => {
        if (
          (order.status === 'OPEN' || order.status === 'IN-PROGRESS') &&
          order.workflow.sales.dueDate
        ) {
          if (
            moment(order.workflow.sales.dueDate).isBefore(
              moment().businessAdd(1).endOf('day'),
            ) &&
            moment(order.workflow.sales.dueDate).isAfter(
              moment().businessAdd(1).startOf('day'),
            )
          ) {
            todaysOpenOrders.push(order)
          }
        }
      })
      return todaysOpenOrders
    },
    readyOrders() {
      var readyOrders = []
      _.each(this.currentOrders, order => {
        if (order.status === 'READY') readyOrders.push(order)
      })
      return readyOrders
    },
    onHoldOrders() {
      var onHoldOrders = []
      _.each(this.currentOrders, order => {
        if (order.status === 'ON-HOLD') onHoldOrders.push(order)
      })
      return onHoldOrders
    },
  },
  mounted() {
    this.getCurrentOrders()
    this.getOrdersInterval = setInterval(() => {
      this.getCurrentOrders()
    }, 60000 * 5)
  },
  beforeDestroy() {
    clearInterval(this.getOrdersInterval)
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}

.order-stats {
  width: 90%;
  margin: 0px 0px 6px 0px;
}

.print-wrapper {
  position: absolute;
  top: 60px;
  right: 6px;
  text-align: right;
}

.print-btn {
  margin-right: 6px;
}

@media print {
  .print-wrapper {
    top: 0px;
  }
  .print-btn {
    display: none;
  }
}

@media print, screen {
  .legend-item-icon {
    display: inline-block;
    font-size: 30px;
    color: black;
    margin-right: 2px;
    line-height: 14px;

    &.past-due {
      color: #ff2600;
    }
    &.time-left {
      color: #02c60b;
    }

    &.requires-vendor-order {
      color: #ff9d00;
    }
  }
  .legend-item {
    display: inline-block;
    margin: 6px;
  }
}
</style>
