var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-page page-wrapper"},[_c('h1',{staticClass:"page-h1"},[_vm._v("Calendar")]),_c('div',{staticClass:"legend"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.vendor =
            !_vm.scheduleDateStore.calendarFilter.vendor}}},[_c('span',{staticClass:"legend-color vendor",class:{ enabled: _vm.scheduleDateStore.calendarFilter.vendor }}),_c('span',{staticClass:"legend-label"},[_vm._v("Vendor Order/Delivery")])]),_c('div',{staticClass:"col",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.delivery =
            !_vm.scheduleDateStore.calendarFilter.delivery}}},[_c('span',{staticClass:"legend-color delivery",class:{ enabled: _vm.scheduleDateStore.calendarFilter.delivery }}),_c('span',{staticClass:"legend-label"},[_vm._v("Delivery")])]),_c('div',{staticClass:"col",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.install =
            !_vm.scheduleDateStore.calendarFilter.install}}},[_c('span',{staticClass:"legend-color install",class:{ enabled: _vm.scheduleDateStore.calendarFilter.install }}),_c('span',{staticClass:"legend-label"},[_vm._v("Install")])]),_c('div',{staticClass:"col"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.$bvModal.show('incoming-payments-modal')}}},[_vm._v("Incoming $")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.holiday =
            !_vm.scheduleDateStore.calendarFilter.holiday}}},[_c('span',{staticClass:"legend-color holiday",class:{ enabled: _vm.scheduleDateStore.calendarFilter.holiday }}),_c('span',{staticClass:"legend-label"},[_vm._v("Holiday/Time Off")])]),_c('div',{staticClass:"col",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.offsite =
            !_vm.scheduleDateStore.calendarFilter.offsite}}},[_c('span',{staticClass:"legend-color offsite",class:{ enabled: _vm.scheduleDateStore.calendarFilter.offsite }}),_c('span',{staticClass:"legend-label"},[_vm._v("Offsite")])]),_c('div',{staticClass:"col",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.concrete =
            !_vm.scheduleDateStore.calendarFilter.concrete}}},[_c('span',{staticClass:"legend-color concrete",class:{ enabled: _vm.scheduleDateStore.calendarFilter.concrete }}),_c('span',{staticClass:"legend-label"},[_vm._v("Concrete")])]),_c('div',{staticClass:"col",on:{"click":function($event){_vm.scheduleDateStore.calendarFilter.inspection =
            !_vm.scheduleDateStore.calendarFilter.inspection}}},[_c('span',{staticClass:"legend-color inspection",class:{ enabled: _vm.scheduleDateStore.calendarFilter.inspection }}),_c('span',{staticClass:"legend-label"},[_vm._v("Inspection")])])])]),_c('b-button',{staticClass:"add-date-btn",attrs:{"variant":"info"},on:{"click":function($event){return _vm.$bvModal.show('add-schedule-date-modal')}}},[_vm._v(" + Date ")]),(_vm.loading)?_c('div',{staticClass:"loader"},[_c('circle-loader',{attrs:{"size":40}})],1):_vm._e(),(!_vm.loading)?_c('OrderCalendar',{attrs:{"orders":_vm.currentOrders,"calendarData":_vm.calendarData,"recurringDates":_vm.filteredRecurringDates}}):_vm._e(),_c('b-modal',{attrs:{"id":"add-schedule-date-modal","title":"Add Calendar Event","hide-footer":"","size":"lg"}},[_c('add-schedule-date')],1),(_vm.currentOrders && _vm.currentOrders.length)?_c('b-modal',{attrs:{"id":"incoming-payments-modal","title":"Incoming Payments","hide-footer":"","size":"lg"}},[_c('incoming-payments',{attrs:{"currentOrders":_vm.currentOrders}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }