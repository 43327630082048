<template>
  <form @submit.prevent="addTask">
    <task-form-fields :task="task" :submitting="submitting"></task-form-fields>
    <div class="modal-ctas mt-4">
      <b-button variant="primary" :disabled="submitting" type="submit">
        <span v-if="submitting">Saving..</span>
        <span v-if="!submitting">Save</span>
      </b-button>
      <b-button
        variant="secondary"
        type="button"
        @click="$bvModal.hide('add-task-modal')"
        :disabled="submitting"
        class="ml-2"
        >Cancel</b-button
      >
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import TaskFormFields from './TaskFormFields.vue'

export default {
  name: 'AddTask',
  props: {},
  components: { TaskFormFields },
  inject: ['userStore', 'taskStore', 'customerStore'],
  data: function () {
    return {
      submitting: false,
      task: {
        name: '',
        dateTime: null,
        endDateTime: null,
        startTime: '',
        allDayEvent: false,
        endTime: '',
        assignedTo: '',
        completed: false,
        memo: '',
        referenceObject: '',
        referenceId: '',
      },
    }
  },
  watch: {},
  methods: {
    addTask() {
      const data = this.task
      if (!this.task.dueDate) {
        return this.$toasted.error('Error: Must Select Date', {
          duration: 3000,
        })
      }
      if (this.task.customerName) {
        let customer = _.find(this.customerStore.customers, customer => {
          return customer.name === this.task.customerName
        })
        if (!customer) {
          return this.$toasted.error(
            'Error: Must Select a Customer from the List, or Leave Customer Blank',
            {
              duration: 3000,
            },
          )
        }
      } else {
        this.task.customerId = '' // ensure id is blank too when user clears out customer.... ehh dont like it
      }
      if (this.task.dueTime) {
        // set time on dueDate?
      }
      this.submitting = true
      return axios
        .post('/services/tasks', data)
        .then(response => {
          this.$toasted.success(`Added Task`, {
            duration: 2500,
          })
          this.taskStore.addTask(response.data.task)
          this.$bvModal.hide('add-task-modal')
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Adding Task`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.custom-checkbox {
  display: inline-block;
  margin-left: 4px;
}
</style>
