<template>
  <div class="vendor-order-list">
    <div v-if="loading">
      <CircleLoader></CircleLoader>
    </div>
    <div v-if="!loading">
      <div v-if="orders.length">
        <b-table
          striped
          :sticky-header="true"
          hover
          @row-clicked="goToOrder"
          :items="orders"
          :fields="fields"
        ></b-table>
      </div>
      <div v-if="!orders.length">- No orders -</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CircleLoader from '@/components/CircleLoader.vue'
import axios from 'axios'

export default {
  name: 'VendorOrderList',
  components: { CircleLoader },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      orders: null,
      loading: true,
      fields: [
        {
          key: 'id',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'type',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'deliveryMethod',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'status',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  computed: {},
  mounted() {
    this.getOrders()
  },
  methods: {
    goToOrder(order, index, e) {
      if (e.metaKey) {
        window.open('/app/orders/' + order.id, '_blank')
      } else {
        this.$router.push('/app/orders/' + order.id)
      }
    },
    async getOrders() {
      try {
        let id = encodeURIComponent(this.vendor._id)
        let response = await axios.get(
          '/services/vendors/orders?vendorId=' + id,
        )

        this.orders = response.data.orders.map(order => {
          const { id, type, deliveryMethod, status } = order
          return {
            id,
            type,
            deliveryMethod,
            status,
          }
        })
      } catch (error) {
        console.log('error: ', error)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
