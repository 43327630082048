<template>
  <div class="building-created-chart">
    <apexchart type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { userStore } from '@/stores/userStore'

export default {
  name: 'RecentBuildingsCreatedChart',
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  inject: {
    userStore,
  },
  data: function () {
    let dates = []
    const NUM_OF_DAYS = 14 // get last 12 dates.

    for (let i = 0; i < NUM_OF_DAYS; i++) {
      let date = moment()
      date.subtract(i, 'day')
      dates.push(date.format('MM-DD'))
    }
    dates = _.reverse(dates)

    return {
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent'],
        },
        xaxis: {
          categories: dates,
        },
        yaxis: {
          title: {
            text: '# of Buildings Priced',
          },
        },
        fill: {
          opacity: 1,
        },
        colors: ['#1aa496'],
        tooltip: {
          y: {
            formatter: function (val) {
              return '# priced ' + val
            },
          },
        },
      },
    }
  },
  methods: {},
  mounted() {},
  computed: {
    // todo: allow switching this to other views like by value, or unique customers
    // todo can we show sales per user and put on tooltip??
    // todo PPULL IN STAST FROM EMAIL LEADS
    // todo: COLORS
    // TODO: ALLLOW TOGGLINGS SALES VS ORDER DATES ON CALENDAR
    series() {
      const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      _.each(this.data, d => {
        let index = this.options.xaxis.categories.indexOf(d.date)
        data[index] = d.count
      })
      return [
        {
          name: '2023',
          data,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped></style>
