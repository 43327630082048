<template>
  <div class="incoming-payments">
    <h4>Today's Payments</h4>
    <b-table
      striped
      hover
      :items="payments.todaysPayments"
      :fields="fields"
      @row-clicked="goToOrder"
    >
    </b-table>
    <div class="mb-5 mt-2">
      Total Incoming Today: <br />{{ incomingToday.actual | currencyFilter }} /
      {{ incomingToday.expected | currencyFilter }}
    </div>
    <h4>This Week's Payments</h4>
    <b-table
      striped
      hover
      :items="payments.thisWeeksPayments"
      :fields="fields"
      @row-clicked="goToOrder"
    >
    </b-table>
    <div class="mb-5 mt-2">
      Total Incoming This Week:<br />
      {{ incomingThisWeek.actual | currencyFilter }} /
      {{ incomingThisWeek.expected | currencyFilter }}
    </div>
    <h4>Next Week's Payments</h4>
    <b-table
      striped
      hover
      :items="payments.nextWeeksPayments"
      :fields="fields"
      @row-clicked="goToOrder"
    >
    </b-table>
    <div class="mb-5 mt-2">
      Total Incoming Next Week: <br />
      {{ incomingNextWeek.actual | currencyFilter }} /
      {{ incomingNextWeek.expected | currencyFilter }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'IncomingPayments',
  props: {
    currentOrders: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data: function () {
    return {
      fields: [
        {
          key: 'name',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'orderId',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'orderType',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'expectedAmount',
          class: ['clickable-row'],
          sortable: true,
        },
        {
          key: 'actualAmount',
          class: ['clickable-row'],
          sortable: true,
        },
      ],
    }
  },
  watch: {},
  methods: {
    goToOrder() {},
  },
  mounted() {},
  computed: {
    payments() {
      let todaysPayments = []
      let thisWeeksPayments = []
      let nextWeeksPayments = []
      let startOfWeek = moment().startOf('week')
      let endOfWeek = moment().endOf('week')
      let endOfNextWeek = moment().endOf('week').add(7, 'days')
      let today = moment()
      _.each(this.currentOrders, order => {
        _.each(order.schedule, sd => {
          if (sd.name === 'Framing' && sd.endDateTime) {
            let framingPayment = _.find(order.payments, p => {
              return p.name === 'Framing'
            })
            if (framingPayment) {
              let paymentObj = {
                orderId: order.id,
                orderType: order.type,
                // could add more data here for
                ...framingPayment,
              }
              console.log('framingPayment: ', framingPayment)
              if (moment(sd.endDateTime).isSame(today, 'day')) {
                todaysPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(startOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfWeek, 'day')
              ) {
                thisWeeksPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(endOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfNextWeek, 'day')
              ) {
                nextWeeksPayments.push(paymentObj)
              }
            }
          }

          if (sd.name === 'Sheeting' && sd.endDateTime) {
            let framingPayment = _.find(order.payments, p => {
              return p.name === 'Sheeting'
            })
            if (framingPayment) {
              let paymentObj = {
                orderId: order.id,
                orderType: order.type,
                // could add more data here for
                ...framingPayment,
              }
              if (moment(sd.endDateTime).isSame(today, 'day')) {
                todaysPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(startOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfWeek, 'day')
              ) {
                thisWeeksPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(endOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfNextWeek, 'day')
              ) {
                nextWeeksPayments.push(paymentObj)
              }
            }
          }

          if (sd.name === 'Concrete' && sd.endDateTime) {
            let framingPayment = _.find(order.payments, p => {
              return p.name === 'Concrete'
            })
            if (framingPayment) {
              let paymentObj = {
                orderId: order.id,
                orderType: order.type,
                // could add more data here for
                ...framingPayment,
              }
              if (moment(sd.endDateTime).isSame(today, 'day')) {
                todaysPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(startOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfWeek, 'day')
              ) {
                thisWeeksPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(endOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfNextWeek, 'day')
              ) {
                nextWeeksPayments.push(paymentObj)
              }
            }
          }

          if (sd.name?.toLowerCase() === 'delivery' && sd.endDateTime) {
            let framingPayment = _.find(order.payments, p => {
              return (
                p.name?.toLowerCase() === 'delivery' ||
                (p.name == 'Initial Payment' && order.type === 'MATERIALS')
              )
            })
            if (framingPayment) {
              let paymentObj = {
                orderId: order.id,
                orderType: order.type,
                // could add more data here for
                ...framingPayment,
              }
              if (moment(sd.endDateTime).isSame(today, 'day')) {
                todaysPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(startOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfWeek, 'day')
              ) {
                thisWeeksPayments.push(paymentObj)
              }
              if (
                moment(sd.endDateTime).isAfter(endOfWeek, 'day') &&
                moment(sd.endDateTime).isBefore(endOfNextWeek, 'day')
              ) {
                nextWeeksPayments.push(paymentObj)
              }
            }
          }
          // delivery should take initial payments if materials order as well as delivery payments
        })
      })
      console.log('todaysPayments', todaysPayments)
      return {
        todaysPayments,
        thisWeeksPayments,
        nextWeeksPayments,
      }
    },
    incomingToday() {
      let expected = 0
      let actual = 0
      _.each(this.payments.todaysPayments, p => {
        expected += parseFloat(p.expectedAmount)
        actual += parseFloat(p.actualAmount || 0)
      })
      return { expected, actual }
    },
    incomingThisWeek() {
      let expected = 0
      let actual = 0
      _.each(this.payments.thisWeeksPayments, p => {
        expected += parseFloat(p.expectedAmount)
        actual += parseFloat(p.actualAmount || 0)
      })
      return { expected, actual }
    },
    incomingNextWeek() {
      let expected = 0
      let actual = 0
      _.each(this.payments.nextWeeksPayments, p => {
        expected += parseFloat(p.expectedAmount)
        actual += parseFloat(p.actualAmount || 0)
      })
      return { expected, actual }
    },
  },
}
</script>

<style lang="scss" scoped></style>
