<template>
  <div class="status-wrapper row">
    <div class="status-display col">
      Status:
      <span
        class="status"
        v-bind:class="{
          open: status === 'OPEN',
          'in-progress': status === 'IN-PROGRESS',
          ready: status === 'READY',
          cancelled: status === 'CANCELLED',
          completed: status === 'COMPLETED',
          'on-hold': status === 'ON-HOLD',
        }"
        >{{ status }}</span
      >
      <span
        class="order-info"
        v-if="order.workflow[this.department].status === 'ON-HOLD'"
      >
        <span class="hold-reason">
          <br />({{ order.workflow[this.department].holdReason }})</span
        >
      </span>
    </div>
    <div class="update-status-form col" v-if="showForm">
      <b-form @submit.prevent="updateOrderStatus()">
        <div id="new-status">
          <label for="status">New Status</label>
          <b-form-select
            id="status"
            v-model="newStatus"
            :options="orderStatuses"
            required
            :disabled="submitting"
          ></b-form-select>
          <!-- <b-form-select
            id="status"
            v-model="newHoldReason"
            :options="holdReasons"
            required
            class="mt-2"
            :disabled="submitting"
            v-if="reasonRequired"
          ></b-form-select> -->
          <b-form-input
            v-model="customHoldReason"
            v-if="newHoldReason == 'Other' && newStatus == 'ON-HOLD'"
            :required="true"
            placeholder="Enter Reason"
            class="mt-2"
          ></b-form-input>
        </div>
        <div id="ctas">
          <div class="ctas text-right mt-2">
            <b-button
              variant="primary"
              type="submit"
              class="mx-2"
              :disabled="submitting"
            >
              <span v-show="submitting">Submitting</span>
              <span v-show="!submitting">Update Status</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { orderStatuses, holdReasons } from './orderInputOptions'
export default {
  name: 'StatusDisplay',
  props: {
    order: {
      type: Object,
      required: true,
    },
    department: {
      type: String,
      required: true,
    },
    showForm: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      newStatus: '',
      orderStatuses,
      holdReasons,
      newHoldReason: 'Other',
      customHoldReason: '',
      submitting: false,
    }
  },
  computed: {
    reasonRequired() {
      return this.newStatus === 'ON-HOLD'
    },
    status() {
      return this.order.workflow[this.department].status
    },
  },
  methods: {
    updateOrderStatus() {
      this.submitting = true
      const data = {
        id: this.order.id,
        status: this.newStatus,
        department: this.department,
      }
      if (this.newStatus === 'ON-HOLD') {
        data.holdReason =
          this.newHoldReason === 'Other'
            ? this.customHoldReason
            : this.newHoldReason
      }
      const orderNumber = this.order.salesOrderNumber || ''

      return axios
        .put('/services/orders/department-status', data)
        .then(response => {
          this.order.workflow[this.department].status = this.newStatus
          this.order.events = response.data.order.events
          this.order.workflow[this.department].holdReason =
            response.data.order.workflow[this.department].holdReason
          this.newStatus = ''
          this.$toasted.success(
            `Order ${orderNumber} updated to ${data.status}`,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
              duration: 4000,
            },
          )
        })
        .catch(error => {
          this.$toasted.error(` Error updating order ${orderNumber}`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.status-display {
  font-size: 20px;
}
.hold-reason {
  font-size: 16px;
}
.status {
  font-weight: bold;
}
.open {
  color: $open-color;
}
.in-progress {
  color: $in-progress-color;
}
.on-hold {
  color: $on-hold-color;
}
.cancelled {
  color: $cancelled-color;
}
.ready {
  color: $ready-color;
}
.completed {
  color: $completed-color;
}
</style>
