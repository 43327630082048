<template>
  <div class="orders page-wrapper">
    <div class="loader text-center mt-5" v-show="loading">
      <CircleLoader :size="45"></CircleLoader>
    </div>
    <div class="current-leads" v-show="!loading">
      <h3>New Leads</h3>
      <div class="text-center" v-if="newLeads.length == 0">
        - no new leads -
      </div>
      <sales-lead-list
        :markSpam="markSpam"
        :assignToCurrentUser="assignToCurrentUser"
        v-if="newLeads.length > 0"
        :showDetail="false"
        :sales-leads="newLeads"
      ></sales-lead-list>
    </div>
    <div class="my-leads mt-4" v-show="!loading">
      <h3>{{ userStore.user.firstName }}'s Leads</h3>
      <div class="text-center" v-if="currentUserLeads.length == 0">
        - no leads assigned to current user -
      </div>
      <sales-lead-list
        v-if="currentUserLeads.length > 0"
        :sales-leads="currentUserLeads"
      ></sales-lead-list>
    </div>
    <div class="in-progress-leads" v-show="false">
      <h3>All In-Progress Leads</h3>
      <div class="text-center" v-if="openLeads.length == 0">
        - no in progress leads -
      </div>
      <sales-lead-list
        v-if="openLeads.length > 0"
        :sales-leads="openLeads"
      ></sales-lead-list>
    </div>

    <!-- todo: build leads-lookup and change to that -->
    <div class="leads-stats text-center" v-show="!loading" v-if="false">
      <div class="row">
        <div class="col">
          <div>Open Leads</div>
          <div></div>
        </div>
        <div class="col">
          <div>In Progress Leads</div>
          <div></div>
        </div>
        <div class="col">
          <div>Converted Leads</div>
          <div></div>
        </div>
        <div class="col">
          <div>% Converted</div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment'
import CircleLoader from '@/components/CircleLoader.vue'
import SalesLeadList from '../components/SalesLeadList.vue'

export default {
  name: 'MangeSalesLeadsPage',
  components: { CircleLoader, SalesLeadList },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      newLeads: [],
      currentUserLeads: [],
      openLeads: [],
    }
  },
  methods: {
    getNewLeads() {
      return axios
        .get('/services/sales-leads/new')
        .then(response => {
          this.newLeads = response.data.salesLeads
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getCurrentUserLeads() {
      return axios
        .get('/services/sales-leads/current-user')
        .then(response => {
          this.currentUserLeads = response.data.salesLeads
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    markSpam(row) {
      let lead = row.item
      let index = row.index
      const data = {
        _id: lead._id,
        status: 'SPAM',
      }
      lead.submitting = true
      return axios
        .put('/services/sales-leads/status', data)
        .then(response => {
          this.newLeads.splice(index, 1)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          if (lead) {
            lead.submitting = false
          }
        })
    },
    assignToCurrentUser(row) {
      let lead = row.item
      let index = row.index
      const data = {
        _id: lead._id,
        salesPersonEmail: this.userStore.user.email,
      }
      lead.submitting = true
      return axios
        .put('/services/sales-leads/assign', data)
        .then(response => {
          this.newLeads.splice(index, 1)
          this.currentUserLeads.push(response.data.salesLead)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          if (lead) {
            lead.submitting = false
          }
        })
    },
  },
  computed: {},
  mounted() {
    return Promise.all([this.getNewLeads(), this.getCurrentUserLeads()]).then(
      responses => {
        this.loading = false
      },
    )
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
