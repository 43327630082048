<template>
  <div class="homeowner-profile">
    <div class="row">
      <div class="col">
        <label for="contractor-name">Contractor Name</label>
        <b-form-input
          v-model="profile.contractorName"
          id="contractor-name"
          :disabled="disabled"
        >
        </b-form-input>
        <label for="contractor-phone">Contractor Phone</label>
        <b-form-input
          v-model="profile.contractorPhone"
          id="contractor-phone"
          :disabled="disabled"
        >
        </b-form-input>
        <label for="contractor-email">Contractor Email</label>
        <b-form-input
          v-model="profile.contractorEmail"
          id="contractor-email"
          :disabled="disabled"
        >
        </b-form-input>
      </div>
      <div class="col"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'HomeownerProfileFormFields',
  props: {
    profile: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
