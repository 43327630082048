<template>
  <div>
    <div class="vendor-schedule-page" v-if="vendor">
      <div class="row">
        <div class="col">
          <div class="schedule">
            <h3>Schedule</h3>
            <vendor-schedule :vendor="vendor"></vendor-schedule>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendorSchedule from '@/components/VendorSchedule'

export default {
  name: 'VendorSchedulePage',
  props: {},
  data: function () {
    return {
      vendor: null,
    }
  },
  components: {
    VendorSchedule,
  },
  mounted() {
    this.vendor = this.$parent.vendor
  },
}
</script>

<style lang="scss" scoped></style>
