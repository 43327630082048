<template>
  <div class="customers" v-if="customer">
    <h3>Edit Customer</h3>
    <form @submit.prevent="showConfirmEdit">
      <div class="row">
        <div class="col">
          <div class="inputs mt-4">
            <customer-form-fields
              :submitting="submitting"
              :customer="customer"
            ></customer-form-fields>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="cta-wrapper">
            <b-button type="submit" variant="primary">Edit Customer</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-modal
      id="confirmEditModal"
      :title="`Edit Customer ${customer.name}`"
      hide-footer
    >
      <p>Are you sure you want to edit {{ customer.name }}</p>
      <div class="ctas text-right">
        <b-button
          variant="secondary"
          class="mx-3"
          @click="$bvModal.hide('confirmEditModal')"
          :disabled="submitting"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="mx-3"
          @click="submitEditCustomer"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Confirm</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment-business-days'
import { customerTypes } from '@/components/customerInputOptions'
import CustomerFormFields from '@/components/CustomerFormFields'

export default {
  name: 'CustomerEditPage',
  components: { CustomerFormFields },
  props: {},
  inject: {
    userStore,
  },
  data: function () {
    return {
      customer: null,
      customerTypes,
      submitting: false,
    }
  },
  methods: {
    showConfirmEdit() {
      this.$bvModal.show('confirmEditModal')
    },
    submitEditCustomer() {
      this.submitting = true
      const data = _.clone(this.customer)
      return axios
        .put('/services/customers/' + data.id, data)
        .then(response => {
          this.$bvModal.hide('confirmEditModal')
          this.$parent.customer = response.data.customer
          return this.$toasted.success('Customer edited', {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            duration: 4000,
          })
        })
        .catch(err => {
          console.log('err', err)
          return this.$toasted.error(
            'Error editing customer: ' + err?.response?.data?.error,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            },
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  watch: {},
  mounted() {
    this.customer = _.cloneDeep(this.$parent.customer)
  },
}
</script>

<style lang="scss" scoped>
.orders {
  padding-left: 6px;
  padding-right: 6px;
  text-align: left;
  width: 1200px;
  max-width: 100%;
}

.building-link {
  cursor: pointer;
  color: #007bff;
}

.inputs {
  width: 1000px;
  max-width: 90%;
}
</style>
