<template>
  <div class="building-sketch">
      <div class="page-one">
        <div class="page-content">
          <div class="mt-3">
            <div class="address text-left">
              Customer Name: <span class="underline">{{customerName}}</span><br>
              Street: <span class="underline">{{building.site.address.street1}}</span>
              City: <span class="underline">{{building.site.address.city}}</span>
              Zip: <span class="underline">{{building.site.address.street1}}</span>
              Phone: <span class="underline">{{building.site.phone}}</span><br>
              County: <span class="underline">{{building.site.address.county}}</span>
              Snow Load: <span class="underline">{{building.snowLoad}}</span>
              Wind Load: <span class="underline">{{building.windLoad}}</span>
            </div>
            <div class="building-drawing mt-3">
              <div class="row">
                <div class="col building-front-view text-center">
                  <svg viewBox="0 0 121 85" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">x
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Group-5">
                              <path d="M0.5,17.7805791 L0.5,67.5 L107.5,67.5 L107.5,17.7805791 L54,0.525362715 L0.5,17.7805791 Z" id="Rectangle" stroke="#404040"></path>
                              <path d="M0.5,17.7805791 L0.5,67.5 L107.5,67.5 L107.5,17.7805791 L54,0.525362715 L0.5,17.7805791 Z" id="Rectangle" stroke="#404040"></path>
                              <text id="14" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="112" y="23.446106">{{building.dimensions.height}}</tspan>
                              </text>
                              <text id="Front-20" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="28" y="84">Front    {{building.dimensions.width}}</tspan>
                              </text>
                          </g>
                      </g>
                  </svg>
                </div>
                <div class="col building-back-view text-center">
                  <svg viewBox="0 0 121 85" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Group-5" transform="translate(-1.000000, 0.553894)">
                              <path d="M14.5,17.7805791 L14.5,67.5 L121.5,67.5 L121.5,17.7805791 L68,0.525362715 L14.5,17.7805791 Z" id="Rectangle" stroke="#404040"></path>
                              <path d="M14.5,17.7805791 L14.5,67.5 L121.5,67.5 L121.5,17.7805791 L68,0.525362715 L14.5,17.7805791 Z" id="Rectangle" stroke="#404040"></path>
                              <text id="14" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="0" y="23.446106">{{building.dimensions.height}}</tspan>
                              </text>
                              <text id="Back-20" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="44" y="83.446106">Back    {{building.dimensions.width}}</tspan>
                              </text>
                          </g>
                      </g>
                  </svg>
                </div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <svg viewBox="0 0 113 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Group-6" transform="translate(1.000000, -0.029626)">
                              <g id="Group-3">
                                  <path d="M0.5,0.529625856 L0.5,58.5296259 L102.5,58.5296259 L102.5,0.529625856 L0.5,0.529625856 Z" id="Rectangle" stroke="#404040"></path>
                                  <path d="M0.476851852,12.5296259 L102.523148,12.5296259" id="Line" stroke="#3A3A3A" stroke-linecap="square"></path>
                              </g>
                              <text id="Left-Side-20" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="18" y="74.0296259">Left Side {{building.dimensions.length}}</tspan>
                              </text>
                              <text id="8" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="103" y="18.0296259">{{building.dimensions.height}}</tspan>
                              </text>
                          </g>
                      </g>
                  </svg>
                </div>
                <div class="col">
                  <svg viewBox="0 0 114 76" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Group-6" transform="translate(0.000000, -0.029626)">
                              <g id="Group-3" transform="translate(10.000000, 0.000000)">
                                  <path d="M0.5,0.529625856 L0.5,58.5296259 L102.5,58.5296259 L102.5,0.529625856 L0.5,0.529625856 Z" id="Rectangle" stroke="#404040"></path>
                                  <path d="M0.476851852,12.5296259 L102.523148,12.5296259" id="Line" stroke="#3A3A3A" stroke-linecap="square"></path>
                              </g>
                              <text id="Right-Side-20" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="25" y="74.0296259">Right Side {{building.dimensions.length}}</tspan>
                              </text>
                              <text id="8" font-family="AndaleMono, Andale Mono" font-size="8" font-weight="normal" fill="#373A37">
                                  <tspan x="0" y="18.0296259">{{building.dimensions.height}}</tspan>
                              </text>
                          </g>
                      </g>
                  </svg>
                </div>
              </div>

            </div>
            <div class="footing-drawing mt-3">
                <div class="row">
                  <div class="col text-center">
                    <img class="footing-sketch" src="../assets/building-sketch-footing.svg">
                  </div>
                </div>
            </div>
            <div class="mt-5">
                Please note: MDI representatives pride themselves on doing their best to present 
                you with the most accurate quote possible based on the information available to 
                them at point of sale. Unfortunately during the engineering phase required to 
                permit your structure changes to your original purchase MAY become necessary to meet
                unforeseen specific code requirements of your County or City ordinances. Although all 
                efforts possible will be made to keep these additional costs at a minimum, these 
                changes ARE THE BUYERS RESPONSIBILITY. No changes will be made to your purchase 
                without the written consent of the BUYER authorizing those changes.
            </div>
          </div>
          <div>
            <div class="mt-5">
                Buyer Signature: __________________________________________  
                <span class="ml-4">
                  Date: <span class='underline'>{{today | dateFilter}}</span>
                </span> 
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
export default {
  name: "BuildingSketch",
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      today: new Date(),
    };
  },
  computed: {
    customerName() {
      if (this.building.customer.lastName) {
        return (
          this.building.customer.firstName +
          " " +
          this.building.customer.lastName
        );
      } else {
        return this.building.customer.email;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.building-sketch {
  position: relative;
}

.page-content {
  text-align: left;
}

.address {
  font-size: 18px;
}

.col {
  padding: 24px 48px;
  position: relative;
}

svg {
  position: relative;
}

.footing-sketch {
  width: 80%;
}

.height {
  position: absolute; 
}

@media print {
  .page-two,
  .page-three {
    page-break-before: always;
  }

  .building-sketch {
    padding: 30px 30px 30px 30px;
  }

  .page-content {
    font-size: 20px;
  }

  .title {
    font-size: 42px;
  }
}
</style>