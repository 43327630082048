<template>
  <div class="last-contacted-wrapper">
    <div class="last-contacted-display">
      <b-form @submit.prevent="updateLastContacted()">
        Last Contacted:
        <span class="last-contacted">{{
          lastContacted | daysAgoWithTime
        }}</span>
        <b-button
          class="ml-2"
          variant="primary"
          type="submit"
          size="sm"
          :disabled="submitting"
        >
          <span v-show="submitting">Submitting</span>
          <span v-show="!submitting">Update Last Contacted</span>
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

export default {
  name: 'StatusDisplay',
  props: {
    order: {
      type: Object,
      required: true,
    },
    department: {
      type: String,
      default: 'sales',
    },
    showForm: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      submitting: false,
    }
  },
  computed: {
    lastContacted() {
      return this.order.workflow[this.department].lastContactedDate
    },
  },
  methods: {
    updateLastContacted() {
      this.submitting = true
      const data = {
        id: this.order.id,
        lastContacted: new Date(),
      }
      const orderNumber = this.order.salesOrderNumber || ''

      return axios
        .put('/services/orders/last-contacted', data)
        .then(response => {
          this.order.workflow[this.department].lastContactedDate =
            response.data.order.workflow[this.department].lastContactedDate
          this.order.events = response.data.order.events

          this.$toasted.success(
            `Last Contacted updated to ${data.lastContacted}`,
            {
              action: {
                text: 'Close',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                },
              },
              duration: 4000,
            },
          )
        })
        .catch(error => {
          this.$toasted.error(` Error updating order ${orderNumber}`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.last-contacted-display {
  //   font-size: 20px;
}

.last-contacted {
  font-weight: bold;
}
</style>
