<template>
  <div class="customer-profile">
    <h3>
      Customer Profile / Questionnaire
      <b-button
        variant="secondary"
        size="sm"
        @click="disableEdit = !disableEdit"
        ><span class="nc-icon nc-settings-tool-66"></span> edit
        profile</b-button
      >
    </h3>
    <div class="customer-type" v-if="customer">
      {{ customer.type }}
    </div>

    <form class="questions" v-if="customer" @submit.prevent="updateProfile">
      <div v-if="customer.type == 'HOMEOWNER'">
        <HomeownerProfileFormFields
          :profile="customer.profile"
          :disabled="disabled"
        ></HomeownerProfileFormFields>
      </div>
      <div v-if="customer.type == 'GOVERNMENT'">
        <GovernmentProfileFormFields
          :profile="customer.profile"
          :disabled="disabled"
        ></GovernmentProfileFormFields>
      </div>
      <div v-if="customer.type == 'BUSINESS'">
        <BusinessProfileFormFields
          :profile="customer.profile"
          :disabled="disabled"
        ></BusinessProfileFormFields>
      </div>
      <div v-if="customer.type == 'CONTRACTOR'">
        <ContractorProfileFormFields
          :profile="customer.profile"
          :disabled="disabled"
        ></ContractorProfileFormFields>
      </div>
      <div class="mt-4">
        <b-button type="submit" variant="primary" :disabled="disabled">
          <span v-if="submitting">Updating..</span>
          <span v-if="!submitting">Update Profile</span>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import HomeownerProfileFormFields from '../components/HomeownerProfileFormFields.vue'
import GovernmentProfileFormFields from '../components/GovernmentProfileFormFields.vue'
import BusinessProfileFormFields from '../components/BusinessProfileFormFields.vue'
import ContractorProfileFormFields from '../components/ContractorProfileFormFields.vue'
export default {
  name: 'CustomerProfilePage',
  props: {},
  data: function () {
    return {
      customer: null,
      disableEdit: true,
      submitting: false,
    }
  },
  components: {
    HomeownerProfileFormFields,
    GovernmentProfileFormFields,
    BusinessProfileFormFields,
    ContractorProfileFormFields,
  },
  computed: {
    disabled() {
      if (this.disableEdit || this.submitting) return true
      return false
    },
  },
  methods: {
    updateProfile() {
      this.submitting = true
      const data = {
        id: this.customer.id,
        profile: this.customer.profile,
      }
      return axios
        .put('/services/customers/profile', data)
        .then(response => {
          this.$toasted.success(`Profile updated`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .catch(error => {
          this.$toasted.error(`Error updating profile`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
  mounted() {
    this.customer = this.$parent.customer
    this.customer.profile = this.customer.profile || {}
  },
}
</script>

<style lang="scss" scoped>
.customer-profile {
  position: relative;
}
.customer-type {
  position: absolute;
  right: 0px;
  top: 0px;
}

.nc-icon {
  font-size: 20px;
}
</style>
