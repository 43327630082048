<template>
  <div class="row">
    <div class="col">
      <form @submit.prevent="submitSearch">
        <div class="row mb-3">
          <div class="col col-sm-6">
            <b-input
              type="text"
              v-model="buildingStore.state.query.email"
              placeholder="Customer Email"
            ></b-input>
            <b-input
              type="text"
              v-model="buildingStore.state.query.firstName"
              placeholder="Customer First Name"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="buildingStore.state.query.nickname"
              placeholder="Building Nickname"
              class="mt-2"
            ></b-input>
            <b-button
              type="submit"
              variant="info"
              :disabled="submitting"
              class="mt-2"
              >Submit Search</b-button
            >
            <b-button
              type="button"
              variant="secondary"
              :disabled="submitting"
              class="mt-2 ml-2"
              @click="clearSearch"
              >Clear Search</b-button
            >
          </div>
          <div class="col col-sm-6">
            <b-form-select
              v-model="buildingStore.state.query.type"
              :options="buildingTypeOptions"
              class=""
            ></b-form-select>
            <b-input
              type="text"
              v-model="buildingStore.state.query.lastName"
              placeholder="Customer Last Name"
              class="mt-2"
            ></b-input>
            <b-input
              type="text"
              v-model="buildingStore.state.query.createdBy"
              placeholder="Created By"
              class="mt-2"
            ></b-input>
          </div>
        </div>
        <div
          v-if="
            buildingStore.state.querySubmitted &&
            buildingStore.state.searchResults.length > 0
          "
        >
          Search results ({{ buildingStore.state.searchResults.length }})
          <building-list
            :buildings="buildingStore.state.searchResults"
          ></building-list>
        </div>
        <div
          v-if="
            buildingStore.state.querySubmitted &&
            buildingStore.state.searchResults.length == 0
          "
        >
          No search results found
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BuildingList from '@/components/BuildingList'
import axios from 'axios'

export default {
  name: 'BuildingLookup',
  components: { BuildingList },
  inject: ['buildingStore'],
  data: function () {
    return {
      submitting: false,
      error: false,
      buildingTypeOptions: [
        { value: null, text: 'Building Type' },
        { value: 'Shop', text: 'Shop' },
        { value: 'Carport', text: 'Carport' },
        { value: 'RV Cover', text: 'RV Cover' },
        { value: 'Shed', text: 'Shed' },
      ],
    }
  },
  methods: {
    submitSearch(query) {
      this.submitting = true
      axios
        .get(
          '/services/buildings/lookup?email=' +
            encodeURIComponent(this.buildingStore.state.query.email) +
            '&type=' +
            this.buildingStore.state.query.type +
            '&firstName=' +
            encodeURIComponent(this.buildingStore.state.query.firstName) +
            '&lastName=' +
            encodeURIComponent(this.buildingStore.state.query.lastName) +
            '&nickname=' +
            encodeURIComponent(this.buildingStore.state.query.nickname) +
            '&createdBy=' +
            encodeURIComponent(this.buildingStore.state.query.createdBy),
        )
        .then(response => {
          this.buildingStore.setSearchResults(response.data.buildings)
        })
        .catch(error => {
          console.log('error: ', error)
          this.$toasted.error(error.response.data.error.message, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    clearSearch() {
      this.buildingStore.clearSearchResults()
      this.buildingStore.clearQuery()
    },
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
