<template>
  <div class="admin-sales-page page-wrapper">
    <h4 class="">Buildings Statistics</h4>
    <div class="text-center" v-if="loading">
      <circle-loader :size="40"></circle-loader>
    </div>
    <div v-if="!loading">
      <div class="row mt-3" v-if="buildingsCreated">
        <div class="col">
          <h5>Buildings Created Last 2 Weeks</h5>
          <recent-buildings-created-chart
            :data="buildingsCreated.dailyBuildings"
          ></recent-buildings-created-chart>
        </div>
        <div class="col">
          <h5>Buildings Created by Month</h5>
          <buildings-created-chart
            :data="buildingsCreated.monthlyBuildings"
          ></buildings-created-chart>
        </div>
      </div>
      <div class="row mt-3" v-if="buildingReferrals">
        <div class="col">
          <h5>Buildings By Referral Source</h5>
          <buildings-by-referral-chart
            :data="buildingReferrals.buildingsByReferral"
          ></buildings-by-referral-chart>
        </div>
        <div class="col">
          <h5>"Other" Referral Sources with > 10 Buildings</h5>
          <buildings-other-sources-chart
            :data="buildingReferrals.otherSources"
          ></buildings-other-sources-chart>
        </div>
      </div>
      <h4 class="mt-5">Website Sales Leads Statistics</h4>
      <div class="row mt-3" v-if="salesLeadStats">
        <div class="col">
          <h5>Sales Leads Last Two Weeks</h5>
          <recent-sales-leads-chart
            :data="salesLeadStats.dailyLeads"
          ></recent-sales-leads-chart>
        </div>
        <div class="col">
          <h5>Sales Leads By Month</h5>
          <sales-leads-chart
            :data="salesLeadStats.monthlyLeads"
          ></sales-leads-chart>
        </div>
      </div>
      <div class="row mt-3" v-if="salesLeadStats">
        <div class="col">
          <h5>Sales Leads By Page</h5>
          <sales-leads-by-page-chart
            :data="salesLeadStats.leadsByPage"
          ></sales-leads-by-page-chart>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import CircleLoader from '@/components/CircleLoader.vue'
import BuildingsCreatedChart from '@/components/BuildingsCreatedChart'
import RecentBuildingsCreatedChart from '../components/RecentBuildingsCreatedChart.vue'
import SalesLeadsByPageChart from '../components/SalesLeadsByPageChart.vue'
import RecentSalesLeadsChart from '../components/RecentSalesLeadsChart.vue'
import SalesLeadsChart from '../components/SalesLeadsChart.vue'
import BuildingsByReferralChart from '../components/BuildingsByReferralChart.vue'
import BuildingsOtherSourcesChart from '../components/BuildingsOtherSourcesChart.vue'

export default {
  name: 'AdminSalesPage',
  components: {
    CircleLoader,
    BuildingsCreatedChart,
    RecentBuildingsCreatedChart,
    SalesLeadsByPageChart,
    RecentSalesLeadsChart,
    SalesLeadsChart,
    BuildingsByReferralChart,
    BuildingsOtherSourcesChart,
  },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      salesLeadStats: null,
      buildingsCreated: null,
      buildingReferrals: null,
    }
  },
  methods: {
    getBuildingCreatedStats() {
      return axios
        .get('/services/buildings/stats/buildings-created')
        .then(response => {
          this.buildingsCreated = response.data
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    getBuildingReferralStats() {
      return axios
        .get('/services/buildings/stats/referrals')
        .then(response => {
          this.buildingReferrals = response.data
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    getSalesLeadStats() {
      return axios
        .get('/services/sales-leads/stats/leads')
        .then(response => {
          this.salesLeadStats = response.data
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
  },
  computed: {},
  mounted() {
    return Promise.all([
      this.getBuildingCreatedStats(),
      this.getBuildingReferralStats(),
      this.getSalesLeadStats(),
    ]).then(responses => {
      this.loading = false
    })
  },
}
</script>

<style lang="scss" scoped></style>
