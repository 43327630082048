<template>
  <div class="table-wrapper">
    <b-table
      hover
      :items="departments"
      :fields="fields"
      :tbody-tr-class="['clickable-row']"
      @row-clicked="goToDepartment"
    ></b-table>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TasksSummary',
  components: {},
  props: {
    stats: {
      type: Object,
      required: true,
    },
    optionalFields: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    const id = _.random(0, 9999999)
    return {
      id: id,
      fields: [
        {
          key: 'DEPARTMENT',
          sortable: true,
        },
        {
          key: 'OPEN',
          sortable: true,
        },
        {
          key: 'IN-PROGRESS',
          sortable: true,
        },
        {
          key: 'READY',
          sortable: true,
        },
        {
          key: 'BLOCKED',
          sortable: true,
        },
        {
          key: 'DUE TODAY',
          sortable: true,
        },
        {
          key: 'PAST DUE',
          sortable: true,
        },
      ],
    }
  },
  mounted() {},
  computed: {
    departments() {
      let departments = [
        {
          DEPARTMENT: 'SALES',
          OPEN: 0,
          'IN-PROGRESS': 0,
          READY: 0,
          BLOCKED: 0,
          'DUE TODAY': 0,
          'PAST DUE': 0,
        },
        {
          DEPARTMENT: 'OPERATIONS',
          OPEN: 0,
          'IN-PROGRESS': 0,
          READY: 0,
          BLOCKED: 0,
          'DUE TODAY': 0,
          'PAST DUE': 0,
        },
        {
          DEPARTMENT: 'MANUFACTURING',
          OPEN: 0,
          'IN-PROGRESS': 0,
          READY: 0,
          BLOCKED: 0,
          'DUE TODAY': 0,
          'PAST DUE': 0,
        },
        {
          DEPARTMENT: 'FINANCE',
          OPEN: 0,
          'IN-PROGRESS': 0,
          READY: 0,
          BLOCKED: 0,
          'DUE TODAY': 0,
          'PAST DUE': 0,
        },
      ]
      _.each(this.stats.totalTasks, stats => {
        const department = departments.find(
          d => d.DEPARTMENT === stats._id.department,
        )
        department[stats._id.status] = stats.total
      })
      _.each(this.stats.pastDueTasks, stats => {
        const department = departments.find(
          d => d.DEPARTMENT === stats._id.department,
        )
        department['PAST DUE'] = stats.total
      })
      _.each(this.stats.dueTodayTasks, stats => {
        const department = departments.find(
          d => d.DEPARTMENT === stats._id.department,
        )
        department['DUE TODAY'] = stats.total
      })
      return departments
    },
  },
  methods: {
    goToDepartment(department, index, e) {
      if (e.metaKey) {
        window.open('/app/tasks/' + department.DEPARTMENT.toLowerCase())
      } else {
        this.$router.push('/app/tasks/' + department.DEPARTMENT.toLowerCase())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media print {
  .table-wrapper {
    max-height: inherit;
    overflow: auto;
  }
}
</style>

<style>
.clickable-row {
  cursor: pointer;
}
</style>
