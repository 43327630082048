<template>
  <div class="building-result">
    <div class="card-title mb-4">Result</div>
    <div class="quote-dates">
      <div class="original-quote-date">
        Quote Started:
        {{ (response.quoteDate || response.createdAt) | dateFilter }}
      </div>
      <div class="updated-quote-date">
        Last Updated: {{ response.lastUpdatedDate | dateFilter }}
      </div>
      <div class="sold-quote-date">
        Sold On: {{ response.soldDate | dateFilter }}
      </div>
    </div>
    <button class="btn btn-info btn-sm print-btn" @click="printResultView()">
      Print {{ startCase(Object.keys(resultView)[0]) }}
    </button>
    <div class="building-short-id" v-show="response.shortId">
      ID: {{ response.shortId }}
    </div>
    <div class="row mb-4" v-if="response._id">
      <div class="col">
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.quoteLetter }"
          @click="toggleResultView('quoteLetter')"
        >
          Quote Letter
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.salesPageNew }"
          @click="toggleResultView('salesPageNew')"
        >
          Sales Page
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.salesPage }"
          @click="toggleResultView('salesPage')"
        >
          Old Sales Page
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.photoRelease }"
          @click="toggleResultView('photoRelease')"
        >
          Photo Release
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.buildingSketch }"
          @click="toggleResultView('buildingSketch')"
        >
          Building Sketch
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.contractorContract }"
          @click="toggleResultView('contractorContract')"
        >
          Contract
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.kitContract }"
          @click="toggleResultView('kitContract')"
        >
          Kit Contract
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.contractPage }"
          @click="toggleResultView('contractPage')"
        >
          Contract Page
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1"
          v-bind:class="{ 'btn-info': resultView.repriceMemo }"
          @click="toggleResultView('repriceMemo')"
        >
          Reprice Memo
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.pricingDetail }"
          @click="toggleResultView('pricingDetail')"
        >
          Pricing Detail
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.billOfMaterials }"
          @click="toggleResultView('billOfMaterials')"
        >
          Bill Of Materials
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1 mr-1"
          v-bind:class="{ 'btn-info': resultView.leakWaiver }"
          @click="toggleResultView('leakWaiver')"
        >
          Leak Waiver
        </button>
        <button
          class="btn btn-sm btn-secondary mt-1"
          v-bind:class="{ 'btn-info': resultView.sprayFoamWaiver }"
          @click="toggleResultView('sprayFoamWaiver')"
        >
          Spray Foam Waiver
        </button>
      </div>
    </div>
    <div class="card-body" v-if="response && response._id" id="resultView">
      <div class="" v-if="resultView.quoteLetter" id="quoteLetter">
        <h5>Quote Letter</h5>
        <quote-letter :building="response"></quote-letter>
      </div>

      <div class="" v-if="resultView.salesPage" id="salesPage">
        <sales-page :building="response"></sales-page>
      </div>

      <div class="" v-if="resultView.salesPageNew" id="salesPageNew">
        <sales-page-new :building="response"></sales-page-new>
      </div>

      <div class="" v-if="resultView.photoRelease" id="photoRelease">
        <photo-release :building="response"></photo-release>
      </div>

      <div class="" v-if="resultView.leakWaiver" id="leakWaiver">
        <leak-waiver :building="response"></leak-waiver>
      </div>
      <div class="" v-if="resultView.sprayFoamWaiver" id="sprayFoamWaiver">
        <spray-foam-waiver :building="response"></spray-foam-waiver>
      </div>

      <div v-if="resultView.buildingSketch" id="buildingSketch">
        <building-sketch :building="response"></building-sketch>
      </div>

      <div v-if="resultView.contractPage" id="contractPage">
        <contract-page :building="response"></contract-page>
      </div>

      <div v-if="resultView.repriceMemo" id="repriceMemo">
        <reprice-memo :building="response"></reprice-memo>
      </div>

      <div v-if="resultView.workflow" id="workflow">
        <workflow :building="response"></workflow>
      </div>

      <div v-if="resultView.changeOrders" id="changeOrders">
        <change-orders :building="response"></change-orders>
      </div>

      <div v-if="resultView.kitContract" id="kitContract">
        <kit-contract :building="response"></kit-contract>
      </div>

      <div v-if="resultView.contractorContract" id="contractorContract">
        <contractor-contract :building="response"></contractor-contract>
      </div>

      <div class="" v-if="resultView.pricingDetail" id="pricingDetail">
        <h5>Pricing &amp; Materials Breakdown</h5>
        <div class="display-section" id="display-section-bom">
          <div class="display-section-title">Bill of Materials</div>
          <div class="row">
            <div class="col">
              <bill-of-materials
                :bom="response.bom"
                :show-prices="true"
                :total="response.price.bom"
              ></bill-of-materials>
            </div>
          </div>
        </div>
        <div class="display-section" id="display-section-bom">
          <div class="display-section-title">Labor</div>
          <div class="row">
            <div class="col">
              <manufacturing-labor
                :labor="response.manufacturingLabor"
                :total="response.price.manufacturingLabor"
              ></manufacturing-labor>
            </div>
          </div>
        </div>
        <div class="display-section" id="display-section-addons">
          <div class="display-section-title">Add-on Pricing Items</div>
          <div class="row">
            <div class="col">
              <add-on-items
                :addOns="response.addOnItems"
                :doInstall="response.install.doInstall"
              ></add-on-items>
            </div>
          </div>
        </div>
        <div class="display-section" id="display-section-addons">
          <div class="display-section-title">Manual Add-on Pricing Items</div>
          <div class="row">
            <div class="col">
              <manual-add-on-items
                :addOns="response.manualAddOnItems"
              ></manual-add-on-items>
            </div>
          </div>
        </div>
        <div class="display-section" id="display-section-addons">
          <div class="display-section-title">Install Add-on Pricing Items</div>
          <div class="row">
            <div class="col">
              <manual-add-on-items
                :addOns="response.installAddOnItems"
              ></manual-add-on-items>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <div class="display-section-title">Total Price</div>
          </div>
        </div>
        <div class="row">
          <div class="col" v-if="response.foundation.doConcrete">
            <div class="customer-display-subtitle">Concrete Estimate</div>
            <div class="customer-display-line">
              {{ response.foundation.anchors }}
            </div>
            <div
              class="customer-display-line"
              v-show="response.foundation.anchors == 'slab w/ footings'"
            >
              {{ response.foundation.price.sqft }} sqft @
              <span v-if="response.foundation.price.max">
                {{ response.foundation.price.perSqftMin | currencyFilter }} -
                {{ response.foundation.price.perSqftMax | currencyFilter }}
                /sqft
              </span>
              <span v-if="!response.foundation.price.max">
                {{ response.foundation.price.perSqft | currencyFilter }}/sqft
              </span>
            </div>
            <div
              class="customer-display-line"
              v-show="response.foundation.anchors == 'footings'"
            >
              {{ response.foundation.price.lft }} lft @
              {{ response.foundation.price.perLft | currencyFilter }}/lft
            </div>
            <div
              class="customer-display-line"
              v-show="response.foundation.anchors == 'piers'"
            >
              {{ response.foundation.price.numPiers }} piers @
              {{ response.foundation.price.perPier | currencyFilter }}/pier
            </div>
            <div class="customer-display-line">
              Price:
              <span v-if="response.foundation.price.max">
                {{ response.foundation.price.min | currencyFilter }} -
                {{ response.foundation.price.max | currencyFilter }}</span
              >
              <span v-if="!response.foundation.price.max">
                {{ response.foundation.price.total | currencyFilter }}
              </span>
            </div>
            <div class="alert alert-warning" role="alert">
              Pending site inspection
            </div>
          </div>
          <div class="col">
            <div class="customer-display-subtitle">Kit &amp; Install</div>
            <div class="customer-display-line">
              Subtotal:
              {{ response.price.kitAndInstall.subTotal | currencyFilter }}
              <span class="per-sqft-cost">
                ({{
                  (response.price.kitAndInstall.subTotal / totalSqft)
                    | currencyFilter
                }}/sqft)
              </span>
            </div>
            <div class="customer-display-line">
              Tax ({{ response.site.salesTaxRate | percentFilter }}):
              {{ response.price.kitAndInstall.salesTax | currencyFilter }}
            </div>
            <div class="customer-display-line strong">
              Total:
              {{ response.price.kitAndInstall.total | currencyFilter }}
              <span class="per-sqft-cost">
                ({{
                  (response.price.kitAndInstall.total / totalSqft)
                    | currencyFilter
                }}/sqft)
              </span>
            </div>
          </div>
          <div class="col">
            <div class="customer-display-subtitle">Kit Only</div>
            <div class="customer-display-line">
              Subtotal:
              {{ response.price.kit.subTotal | currencyFilter }}
              <span class="per-sqft-cost">
                ({{
                  (response.price.kit.subTotal / totalSqft) | currencyFilter
                }}/sqft)
              </span>
            </div>
            <div class="customer-display-line">
              Tax ({{ response.site.salesTaxRate | percentFilter }}):
              {{ response.price.kit.salesTax | currencyFilter }}
            </div>
            <div class="customer-display-line strong">
              Total: {{ response.price.kit.total | currencyFilter }}
              <span class="per-sqft-cost">
                ({{
                  (response.price.kit.total / totalSqft) | currencyFilter
                }}/sqft)
              </span>
            </div>
          </div>
        </div>
        <div
          class="display-section"
          id="display-section-notes"
          v-if="response.noteList"
          v-show="false"
        >
          <div class="display-section-title">Notes</div>
          <div class="row">
            <div class="col text-left">
              {{ response.noteList }}
            </div>
          </div>
        </div>
      </div>

      <div class="" v-if="resultView.billOfMaterials" id="billOfMaterials">
        <h5>Bill of Materials</h5>
        <div class="display-section" id="display-section-bom">
          <div class="display-section-title">Bill of Materials</div>
          <div class="row">
            <div class="col">
              <bill-of-materials
                :bom="response.bom"
                :show-prices="false"
                :total="response.price.bom"
              ></bill-of-materials>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BillOfMaterials from '@/components/BillOfMaterials.vue'
import ManufacturingLabor from '@/components/ManufacturingLabor.vue'
import QuoteLetter from '@/components/QuoteLetter.vue'
import KitContract from '@/components/KitContract.vue'
import RepriceMemo from '@/components/RepriceMemo.vue'
import ChangeOrders from '@/components/ChangeOrders.vue'
import Workflow from '@/components/Workflow.vue'
import ContractorContract from '@/components/ContractorContract.vue'
import ContractPage from '@/components/ContractPage.vue'
import PhotoRelease from '@/components/PhotoRelease.vue'
import LeakWaiver from '@/components/LeakWaiver.vue'
import SprayFoamWaiver from '@/components/SprayFoamWaiver.vue'
import BuildingSketch from '@/components/BuildingSketch.vue'
import SalesPage from '@/components/SalesPage.vue'
import SalesPageNew from '@/components/SalesPageNew.vue'
import AddOnItems from '@/components/AddOnItems.vue'
import ManualAddOnItems from '@/components/ManualAddOnItems.vue'

export default {
  name: 'BuildingResult',
  components: {
    BillOfMaterials,
    ManufacturingLabor,
    QuoteLetter,
    KitContract,
    RepriceMemo,
    ChangeOrders,
    Workflow,
    ContractorContract,
    ContractPage,
    PhotoRelease,
    LeakWaiver,
    SprayFoamWaiver,
    BuildingSketch,
    SalesPage,
    SalesPageNew,
    AddOnItems,
    ManualAddOnItems,
  },
  props: {
    response: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      resultView: { pricingDetail: true },
    }
  },
  computed: {
    totalSqft() {
      return this.response.dimensions.sqft + this.response.mezzanine.sqft || 0
    },
  },
  methods: {
    startCase(value) {
      return _.startCase(value)
    },
    toggleResultView(result) {
      this.resultView = {}
      this.resultView[result] = true
    },
    printResultView() {
      var elem = 'resultView'
      // todo: add styles for printing..

      // Get HTML to print from element
      const prtHtml = document.getElementById(elem).innerHTML

      // Get all stylesheets HTML
      let stylesHtml = ''
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      )

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`)

      window.setTimeout(function () {
        WinPrint.focus()
        WinPrint.print()
      }, 1000)

      window.setTimeout(function () {
        WinPrint.document.close()
        WinPrint.close()
      }, 2500)
    },
  },
}
</script>

<style lang="scss" scoped>
.display-section-title {
  border-bottom: 1px solid gray;
  margin-top: 36px;
  margin-bottom: 12px;
  text-align: left;
}

.card-title {
  margin-top: 12px;
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.card-body {
  padding: 12px;
}
.input-col {
  padding-left: 4px;
  padding-right: 4px;
}

.quote-dates {
  position: absolute;
  font-size: 14px;
  left: 6px;
  top: 6px;
  text-align: left;
}

.print-btn {
  position: absolute;
  top: 6px;
  right: 6px;
}
.customer-display-header {
  font-weight: bold;
  text-align: left;
}
.customer-display-line {
  text-align: left;
}
.customer-display-subtitle {
  font-weight: bold;
  text-align: left;
}

.display-subsection {
  margin-bottom: 10px;
  text-align: left;
}

.display-subsection-title {
  text-align: left;
  font-weight: 500;
}

.alert {
  padding: 0.25rem 0.1rem;
}

.per-sqft-cost {
  color: #666666;
  font-size: 14px;
}
</style>
