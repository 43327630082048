import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import TasksPage from '../views/TasksPage.vue'
import MyTasksPage from '../views/MyTasksPage.vue'
import SalesTasksPage from '../views/SalesTasksPage.vue'
import OperationsTasksPage from '../views/OperationsTasksPage.vue'
import ManufacturingTasksPage from '../views/ManufacturingTasksPage.vue'
import FinanceTasksPage from '../views/FinanceTasksPage.vue'
import TaskDetailPage from '../views/TaskDetailPage.vue'

import PageNotFound from '../views/PageNotFound.vue'
import Account from '../views/Account.vue'
import Icons from '../views/Icons.vue'
import PriceList from '../views/PriceList.vue'
import InstallAnalysis from '../views/InstallAnalysis.vue'
import AdminSalesPage from '../views/AdminSalesPage.vue'
import Index from '../views/Index.vue'
import PricerPage from '../views/PricerPage.vue'
import ManageBuildingsPage from '../views/ManageBuildingsPage.vue'
import CloneBuildingConfiguration from '../views/CloneBuildingConfiguration.vue'
import CustomerPricerPage from '../views/CustomerPricerPage.vue'

import SalesPage from '../views/SalesPage.vue'
import SalesDashboardPage from '../views/SalesDashboardPage.vue'
import ManageSalesLeadsPage from '../views/ManageSalesLeads.vue'
import ManageSalesTrafficPage from '../views/ManageSalesTrafficPage.vue'
import ManageTradeShowsPage from '../views/ManageTradeShowsPage.vue'

import ManageCustomersPage from '../views/ManageCustomersPage.vue'
import CustomerDetailPage from '../views/CustomerDetailPage.vue'
import CustomerOverviewPage from '../views/CustomerOverviewPage.vue'
import CustomerEditPage from '../views/CustomerEditPage.vue'
import CustomerProfilePage from '../views/CustomerProfilePage.vue'
import CustomerFilesPage from '../views/CustomerFilesPage.vue'
import CustomerSchedulePage from '../views/CustomerSchedulePage.vue'
import AddCustomerPage from '../views/AddCustomerPage.vue'

import ManageVendorsPage from '../views/ManageVendorsPage.vue'
import VendorDetailPage from '../views/VendorDetailPage.vue'
import VendorOverviewPage from '../views/VendorOverviewPage.vue'
import VendorEditPage from '../views/VendorEditPage.vue'
import VendorProfilePage from '../views/VendorProfilePage.vue'
import VendorFilesPage from '../views/VendorFilesPage.vue'
import VendorSchedulePage from '../views/VendorSchedulePage.vue'
import AddVendorPage from '../views/AddVendorPage.vue'

import OrderDetailPage from '../views/OrderDetailPage.vue'
import CreateOrderPage from '../views/CreateOrderPage.vue'
import ImportOrdersPage from '../views/ImportOrdersPage.vue'
import OrderEditPage from '../views/OrderEditPage.vue'
import OrderSalesPage from '../views/OrderSalesPage.vue'
import OrderArchitecturePage from '../views/OrderArchitecturePage.vue'
import OrderEngineeringPage from '../views/OrderEngineeringPage.vue'
import OrderPermittingPage from '../views/OrderPermittingPage.vue'
import OrderManufacturingPage from '../views/OrderManufacturingPage.vue'
import OrderInstallPage from '../views/OrderInstallPage.vue'
import OrderPaymentsPage from '../views/OrderPaymentsPage.vue'
import OrderSchedulePage from '../views/OrderSchedulePage.vue'
import OrderOverviewPage from '../views/OrderOverviewPage.vue'
import CurrentOrdersPage from '../views/CurrentOrdersPage.vue'
import OrderCalendarPage from '../views/OrderCalendarPage.vue'
import ManageOrdersPage from '../views/ManageOrdersPage.vue'
import AppLayout from '../components/layout/AppLayout.vue'
import AdminLayout from '../views/AdminLayout.vue'
import ContractorLayout from '../views/ContractorLayout.vue'
import AdminHome from '../views/AdminHome.vue'
import ContractorHome from '../views/ContractorHome.vue'
import Login from '../views/Login.vue'
import { userStore } from '@/stores/userStore'
import { customerStore } from '@/stores/customerStore'

Vue.use(VueRouter)

const routes = [
  {
    path: '/create',
    name: 'CustomerPricerPage',
    component: CustomerPricerPage,
    props: route => ({
      ...route.params,
    }),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: route => {
      return { ...route.params }
    },
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    beforeEnter: async (to, from, next) => {
      let response
      try {
        if (!userStore.user.type) {
          response = await axios.get('/services/user')
          userStore.setUser(response.data.user)
        }
        if (
          userStore.user.type === 'ADMIN' ||
          userStore.user.type === 'SALES' ||
          userStore.user.type === 'MANUFACTURING'
        ) {
          next({ path: '/app', replace: true })
        } else if (userStore.user.type === 'CONTRACTOR') {
          next({ path: '/contractor', replace: true })
        } else {
          next({ path: '/login', replace: true })
        }
      } catch (e) {
        next({ path: '/login', replace: true })
      }
    },
  },
  {
    path: '/app',
    // name: 'App',
    component: AppLayout,
    beforeEnter: async (to, from, next) => {
      let response
      try {
        if (!userStore.user.type) {
          response = await axios.get('/services/user')
          userStore.setUser(response.data.user)
        }

        if (
          userStore.user.type === 'ADMIN' ||
          userStore.user.type === 'SALES' ||
          userStore.user.type === 'MANUFACTURING'
        ) {
          if (!customerStore.customers.length) {
            try {
              let customerResponse = await axios.get(
                '/services/customers/typeahead',
              )
              customerStore.setCustomers(customerResponse.data.customers)
              let usersResponse = await axios.get('/services/user/typeahead')
              userStore.setUserList(usersResponse.data.users)
            } catch (error) {
              console.log('error ', error)
            }
          }
          next()
        } else {
          next({ path: '/login', replace: true })
        }
      } catch (e) {
        next({
          name: 'Login',
          replace: true,
          params: { pathAfterLogin: to.path },
        })
      }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons,
      },
      {
        path: 'buildings',
        name: 'ManageBuildingsPage',
        component: ManageBuildingsPage,
      },
      {
        path: 'clone-building',
        name: 'CloneBuildingConfiguration',
        component: CloneBuildingConfiguration,
        props: route => {
          return { ...route.params }
        },
      },
      {
        path: 'pricer',
        name: 'PricerPage',
        component: PricerPage,
        props: route => {
          return { ...route.params }
        },
      },
      {
        path: 'pricer/:id',
        name: 'BuildingPricerPage',
        component: PricerPage,
        props: route => ({
          id: route.params.id,
        }),
      },
      {
        path: 'account',
        name: 'AccountPage',
        component: Account,
      },
      {
        path: 'orders/',
        name: 'ManageOrdersPage',
        component: ManageOrdersPage,
      },
      {
        path: 'orders/current',
        name: 'CurrentOrdersPage',
        component: CurrentOrdersPage,
      },
      {
        path: 'orders/calendar',
        name: 'OrderCalendarPage',
        component: OrderCalendarPage,
      },
      {
        path: 'orders/new',
        name: 'CreateOrderPage',
        component: CreateOrderPage,
      },
      {
        path: 'orders/import',
        name: 'ImportOrdersPage',
        component: ImportOrdersPage,
      },
      {
        path: 'tasks',
        component: TasksPage,
        children: [
          {
            path: '/',
            name: 'MyTasksPage',
            component: MyTasksPage,
          },
          {
            path: 'sales',
            name: 'SalesTasksPage',
            component: SalesTasksPage,
          },
          {
            path: 'operations',
            name: 'OperationsTasksPage',
            component: OperationsTasksPage,
          },
          {
            path: 'manufacturing',
            name: 'ManufacturingTasksPage',
            component: ManufacturingTasksPage,
          },
          {
            path: 'finance',
            name: 'FinanceTasksPage',
            component: FinanceTasksPage,
          },
        ],
      },
      {
        path: 'tasks/:id',
        name: 'TaskDetailPage',
        component: TaskDetailPage,
        props: route => ({
          id: route.params.id,
        }),
      },
      {
        path: 'orders/:id',
        component: OrderDetailPage,
        props: route => ({
          id: route.params.id,
        }),
        children: [
          {
            path: '/',
            name: 'OrderOverviewPage',
            component: OrderOverviewPage,
          },
          {
            path: 'edit',
            name: 'OrderEditPage',
            component: OrderEditPage,
          },
          {
            path: 'sales',
            name: 'OrderSalesPage',
            component: OrderSalesPage,
          },
          {
            path: 'architecture',
            name: 'OrderArchitecturePage',
            component: OrderArchitecturePage,
          },
          {
            path: 'engineering',
            name: 'OrderEngineeringPage',
            component: OrderEngineeringPage,
          },
          {
            path: 'permitting',
            name: 'OrderPermittingPage',
            component: OrderPermittingPage,
          },
          {
            path: 'manufacturing',
            name: 'OrderManufacturingPage',
            component: OrderManufacturingPage,
          },
          {
            path: 'install',
            name: 'OrderInstallPage',
            component: OrderInstallPage,
          },
          {
            path: 'payments',
            name: 'OrderPaymentsPage',
            component: OrderPaymentsPage,
          },
          {
            path: 'schedule',
            name: 'OrderSchedulePage',
            component: OrderSchedulePage,
          },
        ],
      },
      {
        path: 'customers',
        name: 'ManageCustomersPage',
        component: ManageCustomersPage,
      },
      {
        path: 'customers/new',
        name: 'AddCustomerPage',
        component: AddCustomerPage,
      },
      {
        path: 'customers/:id',
        component: CustomerDetailPage,
        beforeEnter: async (to, from, next) => {
          if (to.query.name) {
            let response = await axios.get(
              '/services/customers/lookup?name=' +
                encodeURIComponent(to.query.name),
            )
            if (response.data?.customers?.length) {
              to.params.id = response.data.customers[0].id
            }
          }
          next()
        },
        props: route => {
          return {
            id: route.params.id,
          }
        },
        children: [
          {
            path: '/',
            name: 'CustomerOverviewPage',
            component: CustomerOverviewPage,
          },
          {
            path: 'edit',
            name: 'CustomerEditPage',
            component: CustomerEditPage,
          },
          {
            path: 'profile',
            name: 'CustomerProfilePage',
            component: CustomerProfilePage,
          },
          {
            path: 'files',
            name: 'CustomerFilesPage',
            component: CustomerFilesPage,
          },
          {
            path: 'schedule',
            name: 'CustomerSchedulePage',
            component: CustomerSchedulePage,
          },
        ],
      },
      {
        path: 'vendors',
        name: 'ManageVendorsPage',
        component: ManageVendorsPage,
      },
      {
        path: 'vendors/new',
        name: 'AddVendorPage',
        component: AddVendorPage,
      },
      {
        path: 'vendors/:id',
        component: VendorDetailPage,
        beforeEnter: async (to, from, next) => {
          if (to.query.name) {
            let response = await axios.get(
              '/services/vendors/lookup?name=' +
                encodeURIComponent(to.query.name),
            )
            if (response.data?.vendors?.length) {
              to.params.id = response.data.vendors[0].id
            }
          }
          next()
        },
        props: route => {
          return {
            id: route.params.id,
          }
        },
        children: [
          {
            path: '/',
            name: 'VendorOverviewPage',
            component: VendorOverviewPage,
          },
          {
            path: 'edit',
            name: 'VendorEditPage',
            component: VendorEditPage,
          },
          {
            path: 'profile',
            name: 'VendorProfilePage',
            component: VendorProfilePage,
          },
          {
            path: 'files',
            name: 'VendorFilesPage',
            component: VendorFilesPage,
          },
          {
            path: 'schedule',
            name: 'VendorSchedulePage',
            component: VendorSchedulePage,
          },
        ],
      },
      {
        path: 'sales',
        name: 'SalesPage',
        component: SalesPage,
        children: [
          {
            path: 'dashboard',
            name: 'SalesDashboardPage',
            component: SalesDashboardPage,
          },
          {
            path: 'leads',
            name: 'ManageSalesLeadsPage',
            component: ManageSalesLeadsPage,
          },
          {
            path: 'trade-shows',
            name: 'ManageTradeShowsPage',
            component: ManageTradeShowsPage,
            props: route => {
              return { ...route.params }
            },
          },
          {
            path: 'traffic',
            name: 'ManageSalesTrafficPage',
            component: ManageSalesTrafficPage,
          },
          {
            path: 'stats',
            name: 'SalesStatsPage',
            component: AdminSalesPage,
          },
        ],
      },
      {
        path: 'admin',
        component: AdminLayout,
        beforeEnter: async (to, from, next) => {
          let response
          try {
            if (!userStore.user.type) {
              response = await axios.get('/services/user')
              userStore.setUser(response.data.user)
            }
            if (userStore.user.type === 'ADMIN') {
              next()
            } else {
              next({ path: '/login', replace: true })
            }
          } catch (e) {
            next({ path: '/login', replace: true })
          }
        },
        children: [
          {
            path: '/',
            name: 'AdminHome',
            component: AdminHome,
          },
          {
            path: 'price-list',
            name: 'PriceList',
            component: PriceList,
          },
          {
            path: 'install-analysis',
            name: 'InstallAnalysis',
            component: InstallAnalysis,
          },
          {
            path: 'sales',
            name: 'AdminSalesPage',
            component: AdminSalesPage,
          },
        ],
      },
    ],
  },
  {
    path: '/contractor',
    component: ContractorLayout,
    beforeEnter: async (to, from, next) => {
      let response
      try {
        if (!userStore.user.type) {
          response = await axios.get('/services/user')
          userStore.setUser(response.data.user)
        }
        if (userStore.user.type === 'CONTRACTOR') {
          console.log('user tuype is contractor! ')
          next()
        } else {
          next({ path: '/login', replace: true })
        }
      } catch (e) {
        console.log('---- in catch! E: ', e)
        next({ path: '/login', replace: true })
      }
    },
    children: [
      {
        path: '/',
        name: 'ContractorHome',
        component: ContractorHome,
      },
      {
        path: 'account',
        name: 'ContractorAccountPage',
        component: Account,
      },
    ],
  },
  { path: '*', component: PageNotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
