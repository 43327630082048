<template>
  <div class="orders page-wrapper">
    <div class="loader text-center mt-5" v-show="loading">
      <CircleLoader :size="45"></CircleLoader>
    </div>
    <div class="current-leads" v-show="!loading">
      <h3>Sales Traffic</h3>
      <div>Coming Soon! Section to record daily sales traffic and notes</div>
      <div>
        Should this be per user? Each sales person records their own vs entire
        team shares 1
      </div>
      <div>
        Should this be just 1 number? or record from different channels?
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'
import moment from 'moment'
import CircleLoader from '@/components/CircleLoader.vue'

export default {
  name: 'ManageTradeShowsPage',
  components: { CircleLoader },
  inject: {
    userStore,
  },
  data: function () {
    return {
      loading: true,
      salesTraffic: [],
    }
  },
  methods: {
    getTradeShows() {
      return axios
        .get('/services/sales/traffic')
        .then(response => {
          this.salesTraffic = response.data.salesTraffic || []
        })
        .catch(err => {
          console.log('err', err)
        })
    },
  },
  computed: {},
  mounted() {
    this.loading = false
    // return Promise.all([this.getTradeShows()]).then(responses => {
    //   this.loading = false
    // })
  },
}
</script>

<style lang="scss" scoped>
.building-link {
  cursor: pointer;
  color: #007bff;
}
</style>
