import './compositionAPI'
import { reactive, readonly, computed } from '@vue/composition-api'
import _ from 'lodash'

const state = reactive({
  scheduleDates: [],
  count: 0,
})

const calendarFilter = reactive({
  vendor: true,
  holiday: true,
  delivery: true,
  offsite: true,
  install: true,
  concrete: true,
  inspection: true,
})

function addScheduleDates(dates) {
  state.scheduleDates = []
  _.each(dates, sd => {
    addScheduleDate(sd)
  })
}

function addScheduleDate(sd) {
  state.scheduleDates.push(sd)
  state.count = state.scheduleDates.length
}

function removeScheduleDate(sd) {
  _.remove(state.scheduleDates, d => {
    return sd?.id === d.id
  })
  state.count = state.scheduleDates.length
}

export const scheduleDateStore = readonly({
  state,
  calendarFilter,
  addScheduleDates,
  addScheduleDate,
  removeScheduleDate,
})
