<template>
  <div class="customer-task-list">
    <div v-if="loading">
      <CircleLoader></CircleLoader>
    </div>
    <div v-if="!loading">
      <div v-if="tasks.length">
        <task-list
          :tasks="tasks"
          :hidden-fields="['customerName']"
          :optional-fields="{ assignedTo: true, department: true }"
        ></task-list>
      </div>
      <div v-if="!tasks.length">- No Tasks -</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CircleLoader from '@/components/CircleLoader.vue'
import axios from 'axios'
import TaskList from './TaskList.vue'

export default {
  name: 'CustomerTaskList',
  components: { CircleLoader, TaskList },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      tasks: null,
      loading: true,
    }
  },
  computed: {},
  mounted() {
    this.getTasks()
  },
  methods: {
    async getTasks() {
      try {
        let response = await axios.get(
          '/services/tasks/lookup?customerId=' + this.customer._id,
        )

        this.tasks = response.data.tasks
      } catch (error) {
        console.log('error: ', error)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
