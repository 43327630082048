<template>
  <div class="admin-home page-wrapper">
    <h1 class="page-h1">Admin</h1>
    <div class="nav text-center">
      <router-link class="nav-link" to="/app/admin/" style="margin-left: 2px"
        >Dashboard</router-link
      >
      <router-link
        class="nav-link"
        to="/app/admin/price-list"
        style="margin-left: 2px"
        >Price List</router-link
      >
      <router-link class="nav-link" to="/app/admin/sales">Sales</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'

export default {
  name: 'AdminLayout',
  components: {},
  inject: {
    userStore,
  },
  data: function () {
    return {}
  },
  methods: {},
  mounted() {},
}
</script>

<style lang="scss" scoped>
a {
  font-weight: bold;
  color: #2c3e50;
  padding-left: 8px;
  padding-right: 8px;
  &.router-link-exact-active {
    color: #42b983;
  }

  &:first-child {
    margin-left: 0px;
  }
}

.nav {
  margin-bottom: 2px;
}

.nav-link {
  &::after {
    content: '|';
    color: #2c3e50;
    margin-left: 16px;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
</style>
