<template>
  <div class="vendor-profile">
    <div class="row">
      <div class="col">
        <h3>Orders</h3>
        <b-form-group label="Orders Are Placed On: ">
          <b-form-checkbox-group
            id="orderDaysOfWeek"
            v-model="profile.orderDaysOfWeek"
            :options="orderDaysOptions"
            name="orderDaysOfWeek"
            :disabled="disabled"
          >
          </b-form-checkbox-group>
        </b-form-group>
        <b-label for="orderTimeOfDay">Placed By Time: </b-label>
        <b-form-input
          name="orderTimeOfDay"
          id="orderTimeOfDay"
          type="time"
          v-model="profile.orderTimeOfDay"
          :disabled="disabled"
        ></b-form-input>
        <b-button
          size="small"
          type="button"
          @click="profile.orderTimeOfDay = ''"
          >Clear Time</b-button
        >
      </div>
      <div class="col">
        <h3>Deliveries</h3>
        <b-form-group label="Orders Are Delivered On: ">
          <b-form-checkbox-group
            id="deliveryDaysOfWeek"
            v-model="profile.deliveryDaysOfWeek"
            :options="deliveryDaysOptions"
            name="deliveryDaysOfWeek"
            :disabled="disabled"
          >
          </b-form-checkbox-group>
        </b-form-group>
        <b-label for="deliveryTimeOfDay">Delivered At Time: </b-label>
        <b-form-input
          name="deliveryTimeOfDay"
          id="deliveryTimeOfDay"
          type="time"
          v-model="profile.deliveryTimeOfDay"
          :disabled="disabled"
        ></b-form-input>
        <b-button
          size="small"
          type="button"
          @click="profile.delvieryTimeOfDay = ''"
          >Clear Time</b-button
        >
      </div>
    </div>
    <br />
    // could add more information about inventory they hold, delivery specifics
    <br />
    // contacts and locations covered on detail page
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  components: {},
  name: 'VendorProfileFormFields',
  props: {
    profile: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      orderDaysOptions: [
        { value: 0, text: 'Sunday' },
        { value: 1, text: 'Monday' },
        { value: 2, text: 'Tuesday' },
        { value: 3, text: 'Wednesday' },
        { value: 4, text: 'Thursday' },
        { value: 5, text: 'Friday' },
        { value: 6, text: 'Saturday' },
      ],
      deliveryDaysOptions: [
        { value: 0, text: 'Sunday' },
        { value: 1, text: 'Monday' },
        { value: 2, text: 'Tuesday' },
        { value: 3, text: 'Wednesday' },
        { value: 4, text: 'Thursday' },
        { value: 5, text: 'Friday' },
        { value: 6, text: 'Saturday' },
      ],
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
