<template>
  <div class="addons">
    <button
      class="btn btn-sm btn-light show-detail-button"
      @click="showDetail = !showDetail"
    >
      {{ showDetail ? 'Hide Detail' : 'Show Detail' }}
    </button>
    <table class="addons-table">
      <thead>
        <td>Item</td>
        <td>Details</td>
        <td>Materials</td>
        <td v-if="doInstall">Install</td>
        <td>Total</td>
      </thead>
      <tbody v-show="showDetail">
        <tr v-for="(item, index) in addOns" :key="index">
          <td>
            {{ item.name }} {{ item.installItem ? '(install only)' : '' }}
          </td>
          <td>
            {{
              item.totalSqft || item.totalSqft === 0
                ? item.totalSqft + 'sqft @' + item.perSqft + '/sqft'
                : item.totalLft
                ? item.totalLft + 'lft @' + item.perLft + '/lft'
                : item.numDays
                ? item.numDays + ' days @ ' + item.pricePerDay + '/day'
                : item.details
            }}
          </td>
          <td>{{ item.materials | currencyFilter }}</td>
          <td v-if="doInstall">{{ item.install | currencyFilter }}</td>
          <td>{{ item.total | currencyFilter }}</td>
        </tr>
      </tbody>
      <tfoot class="addons-total-row" v-if="total || total === 0">
        <tr>
          <td>&emsp;</td>
          <td>&emsp;</td>
          <td v-if="doInstall">&emsp;</td>
          <td>Total:</td>
          <td>{{ total | currencyFilter }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AddOnItems',
  props: {
    addOns: {
      type: Array,
      required: true,
    },
    doInstall: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      showDetail: false,
    }
  },
  computed: {
    total() {
      let total = 0
      _.each(this.addOns, addOn => {
        total += parseFloat(addOn.total)
      })
      return total
    },
  },
}
</script>

<style lang="scss" scoped>
.addons {
  text-align: left;
}
.addons-table {
  width: 100%;
  text-align: left;
  font-size: 14px;

  thead {
    text-decoration: underline;
  }
}

.addons-total-row {
  font-weight: bold;
  border-top: 1px dashed darkslategray;
}
</style>
