<template>
  <div class="photo-release">
      <div class="page-one">
        <div class="page-one-header">
          <div class="logo">
            <img alt="MD logo" src="../assets/md-logo.png">
            <div class="address">
                6771 Eastside Rd Redding, CA 96001<br>
                (530)605-1931 PH (530)605-1938 Fax <br>
                www.metalsdirect.com <br>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="mt-3">
            <div class="permission-header">Permission to use</div>
            <div class="mt-3">
                I agree to allow Metals Direct Inc to any photo (or photos) of my project, likeness and or statements made in any and all publications, advertising, social advertising or communications necessary toward advertising their company. I understand that my personal information (address, phone number) will remain private and at no time be shared with a 3rd party.
            </div>
            <div class="mt-3">
                I understand that by signing this release these photos become the sole property of Metals Direct Inc as long as used for their intended purpose.
            </div>
            <div class="address">
              name: {{customerName}}<br><br>
              Street: {{building.customer.address.street1}}<br> 
              City: {{building.customer.address.city}}<br>
              Zip: {{building.customer.address.street1}}<br>
              Phone: {{building.customer.phone}}
            </div>
          </div>
          <div>
            <div class="mt-5">
                Your signature of permission: ____________________________________________  
            </div>
            <div class="mt-4">
                Date: ___________________________________________
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>

export default {
    name: 'PhotoRelease',
    props: {
        building: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {
          today: new Date(),
        }
    },
    computed: {
        customerName() {
            if (this.building.customer.lastName) {
                return this.building.customer.firstName + ' ' + this.building.customer.lastName;
            } else {
                return this.building.customer.email;
            }  
        }
    }
}
</script>

<style lang="scss" scoped>
  .underline {
    text-decoration: underline;
  }

    .photo-release {
      position: relative;
    }
 
    .page-content {
      text-align: left;
    }

    .address {
      padding: 18px;
      font-size: 18px;
    }

    .logo {
        text-align: center;
    }

    .permission-header {
        text-transform: uppercase;
        font-weight: bold;
    }

    @media print {
      .page-two,
      .page-three {
          page-break-before: always;
          }

      .photo-release {
        padding: 30px 30px 30px 30px;
      }

      .page-content {
        font-size: 20px;
      }

      .title {
        font-size: 42px;
      }
    }

</style>