<template>
  <div class="install-analysis page-wrapper">
    <h1 class="page-h1">Install Analysis</h1>
    <div v-if="loading">
      Loading ..
    </div>
    <div v-if="!loading">
      <table class="table">
        <thead>
          <td>Customer</td>
          <td>Type</td>
          <td>Created On</td>
          <td>Dimensions</td>
          <td>Price</td>
          <td>Kit Price</td>
          <td>Install Price</td>
          <td>9.50/sqft Min Install Price</td>
          <td>Install Diff</td>
          <td>Sqft</td>
          <td>Install/Sqft</td>
          <td>Sheets Sqft</td>
          <td>Install/Sheets Sqft</td>
          <td>Min Install/Sheets Sqft</td>
          <!-- <td>Deluxe Trim Materials</td>
                <td>Deluxe Trim Install</td>
                <td>Ridge Cap (materials/install)</td>
                <td>Gable (materials/install)</td>
                <td>J Trim (materials/install)</td>
                <td>Special Angle (materials/install)</td>
                <td>Corner Trim (materials/install)</td>
                <td>Custom Door Trim (materials/install)</td> -->
        </thead>
        <tbody v-if="!buildings.length">
          <div class="text-center mt-3 mb-3"><div class="loader"></div></div>
        </tbody>
        <tbody v-if="buildings.length">
          <tr
            v-for="(building, index) in buildings"
            :key="index"
            class="building-link"
          >
            <td class="text-left">{{ building.customer.email }}</td>
            <td>{{ building.type }}</td>
            <td>{{ building.createdAt | dateFilter }}</td>
            <td>{{ building.formattedDimensions }}</td>
            <td>{{ building.price.kitAndInstall.total | currencyFilter }}</td>
            <td>{{ building.price.kit.total | currencyFilter }}</td>
            <td>{{ building.installPrice | currencyFilter }}</td>
            <td>{{ (building.dimensions.sqft * 9.5) | currencyFilter }}</td>
            <td>
              {{
                (building.installPrice - building.dimensions.sqft * 9.5)
                  | currencyFilter
              }}
            </td>
            <td>{{ building.dimensions.sqft + 'sqft' }}</td>
            <td>{{ building.installSqft | currencyFilter }}</td>
            <td>{{ building.sheetsSqft + 'sqft' }}</td>
            <td>{{ building.installSheetsSqft | currencyFilter }}</td>
            <td>{{ building.minInstallSheetsSqft | currencyFilter }}</td>

            <!-- <td>{{building.deluxeTrimCost}}</td>
                <td>{{(building.deluxeTrimCost * 0.45) | currencyFilter }}</td>
                <td>{{building.deluxeTrimPackage.trimComponents[0].price | currencyFilter}} / {{(building.deluxeTrimPackage.trimComponents[0].price * 0.45) | currencyFilter}}</td>
                <td>{{building.deluxeTrimPackage.trimComponents[1].price | currencyFilter}} / {{(building.deluxeTrimPackage.trimComponents[1].price * 0.45) | currencyFilter}}</td>
                <td>{{building.deluxeTrimPackage.trimComponents[2].price | currencyFilter}} / {{(building.deluxeTrimPackage.trimComponents[2].price * 0.45) | currencyFilter}}</td>
                <td>{{building.deluxeTrimPackage.trimComponents[3].price | currencyFilter}} / {{(building.deluxeTrimPackage.trimComponents[3].price * 0.45) | currencyFilter}}</td>
                <td>{{building.deluxeTrimPackage.trimComponents[4].price | currencyFilter}} / {{(building.deluxeTrimPackage.trimComponents[4].price * 0.45) | currencyFilter}}</td>
                <td>{{building.deluxeTrimPackage.trimComponents[5].price | currencyFilter}} / {{(building.deluxeTrimPackage.trimComponents[5].price * 0.45) | currencyFilter}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import { userStore } from '@/stores/userStore'

export default {
  name: 'InstallAnalysis',
  components: {},
  inject: {
    userStore,
  },
  data: function() {
    return {
      priceList: null,
      loading: true,
      buildings: null,
    }
  },
  methods: {
    fetchBuildings() {
      return axios
        .get('/services/buildings/install-analysis')
        .then(response => {
          console.log('response: ', response)
          this.buildings = response.data.buildings
          _.each(this.buildings, building => {
            building.formattedDimensions = this.formatDimensions(
              building.dimensions,
            )
            building.sheetsSqft =
              building.dimensions.eaveWallsSqft +
              building.dimensions.gableWallsSqft +
              building.dimensions.roofSqft
            building.installPrice = _.round(
              building.price.kitAndInstall.total - building.price.kit.total,
              2,
            )
            building.installSqft = _.round(
              building.installPrice / building.dimensions.sqft,
              2,
            )
            building.installSheetsSqft = _.round(
              building.installPrice / building.sheetsSqft,
              2,
            )
            building.minInstallSheetsSqft = _.round(
              (building.dimensions.sqft * 9.5) / building.sheetsSqft,
              2,
            )
            building.deluxeTrimCost = _.round(
              building.deluxeTrimPackage.trimComponents[0].price +
                building.deluxeTrimPackage.trimComponents[1].price +
                building.deluxeTrimPackage.trimComponents[2].price +
                building.deluxeTrimPackage.trimComponents[3].price +
                building.deluxeTrimPackage.trimComponents[4].price +
                building.deluxeTrimPackage.trimComponents[5].price,
              2,
            )
          })
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDimensions(dimensions) {
      return (
        dimensions.width + 'x' + dimensions.length + 'x' + dimensions.height
      )
    },
  },
  computed: {},
  mounted() {
    this.fetchBuildings()
  },
}
</script>

<style lang="scss" scoped></style>
