<template>
  <div>
    <div class="loading" v-if="loading">
      <Circle-Loader :size="36" class="mt-5"></Circle-Loader>
    </div>
    <div class="schedule" v-if="!loading">
      <div>
        <b-table
          :tbody-tr-class="rowClass"
          :items="scheduleList"
          :fields="fields"
          @row-clicked="confirmEditScheduleDate"
        >
          <template #cell(dateTime)="row">
            <span v-if="row.item.dateTime">
              <span v-if="row.item.allDayEvent">
                {{ row.item.dateTime | shortDateFilter }}
              </span>
              <span v-if="!row.item.allDayEvent">
                <span v-if="row.item.startTime">
                  {{ row.item.dateTime | shortDateFilter }}
                  {{ row.item.startTime | shortTimeFilter }}
                </span>
                <span v-if="!row.item.startTime">
                  {{ row.item.dateTime | shortDateTimeFilter }}
                </span>
              </span>
            </span>
            <span v-if="!row.item.dateTime">
              <b-button
                size="sm"
                variant="info"
                @click="confirmEditScheduleDate(row.item)"
                >Set Date</b-button
              >
            </span>
          </template>
          <template #cell(completed)="row">
            <span v-if="row.item.completed" class="nc-icon nc-check-2"> </span>
          </template>
        </b-table>
        <b-button variant="success" size="sm" @click="confirmAddScheduleDate()"
          >+ Add Schedule Date</b-button
        >
      </div>

      <!-- Set/Update Schedule Date -->

      <b-modal
        :title="`Edit Schedule Date`"
        id="confirmEditScheduleDate"
        size="lg"
        hide-footer
      >
        <form v-if="currentScheduleDate" @submit.prevent="setScheduleDate">
          <schedule-date-form-fields
            :scheduleDate="currentScheduleDate"
            :submitting="submitting"
          ></schedule-date-form-fields>

          <div class="modal-ctas mt-4">
            <b-button
              variant="primary"
              :disabled="submittingSetScheduleDate"
              type="submit"
            >
              <span v-if="submittingSetScheduleDate">Saving..</span>
              <span v-if="!submittingSetScheduleDate">Save</span>
            </b-button>
            <b-button
              variant="secondary"
              type="button"
              @click="$bvModal.hide('confirmEditScheduleDate')"
              :disabled="submittingSetScheduleDate"
              class="ml-2"
              >Cancel</b-button
            >
            <b-button
              variant="danger"
              type="button"
              @click="$bvModal.show('confirmDeleteScheduleDate')"
              :disabled="submittingSetScheduleDate"
              class="delete-btn"
              >Delete</b-button
            >
          </div>
        </form>
      </b-modal>

      <!-- Add Date To List -->
      <b-modal
        title="Add Schedule Date"
        hide-footer
        size="lg"
        id="confirmAddScheduleDate"
      >
        <form @submit.prevent="addDateToSchedule" v-if="currentScheduleDate">
          <schedule-date-form-fields
            :scheduleDate="currentScheduleDate"
            :submitting="submitting"
          ></schedule-date-form-fields>
          <div class="modal-ctas mt-4">
            <b-button
              variant="primary"
              :disabled="submittingAddDateToSchedule"
              type="submit"
            >
              <span v-if="submittingAddDateToSchedule">Confirming..</span>
              <span v-if="!submittingAddDateToSchedule">Confirm</span>
            </b-button>
            <b-button
              variant="secondary"
              @click="$bvModal.hide('confirmAddScheduleDate')"
              :disabled="submittingAddDateToSchedule"
              class="ml-2"
              >Cancel</b-button
            >
          </div>
        </form>
      </b-modal>

      <!-- delete schedule modal -->
      <b-modal
        title="Delete Schedule"
        id="confirmDeleteScheduleDate"
        hide-footer
      >
        <div class="" v-if="currentScheduleDate">
          <div>
            <strong>{{ currentScheduleDate.name }}</strong>
          </div>

          Are you sure you want to delete this schedule date?
          <div class="detail-modal-ctas mt-5">
            <b-button
              variant="primary"
              @click="$bvModal.hide('confirmDeleteScheduleDate')"
              :disabled="submittingRemoveScheduleDate"
              class="ml-2"
              >Cancel</b-button
            >
            <b-button
              variant="danger"
              @click="deleteScheduleDate()"
              :disabled="submittingRemoveScheduleDate"
            >
              <span v-if="submittingRemoveScheduleDate">Deleting..</span>
              <span v-if="!submittingRemoveScheduleDate"
                >Delete Schedule Date</span
              >
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import ScheduleDateFormFields from './ScheduleDateFormFields.vue'

export default {
  name: 'VendorSchedule',
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  inject: ['userStore'],
  components: { ScheduleDateFormFields },
  data: function () {
    return {
      submitting: false,
      loading: true,
      building: null,
      currentScheduleDate: null,
      newScheduleDateTime: '',
      newMemo: '',
      newScheduleDateName: '',
      newAssignedTo: '',
      newCompleted: false,
      submittingSetScheduleDate: false,
      submittingRemoveScheduleDate: false,
      submittingAddDateToSchedule: false,
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'dateTime',
          sortable: true,
          label: 'Date Time',
        },
        {
          key: 'assignedTo',
          label: 'Assigned To',
        },
        {
          key: 'completed',
        },
      ],
    }
  },
  computed: {
    scheduleList() {
      let list = []
      if (this.vendor.schedule) {
        this.vendor.schedule.forEach(scheduleDate => {
          list.push(scheduleDate)
        })
      }

      return list
    },
  },
  methods: {
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.name !== 'Total') {
          return 'clickable-row'
        } else {
          return null
        }
      } else {
        return null
      }
    },
    async addDateToSchedule() {
      this.submittingAddDateToSchedule = true

      const data = {
        id: this.vendor.id,
        scheduleDate: this.currentScheduleDate,
      }
      return axios
        .put('/services/vendors/add-schedule-date', data)
        .then(response => {
          this.vendor.schedule = response.data.schedule
          this.$toasted.success(`Added date to schedule`, {
            duration: 2500,
          })
          this.$bvModal.hide('confirmAddScheduleDate')
        })
        .catch(error => {
          this.$toasted.error(` Error saving schedule`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submittingAddDateToSchedule = false
        })
    },
    setScheduleDate() {
      const data = {
        id: this.vendor.id,
        scheduleDate: this.currentScheduleDate,
      }
      this.submittingSetScheduleDate = true
      return axios
        .put('/services/vendors/save-schedule-date', data)
        .then(response => {
          this.vendor.schedule = response.data.schedule
          this.$bvModal.hide('confirmEditScheduleDate')
          this.$toasted.success(`Saved Schedule`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error saving schedule`, {
            action: {
              text: 'Close',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
          })
        })
        .finally(() => {
          this.submittingSetScheduleDate = false
        })
    },
    deleteScheduleDate() {
      const data = {
        id: this.vendor.id,
        scheduleDate: this.currentScheduleDate,
      }
      this.submittingRemoveScheduleDate = true
      return axios
        .put('/services/vendors/remove-schedule-date', data)
        .then(response => {
          this.vendor.schedule = response.data.schedule
          this.$bvModal.hide('confirmDeleteScheduleDate')
          this.$bvModal.hide('confirmEditScheduleDate')
          this.$toasted.success(`Removed Schedule`, {
            duration: 2500,
          })
        })
        .catch(error => {
          this.$toasted.error(`Error removing schedule`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.submittingRemoveScheduleDate = false
        })
    },
    confirmEditScheduleDate(ogScheduleDate) {
      const scheduleDate = _.clone(ogScheduleDate)
      if (scheduleDate.dateTime) {
        scheduleDate.dateTime = moment(scheduleDate.dateTime).toDate()
      }
      if (scheduleDate.endDateTime) {
        scheduleDate.endDateTime = moment(scheduleDate.endDateTime).toDate()
      }

      this.currentScheduleDate = scheduleDate
      this.$bvModal.show('confirmEditScheduleDate')
    },
    confirmAddScheduleDate() {
      this.currentScheduleDate = {
        name: '',
        dateTime: null,
        endDateTime: null,
        startTime: '',
        allDayEvent: false,
        endTime: '',
        assignedTo: '',
        completed: false,
        memo: '',
        referenceObject: '',
        referenceId: '',
      }
      this.$bvModal.show('confirmAddScheduleDate')
    },
    confirmDeleteScheduleDate(item) {
      this.currentItem = item
      this.$bvModal.hide('scheduleDetailView')
      this.$bvModal.show('confirmDeleteScheduleDate')
    },
  },
  async mounted() {
    this.vendor.schedule = this.vendor.schedule || []
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
.detail-modal-ctas {
  display: flex;
  justify-content: space-between;
}

.delete-btn {
  position: absolute;
  right: 12px;
}
</style>
