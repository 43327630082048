import './compositionAPI'
import { reactive, readonly, computed } from '@vue/composition-api'
import _ from 'lodash'

const state = reactive({
  tasks: [],
  count: 0,
})

function addTasks(dates) {
  state.tasks = []
  _.each(dates, task => {
    addTask(task)
  })
}

function addTask(task) {
  state.tasks.push(task)
  state.count = state.tasks.length
}

function deleteTask(task) {
  console.log('calling delete task with task: ', task)
  _.remove(state.tasks, t => {
    return task?.id === t.id
  })
  state.count = state.tasks.length
  console.log('tasks deleted, tsaks is now ... state.tasks', state.tasks)
}

export const taskStore = readonly({
  state,
  addTasks,
  addTask,
  deleteTask,
})
