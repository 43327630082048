<template>
  <div class="contractor-profile"></div>
</template>

<script>
export default {
  components: {},
  name: 'ContractorProfileFormFields',
  props: {
    profile: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
