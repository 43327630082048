<template>
  <div>
    <div class="tasks-detail-page p-3">
      <div class="mt-1">
        <h3>
          Task Detail
          <span v-if="task"> - {{ task.name }}</span>
        </h3>
        <div v-if="loading">
          <circle-loader :size="36"></circle-loader>
        </div>
        <div v-if="!loading && task">
          <task-detail
            :modal-id="modalId.toString()"
            :og-task="task"
          ></task-detail>
        </div>
        .. task detail ..
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TaskDetail from '../components/TaskDetail.vue'
import _ from 'lodash'
import CircleLoader from '../components/CircleLoader.vue'

export default {
  name: 'TaskDetailPage',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: function () {
    const modalId = _.random(0, 9999999)

    return {
      task: null,
      loading: true,
      modalId,
    }
  },
  components: { TaskDetail, CircleLoader },
  mounted() {
    this.fetchTask()
  },
  methods: {
    fetchTask() {
      return axios
        .get('/services/tasks/' + this.id)
        .then(response => {
          //   this.taskStore.deleteTask(response.data.task)
          //   this.taskStore.addTask(response.data.task)
          //   this.task = _.clone(response.data.task)
          this.task = response.data.task
        })
        .catch(err => {
          console.log('err', err)
          this.$toasted.error(`Error Loading Task`, {
            duration: 2500,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.tasks-detail-page {
  text-align: left;
}
.order-summary {
  font-size: 20px;
}

.notes-list {
  min-height: 200px;
  max-height: 300px;
  overflow: scroll;
}

.timeline {
  // width: 95%;
}
</style>

//// todo: 1. add tasks list component and show on differnt screens 2. add table
of # of tasks due today on dashboard 3. maybe add 1 example of a task that can
be blocked? 4. consider doing a flow for materials first since it's wayh simpler
5. mock out manager override modal 6. alert on dashboard if current user has
tasks or overrides due now
