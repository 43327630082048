export const rollDoorModels = {
  '8x8': '1950',
  '8x9': '1950',
  '8x10': '1950',
  '8x12': '1950',
  '8x14': '1950',
  '9x8': '1950',
  '9x9': '1950',
  '9x10': '1950',
  '9x12': '1950',
  '9x14': '1950',
  '10x8': '1950',
  '10x9': '1950',
  '10x10': '1950',
  '10x12': '1950',
  '10x14': '1950',
  '12x8': '1950',
  '12x9': '1950',
  '12x10': '1950',
  '12x12': '1950',
  '12x14': '1950',
  '8x16': '2000',
  '9x16': '2000',
  '10x16': '2000',
  '12x16': '2000',
  '14x8': '3100',
  '14x9': '3100',
  '14x10': '3100',
  '14x12': '3100',
  '14x14': '3100',
  '14x16': '2500',
  '16x8': '3100',
  '16x9': '3100',
  '16x10': '3100',
  '16x12': '3100',
  '16x14': '3100',
  '18x8': '3100',
  '18x9': '3100',
  '18x10': '3100',
  '18x12': '3100',
  '18x14': '3100',
  '20x8': '3100',
  '20x9': '3100',
  '20x10': '3100',
  '20x12': '3100',
  '20x14': '3100',
}

export const shedDoorModels = {
  "3'0x6'8": '650',
  "3'0x7'0": '650',
  "3'0x7'4": '650',
  "3'0x8'0": '650',
  "3'4x6'8": '650',
  "3'4x7'0": '650',
  "3'4x7'4": '650',
  "3'4x8'0": '650',
  "3'6x6'8": '650',
  "3'6x7'0": '650',
  "3'6x7'4": '650',
  "3'6x8'0": '650',
  "3'8x6'8": '650',
  "3'8x7'0": '650',
  "3'8x7'4": '650',
  "3'8x8'0": '650',
  "4'0x6'8": '650',
  "4'0x7'0": '650',
  "4'0x7'4": '650',
  "4'0x8'0": '650',
  "5'0x6'8": '650',
  "5'0x7'0": '650',
  "5'0x7'4": '650',
  "5'0x8'0": '650',
  "5'6x6'8": '650',
  "5'6x7'0": '650',
  "5'6x8'0": '650',
  "6'0x6'8": '650',
  "6'0x7'0": '650',
  "6'0x7'4": '650',
  "6'0x8'0": '650',
  "7'0x6'8": '650',
  "7'0x7'0": '650',
  "7'0x7'4": '650',
  "7'0x8'0": '650',
  "8'0x6'8": '650',
  "8'0x7'0": '650',
  "8'0x7'4": '650',
  "8'0x8'0": '650',
  "8'0x9'0": '650',
  "8'8x6'8": '650',
  "8'8x7'0": '650',
  "8'8x7'4": '650',
  "8'8x8'0": '650',
  "8'8x9'0": '650',
  "9'0x6'8": '650',
  "9'0x7'0": '650',
  "9'0x7'4": '650',
  "9'0x8'0": '650',
  "9'0x9'0": '650',
  "9'0x10'0": '650',
  "10'0x7'0": '650',
  "10'0x8'0": '650',
  "10'0x9'0": '650',
  "10'0x10'0": '650',
}

export const rollDoorModelsWithInches = {
  "8'0x8'0": '1950',
  "8'0x9'0": '1950',
  "8'0x10'0": '1950',
  "8'0x12'0": '1950',
  "8'0x14'0": '1950',
  "9'0x8'0": '1950',
  "9'0x9'0": '1950',
  "9'0x10'0": '1950',
  "9'0x12'0": '1950',
  "9'0x14'0": '1950',
  "10'0x8'0": '1950',
  "10'0x9'0": '1950',
  "10'0x10'0": '1950',
  "10'0x12'0": '1950',
  "10'0x14'0": '1950',
  "12'0x8'0": '1950',
  "12'0x9'0": '1950',
  "12'0x10'0": '1950',
  "12'0x12'0": '1950',
  "12'0x14'0": '1950',
  "8'0x16'0": '2000',
  "9'0x16'0": '2000',
  "10'0x16'0": '2000',
  "12'0x16'0": '2000',
  "14'0x8'0": '3100',
  "14'0x9'0": '3100',
  "14'0x10'0": '3100',
  "14'0x12'0": '3100',
  "14'0x14'0": '3100',
  "14'0x16'0": '2500',
  "16'0x8'0": '3100',
  "16'0x9'0": '3100',
  "16'0x10'0": '3100',
  "16'0x12'0": '3100',
  "16'0x14'0": '3100',
  "18'0x8'0": '3100',
  "18'0x9'0": '3100',
  "18'0x10'0": '3100',
  "18'0x12'0": '3100',
  "18'0x14'0": '3100',
  "20'0x8'0": '3100',
  "20'0x9'0": '3100',
  "20'0x10'0": '3100',
  "20'0x12'0": '3100',
  "20'0x14'0": '3100',
}

export const manDoorModels = {
  "3'0x6'8": '95 Series',
}

export const windowModels = [
  { text: 'Select Model', value: null },
  { text: 'With Grid', value: 'With Grid' },
  { text: 'Without Grid', value: 'Without Grid' },
]

export const windowSizes = {
  "3'0x3'0": true,
}
