<template>
  <div class="calendar-page page-wrapper">
    <h1 class="page-h1">Calendar</h1>
    <div class="legend">
      <div class="row">
        <div
          class="col-4"
          @click="
            scheduleDateStore.calendarFilter.vendor =
              !scheduleDateStore.calendarFilter.vendor
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.vendor }"
            class="legend-color vendor"
          ></span
          ><span class="legend-label">Vendor Order/Delivery</span>
        </div>
        <div
          class="col"
          @click="
            scheduleDateStore.calendarFilter.delivery =
              !scheduleDateStore.calendarFilter.delivery
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.delivery }"
            class="legend-color delivery"
          ></span
          ><span class="legend-label">Delivery</span>
        </div>
        <div
          class="col"
          @click="
            scheduleDateStore.calendarFilter.install =
              !scheduleDateStore.calendarFilter.install
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.install }"
            class="legend-color install"
          ></span
          ><span class="legend-label">Install</span>
        </div>
        <div class="col">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="$bvModal.show('incoming-payments-modal')"
            >Incoming $</b-button
          >
        </div>
      </div>
      <div class="row">
        <div
          class="col-4"
          @click="
            scheduleDateStore.calendarFilter.holiday =
              !scheduleDateStore.calendarFilter.holiday
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.holiday }"
            class="legend-color holiday"
          ></span
          ><span class="legend-label">Holiday/Time Off</span>
        </div>
        <div
          class="col"
          @click="
            scheduleDateStore.calendarFilter.offsite =
              !scheduleDateStore.calendarFilter.offsite
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.offsite }"
            class="legend-color offsite"
          ></span
          ><span class="legend-label">Offsite</span>
        </div>
        <div
          class="col"
          @click="
            scheduleDateStore.calendarFilter.concrete =
              !scheduleDateStore.calendarFilter.concrete
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.concrete }"
            class="legend-color concrete"
          ></span
          ><span class="legend-label">Concrete</span>
        </div>
        <div
          class="col"
          @click="
            scheduleDateStore.calendarFilter.inspection =
              !scheduleDateStore.calendarFilter.inspection
          "
        >
          <span
            :class="{ enabled: scheduleDateStore.calendarFilter.inspection }"
            class="legend-color inspection"
          ></span
          ><span class="legend-label">Inspection</span>
        </div>
      </div>
    </div>
    <b-button
      class="add-date-btn"
      @click="$bvModal.show('add-schedule-date-modal')"
      variant="info"
    >
      + Date
    </b-button>
    <div class="loader" v-if="loading"><circle-loader :size="40" /></div>
    <OrderCalendar
      v-if="!loading"
      :orders="currentOrders"
      :calendarData="calendarData"
      :recurringDates="filteredRecurringDates"
    ></OrderCalendar>
    <b-modal
      id="add-schedule-date-modal"
      title="Add Calendar Event"
      hide-footer
      size="lg"
    >
      <add-schedule-date></add-schedule-date>
    </b-modal>
    <b-modal
      id="incoming-payments-modal"
      title="Incoming Payments"
      hide-footer
      size="lg"
      v-if="currentOrders && currentOrders.length"
    >
      <incoming-payments :currentOrders="currentOrders"></incoming-payments>
    </b-modal>
  </div>
</template>

<script>
import OrderCalendar from '@/components/OrderCalendar'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import AddScheduleDate from '../components/AddScheduleDate.vue'
import IncomingPayments from '../components/IncomingPayments.vue'
import { scheduleDateStore } from '../stores/scheduleDateStore'
import CircleLoader from '../components/CircleLoader.vue'
const recurringDatesTemplate = {
  weekly: [[], [], [], [], [], [], []],
  monthly: [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ],
}

export default {
  name: 'OrderCalendarPage',
  data() {
    return {
      currentOrders: [],
      customers: [],
      tradeShows: [],
      vendors: [],
      recurringDates: _.cloneDeep(recurringDatesTemplate),
      calendarEvents: [],
      newScheduleDate: {},
      submittingScheduleDate: {},
      loading: true,
      scheduleDateStore,
      scheduleDates: [],
    }
  },
  components: {
    IncomingPayments,
    OrderCalendar,
    AddScheduleDate,
    CircleLoader,
  },
  computed: {
    filteredRecurringDates() {
      if (this.scheduleDateStore.calendarFilter.vendor) {
        return this.recurringDates
      } else {
        return _.cloneDeep(recurringDatesTemplate)
      }
    },
    calendarData() {
      const calendarData = {}
      // eslint-disable-next-line
      this.scheduleDateStore.state.count
      _.each(this.scheduleDateStore.state.scheduleDates, sd => {
        if (sd.dateTime && this.includedInFilters(sd)) {
          let dateTime = moment(sd.dateTime)
          let endDateTime = sd.endDateTime
            ? moment(sd.endDateTime)
            : moment(sd.dateTime)
          let key = dateTime.format('MM/DD/YYYY')
          if (!sd.allDayEvent) {
            dateTime.set('hour', parseInt(sd.startTime.split(':')[0]))
            dateTime.set('minute', parseInt(sd.startTime.split(':')[1]))
          }
          calendarData[key] = calendarData[key] || []
          let newData = {
            ...sd,
            dateTime,
            endDateTime,
            ogDateTime: dateTime.clone(),
          }
          if (endDateTime.isAfter(dateTime, 'days')) {
            newData.multiDayEvent = true
            newData.firstOfMultiDay = true
          }
          calendarData[key].push(newData)
          if (endDateTime.isAfter(dateTime, 'days')) {
            this.padBeginningOfWeek(
              dateTime.clone().subtract(1, 'days'),
              calendarData,
              { ...newData },
            )
            this.copyDate(
              dateTime.add(1, 'days'),
              endDateTime,
              calendarData,
              newData,
            )
          }
        }
      })

      _.each(this.currentOrders, order => {
        _.each(order.schedule, sd => {
          if (sd.dateTime && this.includedInFilters(sd)) {
            let dateTime = moment(sd.dateTime)
            let endDateTime = sd.endDateTime
              ? moment(sd.endDateTime)
              : moment(sd.dateTime)
            if (sd.startTime) {
              dateTime.set('hours', sd.startTime.split(':')[0])
              dateTime.set('minutes', sd.startTime.split(':')[1])
            }
            let key = dateTime.format('MM/DD/YYYY')
            calendarData[key] = calendarData[key] || []
            let newData = {
              ...sd,
              dateTime,
              endDateTime,
              order,
              ogDateTime: dateTime.clone(),
            }
            if (endDateTime.isAfter(dateTime, 'days')) {
              newData.multiDayEvent = true
              newData.firstOfMultiDay = true
            }
            calendarData[key].push(newData)
            if (endDateTime.isAfter(dateTime, 'days')) {
              this.padBeginningOfWeek(
                dateTime.clone().subtract(1, 'days'),
                calendarData,
                { ...newData },
              )
              this.copyDate(
                dateTime.add(1, 'days'),
                endDateTime,
                calendarData,
                { ...newData, firstOfMultiDay: false },
              )
            }
          }
        })
      })

      _.each(this.customers, customer => {
        _.each(customer.schedule, sd => {
          if (sd.dateTime) {
            let dateTime = moment(sd.dateTime)
            let endDateTime = sd.endDateTime
              ? moment(sd.endDateTime)
              : moment(sd.dateTime)
            if (sd.startTime) {
              dateTime.set('hours', sd.startTime.split(':')[0])
              dateTime.set('minutes', sd.startTime.split(':')[1])
            }
            let key = dateTime.format('MM/DD/YYYY')
            calendarData[key] = calendarData[key] || []
            let newData = {
              ...sd,
              dateTime,
              endDateTime,
              customer,
              ogDateTime: dateTime.clone(),
            }
            if (endDateTime.isAfter(dateTime, 'days')) {
              newData.multiDayEvent = true
              newData.firstOfMultiDay = true
            }
            calendarData[key].push(newData)

            // push additional days...
            if (endDateTime.isAfter(dateTime, 'days')) {
              this.padBeginningOfWeek(
                dateTime.clone().subtract(1, 'days'),
                calendarData,
                { ...newData },
              )
              this.copyDate(
                dateTime.add(1, 'days'),
                endDateTime,
                calendarData,
                { ...newData, firstOfMultiDay: false },
                false,
              )
            }
          }
        })
      })

      _.each(this.tradeShows, tradeShow => {
        _.each(tradeShow.schedule, sd => {
          if (sd.dateTime) {
            let dateTime = moment(sd.dateTime)
            let endDateTime = sd.endDateTime
              ? moment(sd.endDateTime)
              : moment(sd.dateTime)
            if (sd.startTime) {
              dateTime.set('hours', sd.startTime.split(':')[0])
              dateTime.set('minutes', sd.startTime.split(':')[1])
            }
            let key = dateTime.format('MM/DD/YYYY')
            calendarData[key] = calendarData[key] || []
            let newData = {
              ...sd,
              dateTime,
              endDateTime,
              tradeShow,
              ogDateTime: dateTime.clone(),
            }
            if (endDateTime.isAfter(dateTime, 'days')) {
              newData.multiDayEvent = true
              newData.firstOfMultiDay = true
            }
            calendarData[key].push(newData)
            if (endDateTime.isAfter(dateTime, 'days')) {
              this.padBeginningOfWeek(
                dateTime.clone().subtract(1, 'days'),
                calendarData,
                { ...newData },
              )
              this.copyDate(
                dateTime.add(1, 'days'),
                endDateTime,
                calendarData,
                { ...newData, firstOfMultiDay: false },
              )
            }
          }
        })
      })

      _.each(this.vendors, vendor => {
        _.each(vendor.schedule, sd => {
          if (sd.dateTime) {
            let dateTime = moment(sd.dateTime)
            let endDateTime = sd.endDateTime
              ? moment(sd.endDateTime)
              : moment(sd.dateTime)
            if (sd.startTime) {
              dateTime.set('hours', sd.startTime.split(':')[0])
              dateTime.set('minutes', sd.startTime.split(':')[1])
            }
            let key = dateTime.format('MM/DD/YYYY')
            calendarData[key] = calendarData[key] || []
            let newData = {
              ...sd,
              dateTime,
              endDateTime,
              vendor,
            }
            if (endDateTime.isAfter(dateTime, 'days')) {
              newData.multiDayEvent = true
              newData.firstOfMultiDay = true
            }
            calendarData[key].push(newData)

            // push additional days...
            if (endDateTime.isAfter(dateTime, 'days')) {
              this.padBeginningOfWeek(
                dateTime.clone().subtract(1, 'days'),
                calendarData,
                { ...newData },
              )
              this.copyDate(
                dateTime.clone().add(1, 'days'),
                endDateTime,
                calendarData,
                { ...newData, firstOfMultiDay: false },
              )
            }
          }
        })
      })
      return calendarData
    },
  },
  methods: {
    includedInFilters(date) {
      if (
        !this.scheduleDateStore.calendarFilter.delivery &&
        date?.name?.toLowerCase().match('delivery')
      ) {
        return false
      }
      if (
        !this.scheduleDateStore.calendarFilter.holiday &&
        (date.name.toLowerCase().match('pto') ||
          date.name.toLowerCase().match('vacation') ||
          date.name.toLowerCase().match('holiday') ||
          date.name.toLowerCase().match('dentist') ||
          date.name.toLowerCase().match('doctor'))
      ) {
        return false
      }
      if (
        !this.scheduleDateStore.calendarFilter.offsite &&
        (date.customer || date.name.toLowerCase().match('offsite'))
      ) {
        return false
      }
      if (
        !this.scheduleDateStore.calendarFilter.install &&
        (date.name.toLowerCase().match('framing') ||
          date.name.toLowerCase().match('sheeting') ||
          date.name.toLowerCase().match('insulation'))
      ) {
        return false
      }
      if (
        !this.scheduleDateStore.calendarFilter.inspection &&
        date.name.toLowerCase().match('inspection')
      ) {
        return false
      }
      if (
        !this.scheduleDateStore.calendarFilter.concrete &&
        date.name.toLowerCase().match('concrete')
      ) {
        return false
      }

      return true
    },
    padBeginningOfWeek(dateTime, calendarData, newData) {
      if (dateTime.day() !== 0) {
        let key = dateTime.format('MM/DD/YYYY')
        if (newData.name === 'first week of july') {
          console.log('--------.> pushing to calendar on day ', key, newData)
        }
        calendarData[key] = calendarData[key] || []

        calendarData[key].push({
          ...newData,
          lastOfMultiDay: false,
          firstOfMultiDay: false,
          hideDate: true,
        })
        this.padBeginningOfWeek(
          dateTime.clone().subtract(1, 'days'),
          calendarData,
          { ...newData },
        )
      }
    },
    copyDate(dateTime, endDateTime, calendarData, newData, hideDate = false) {
      // add check IF day isn't weekend...
      // Maybe pass in whether or not to include weekends? should we allow weekends for normal schedule dates?
      if (dateTime.day() !== 6 && dateTime.day() !== 0) {
        let key = dateTime.format('MM/DD/YYYY')
        let isLastDay = false
        calendarData[key] = calendarData[key] || []
        if (endDateTime.isSame(dateTime, 'days')) {
          // newData.lastOfMultiDay = true
          isLastDay = true
        }
        calendarData[key].push({
          ...newData,
          lastOfMultiDay: isLastDay,
          firstOfMultiDay: false,
          hideDate: hideDate,
        })
      }

      if (endDateTime.isAfter(dateTime, 'days')) {
        this.copyDate(
          dateTime.add(1, 'days'),
          endDateTime,
          calendarData,
          newData,
        )
      } else if (dateTime.day() !== 6) {
        // padding end of week.
        this.copyDate(
          dateTime.add(1, 'days'),
          endDateTime,
          calendarData,
          newData,
          true,
        )
      }
    },
    getScheduleDates() {
      return axios
        .get('/services/schedule-dates/lookup')
        .then(response => {
          this.scheduleDateStore.addScheduleDates(response.data.scheduleDates)
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    getCurrentOrders() {
      return axios
        .get('/services/orders/calendar')
        .then(response => {
          this.currentOrders = response.data.orders
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },

    getCurrentCustomers() {
      return axios
        .get('/services/customers/calendar')
        .then(response => {
          this.customers = response.data.customers
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    getCurrentTradeShows() {
      return axios
        .get('/services/trade-shows/calendar')
        .then(response => {
          this.tradeShows = response.data.tradeShows
        })
        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
    getCurrentVendors() {
      return axios
        .get('/services/vendors/calendar')
        .then(response => {
          // adds schedule dates from vendors
          this.vendors = response.data.vendors

          // maps recurring order/delivery days from vendors to recurringDates
          _.each(response.data.vendorDaysOfWeek, vendor => {
            if (vendor.profile.orderDaysOfWeek) {
              _.each(vendor.profile.orderDaysOfWeek, dayOfWeek => {
                this.recurringDates.weekly[dayOfWeek].push({
                  name: `${_.startCase(vendor.name)} Order`,
                  icon: 'nc-icon nc-tag-content',
                  allDayEvent: vendor.profile.orderTimeOfDay ? false : true,
                  recurringVendorDay: true,
                  vendorId: vendor.id,
                  time: vendor.profile.orderTimeOfDay,
                })
              })
            }
            if (vendor.profile.deliveryDaysOfWeek) {
              _.each(vendor.profile.deliveryDaysOfWeek, dayOfWeek => {
                this.recurringDates.weekly[dayOfWeek].push({
                  name: `${_.startCase(vendor.name)} Delivery`,
                  icon: 'nc-icon nc-delivery-fast',
                  allDayEvent: vendor.profile.deliveryTimeOfDay ? false : true,
                  recurringVendorDay: true,
                  vendorId: vendor.id,
                  time: vendor.profile.deliveryTimeOfDay,
                })
              })
            }
          })
        })

        .catch(err => {
          console.log('err', err)
        })
        .finally(() => {})
    },
  },
  mounted() {
    Promise.all([
      this.getCurrentOrders(),
      this.getCurrentCustomers(),
      this.getCurrentVendors(),
      this.getCurrentTradeShows(),
      this.getScheduleDates(),
    ]).finally(() => (this.loading = false))
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.page-wrapper {
  position: relative;
}
.add-date-btn {
  position: absolute;
  top: 66px;
  right: 210px;
  z-index: 1;
}

.legend {
  position: absolute;
  top: 66px;
  right: 380px;
  z-index: 1;
  width: 580px;
  font-size: 14px;
}

.legend-color {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 4px;
  vertical-align: middle;
  border: 2px solid;
  border-radius: 4px;

  cursor: pointer;
}

.legend-label {
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
}

.vendor {
  border-color: $vendor-delivery-color;

  &:hover,
  &.enabled {
    background-color: $vendor-delivery-color;
  }
}

.delivery {
  border-color: $delivery-color;

  &:hover,
  &.enabled {
    background-color: $delivery-color;
  }
}

.offsite {
  border-color: $offsite-color;

  &:hover,
  &.enabled {
    background-color: $offsite-color;
  }
}

.holiday {
  border-color: $holiday-color;

  &:hover,
  &.enabled {
    background-color: $holiday-color;
  }
}
.install {
  border-color: $install-color;

  &:hover,
  &.enabled {
    background-color: $install-color;
  }
}
.concrete {
  border-color: $concrete-color;

  &:hover,
  &.enabled {
    background-color: $concrete-color;
  }
}
.inspection {
  border-color: $inspection-color;

  &:hover,
  &.enabled {
    background-color: $inspection-color;
  }
}

.loader {
  margin-top: 200px;
  text-align: center;
}
</style>
